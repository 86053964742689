import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import NumberFormat, { NumberFormatValues } from "react-number-format";
// asset
import InfoIcon from "../../asset/icon/info.svg";
import AlertIcon from "../../asset/icon/alert.svg";
import OpenEyeIcon from "../../asset/icon/eye-open.svg";
import CloseEyeIcon from "../../asset/icon/eye-closed.svg";

// constant
import { colors } from "../../constant/theme";
import theme from "../../constant/styledTheme";

type TextInputProps = {
  allowLeadingZeros?: boolean;
  allowNegative?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  inputLabel?: string;
  isError?: boolean;
  label?: string;
  placeholder?: string;
  subtitle?: string;
  subtitleType?: "error";
  textAlign?: "center" | "left" | "right";
  type?: "number" | "text" | "password";
  unitLabel?: string | null;
  value?: string | number;
  width?: number;
  fullWidth?: boolean;
  decimalScale?: number;
  hidePlaceholder?: boolean;
  disableNumberFormatting?: boolean;
  numberValidation?: (value: NumberFormatValues) => boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (text: string) => void;
};

const TextInput = (props: TextInputProps) => {
  const {
    allowLeadingZeros,
    allowNegative,
    autoFocus,
    disabled,
    label,
    placeholder,
    value,
    type,
    textAlign,
    inputLabel,
    subtitle,
    isError,
    subtitleType,
    unitLabel,
    width,
    decimalScale,
    fullWidth,
    hidePlaceholder,
    disableNumberFormatting,
    numberValidation,
    onBlur,
    onChange,
  } = props;

  const [visible, setVisible] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const { value } = e.target;
      onChange(value);
    }
    return;
  };

  const handleNumberValueChange = (values: NumberFormatValues) => {
    const { value } = values;

    if (onChange) {
      onChange(value);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (onBlur) {
      onBlur!(e);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (+e.target.value === 0) {
      e.target.value = undefined || "";
    }
  };

  const getInputType = useMemo(() => {
    if (type === "password" && visible) {
      return "text";
    }

    if (type === "password" && !visible) {
      return "password";
    }

    return type;
  }, [visible, type]);

  return (
    <Container isError={!!isError} width={width} fullWidth={!!fullWidth}>
      {label ? <div className="input-label">{label}</div> : null}
      <TextContainer textAlign={textAlign} isError={!!isError}>
        {inputLabel ? <div className="left-label"> {inputLabel}</div> : null}

        {type === "text" || type === "password" || type === undefined ? (
          <input
            autoFocus={autoFocus}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => handleOnChange(e)}
            onBlur={(e) => handleOnBlur(e)}
            className="input-text"
            value={value}
            type={getInputType}
          />
        ) : (
          <NumberFormat
            allowNegative={allowNegative}
            className="input-text"
            placeholder={hidePlaceholder ? "" : placeholder || "0"}
            autoFocus={autoFocus}
            disabled={disabled}
            onValueChange={(values) => handleNumberValueChange(values)}
            onBlur={(e) => handleOnBlur(e)}
            value={value}
            decimalScale={decimalScale || 0}
            decimalSeparator={","}
            allowLeadingZeros={allowLeadingZeros || false}
            thousandSeparator={disableNumberFormatting ? undefined : "."}
            isAllowed={(value) =>
              numberValidation ? numberValidation(value) : true
            }
            onFocus={(e) => handleOnFocus(e)}
          />
        )}
        {type === "password" ? (
          <div
            className="password-visibility"
            onClick={() => setVisible(!visible)}
          >
            {visible ? (
              <img src={OpenEyeIcon} alt={"show"} width={24} />
            ) : (
              <img src={CloseEyeIcon} alt={"hide"} width={24} />
            )}
          </div>
        ) : null}
        {unitLabel ? (
          <div className="input__unit-label">{unitLabel}</div>
        ) : null}
      </TextContainer>
      {subtitle ? (
        <div className="subtitle__container">
          {subtitleType !== "error" ? (
            <div className="subtitle__icon">
              <img src={InfoIcon} alt="info" height={14} />
            </div>
          ) : (
            <div className="subtitle__icon">
              <img src={AlertIcon} alt="alert" height={14} />
            </div>
          )}
          <div className="subtitle__text">{subtitle}</div>
        </div>
      ) : null}
    </Container>
  );
};

type ContainerProps = {
  isError: boolean;
  width: number | undefined;
  fullWidth: boolean;
};

const Container = styled.div<ContainerProps>`
  width: ${({ width, fullWidth }) =>
    fullWidth ? "100%" : width ? `${width}px` : "auto"};

  .input-label {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }

  .subtitle__container {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;

    .subtitle__icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    .subtitle__text {
      margin-top: 2px;
      display: flex;
      align-items: center;
      color: ${({ isError }) =>
        isError ? theme.colors.coralDark : theme.colors.blackLight};
    }
  }
`;

export type TextContainerProps = {
  textAlign: "center" | "left" | "right" | undefined;
  isError: boolean;
};

const TextContainer = styled.div<TextContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid
    ${({ isError }) =>
      isError ? theme.colors.coralLight : theme.colors.blackLighter};
  box-shadow: inset 0px 2px 4px rgba(51, 51, 51, 0.15);
  background: ${({ isError }) =>
    isError ? theme.colors.coralLighter : "#ffffff"};

  .left-label {
    font-size: 14px;
    line-height: 22px;
    padding-left: 10px;
  }

  .input-text {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    padding: 5px 10px;
    text-align: ${({ textAlign }) => textAlign || "left"};
    border: 1px solid transparent;
    outline: none;
    background: transparent;
  }

  :focus-within {
    border: ${colors.primary1} 1px solid;
    transition: 0.1s ease-in;
  }

  .input__unit-label {
    padding: 0px 8px;
    color: ${theme.colors.blackLight};
  }

  .password-visibility {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export default TextInput;
