import React, { useEffect, Suspense, useState, useMemo } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// redux action
import { getListInvoiceAsync } from "../../reducers/invoice/invoice.action";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import SearchBar from "../../component/SearchBar";
import Select from "../../component/Select";
import Pagination from "../../component/Pagination";
import Header from "../../component/Header/Header";
import { BodyPage, Flex, H3, Box, Wrapper } from "../../component/Styles";
import { Button } from "../../component/Button";

// asset
import { AddIcon } from "../../asset/icon";
import { debounce } from "lodash";

// type
import { States } from "../../types/general";

const InvoiceList = React.lazy(() => import("./InvoiceList"));

const Invoice = () => {
  const dispatch = useDispatch();

  const sortingOptions = [
    {
      label: "Penerimaan terbaru",
      value: {
        sortedBy: "invoice_date",
        orderBy: "desc",
      },
    },
    {
      label: "Penerimaan terlama",
      value: {
        sortedBy: "invoice_date",
        orderBy: "asc",
      },
    },
  ];

  // state
  const [searchFilter, setSearchFilter] = useState("");

  // redux state
  const invoice = useSelector((state: States) => state.invoice);

  const handleNavigateNewInvoice = () => {
    dispatch(push("/invoice/new"));
  };

  const [activeSorting, setActiveSorting] = useState({
    sortedBy: "invoice_date",
    orderBy: "desc",
  });

  const handleChangeActiveSorting = (option: {
    sortedBy: string;
    orderBy: string;
  }) => {
    setActiveSorting(option);

    const query: { [key: string]: string | number } = {
      sorted_by: option.sortedBy,
      order_by: option.orderBy,
    };

    if (searchFilter) {
      query.search = searchFilter.trim();
    }

    dispatch(getListInvoiceAsync.request(query));
  };

  const getSelectedSortOption = useMemo(() => {
    const selectedOption = sortingOptions.find(
      (el) =>
        el.value.orderBy === activeSorting.orderBy &&
        el.value.sortedBy === activeSorting.sortedBy
    );

    return selectedOption;
  }, [activeSorting]);

  const getSortFilterQuery = useMemo(() => {
    const query: { [key: string]: string } = {
      sorted_by: activeSorting.sortedBy,
      order_by: activeSorting.orderBy,
    };

    if (searchFilter) {
      query.search = searchFilter.trim();
    }

    return query;
  }, [activeSorting, searchFilter]);

  const handleChangeSearchFilter = debounce((text) => {
    setSearchFilter(text);
  }, 1000);

  const handleChangePage = (pageIndex: number) => {
    const query = getSortFilterQuery;
    dispatch(getListInvoiceAsync.request({ ...query, page: pageIndex }));
  };

  useEffect(() => {
    dispatch(
      getListInvoiceAsync.request({
        sorted_by: "invoice_date",
        order_by: "desc",
      })
    );
  }, []);

  useEffect(() => {
    const query = getSortFilterQuery;
    dispatch(getListInvoiceAsync.request({ ...query, page: 1 }));
  }, [searchFilter]);

  return (
    <Layout>
      <TopBar companyHeader />

      <Wrapper p="2" pb="5">
        <Header
          title="Penerimaan stok"
          actionButton={
            <Button
              title={"Faktur"}
              icon={AddIcon}
              onClick={() => handleNavigateNewInvoice()}
            />
          }
        />
        <BodyPage>
          <Box mt="2">
            <H3>Faktur pembelian</H3>
          </Box>

          <Flex maxWidth="400px" mt={0}>
            <SearchBar
              onChange={handleChangeSearchFilter}
              placeholder="Cari faktur"
            />
          </Flex>

          <Flex
            mt={1}
            width="200px"
            maxWidth="200px"
            display={["flex", "flex", "flex", "none"]}
          >
            <Select
              selectedOption={getSelectedSortOption}
              onSelect={(option) => handleChangeActiveSorting(option.value)}
              options={sortingOptions}
              width={200}
            />
          </Flex>

          <Box mt={3}>
            <Suspense fallback={"Memuat..."}>
              <InvoiceList
                onChangeActiveSorting={handleChangeActiveSorting}
                activeSorting={activeSorting}
              />
            </Suspense>
          </Box>

          {invoice.isLoading || !invoice.totalData ? null : (
            <Flex justifyContent="center">
              <Pagination
                onChangePage={handleChangePage}
                currentPage={invoice.pageIndex}
                totalData={invoice.totalData}
                isLoading={invoice.isLoading}
              />
            </Flex>
          )}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default Invoice;
