import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";

// redux action
import { getInventoryListAsync } from "../../reducers/inventory/inventory.action";

// component
import Header from "../../component/Header/Header";
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout";
import SearchBar from "../../component/SearchBar";
import Select from "../../component/Select";
import Pagination from "../../component/Pagination";
import { Box, Flex, Text, Wrapper, BodyPage } from "../../component/Styles";

import InventoryList from "./InventoryList";

// type
import { States } from "../../types/general";
import { nominalFormat } from "../../utils/nominalFormat";

// data
import { sortingOptions } from "./data/templateSortOption";

const Inventory = () => {
  const dispatch = useDispatch();

  const [searchFilter, setSearchFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState({
    sorted_by: "name",
    order_by: "asc",
  });

  const inventory = useSelector((state: States) => state.inventory.summary);
  const isLoading = useSelector((state: States) => state.inventory.isLoading);
  const currentPage = useSelector((state: States) => state.inventory.pageIndex);

  useEffect(() => {
    // fetch first time when mounted page
    dispatch(getInventoryListAsync.request({}));
  }, []);

  useEffect(() => {
    dispatch(getInventoryListAsync.request({ search: searchFilter }));
  }, [searchFilter]);

  const handleChangeSearchFilter = debounce((text) => {
    setSearchFilter(text.trim());
  }, 1000);

  const getSelectedOption = useMemo(() => {
    const selectedOption = sortingOptions.find(
      (o) =>
        o.value.order_by === activeFilter.order_by &&
        o.value.sorted_by === activeFilter.sorted_by
    );

    return selectedOption;
  }, [activeFilter]);

  const handleChangeActiveFilter = (value: any) => {
    const query: { [key: string]: string } = {};

    query["sorted_by"] = value.sorted_by;
    query["order_by"] = value.order_by;

    if (searchFilter) {
      query["search"] = searchFilter.trim();
    }

    dispatch(getInventoryListAsync.request(query));
    setActiveFilter(value);
  };

  /**
   * Handle change page index pagination
   * @param pageIndex
   */
  const handleChangePage = (pageIndex: number) => {
    const query: { [key: string]: string } = {};

    query["sorted_by"] = activeFilter.sorted_by;
    query["order_by"] = activeFilter.order_by;

    if (searchFilter) {
      query["search"] = searchFilter.trim();
    }

    dispatch(
      getInventoryListAsync.request({
        ...query,
        page: pageIndex,
      })
    );
  };

  return (
    <Layout>
      <TopBar companyHeader />
      {/* <InventoryUpdateStockPackage /> */}

      <Wrapper p="2">
        <BodyPage>
          <Header title="Stok" />
          <Flex flexDirection="column" mt="2">
            <Text fontSize="2" lineHeight="2">
              Total harga beli stok
            </Text>
            <Text fontSize="4" lineHeight="4">
              Rp{nominalFormat(inventory?.total_COGS || 0)}
            </Text>
          </Flex>

          <Flex mt="3" maxWidth="400px">
            <SearchBar
              placeholder="Cari barang"
              onChange={(text) => handleChangeSearchFilter(text)}
            />
          </Flex>

          <Box display={["block", "block", "none", "none"]} mt={2}>
            <Select
              options={sortingOptions}
              selectedOption={getSelectedOption}
              onSelect={(option) => handleChangeActiveFilter(option.value)}
              width={160}
            />
          </Box>

          <Box mt="1" width="100%">
            <InventoryList
              inventoryList={inventory?.list || []}
              isLoading={isLoading}
              activeFilter={activeFilter}
              setActiveFilter={handleChangeActiveFilter}
            />
          </Box>

          {isLoading || !inventory?.list.length ? null : (
            <Flex justifyContent="center">
              <Pagination
                currentPage={currentPage}
                onChangePage={(pageIndex) => handleChangePage(pageIndex)}
                isLoading={isLoading}
                totalData={inventory?.total_data || 1}
              />
            </Flex>
          )}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default Inventory;
