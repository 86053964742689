import { createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./toast.actionTypes";

// type
import { ToastType } from "../../types/general";

export const showToast = createAsyncAction(
  types.SHOW_TOAST_REQUEST,
  types.SHOW_TOAST_SUCCESS,
  types.SHOW_TOAST_FAILURE
)<{ type: ToastType, message: string; duration?: number }, undefined, undefined>();
