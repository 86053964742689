import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './profile.action'

// actions types
import * as types from './profile.actionTypes'

// type
import { user} from '../../types/index'
export type ProfileActions = ActionType<typeof actions>;

export type ProfileState = {
    profile: user.User | null, 
    isLoading: boolean;
}

const initialState: ProfileState = {
    profile: null,
    isLoading: false
}

export const profileReducer = createReducer<ProfileState, ProfileActions>(initialState)
    .handleAction(actions.getUserProfileAsync.request, (state: ProfileState, action: ActionType<typeof actions.getUserProfileAsync.request>) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.getUserProfileAsync.success, (state: ProfileState, action: ActionType<typeof actions.getUserProfileAsync.success>) => ({ 
        ...state,
        isLoading: false,
        profile: action.payload
    }))
    .handleAction(actions.getUserProfileAsync.failure, (state: ProfileState, action: ActionType<typeof actions.getUserProfileAsync.failure>) => ({ 
        ...state,
        isLoading: false,
    }))
  



