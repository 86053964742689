export default {
  colors: {
    black: "#333333",
    blackLight: "#858585",
    blackLighter: "#CCCCCC",
    whiteDarker: "#EFEFEF",
    whiteDark: "#F7F7F7",
    blueDark: "#006A7A",
    blue: "#00C1D4",
    blueLight: "#7BE2EA",
    blueLighter: "#D4F7F7",
    whiteBlue: "#D4F7F780",
    coralDark: "#CC2200",
    coral: "#FF6C47",
    coralLight: "#FFA391",
    coralLighter: "#FFEDEB",
    greenDark: "#007527",
    green: "#00C65E",
    greenLight: "#82E3B2",
    greenLighter: "#BFF1D7",
  },
  // base rem 16px;
  // 1 - 8px
  // 2 - 16px
  // 3 - 24px
  // 4 - 40px
  // 5 - 64px
  // 6 - 96px
  space: ["0.5rem", "1rem", "1.5rem", "2.5rem", "4rem", "6rem"],
  fontSizes: ["0.625rem", "0.75rem", "0.825rem", "1rem", "2rem"],
  lineHeights: ["1rem", "1rem", "1.5rem", "1.5rem", "2rem"],
  breakpoints: ['320px', '632px', '1008px', '1248px']
};
