import React, { useState } from "react";
import debounce from "lodash/debounce";
import styled, { keyframes } from "styled-components/macro";

// component
import { Overlay, H2, Text, Flex } from "../../component/Styles";
import { Button } from "../../component/Button";
import TextBox from "../TextBox";

export type Props = {
  title: string;
  subtitle?: string;
  saveTitle?: string;
  cancelTitle?: string;
  id: string;
  label?: string;
  isVisible?: boolean;
  onCancel: () => void;
  onSave: (text: string) => void;
};

const ModalReason = (props: Props) => {
  const {
    isVisible,
    title,
    subtitle,
    saveTitle,
    cancelTitle,
    id,
    label,
    onCancel,
    onSave,
  } = props;

  const [reason, setReason] = useState("");

  const handleChange = debounce((text: string) => {
    setReason(text);
  }, 500);

  return (
    <ExtendOverlay isVisible={isVisible === undefined ? true : !!isVisible}>
      <Container id={id}>
        <H2>{title}</H2>
        <Text fontSize="2" lineHeight="2">
          {subtitle}
        </Text>
        <Flex mt={1} width="100%">
          <TextBox
            id={`text-box-${id}`}
            onChange={handleChange}
            label={label}
            maxLength={255}
          />
        </Flex>
        <Flex mt={2} justifyContent="flex-end">
          <Flex>
            <div className="modal-reason__button-action">
              <Button
                title={saveTitle || "Simpan"}
                onClick={() => onSave(reason)}
                disabled={!reason}
              />
            </div>
            <div className="modal-reason__button-action">
              <Button
                title={cancelTitle || "Batal"}
                secondary
                onClick={() => onCancel()}
              />
            </div>
          </Flex>
        </Flex>
      </Container>
    </ExtendOverlay>
  );
};

const fadeInAnimation = keyframes`
  100% { opacity: 1 }   
`;

const ExtendOverlay = styled(Overlay)`
  z-index: 999;
`;

const Container = styled.div<{ maxWidth?: string }>`
  opacity: 0;
  position: absolute;
  background: #fff;
  padding: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 320px;
  width: 80%;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  animation: ${fadeInAnimation} 0.4s forwards;

  .modal-reason__button-action:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export default ModalReason;
