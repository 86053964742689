import { put, takeLeading } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from '../auth/auth.action'

// related redux
import * as actions from "./package.action";
import * as types from "./package.actionTypes";
import { getProductDetailAsync } from '../catalog/catalog.action'

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from '../../utils/sentryErrorReporter'

// constant
import { BASE_URL_BETA } from "../../constant/api";

function* getPackageList(
  action: ReturnType<typeof actions.getPackagesAsync.request>
) {
  try {
    const { productID } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/package`;

    const response = yield axios.get(url, {
      params: {
        product_id: productID,
      },
    });

    if (response && response.status === 200) {
      yield put(
        actions.getPackagesAsync.success({ data: response.data.package })
      );
    }
  } catch (err) {
    errorReporter(err,  types.GET_PACKAGES_REQUEST, action.payload)
    yield put(actions.getPackagesAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(
      showToast.request({
        message: "Gagal memuat kemasan",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* addPackage(
  action: ReturnType<typeof actions.addPackageAsync.request>
) {
  try {
    const payload = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/package`;
    const response = yield axios.post(url, payload);

    if (response && response.status === 201) {
      yield put(
        yield put(getProductDetailAsync.request({ productID: payload?.product_id! }))
      );
      yield put(actions.addPackageAsync.success());
      yield put(
        showToast.request({
          message: "Berhasil menambahkan kemasan",
          type: "success",
          duration: 5000,
        })
      );
     
      yield put(actions.visiblePackageModal(false));
    }
  } catch (err) {
    yield put(actions.addPackageAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if(err.response.status === 409){
      yield put(
        showToast.request({
          message: "Kemasan sudah terdaftar",
          type: "error",
          duration: 5000,
        })
      );
      return 
    }
    errorReporter(err,  types.ADD_PACKAGE_REQUEST, action.payload)
    yield put(
      showToast.request({
        message: "Gagal memproses",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* editPackage(
  action: ReturnType<typeof actions.editPackageAsync.request>
) {
  try {
    const { unitCode, unitName, packageID, productID, conversionPackageID } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/package/${packageID}`;

    const response = yield axios.put(url, {
      unit_code: unitCode,
      unit_name: unitName,
      conversion_package_id: conversionPackageID
    });

    if (response && response.status === 201) {
      yield put(
        showToast.request({
          message: "Berhasil mengubah kemasan",
          type: "success",
          duration: 5000,
        })
      );
      yield put(getProductDetailAsync.request({ productID }))
     
      yield put(actions.visiblePackageModal(false));
    }
  } catch (err) {
    yield put(actions.getPackagesAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(
      showToast.request({
        message: "Gagal memperbaharui kemasan",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* deletePackage(
  action: ReturnType<typeof actions.deletePackageAsync.request>
) {
  const { packageID, productID } = action.payload;
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/package/${packageID}`;

    const response = yield axios.delete(url);

    if (response && response.status === 200) {
     
      yield put(actions.deletePackageAsync.success());
      yield put(
        showToast.request({
          message: "Berhasil menghapus kemasan",
          type: "deleted",
          duration: 5000,
        })
      );
      // yield put(actions.getPackagesAsync.request({ productID }));
      yield put(getProductDetailAsync.request({ productID }))
      yield put(actions.visiblePackageModal(false));
    }
  } catch (err) {
    yield put(actions.deletePackageAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    
    if (err.response.status === 410) {
      yield put(getProductDetailAsync.request({ productID }))
      // yield put(actions.getPackagesAsync.request({ productID }));

      yield put(
        showToast.request({
          message: "Kemasan sudah terhapus",
          type: "error",
          duration: 5000,
        })
      );

      return;
    }

    errorReporter(err,  types.DELETE_PACKAGE_REQUEST, action.payload)
    yield put(
      showToast.request({
        message: "Gagal menghapus",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* transactionSaga() {
  yield takeLeading(types.GET_PACKAGES_REQUEST, getPackageList);
  yield takeLeading(types.ADD_PACKAGE_REQUEST, addPackage);
  yield takeLeading(types.EDIT_PACKAGE_REQUEST, editPackage);
  yield takeLeading(types.DELETE_PACKAGE_REQUEST, deletePackage);
}
