import axios from '../../../config/axios'

// constant
import {BASE_URL_BETA} from '../../../constant/api'

// utils
import errorReporter from '../../../utils/sentryErrorReporter'

export const getRegionOptions = async(filter: string, type: 'city' | 'province', provinceID?: number) => {
    try {
        const url = `${BASE_URL_BETA}/region`;
        const params: any = { search: filter, type }

        if(provinceID){
            params.province_id = provinceID
        }

        const response = await axios.get(url,  { params } );

    
        if (response && response.status === 200) {
          const { cities, province, provincies } = response.data.region;
          return { cities, provinces: province || provincies } 
        }
    }
    catch(err){
        errorReporter(err)
        throw err
    }
}