import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// component
import InventoryItem from "./InventoryItem";
import { Box, Flex, Table, Text, H2, RotatedBox } from "../../component/Styles";

// type
import { InventoryProduct } from "../../types/inventory";
import { nominalFormat } from "../../utils/nominalFormat";
import { Spinner } from "../../component/Loading";
import { ChevronDown } from "../../asset/icon";

// utils
import { getCurrentOrderList } from "../../utils/getCurrentOrderList";
import { renderDate } from "./utils/renderDate";

export type Props = {
  activeFilter: { sorted_by: string; order_by: string };
  inventoryList: InventoryProduct[];
  isLoading?: boolean;
  setActiveFilter: (value: { sorted_by: string; order_by: string }) => void;
};

const InventoryList = (props: Props) => {
  const { inventoryList, isLoading, activeFilter, setActiveFilter } = props;

  const dispatch = useDispatch();

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  const handleChangeSorting = (sortOption: {
    sorted_by: string;
    order_by: string;
  }) => {
    if (activeFilter.sorted_by === sortOption.sorted_by) {
      if (sortOption.order_by === "asc") {
        setActiveFilter({ sorted_by: sortOption.sorted_by, order_by: "desc" });
        return;
      }

      setActiveFilter({ sorted_by: sortOption.sorted_by, order_by: "asc" });
      return;
    }

    setActiveFilter({
      ...sortOption,
      order_by: getCurrentOrderList(sortOption.order_by),
    });
  };

  const getArrowDirection = useMemo(() => {
    const keyDirection = {
      name: { arrow: "up", order_by: "asc" },
      stock: { arrow: "up", order_by: "asc" },
      ED: { arrow: "up", order_by: "asc" },
      HPP: { arrow: "up", order_by: "asc" },
    };

    const getDirection = (orderBy: string) => {
      if (orderBy === "asc") {
        return "up";
      }
      return "down";
    };

    switch (true) {
      case activeFilter.sorted_by === "name":
        keyDirection.name = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "stock":
        keyDirection.stock = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "ED":
        keyDirection.ED = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "HPP":
        keyDirection.HPP = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
    }

    return keyDirection;
  }, [activeFilter]);

  const renderTable = useMemo(() => {
    const renderTableBody = () => {
      return (inventoryList || []).map((i, index) => {
        return (
          <tr
            key={`inventory-table-item-${index}`}
            onClick={() => handleNavigate(`/inventory/${i.id}`)}
          >
            <td className={`table-inventory__name`}>
              <Text
                fontSize="2"
                lineHeight="2"
                fontWeight="bold"
                color="blueDark"
              >
                {i.name}
              </Text>
            </td>
            <td className={`table-inventory__qty`}>
              <Text fontSize="2" lineHeight="2">
                {i.stock_qty}
              </Text>
            </td>
            <td className={`table-inventory__unit`}>
              <Text fontSize="2" lineHeight="2">
                {i.stock_unit}
              </Text>
            </td>
            <td className={`table-inventory__ed`}>
              <Text fontSize="2" lineHeight="2">
                {renderDate(i.nearest_ED)}
              </Text>
            </td>
            <td className={`table-inventory__cogs`}>
              <Text fontSize="2" lineHeight="2">
                Rp{nominalFormat(i.total_COGS)}
              </Text>
            </td>
          </tr>
        );
      });
    };

    return (
      <ExtendTable width="100%">
        <thead>
          <tr>
            <th className={`table-inventory__name`}>
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "name",
                    order_by: getArrowDirection.name.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "name" ? "blueDark" : "black"
                  }
                >
                  Barang
                </Text>
                <RotatedBox direction={getArrowDirection.name.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className={`table-inventory__stock`}>
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "stock",
                    order_by: getArrowDirection.stock.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "stock" ? "blueDark" : "black"
                  }
                >
                  Stok
                </Text>
                <RotatedBox direction={getArrowDirection.stock.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className={`table-inventory__unit`}>
              <Text fontSize="2" lineHeight="2">
                Satuan
              </Text>
            </th>
            <th className={`table-inventory__ed`}>
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "ED",
                    order_by: getArrowDirection.ED.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={activeFilter.sorted_by === "ED" ? "blueDark" : "black"}
                >
                  ED terdekat
                </Text>
                <RotatedBox direction={getArrowDirection.ED.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className={`table-inventory__cogs`}>
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "HPP",
                    order_by: getArrowDirection.HPP.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "HPP" ? "blueDark" : "black"
                  }
                >
                  Total harga beli
                </Text>
                <RotatedBox direction={getArrowDirection.HPP.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </ExtendTable>
    );
  }, [inventoryList, activeFilter]);

  const renderList = useMemo(() => {
    return (inventoryList || []).map((i, index) => {
      return (
        <InventoryItem
          onClick={() => handleNavigate(`/inventory/${i.id}`)}
          inventory={i}
          key={`inventory-item-${index}`}
        />
      );
    });
  }, [inventoryList, activeFilter]);

  if (isLoading) {
    return (
      <Flex p="3" mt="2" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  if (!inventoryList.length) {
    return (
      <Flex
        p="3"
        mt="2"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <H2>Data tidak ditemukan</H2>
        <Text mt={0} textAlign="center">
          Masukkan data atau ganti data pencarian.
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box
        display={["block", "block", "none"]}
        borderTop="1px solid"
        borderColor="blackLighter"
      >
        {renderList}
      </Box>
      <Box
        display={["none", "none", "block"]}
        borderTop="1px solid"
        borderColor="blackLighter"
      >
        {renderTable}
      </Box>
    </>
  );
};

const ExtendTable = styled(Table)`
  tr {
    cursor: pointer;
  }

  .table-inventory__name {
    width: calc(100% - 160px - 160px - 160px - 80px);
    min-width: 300px;
  }

  .table-inventory__qty {
    width: 80px;
  }

  .table-inventory__unit {
    width: 160px;
  }

  .table-inventory__ed {
    width: 160px;
  }

  .table-inventory__cogs {
    width: 160px;
  }
`;

export default InventoryList;
