import { put, takeLeading } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./pbf.action";
import * as types from "./pbf.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import { generateSortFilterQuery } from "../../utils/generateSortFilterQuery";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// type
import { Option } from "../../types/general";

function* getPBFList(
  action: ReturnType<typeof actions.getPBFListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const query = generateSortFilterQuery(action.payload);
    const url = `${BASE_URL_BETA}/company/${companyID}/supplier`;

    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(actions.getPBFListAsync.success(response.data.supplier));
    }
  } catch (err) {
    errorReporter(err, types.GET_PBF_LIST_REQUEST, action.payload);
    yield put(actions.getPBFListAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* addPBF(action: ReturnType<typeof actions.addPBFAsync.request>) {
  try {
    const { name } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/supplier`;

    const response = yield axios.post(url, { supplier_name: name });

    if (response && response.status === 201) {
      yield put(
        showToast.request({
          message: "Sukses menambahkan PBF baru",
          type: "success",
          duration: 5000,
        })
      );
      const pbf = response.data.data;
      yield put(
        actions.setSelectedPBF({ label: pbf.name, value: pbf.id } as Option)
      );
      yield put(actions.addPBFAsync.success());
      yield put(actions.getPBFListAsync.request({}));
      yield put(actions.showEditModalPBF(false));
    }
  } catch (err) {
    yield put(actions.addPBFAsync.failure());

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.ADD_PBF_REQUEST, action.payload);

    yield put(actions.getPBFListAsync.request({}));
    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* editPBF(action: ReturnType<typeof actions.editPBFAsync.request>) {
  try {
    const { name, PBFid } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/supplier/${PBFid}`;

    const response = yield axios.put(url, { supplier_name: name });

    if (response && response.status === 201) {
      yield put(actions.editPBFAsync.success());
      yield put(
        showToast.request({
          message: "Sukses mengubah PBF",
          type: "success",
          duration: 5000,
        })
      );
      yield put(actions.getPBFListAsync.request({}));
      yield put(actions.showEditModalPBF(false));
    }
  } catch (err) {
    yield put(actions.editPBFAsync.failure());

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.UPDATE_PBF_REQUEST, action.payload);
    yield put(actions.getPBFListAsync.request({}));
    yield put(
      showToast.request({
        message: "Gagal melakukan perubahan",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* deletePBF(action: ReturnType<typeof actions.deletePBFAsync.request>) {
  try {
    const { PBFid } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/supplier/${PBFid}`;

    const response = yield axios.delete(url);

    if (response && response.status === 200) {
      yield put(actions.deletePBFAsync.success());
      yield put(
        showToast.request({
          message: "Sukses mengahapus PBF",
          type: "deleted",
          duration: 5000,
        })
      );
      yield put(actions.getPBFListAsync.request({}));
      yield put(actions.showEditModalPBF(false));
      yield put(actions.getPBFListAsync.request({}));
    }
  } catch (err) {
    yield put(actions.deletePBFAsync.failure());

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.DELETE_PBF_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal menghapus PBF",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* pbfSaga() {
  yield takeLeading(types.GET_PBF_LIST_REQUEST, getPBFList);
  yield takeLeading(types.ADD_PBF_REQUEST, addPBF);
  yield takeLeading(types.UPDATE_PBF_REQUEST, editPBF);
  yield takeLeading(types.DELETE_PBF_REQUEST, deletePBF);
}
