import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './employee.action'

// actions types
import * as types from './employee.actionTypes'

// type
import { user } from '../../types/index'
export type EmployeeActions = ActionType<typeof actions>;

export type EmployeeState = {
    list: user.User[],
    selectedUser: user.User | null,
    isLoading: boolean;
    isShowModalDetail: boolean;
}

const initialState: EmployeeState = {
    list: [],
    selectedUser: null,
    isLoading: false,
    isShowModalDetail: false
}

export const employeeReducer = createReducer<EmployeeState, EmployeeActions>(initialState)
    .handleAction(actions.getUserListAsync.request, (state: EmployeeState, action: ActionType<typeof actions.getUserListAsync.request>) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.getUserListAsync.success, (state: EmployeeState, action: ActionType<typeof actions.getUserListAsync.success>) => ({ 
        ...state,
        isLoading: false,
        list: action.payload
    }))
    .handleAction(actions.getUserListAsync.failure, (state: EmployeeState, action: ActionType<typeof actions.getUserListAsync.failure>) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.sendInvitation.request, (state: EmployeeState, action: ActionType<typeof actions.sendInvitation.request>) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.sendInvitation.success, (state: EmployeeState, action: ActionType<typeof actions.sendInvitation.success>) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.sendInvitation.failure, (state: EmployeeState, action: ActionType<typeof actions.sendInvitation.failure>) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(types.SET_SELECTED_USER as any, (state: EmployeeState, action: ActionType<typeof actions.setSelectedUser>) => ({ 
        ...state,
        selectedUser: action.payload.user,
        isShowModalDetail: true
    }))
    .handleAction(types.SET_SHOW_EMPLOYEE_DETAIL_MODAL as any, (state: EmployeeState, action: ActionType<typeof actions.showModalEmployeeDetail>) => ({ 
        ...state,
        isShowModalDetail: action.payload.value
    }))
  
  



