import React from "react";
import styled from "styled-components/macro";

// asset
import CheckIcon from "../../asset/icon/check-light.svg";

// constant
import { colors } from "../../constant/theme";

export type CheckboxProps = {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: (value: boolean) => void;
};

export const Checkbox = (props: CheckboxProps) => {
  const { checked, label, onClick } = props;

  const preventTriggerAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventTriggerAction(e);

    if (onClick) {
      onClick(!checked);
    }

    return;
  };

  return (
    <Container checked={!!checked}>
      <div className="checkbox__mark" onClick={(e) => handleChange(e)}>
        {checked ? (
          <img src={CheckIcon} width="8" height="9" alt="check" />
        ) : null}
      </div>
      <div
        className="checkbox__title text"
        onClick={(e) => preventTriggerAction(e)}
      >
        {label ? label : null}
      </div>
    </Container>
  );
};

export default Checkbox;

const Container = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;

  .checkbox__mark {
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ checked }) => (checked ? colors.primary1 : "#ffffff")};
    transition: 0.5s ease;

    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: ${({ checked }) =>
      checked
        ? `1px solid ${colors.primary1} `
        : `1px solid ${colors.secondary1}`};
  }

  .checkbox__title {
    margin-left: 10px;
  }

  cursor: pointer;
`;
