import React from "react";
import moment from "moment";
import styled from "styled-components/macro";
import "moment/locale/id";

// component
import { Flex, Text, H2, H3, H4, Box } from "../../component/Styles";
import { TransactionProduct } from "../../types/product";

// type
import { TransactionDetail } from "../../types/transaction";

// utils
import { nominalFormat } from "../../utils/nominalFormat";

// asset
import { DarkLogo } from "../../asset/icon";
import { User } from "../../types/user";

type Props = {
  ref: any;
  detail: TransactionDetail | null;
  profile: User | null;
  customerPaid?: number;
  changes?: number;
};

class PrintLayoutTransaction extends React.PureComponent<Props> {
  renderDiscount = (p: TransactionProduct) => {
    if (p.discount_type === "percentage") {
      return `Diskon ${nominalFormat(p.discount_amount)} %`;
    }

    return `Diskon Rp${nominalFormat(p.discount_amount)}`;
  };

  getDiscAmount = (p: TransactionProduct) => {
    if (p.discount_type === "percentage") {
      const calculateDisc = p.subtotal * p.discount_amount * 0.01;
      return `${nominalFormat(p.subtotal - calculateDisc)}`;
    }

    return null;
  };

  renderProductList = () => {
    const { detail } = this.props;
    const productList = detail?.list_product || [];

    return (
      <Box>
        {productList.map((p, index) => {
          return (
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
              key={`product-item-print-${index}`}
            >
              <Text fontWeight="bold" variant="rg">
                {p.product_name}
              </Text>
              <Flex justifyContent="space-between" width="100%">
                <Text variant="rg">
                  Rp{nominalFormat(p.price)} x {nominalFormat(p.qty)}{" "}
                  {` ${p.unit}`}
                </Text>
                {p.discount_amount ? null : (
                  <Text variant="rg">Rp{nominalFormat(p.price * p.qty)}</Text>
                )}
              </Flex>
              {p.discount_amount ? (
                <Flex justifyContent="space-between" width="100%">
                  <Text variant="rg">{this.renderDiscount(p)}</Text>
                  {p.discount_amount ? (
                    <Text variant="rg">Rp{nominalFormat(p.subtotal)}</Text>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          );
        })}
      </Box>
    );
  };

  render() {
    return (
      <Container ref={this.props.ref}>
        <Flex justifyContent="center">
          <H4>{this.props.detail?.pharmacy_name || ""}</H4>
        </Flex>
        <Divider />

        <Flex flexDirection="column" alignItems="center">
          <Text textAlign="center" variant="rg">
            {this.props.detail?.total_item || 0} barang,{" "}
            {this.props.detail?.kinds_item || 0} jenis
          </Text>
          <H2>Rp{nominalFormat(this.props.detail?.total_basket_size! || 0)}</H2>

          <Text mt={1} textAlign="center" variant="rg">
            {moment(new Date()).locale("id").format("dddd, DD MMM YYYY HH:mm")}
          </Text>
        </Flex>
        <Divider />

        {this.renderProductList()}

        <Flex mt={2} flexDirection="column">
          <Flex justifyContent="space-between">
            <H3>Total</H3>
            <H3>
              Rp{nominalFormat(this.props.detail?.total_basket_size! || 0)}
            </H3>
          </Flex>
          <Flex justifyContent="space-between">
            <Text variant="rg">Tunai</Text>
            <Text variant="rg">
              Rp
              {nominalFormat(
                this.props.customerPaid ||
                  this.props.detail?.customer_paid! ||
                  0
              )}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text variant="rg">Kembalian</Text>
            <Text variant="rg">
              Rp
              {nominalFormat(
                this.props.changes || this.props.detail?.customer_change! || 0
              )}
            </Text>
          </Flex>
        </Flex>

        <Divider />
        <Text textAlign="center" variant="rg">
          Semoga lekas sembuh!
        </Text>
        <Divider />
        <Text variant="rg" fontWeight="bold">
          #{this.props.detail?.unique_id}{" "}
        </Text>
        <Text variant="rg">Dilayani oleh {this.props.profile?.name}</Text>
        <Text variant="rg">Harga sudah termasuk PPN</Text>

        <Divider />

        <Flex alignItems="center" flexDirection="column">
          <Text variant="rg">Dibuat dengan</Text>
          <img src={DarkLogo} alt="logo" height="26" />
        </Flex>
      </Container>
    );
  }
}

const Divider = styled(Flex)`
  border: 1px dashed;
  width: 100%;
  margin: 0.4rem 0rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0rem 0.5rem;
  padding-right: 0.5rem;
`;

export default PrintLayoutTransaction;
