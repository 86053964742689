import React from "react";

// component
import { Flex, Text, H4, Box } from "../Styles";

export type Props = {
  title: string;
  subtitle?: string;
  action?: any;
};

const Placeholder = (props: Props) => {
  const { title, subtitle, action } = props;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      borderRadius="4px"
      border="1px solid"
      borderColor="blackLighter"
      py="3"
      px="2"
    >
      <H4>{title}</H4>
      <Text variant="rg" textAlign="center">
        {subtitle}
      </Text>
      <Box mt={1}>{action}</Box>
    </Flex>
  );
};

export default Placeholder;
