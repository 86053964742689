import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import {
  getTransactionHistoryAsync,
  setSelectedDate,
  setPeriodType,
} from "../../reducers/history/history.action";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import SearchBar from "../../component/SearchBar";
import HistoryItem from "./HistoryItem";
import HistoryFilterModal from "./HistoryFilterModal";
import TapInput from "../../component/TapInput";
import Chip from "../../component/Chip";
import { Spinner } from "../../component/Loading";
import { Box } from "../../component/Styles";

// type
import { general, transaction } from "../../types";
import { SelectedDate, PeriodType } from "../../types/history";
import { Wrapper } from "../../component/Styles";
import Placeholder from "../../component/Placeholder";
import { Button } from "../../component/Button";
import { AddIcon } from "../../asset/icon";

const History = () => {
  const dispatch = useDispatch();

  const [showFilterModal, setShowFilterModal] = useState(false);

  const groupedHistory = useSelector(
    (states: general.States) => states.history.groupedByDate
  );
  const selectedDate = useSelector(
    (states: general.States) => states.history.filterDate
  );
  const selectedPeriod = useSelector(
    (states: general.States) => states.history.filterPeriod
  );
  const isLoading = useSelector(
    (states: general.States) => states.history.isLoading
  );

  const handleNavigate = () => {
    dispatch(push("/transaction"));
  };

  const handleChangeDate = (date: SelectedDate) => {
    dispatch(setSelectedDate(date));
  };

  const setSelectedPeriod = (period: PeriodType) => {
    dispatch(setPeriodType(period));
  };

  const renderItemList = (list: transaction.History[]) => {
    return list.map((el, index) => {
      return <HistoryItem item={el} key={`history-item-${index}`} />;
    });
  };

  const renderHistoryList = useMemo(() => {
    const dateList: any = [];

    Object.keys(groupedHistory).forEach((el, index) => {
      dateList.push(
        <div className="grouped__list" key={`history-grouped-list-${index}`}>
          <div className="title">{el}</div>
          <div className="history-list">
            {renderItemList(groupedHistory[el])}
          </div>
        </div>
      );
    });

    return dateList;
  }, [groupedHistory]);

  const handleNavigateToSearch = () => {
    dispatch(push("/history/search"));
  };

  const renderFilterLabel = useMemo(() => {
    switch (selectedPeriod) {
      case "today":
        return `${moment(selectedDate.startDate).format(
          "DD-MM-YYYY"
        )}, Hari ini`;
      case "specificDate":
        return `${moment(selectedDate.startDate).format("DD-MM-YYYY")}`;
      default:
        return `${moment(selectedDate.startDate).format(
          "DD-MM-YYYY"
        )} - ${moment(selectedDate.endDate).format("DD-MM-YYYY")}`;
    }
  }, [selectedPeriod, selectedDate]);

  useEffect(() => {
    dispatch(
      getTransactionHistoryAsync.request({
        start_date: moment(selectedDate.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedDate.endDate).format("YYYY-MM-DD"),
        sort_by: "desc",
        order_by: "created_at",
      })
    );
  }, []);

  const clearFilterHistory = () => {
    dispatch(setPeriodType(null));

    dispatch(getTransactionHistoryAsync.request({}));
  };

  return (
    <Layout>
      <HistoryFilterModal
        isVisible={showFilterModal}
        periodType={selectedPeriod!}
        selectedDate={selectedDate}
        onCloseModal={() => setShowFilterModal(false)}
        onChangePeriodType={(type) => setSelectedPeriod(type)}
        onChangeDate={(date) => handleChangeDate(date)}
      />
      <TopBar companyHeader leftHeaderType="menu" />
      <Wrapper p={2}>
        <Body>
          <h1 className="history__title">Riwayat transaksi</h1>
          <div className="history__filter">
            <div className="filter__search" onClick={handleNavigateToSearch}>
              <SearchBar />
            </div>
            <div className="filter__period">
              <TapInput
                type="select"
                onTap={() => setShowFilterModal(true)}
                value={"Periode"}
              />
            </div>
          </div>
          <div className="filter__container">
            {selectedPeriod ? (
              <Chip
                onClick={() => clearFilterHistory()}
                label={renderFilterLabel}
              />
            ) : null}
          </div>
          {!Object.keys(groupedHistory).length ? (
            <Box mt={2}>
              <Placeholder
                title="Tidak ada data transaksi"
                action={
                  <Button
                    icon={AddIcon}
                    title="Transaksi"
                    secondary
                    onClick={() => handleNavigate()}
                  />
                }
              />
            </Box>
          ) : isLoading ? (
            <div className="history--loading">
              <Spinner />
            </div>
          ) : (
            <div className="history__list">{renderHistoryList}</div>
          )}
        </Body>
      </Wrapper>
    </Layout>
  );
};

const Body = styled.div`
  width: 100%;
  max-width: 1400px;

  .history__title {
    margin-bottom: 24px;
  }

  .history__list {
    margin-top: 24px;
  }

  .grouped__list {
    > .title {
      font-weight: 700;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .history-list {
    }
  }

  .history__filter {
    display: flex;

    .filter__search {
      flex: 6;
    }

    .filter__period {
      flex: 4;
      max-width: 220px;
      margin-left: 30px;
    }
  }

  .filter__container {
    display: flex;
    margin-top: 18px;
  }

  .history--loading {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default History;
