import React, { useState } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// asset
import FarmacareLogo from "../../asset/img/logo.png";

// constant
import { colors } from "../../constant/theme";
import theme from "../../constant/styledTheme";
import { APP_VERSION } from "../../constant/version";
import metadata from "../../constant/metadata.json";
import { ENV } from "../../constant/env";

// redux action
import { loginAsync } from "../../reducers/auth/auth.action";
import { showToast } from "../../reducers/toast/toast.action";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import { Button } from "../../component/Button";
import { Flex } from "../../component/Styles";

// type
import { States } from "../../types/general";

const Login = () => {
  const dispatch = useDispatch();

  const isError = useSelector((state: States) => state.auth.isError);
  const isLoading = useSelector((state: States) => state.auth.isLoading);

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id || !password) {
      dispatch(
        showToast.request({
          message: "Email atau password tidak sesuai",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    dispatch(loginAsync.request({ identifier: id, password }));
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <Body>
        <Flex
          justifyContent="center"
          flexDirection="column"
          width="100%"
          maxWidth="962px"
        >
          <h1>Login</h1>
          <div className="text"> Masukkan email dan password kamu</div>

          <form onSubmit={(e) => handleSubmit(e)} className="login__form">
            <div className="form__row">
              <TextInput
                label="Email"
                value={id}
                onChange={(text) => setId(text)}
                isError={isError}
              />
            </div>
            <div className="form__row">
              <TextInput
                label="Password"
                type="password"
                value={password}
                onChange={(text) => setPassword(text)}
                isError={isError}
              />
            </div>
            <Button
              fullWidth
              title="Masuk"
              isLoading={isLoading}
              type="submit"
            />

            <h3
              className="login__forget-pass"
              onClick={() => handleNavigate("/forgot-password")}
            >
              Lupa password?
            </h3>
          </form>

          <div className="signup__container">
            <div className="text">Belum punya akun? </div>
            <Button
              title="Buat akun"
              secondary
              onClick={() => handleNavigate("/register")}
            />
          </div>
          <div className="version__container">
            <div className="subtitle">Farmacare {APP_VERSION}</div>

            {ENV === "development" || ENV === "test" ? (
              <div className="subtitle">
                Build {metadata.buildDate}.{metadata.buildMajor}.
                {metadata.buildMinor}.{metadata.buildRevision}/
                {metadata.buildNumber}
              </div>
            ) : null}
          </div>
        </Flex>
      </Body>
    </Layout>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (min-width: ${theme.breakpoints[3]}) {
    border-bottom: 1px solid ${colors.secondary2};
  }
`;

const Body = styled.div`
  padding: 24px;

  @media (min-width: ${theme.breakpoints[3]}) {
    display: flex;
    justify-content: center;
  }

  .version__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 14px;

    > div:not(:first-child) {
      margin-top: 5px;
    }
  }

  .signup__container {
    padding: 28px 0px;
    border-top: 1px solid ${colors.secondary1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  .login__forget-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.primary1};
    margin-top: 18px;
  }

  .form__row {
    margin-top: 10px;
  }

  .login__form {
    margin-top: 24px;

    > button {
      margin-top: 24px;
    }
  }
`;

export default Login;
