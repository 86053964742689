import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./catalog.actionTypes";

// type
import { catalog } from "../../types/index";
import {Package} from "../../types/package"
import { ProductDetail } from "../../types/product";

export const postCatalogAsync = createAsyncAction(
  types.POST_CATALOG_REQUEST,
  types.POST_CATALOG_SUCCESS,
  types.POST_CATALOG_FAILURE
)<Partial<catalog.Catalog> & { package_list: Partial<Package>[] }, undefined, undefined>();

export const getProductDetailAsync = createAsyncAction(
  types.GET_PRODUCT_DETAIL_REQUEST,
  types.GET_PRODUCT_DETAIL_SUCCESS,
  types.GET_PRODUCT_DETAIL_FAILURE
)<{ productID: string }, ProductDetail, undefined>();

export const updateCatalogAsync = createAsyncAction(
  types.UPDATE_CATALOG_REQUEST,
  types.UPDATE_CATALOG_SUCCESS,
  types.UPDATE_CATALOG_FAILURE
)<Partial<catalog.Catalog>, undefined, undefined>();

export const deleteCatalogAsync = createAsyncAction(
  types.DELETE_CATALOG_REQUEST,
  types.DELETE_CATALOG_SUCCESS,
  types.DELETE_CATALOG_FAILURE
)<{ id: string }, undefined, undefined>();

export const uploadCSVAsync = createAsyncAction(
  types.UPLOAD_CSV_REQUEST,
  types.UPLOAD_CSV_SUCCESS,
  types.UPLOAD_CSV_FAILURE
)<{ file: any }, undefined, { link: string }>();

export const patchProductPriceAsync = createAsyncAction(
  types.PATCH_PRODUCT_PRICE_REQUEST,
  types.PATCH_PRODUCT_PRICE_SUCCESS,
  types.PATCH_PRODUCT_PRICE_FAILURE
)<{ productID: string, price: number }, undefined, undefined>();

export const setSelectedCatalog = (catalog: catalog.Catalog) =>
  action(types.SET_SELECTED_CATALOG, { catalog });

export const setShowUploadCSVModal = (visible: boolean) =>
  action(types.SET_SHOW_UPLOAD_CSV_MODAL, { visible });

export const removeFileFailedLink = () => action(types.REMOVE_FAILED_FILE_LINK);

export const setShowAddCatalogModal = (visible: boolean) => action(types.SET_SHOW_ADD_CATALOG_MODAL, { visible })

export const onChangeSearchFilter = (text: string) => action(types.SET_SEARCH_FILTER  , { text })

export const setShowChangePriceModal = (value: boolean) => action(types.SET_VISIBLE_ADD_PRICE_MODAL, { visible: value })
