import React, { useState } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { submitForgotPasswpordAsync } from "../../reducers/auth/auth.action";

//asset
import FarmacareLogo from "../../asset/img/logo.png";

//icons
import ArrowBackIcon from "../../asset/icon/arrow-back.svg";

// constant
import theme from "../../constant/styledTheme";

//component
import { TouchableButton, Button } from "../../component/Button";
import Layout from "../../component/Layout";
import { Box, Wrapper, H1, Text, BodyPage } from "../../component/Styles";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";

// type
import { States } from "../../types/general";

const ForgotPass = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: States) => state.auth.isLoading);

  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const isEmailValid = (email: string) => {
    const emailExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const match = emailExp.test(email);

    return match;
  };

  const handleSubmit = () => {
    setError("");
    if (email.length <= 0) {
      setError("Kolom tidak boleh kosong");
    }
    if (isEmailValid(email)) {
      dispatch(
        submitForgotPasswpordAsync.request({
          identifier: email,
        })
      );
      return;
    } else {
      setError("Email yang dimasukkan tidak valid");
    }
  };

  const handleBack = () => {
    dispatch(push("/signin"));
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <div className="forgot-pass--back">
          <TouchableButton
            icon={ArrowBackIcon}
            alt="back"
            onClick={handleBack}
          />
        </div>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <Wrapper p="2">
        <Body>
          <H1>Lupa Password</H1>
          <Text mt={0}>
            Masukkan alamat email yang kamu gunakan untuk mendaftar di
            Farmacare.
          </Text>

          <Box mt={2}>
            <TextInput
              type="text"
              label="Alamat email"
              onChange={(text) => setEmail(text)}
              placeholder="name@example.com"
              subtitle={error}
              isError={!!error}
              subtitleType="error"
            />
          </Box>
          <Box mt={1}>
            <Button
              title="Lanjut"
              onClick={() => handleSubmit()}
              fullWidth
              isLoading={isLoading}
            />
          </Box>
        </Body>
      </Wrapper>
    </Layout>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;

  .forgot-pass--back {
    position: absolute;
    left: 14px;
    top: 12px;
  }

  @media (min-width: ${theme.breakpoints[3]}) {
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }
`;

const Body = styled(BodyPage)`
  max-width: 962px;
`;

export default ForgotPass;
