import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components/macro";

// redux action
import {
  setPeriodType,
  setSelectedDate,
  getDashboardSummaryAsync,
} from "../../reducers/home/home.action";
import { showToast } from "../../reducers/toast/toast.action";

// constant
import { colors } from "../../constant/theme";
import { device } from "../../constant/media";

// asset
import CloseIcon from "../../asset/icon/close.svg";

// component
import TopBar from "../../component/TopBar";
import RadioButton from "../../component/RadioButton";
import { Button, TouchableButton } from "../../component/Button";
import DatePicker from "../../component/DatePicker";

// type
import { States } from "../../types/general";
import { SelectedDate, PeriodType } from "../../types/filter";

// style
import { Modal, Overlay } from "../../component/Styles/index";

export type HistoryFilterProps = {
  isVisible: boolean;
  onCloseModal: () => void;
};

const HistoryFilter = (props: HistoryFilterProps) => {
  const { isVisible, onCloseModal } = props;

  const dispatch = useDispatch();

  const isLoading = useSelector((state: States) => state.history.isLoading);
  const periodType = useSelector((states: States) => states.home.filterPeriod);
  const selectedDate = useSelector((states: States) => states.home.filterDate);

  const onChangePeriodType = (periodType: PeriodType) => {
    dispatch(setPeriodType(periodType));
  };

  const onChangeDate = (date: SelectedDate) => {
    dispatch(setSelectedDate(date));
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt={"close"}
        onClick={() => handleCloseModal()}
      />
    );
  };

  const handleChangeDate = (
    type: "startDate" | "endDate",
    date: Date | null
  ) => {
    if (periodType === "specificDate") {
      onChangeDate({ startDate: date, endDate: date });
      return;
    }

    // for date range
    if (type === "startDate") {
      if (moment(date).isAfter(selectedDate.startDate, "dates")) {
        dispatch(
          showToast.request({
            type: "error",
            message: "Mohon masukkan tanggal yang valid",
            duration: 5000,
          })
        );
        return;
      }

      if (!selectedDate.endDate) {
        onChangeDate({ startDate: date, endDate: date });
      } else {
        onChangeDate({ ...selectedDate, startDate: date });
      }
      return;
    }

    if (type === "endDate") {
      if (moment(date).isBefore(selectedDate.startDate, "dates")) {
        dispatch(
          showToast.request({
            type: "error",
            message: "Mohon masukkan tanggal yang valid",
            duration: 5000,
          })
        );
        return;
      }

      if (!selectedDate.startDate) {
        onChangeDate({ startDate: date, endDate: date });
      } else {
        onChangeDate({ ...selectedDate, endDate: date });
      }
    }
  };

  const handleChangePeriodType = (periodType: PeriodType) => {
    const today = new Date();

    switch (periodType) {
      case "today":
        onChangePeriodType(periodType);
        onChangeDate({ startDate: today, endDate: today });
        break;
      case "specificDate":
        onChangePeriodType(periodType);
        onChangeDate({
          startDate: today,
          endDate: today,
        });
        break;
      case "dateRange":
        onChangePeriodType(periodType);
        break;
    }
  };

  const handleRequestHistory = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedDate.startDate && selectedDate.endDate) {
      dispatch(getDashboardSummaryAsync.request());
      onCloseModal();
    }
  };

  return (
    <Overlay isVisible={isVisible}>
      <ModalContainer isVisible={isVisible}>
        <TopBar
          leftHeader={renderLeftHeader}
          title="Periode transaksi"
          isModal
        />
        <Body>
          <h2>Filter berdasarkan tanggal</h2>

          <form onSubmit={(e) => handleRequestHistory(e)}>
            <div className="option__container">
              <div className="option__row">
                <RadioButton
                  label="Hari ini"
                  checked={periodType === "today"}
                  onClick={() => handleChangePeriodType("today")}
                />
              </div>
              <div className="option__row">
                <RadioButton
                  label="Tanggal spesifik"
                  checked={periodType === "specificDate"}
                  onClick={() => handleChangePeriodType("specificDate")}
                />
              </div>
              <div className="option__row">
                <RadioButton
                  label="Rentang Waktu"
                  checked={periodType === "dateRange"}
                  onClick={() => handleChangePeriodType("dateRange")}
                />
              </div>
            </div>

            <div className="date__container">
              <div className="date__row">
                <DatePicker
                  label={periodType === "dateRange" ? "Mulai" : "Tanggal"}
                  selectedDate={selectedDate.startDate}
                  onChange={(date) => handleChangeDate("startDate", date)}
                  disabled={periodType === "today"}
                />
              </div>
              {periodType === "dateRange" ? (
                <div className="date__row">
                  <DatePicker
                    label={"Sampai"}
                    selectedDate={selectedDate.endDate}
                    onChange={(date) => handleChangeDate("endDate", date)}
                  />
                </div>
              ) : null}
            </div>

            <Button
              title="Filter"
              fullWidth
              isLoading={isLoading}
              type="submit"
            />
          </form>
        </Body>
      </ModalContainer>
    </Overlay>
  );
};

const ModalContainer = styled(Modal)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};

  @media ${device.md} {
    width: 400px;
  }
`;

const Body = styled.div`
  padding: 24px;
  // total height - header height - padding
  height: calc(100vh - 60px - 24px - 24px);

  @media ${device.lg} {
    height: auto;
  }

  .option__container {
    padding: 24px 2px;
    margin-top: 2px;
    border-bottom: 1px solid ${colors.secondary1};

    .option__row {
      :not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .date__container {
    margin-top: 20px;
    padding-bottom: 28px;

    .date__row {
      :not(:first-child) {
        margin-top: 10px;
      }
    }
  }
`;

export default HistoryFilter;
