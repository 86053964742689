export const GET_COMPANY_LIST_REQUEST = '@company/GET_COMPANY_LIST_REQUEST';
export const GET_COMPANY_LIST_SUCCESS = '@company/GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAILURE = '@company/GET_COMPANY_LIST_FAILURE';

export const ADD_COMPANY_REQUEST = '@company/ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = '@company/ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = '@company/ADD_COMPANY_FAILURE';

export const SET_SELECTED_COMPANY = '@company/SET_SELECTED_COMPANY'
export const SHOW_ADD_COMPANY_MODAL = '@company/SHOW_ADD_COMPANY_MODAL';
export const SHOW_IN_PROGRESS_MODAL = '@company/SHOW_IN_PROGRESS_MODAL';
export const SET_SELECTED_PHARMACY = '@company/SET_SELECTED_PHARMACY'