export const GET_INVENTORY_LIST_REQUEST = '@inventory/GET_INVENTORY_LIST_REQUEST';
export const GET_INVENTORY_LIST_SUCCESS = '@inventory/GET_INVENTORY_LIST_SUCCESS';
export const GET_INVENTORY_LIST_FAILURE = '@inventory/GET_INVENTORY_LIST_FAILURE';

export const GET_INVENTORY_DETAIL_REQUEST = '@inventory/GET_INVENTORY_DETAIL_REQUEST';
export const GET_INVENTORY_DETAIL_SUCCESS = '@inventory/GET_INVENTORY_DETAIL_SUCCESS';
export const GET_INVENTORY_DETAIL_FAILURE = '@inventory/GET_INVENTORY_DETAIL_FAILURE';

export const GET_STOCK_LIST_REQUEST = '@inventory/GET_STOCK_LIST_REQUEST';
export const GET_STOCK_LIST_SUCCESS = '@inventory/GET_STOCK_LIST_SUCCESS';
export const GET_STOCK_LIST_FAILURE = '@inventory/GET_STOCK_LIST_FAILURE';


