import { action, createAsyncAction } from 'typesafe-actions'

// type
import { transaction, history } from '../../types'

// action type
import * as types from './history.actionTypes'

export const getTransactionHistoryAsync = createAsyncAction(
    types.GET_TRANSACTION_HISTORY_REQUEST,
    types.GET_TRANSACTION_HISTORY_SUCCESS,
    types.GET_TRANSACTION_HISTORY_FAILURE,
)<{[queryKey: string]: string}, { data: transaction.History[], groupedData: { [key: string]: transaction.History[] }, isEmpty: boolean }, { error: string }>();

export const getTransactionHistoryDetailAsync = createAsyncAction(
    types.GET_TRANSACTION_HISTORY_DETAIL_REQUEST,
    types.GET_TRANSACTION_HISTORY_DETAIL_SUCCESS,
    types.GET_TRANSACTION_HISTORY_DETAIL_FAILURE
)<{ transactionID: string,  }, { data: any }, { error: string }>();

export const setSelectedDate = (date: history.SelectedDate) => action(types.SET_SELECTED_DATE, { date  })

export const setPeriodType = (period: history.PeriodType | null) => action(types.SET_PERIOD_TYPE, {period});

export const clearListHistory = () => action(types.CLEAR_HISTORY_LIST)