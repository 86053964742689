export const POST_FORGOT_PASSWORD_REQUEST ="@auth/POST_FORGOT_PASSWORD_REQUEST";
export const POST_FORGOT_PASSWORD_SUCCESS ="@auth/POST_FORGOT_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_FAILURE ="@auth/POST_FORGOT_PASSWORD_FAILURE";

export const POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_REQUEST ="@auth/POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_REQUEST";
export const POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_SUCCESS ="@auth/POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_FAILURE ="@auth/POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_FAILURE";

export const SET_NEW_PASSWORD_REQUEST = '@auth/SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_FAILURE = '@auth/SET_NEW_PASSWORD_FAILURE'
export const SET_NEW_PASSWORD_SUCCESS = '@auth/SET_NEW_PASSWORD_SUCCESS'

export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';

export const SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = '@auth/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = '@auth/SIGN_UP_FAILURE';

export const HANDLE_CHANGE_EMAIL_REQUEST = '@auth/HANDLE_CHANGE_EMAIL_REQUEST';
export const HANDLE_CHANGE_EMAIL_SUCCESS = '@auth/HANDLE_CHANGE_EMAIL_SUCCESS';
export const HANDLE_CHANGE_EMAIL_FAILURE = '@auth/HANDLE_CHANGE_EMAIL_FAILURE';

export const LOGOUT = '@auth/LOGOUT';
