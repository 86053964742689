import React, { useEffect, useMemo, useState } from "react";

// asset
import {
  AlertIcon,
  SuccessMiniIcon,
  InfoIcon,
  DeleteIcon,
} from "../../asset/icon";

// component
import { Flex, Box, Text } from "../Styles";

export type Props = {
  /** alert type that define icon and background color */
  type: "alert" | "success" | "info" | "delete";
  /** title alert  */
  title: string;
  /** description alert */
  description?: string;
};

const Alert = (props: Props) => {
  const { type, title, description } = props;

  /** get icon acordding type prop */
  const getIcon = () => {
    switch (type) {
      case "alert":
        return <img src={AlertIcon} width="16" height="16" alt="alert" />;
      case "success":
        return (
          <img src={SuccessMiniIcon} width="16" height="16" alt="success" />
        );
      case "info":
        return <img src={InfoIcon} width="16" height="16" alt="info" />;
      case "delete":
        return <img src={DeleteIcon} width="16" height="16" alt="delete" />;
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case "alert":
        return "coralLighter";
      case "success":
        return "green";
      case "info":
        return "#D4F7F780";
      case "delete":
        return "whiteDarker";
    }
  };

  const getTextColor = () => {
    switch (type) {
      case "alert":
        return "coralDark";
      case "success":
        return "greenDark";
      case "info":
        return "blueDark";
      case "delete":
        return "whiteDark";
    }
  };

  return (
    <Flex
      maxWidth="560px"
      p="0"
      px="1"
      backgroundColor={getBackgroundColor()}
      borderRadius="4px"
    >
      <Flex justifyContent="center" alignItems="center">
        {getIcon()}
      </Flex>
      <Flex flexDirection="column" ml={0}>
        <Text
          fontSize={2}
          lineHeight={2}
          fontWeight="700"
          color={getTextColor()}
        >
          {title}
        </Text>
        <Text fontSize={1} lineHeight={1} color={getTextColor()}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Alert;
