import styled from 'styled-components';
import {typography, TypographyProps, space, SpaceProps, color, variant } from 'styled-system';


//constant
import theme from '../../constant/styledTheme'

const Text = styled.div<TypographyProps & SpaceProps &  { breakAll? :boolean, rubik?: boolean, variant?: 'xs' | 'sm' | 'rg' | 'md' | 'lg'} >`
    ${typography};
    ${space};
    ${color};

    font-family: ${({ rubik }) =>rubik ? 'Rubik': "Nunito" };
    word-break: ${({ breakAll }) => breakAll? 'break-all': "break-word" };

    > .danger {
        color: ${theme.colors.coralDark};
    }

    > .success {
        color: ${theme.colors.greenDark};
    }

    > .bold {
        font-weight: bold
    }

    a {
        text-decoration: none;
        color: ${theme.colors.blueDark};
    }

    > .link {
        color: ${theme.colors.blueDark};
    }
    
`

const ExtendText = styled(Text)(
    variant({
        variants: {
            xs: {
                lineHeights: "0",
                fontSize: "0"
            },
            sm: {
                lineHeights: "1",
                fontSize: "1"
            },
            rg: {
                lineHeights: "2",
                fontSize: "2"
            },
            md: {
                lineHeights: "3",
                fontSize: "3"
            },
            lg: {
                lineHeights: "4",
                fontSize: "4"
            }
        }
    })
)

export default ExtendText