import moment from 'moment'

export const getValidDate = (stringDate: string | undefined | null) => {
    if (
      stringDate === "0000-00-00 00:00:00" ||
      !stringDate ||
      stringDate === "None"
    ) {
      return null;
    }

    return moment(stringDate).toDate();
  };
