import styled from 'styled-components';
import {space, SpaceProps, layout, LayoutProps, border, BorderProps } from 'styled-system';

const BodyPage = styled.div<SpaceProps & LayoutProps & BorderProps >`
    ${space};
    ${layout};
    ${border}

    width: 100%;
    max-width: 1400px;
`

export default BodyPage