import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";

// type
import { States } from "../types/general";

// pages
import Account from "../page/Account";
import HistoryDetail from "../page/History/HistoryDetail";

import Transaction from "../page/Transaction/Transaction";
import TransactionDetail from "../page/TransactionDetail/TransactionDetail";
import Checkout from "../page/Checkout/Checkout";
import ForgotPass from "../page/ForgotPass/ForgotPass";
import ForgotPassSent from "../page/ForgotPass/ForgotPassSent";
import CreateNewPassword from "../page/ForgotPass/CreateNewPassword";
import Invitation from "../page/Invitation/Invitation";
import InviteEmployee from "../page/Employee/EnployeeInvite";

import Inventory from "../page/Inventory/Inventory";
import InventoryBatchHistory from "../page/Inventory/InventoryBatchHistory";
import InventoryDetail from "../page/Inventory/InventoryDetail";
import InventoryStockHistory from "../page/Inventory/InventoryStockHistory";

import InvoiceEdit from "../page/Invoice/InvoiceEdit";
import InvoiceDetail from "../page/Invoice/InvoiceDetail";
import Invoice from "../page/Invoice/Invoice";
import { PBFPage } from "../page/PBF";
import { Catalog } from "../page/Catalog";
import { Company } from "../page/Company";
import { History, HistorySearch } from "../page/History";
import { ConfirmationChangeEmail } from "../page/Confirmation";
import { Confirmation } from "../page/Confirmation";
import { SignUpPage } from "../page/SignUp";
import { LoginPage } from "../page/Login";
import { Home } from "../page/Home";
import { EmployeePage } from "../page/Employee";
import { LoginRoute } from "./LoginRoute";

// import Doctor from "../page/Doctor/Doctor";
// import DoctorForm from "../page/Doctor/DoctorForm";
// import Recipe from "../page/Recipe/Recipe";
// import Patient from "../page/Patient/Patient";
// import PatientForm from "../page/Patient/PatientForm";

const MainRoutes = () => {
  const profile = useSelector((state: States) => state.profile.profile);

  return (
    <Switch>
      <Route exact path="/create-password" component={Invitation} />
      <Route exact path="/register" component={SignUpPage} />
      <LoginRoute exact path="/signin" component={LoginPage} />
      <Route exact path="/account" component={Account} />
      <Route exact path="/confirm" component={Confirmation} />
      <Route exact path="/forgot-password" component={ForgotPass} />
      <Route exact path="/forgot-password/sent" component={ForgotPassSent} />
      <Route exact path="/forgot-password/new" component={CreateNewPassword} />
      <Route exact path="/change-email" component={ConfirmationChangeEmail} />

      <PrivateRoute exact path="/" component={Home} />

      {/* PBF Route */}
      <PrivateRoute exact path="/pbf" component={PBFPage} />

      {/* History route */}
      <PrivateRoute exact path="/history" component={History} />
      <PrivateRoute exact path="/history/search" component={HistorySearch} />

      <PrivateRoute exact path="/company" component={Company} />

      {/* employee route */}
      <PrivateRoute
        exact
        path="/employee"
        component={EmployeePage}
        profile={profile}
        isOwner
      />
      <PrivateRoute
        exact
        path="/employee/invite"
        component={InviteEmployee}
        profile={profile}
        isOwner
      />

      {/* transaction route */}
      <PrivateRoute exact path="/transaction" component={Transaction} />
      <PrivateRoute
        exact
        path="/history/:transactionID"
        component={HistoryDetail}
      />
      <PrivateRoute
        exact
        path="/transaction/:transactionID"
        component={TransactionDetail}
      />

      {/* invoice page */}
      <PrivateRoute exact path="/invoice" component={Invoice} />
      <PrivateRoute exact path="/invoice/new" component={InvoiceEdit} />
      <PrivateRoute
        exact
        path="/invoice/:invoiceID"
        component={InvoiceDetail}
      />
      <PrivateRoute
        exact
        path="/invoice/edit/:invoiceID"
        component={InvoiceEdit}
      />

      {/* inventory page */}
      <PrivateRoute exact path="/inventory" component={Inventory} />
      <PrivateRoute
        exact
        path="/inventory/:inventoryID"
        component={InventoryDetail}
      />
      <PrivateRoute
        exact
        path="/inventory/:inventoryID/update-stock/:updateStockID"
        component={InventoryDetail}
      />
      <PrivateRoute
        exact
        path="/inventory/:inventoryID/history"
        component={InventoryBatchHistory}
      />
      <PrivateRoute
        exact
        path="/inventory/:inventoryID/stock"
        component={InventoryStockHistory}
      />

      <PrivateRoute
        exact
        path="/checkout/:transactionID"
        component={Checkout}
      />

      <PrivateRoute exact path="/goods" component={Catalog} />
      <Route component={() => <div></div>} />
    </Switch>
  );
};

export default MainRoutes;
