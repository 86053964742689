import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { setEmployeeFilter } from "../../reducers/home/home.action";
import { getDashboardSummaryAsync } from "../../reducers/home/home.action";

// asset
import CloseIcon from "../../asset/icon/close.svg";
import TrashIcon from "../../asset/icon/delete.svg";

//component
import TopBar from "../../component/TopBar";
import { Button, TouchableButton } from "../../component/Button";
import { Modal, Overlay, Body } from "../../component/Styles";
import EmployeeItem from "./HomeEmployeeItem";

// type
import { general } from "../../types";
import { device } from "../../constant/media";

export type HomeEmployeeModalFilterProps = {
  isVisible: boolean;
  onCloseModal: () => void;
};

const HomeEmployeeModalFilter = (props: HomeEmployeeModalFilterProps) => {
  const { isVisible, onCloseModal } = props;

  const dispatch = useDispatch();

  const userList = useSelector(
    (states: general.States) => states.employee.list
  );

  const employeeFilter = useSelector(
    (states: general.States) => states.home.employeeFilter
  );

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => onCloseModal()}
      />
    );
  };

  const renderRightHeader = () => {
    return (
      <TouchableButton
        icon={TrashIcon}
        onClick={() => resetEmployeeFilter()}
        alt="delete"
      />
    );
  };

  const resetEmployeeFilter = () => {
    dispatch(setEmployeeFilter({}));
  };

  const renderEmployeeList = useMemo(() => {
    return userList.map((u, index) => (
      <EmployeeItem
        employee={u}
        isChecked={employeeFilter[u.id]}
        onClick={() => onClickEmployee(u.id)}
        key={`home-filter-employee-item-${index}`}
      />
    ));
  }, [userList, employeeFilter]);

  const onClickEmployee = (id: string) => {
    let filterValue = !employeeFilter[id];

    dispatch(setEmployeeFilter({ ...employeeFilter, [id]: filterValue }));
  };

  const onClickFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(getDashboardSummaryAsync.request());
    onCloseModal();
  };

  return (
    <Overlay isVisible={isVisible}>
      <ModalContainer isVisible={isVisible}>
        <TopBar
          title="Pegawai"
          leftHeader={renderLeftHeader}
          rightHeader={renderRightHeader}
          isModal
        />
        <BodyPage>
          <form onSubmit={(e) => onClickFilter(e)}>
            <div className="employee-filter__list">{renderEmployeeList}</div>
            <Button title="Filter" fullWidth type="submit" />
          </form>
        </BodyPage>
      </ModalContainer>
    </Overlay>
  );
};

const ModalContainer = styled(Modal)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  max-width: 100vh;

  @media ${device.md} {
    width: 400px;
  }
`;

const BodyPage = styled(Body)`
  padding: 24px;

  .employee-filter__list {
    margin-bottom: 26px;
    overflow-y: scroll;
    max-height: calc(100vh - 60px - 125px);
  }
`;

export default HomeEmployeeModalFilter;
