import styled from "styled-components";
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  background,
  BackgroundProps,
} from "styled-system";

const Flex = styled.div<
  ColorProps &
    SpaceProps &
    FlexboxProps &
    BorderProps &
    LayoutProps &
    PositionProps &
    ShadowProps &
    BackgroundProps
>`
  ${border};
  ${space};
  ${flexbox};
  ${layout};
  ${position};
  ${shadow};
  ${background};
  ${color};

  display: flex;
`;

export default Flex;
