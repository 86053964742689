export const GET_PBF_LIST_REQUEST = '@pbf/GET_PBF_LIST_REQUEST';
export const GET_PBF_LIST_SUCCESS = '@pbf/GET_PBF_LIST_SUCCESS';
export const GET_PBF_LIST_FAILURE = '@pbf/GET_PBF_LIST_FAILURE';

export const ADD_PBF_REQUEST = '@pbf/ADD_PBF_REQUEST';
export const ADD_PBF_SUCCESS = '@pbf/ADD_PBF_SUCCESS';
export const ADD_PBF_FAILURE = '@pbf/ADD_PBF_FAILURE';

export const UPDATE_PBF_REQUEST = '@pbf/UPDATE_PBF_REQUEST';
export const UPDATE_PBF_SUCCESS = '@pbf/UPDATE_PBF_SUCCESS';
export const UPDATE_PBF_FAILURE = '@pbf/UPDATE_PBF_FAILURE';

export const DELETE_PBF_REQUEST = '@pbf/DELETE_PBF_REQUEST';
export const DELETE_PBF_SUCCESS = '@pbf/DELETE_PBF_SUCCESS';
export const DELETE_PBF_FAILURE = '@pbf/DELETE_PBF_FAILURE';

export const SHOW_EDIT_MODAL_PBF = '@pbf/SHOW_EDIT_MODAL_PBF';
export const SET_SELECTED_PBF = '@pbf/SHOWS_SELECTED_PBF'