export const calculateDiscount = (
    discountType: "percentage" | "cash" | string,
    amount: number,
    total: number
  ) => {
    let totalDiscount = amount;

    if (amount) {
      if (discountType === "percentage") {
        totalDiscount = (amount / 100) * total;
      }
    }

    return totalDiscount;
  };