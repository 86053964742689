import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

// redux action
import { setSelectedTransaction } from "../../reducers/transaction/transaction.action";

// constant
import theme from "../../constant/styledTheme";

// component
import { Flex, Text, Box } from "../../component/Styles";
import { ChevronNext } from "../../asset/icon";
import InitialName from "../../component/InitialName/InitialName";

// type
import { transaction } from "../../types";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { TouchableButton } from "../../component/Button";

export type TransactionItemProps = {
  transaction: transaction.Transaction;
  index: number;
};

const TransactionItem = (props: TransactionItemProps) => {
  const dispatch = useDispatch();

  const { transaction, index } = props;
  const { unique_id, total_basket_size, id } = transaction;

  const handleClick = () => {
    dispatch(push(`/transaction/${id}`));
    dispatch(setSelectedTransaction(transaction));
  };

  const renderUniqueProductList = useMemo(() => {
    if (transaction.unique_product_list) {
      return transaction.unique_product_list.join(", ");
    }

    return "Belum ada barang";
  }, [transaction.unique_product_list]);

  return (
    <Card href="" id={`transaction-item-${index}`}>
      <Container
        borderTop={index === 0 ? "1px solid" : undefined}
        borderBottom="1px solid"
        borderColor="blackLighter"
        justifyContent="space-between"
        p={0}
        onClick={() => handleClick()}
      >
        <Flex flexDirection="column">
          <Text variant="rg" color="blueDark" fontWeight="bold">
            #{unique_id}
          </Text>
          <Text variant="sm">{renderUniqueProductList}</Text>
          {transaction.creator ? (
            <Box mt={1}>
              <InitialName name={transaction.creator || ""} />
            </Box>
          ) : null}
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text variant="rg" fontWeight="bold">
            Rp{nominalFormat(total_basket_size)}
          </Text>
          <TouchableButton icon={ChevronNext} alt="detail" />
        </Flex>
      </Container>
    </Card>
  );
};

const Card = styled.a`
  text-decoration: none;
  color: ${theme.colors.black};
`;

const Container = styled(Flex)`
  cursor: pointer;
`;

export default TransactionItem;
