import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './company.action'

// actions types
import * as types from './company.actionTypes'

// type
import { company } from '../../types/index'
export type CompanyActions = ActionType<typeof actions>;

export type CompanyState = {
    list: company.Company[];
    isLoading: boolean;
    selectedCompany: null | company.Company;
    showAddCompanyModal: boolean;
    showInProgressModal: boolean;
}

const initialState: CompanyState = {
    list: [],
    isLoading: false,
    selectedCompany: null,
    showAddCompanyModal: false,
    showInProgressModal: false
}

export const companyReducer = createReducer<CompanyState, CompanyActions>(initialState)
    .handleAction(actions.getCompanyListASync.request, (state: CompanyState, action: ActionType<typeof actions.getCompanyListASync.request>) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.getCompanyListASync.success, (state: CompanyState, action: ActionType<typeof actions.getCompanyListASync.success>) => ({ 
        ...state,
        isLoading: false,
        list: action.payload
    }))
    .handleAction(actions.getCompanyListASync.failure, (state: CompanyState ) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.addCompanyAsync.request, (state: CompanyState ) => ({ 
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.addCompanyAsync.success, (state: CompanyState, action: ActionType<typeof actions.addCompanyAsync.success> ) => ({ 
        ...state,
        isLoading: false,
        showInProgressModal: true,
        selectedCompany: action.payload.company
    }))
    .handleAction(actions.addCompanyAsync.failure, (state: CompanyState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(types.SET_SELECTED_COMPANY as any, (state: CompanyState, action: ActionType<typeof actions.setSelectedCompany>) => ({
        ...state,
        selectedCompany: action.payload.company
    }))
    .handleAction(types.SHOW_ADD_COMPANY_MODAL as any, (state: CompanyState, action: ActionType<typeof actions.showAddCompanyModal>) => ({
        ...state,
        showAddCompanyModal: action.payload.visible
    }))
    .handleAction(types.SHOW_IN_PROGRESS_MODAL as any, (state: CompanyState, action: ActionType<typeof actions.setShowInprogressModal>) => ({
        ...state,
       showInProgressModal: action.payload.visible
    }))
 