import {  createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./inventory.actionTypes";

// type
import { Inventory, InventoryDetail, InventoryStock } from "../../types/inventory";
import { QueryParams } from "../../types/general";

export const getInventoryListAsync = createAsyncAction(
  types.GET_INVENTORY_LIST_REQUEST,
  types.GET_INVENTORY_LIST_SUCCESS,
  types.GET_INVENTORY_LIST_FAILURE
)<{[key: string]: any} & { page?: number } , Inventory, undefined>();

export const getInventoryDetailAsync = createAsyncAction(
  types.GET_INVENTORY_DETAIL_REQUEST,
  types.GET_INVENTORY_DETAIL_SUCCESS,
  types.GET_INVENTORY_DETAIL_FAILURE
)<{productID: string} & QueryParams, InventoryDetail, undefined>();

export const getStockListAsync = createAsyncAction(
  types.GET_STOCK_LIST_REQUEST,
  types.GET_STOCK_LIST_SUCCESS, 
  types.GET_STOCK_LIST_FAILURE
)<{productID: string} & QueryParams, InventoryStock, undefined>();



