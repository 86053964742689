import { put, takeLeading, select } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// related redux
import * as actions from "./catalog.action";
import * as types from "./catalog.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";
import { showToast } from "../toast/toast.action";

// redux action
import { getProductListAsync } from "../product/product.action";
import { generateSortFilterQuery } from "../../utils/generateSortFilterQuery";
import { setShowTransactionModalDetail } from "../transaction/transaction.action";
import { handleLogout } from "../auth/auth.action";

function* postCatalog(
  action: ReturnType<typeof actions.postCatalogAsync.request>
) {
  try {
    const payload = action.payload;
    const companyID = localStorageService.getCompanyID();

    const state = yield select();
    const searchFilter = state.catalog.searchFilter;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/product`;
    const response = yield axios.post(url, payload);

    if (response && response.status === 201) {
      const query = generateSortFilterQuery({ search: searchFilter });
      yield put(getProductListAsync.request(query));
      yield put(
        showToast.request({
          message: "Berhasil menambahkan barang",
          type: "success",
          duration: 5000,
        })
      );
      yield put(actions.setShowAddCatalogModal(false));
    }
  } catch (err) {
    yield put(actions.postCatalogAsync.failure())

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.POST_CATALOG_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Tidak dapat menambahkan barang saat ini.",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* getProductDetail(
  action: ReturnType<typeof actions.getProductDetailAsync.request>
) {
  try {
    const { productID } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }
    const url = `${BASE_URL_BETA}/company/${companyID}/product/${productID}`;

    const response = yield axios.get(url);

    if (response && response.status === 200) {
      const productDetail = response.data.data;
      yield put(actions.getProductDetailAsync.success(productDetail));
    }
  } catch (err) {
    yield put(actions.getProductDetailAsync.failure());
    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.GET_PRODUCT_DETAIL_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Tidak dapat memuat kemasan",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* patchProductPrice(
  action: ReturnType<typeof actions.patchProductPriceAsync.request>
) {
  try {
    const { productID, price } = action.payload;
    const companyID = localStorageService.getCompanyID();

    const state = yield select();
    const searchFilter = state.catalog.searchFilter;

    if (!companyID) {
      throw new Error("Company ID not found");
    }
    const url = `${BASE_URL_BETA}/company/${companyID}/product/${productID}`;
    const response = yield axios.patch(url, { price });

    if (response && response.status === 201) {
      yield put(actions.patchProductPriceAsync.success());
      yield put(getProductListAsync.request({ search: searchFilter || "" }));
      yield put(actions.getProductDetailAsync.request({ productID }));
      yield put(setShowTransactionModalDetail(true));
    }
  } catch (err) {
    yield put(actions.patchProductPriceAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.PATCH_PRODUCT_PRICE_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Tidak dapat mengubah harga product",
        duration: 5000,
        type: "error",
      })
    );
  }
}


function* updateCatalog(
  action: ReturnType<typeof actions.updateCatalogAsync.request>
) {
  try {
    const { name, price, unit, id, unit_code } = action.payload;
    const companyID = localStorageService.getCompanyID();

    const state = yield select();
    const searchFilter = state.catalog.searchFilter;

    if (!companyID) {
      throw new Error("Company ID not found");
    }
    const url = `${BASE_URL_BETA}/company/${companyID}/product/${id}`;

    const bodyRequest = {
      name: name,
      price: price,
      unit: unit,
      unit_code,
    };

    const response = yield axios.put(url, bodyRequest);

    if (response && response.status === 201) {
      yield put(actions.setShowAddCatalogModal(false));
      yield put(
        showToast.request({
          message: "Sukses meng-update barang",
          duration: 5000,
          type: "success",
        })
      );

      const query = generateSortFilterQuery({ search: searchFilter });
      yield put(getProductListAsync.request(query));
    }
  } catch (err) {
    yield put(actions.updateCatalogAsync.failure());

    if (err.response.status === 410) {
      yield put(
        showToast.request({
          message: "Barang tidak tersedia.",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.UPDATE_CATALOG_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal memberbaharui barang",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* deleteCatalog(
  action: ReturnType<typeof actions.deleteCatalogAsync.request>
) {
  try {
    const { id } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const state = yield select();
    const searchFilter = state.catalog.searchFilter;

    const token = localStorageService.getToken();
    const url = `${BASE_URL_BETA}/company/${companyID}/product/${id}`;

    const response = yield axios.delete(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response && response.status === 200) {
      const query = generateSortFilterQuery({ search: searchFilter });
      yield put(getProductListAsync.request(query));
      yield put(actions.setShowAddCatalogModal(false));
      yield put(showToast.request({ type: "deleted", message: "Berhasil menghapus barang", duration: 5000 }))
    }
  } catch (err) {
    yield put(actions.deleteCatalogAsync.failure());
    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.DELETE_CATALOG_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal mengahapus barang",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* uploadCSV(action: ReturnType<typeof actions.uploadCSVAsync.request>) {
  try {
    const { file } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }
    const url = `${BASE_URL_BETA}/company/${companyID}/product-bulk`;
    const data = new FormData();
    data.append("file", file);

    const response = yield axios.post(url, data);

    if (response && response.status === 200) {
      yield put(actions.setShowUploadCSVModal(false));
      yield put(actions.uploadCSVAsync.success());
      yield put(getProductListAsync.request({}));
      yield put(
        showToast.request({
          message: "Berhasil upload",
          duration: 5000,
          type: "success",
        })
      );
    }
  } catch (err) {
    if (err.response.status === 406) {
      yield put(
        actions.uploadCSVAsync.failure({ link: err.response.data.data.link })
      );
      yield put(
        showToast.request({
          message: "Upload gagal dilakukan",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    yield put(actions.uploadCSVAsync.failure({ link: "" }));

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 422) {
      yield put(
        showToast.request({
          message: "Dokumen tidak sesuai",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    errorReporter(err, types.UPLOAD_CSV_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Upload gagal dilakukan",
        duration: 5000,
        type: "error",
      })
    );
  }
}

export default function* catalogSaga() {
  yield takeLeading(types.POST_CATALOG_REQUEST, postCatalog);
  yield takeLeading(types.UPDATE_CATALOG_REQUEST, updateCatalog);
  yield takeLeading(types.DELETE_CATALOG_REQUEST, deleteCatalog);
  yield takeLeading(types.UPLOAD_CSV_REQUEST, uploadCSV);
  yield takeLeading(types.GET_PRODUCT_DETAIL_REQUEST, getProductDetail);
  yield takeLeading(types.PATCH_PRODUCT_PRICE_REQUEST, patchProductPrice);
}
