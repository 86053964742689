import { createAsyncAction, action } from "typesafe-actions";

// action types
import * as types from "./employee.actionTypes";

// type
import { User } from "../../types/user";

export const getUserListAsync = createAsyncAction(
  types.GET_USER_LIST_REQUEST,
  types.GET_USER_LIST_SUCCESS,
  types.GET_USER_LIST_FAILURE
)<undefined, User[], undefined>();

export const sendInvitation = createAsyncAction(
  types.SEND_INVITATION_REQUEST,
  types.SEND_INVITATION_SUCCESS,
  types.SEND_INVITATION_FAILURE
)<{email: string, role: string, type: 'change-owner' | 'update-status' | 'self-to-employee' | 'default' }, undefined, { error: string }>();

export const deleteUserAsync = createAsyncAction(
  types.DELETE_USER_REQUEST,
  types.DELETE_USER_SUCCESS,
  types.DELETE_USER_FAILURE
)<{ email: string, name: string, type: 'remove-single-owner' | 'default' }, undefined, undefined>();

export const setSelectedUser = (user: User) => action(types.SET_SELECTED_USER, {user})

export const showModalEmployeeDetail = (value: boolean) => action(types.SET_SHOW_EMPLOYEE_DETAIL_MODAL, { value })