import { put, takeLeading, select, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";

// config
import axios from "../../config/axios";

// redux
import * as actions from "./employee.action";
import * as types from "./employee.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

function* getUserList(
  action: ReturnType<typeof actions.getUserListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/user?company_id=${companyID}`;
    const response = yield axios.get(url);

    if (response && response.status === 200) {
      yield put(actions.getUserListAsync.success(response.data.list_user));
    }
  } catch (err) {
    yield put(actions.getUserListAsync.failure())

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if(err.response.status === 451){
      yield put(showToast.request({ message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
    }

    errorReporter(err, types.GET_USER_LIST_REQUEST);
    yield put(
      showToast.request({
        duration: 5000,
        message: "Gagal memuat",
        type: "error",
      })
    );
  }
}

function* sendInvitation(
  action: ReturnType<typeof actions.sendInvitation.request>
) {
  try {
    const { email, role, type } = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/invite-user`;
    const payload = {
      email,
      company_id: companyID,
      role,
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 200) {
      yield put(actions.sendInvitation.success());
      yield put(actions.getUserListAsync.request());
      yield put(actions.showModalEmployeeDetail(false));

      const getCopySuccessInvitation = () => {
        if (payload.role === "owner") {
          return `${payload.email} telah diundang sebagai pemilik`;
        }

        return `${payload.email} telah diundang sebagai pegawai`;
      };

      switch (type) {
        case "self-to-employee":
          yield put(
            showToast.request({
              duration: 5000,
              type: "success",
              message: "Posisi kamu telah diperbaharui, silahkan login kembali",
            })
          );
          yield put(handleLogout());
          localStorageService.clearToken();
          break;
        case "update-status":
          yield put(
            showToast.request({
              duration: 5000,
              type: "success",
              message: "Posisi telah berhasil diperbaharui",
            })
          );
          yield put(push("/employee"));
          break;
        case "change-owner":
          yield put(
            showToast.request({
              duration: 5000,
              type: "success",
              message: "Kamu telah keluar dari Apotek",
            })
          );
          break;
        default:
          yield put(
            showToast.request({
              duration: 5000,
              type: "success",
              message: getCopySuccessInvitation(),
            })
          );
          yield put(push("/employee"));
          break;
      }
    }
  } catch (err) {
    yield put(
      actions.sendInvitation.failure({ error: "Failed update user status" })
    );

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 409) {
      yield put(
        showToast.request({
          duration: 5000,
          type: "success",
          message: "User sudah terdaftar",
        })
      );
      return;
    }
    yield put(
      showToast.request({
        duration: 5000,
        type: "error",
        message: "Gagal memproses permintaan",
      })
    );

    errorReporter(err, types.SEND_INVITATION_REQUEST, action.payload);
  }
}

function* deleteUser(
  action: ReturnType<typeof actions.deleteUserAsync.request>
) {
  try {
    const { email, name, type } = action.payload;
    const companyID = localStorageService.getCompanyID();

    // get user profile
    const state = yield select();
    const profile = state.profile.profile;

    if (!companyID) {
      throw new Error("Company ID not found");
    }
    const url = `${BASE_URL_BETA}/user`;

    const response = yield axios.delete(url, {
      params: {
        email,
        company_id: companyID,
      },
    });

    if (response && response.status === 200) {
      yield put(actions.showModalEmployeeDetail(false));
      yield put(actions.getUserListAsync.request());
      yield put(actions.sendInvitation.success());
      yield put(
        showToast.request({
          duration: 5000,
          type: "deleted",
          message: `Akses ${name} telah ditutup`,
        })
      );

      if (type === "default") {
        if (profile.email === email) {
          yield put(handleLogout());
        }
      } else {
        yield put(handleLogout());
        localStorageService.clearToken();
      }
    }
  } catch (err) {
    yield put(actions.deleteUserAsync.failure())

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.DELETE_USER_REQUEST, action.payload);
    yield put(
      showToast.request({
        duration: 5000,
        type: "error",
        message: "Gagal menutup akses",
      })
    );
  }
}

export default function* transactionSaga() {
  yield takeLeading(types.GET_USER_LIST_REQUEST, getUserList);
  yield takeEvery(types.SEND_INVITATION_REQUEST, sendInvitation);
  yield takeLeading(types.DELETE_USER_REQUEST, deleteUser);
}
