
import styled from 'styled-components/macro';
import {typography, TypographyProps, space, SpaceProps} from 'styled-system';


export const H1 = styled.h1<TypographyProps & SpaceProps>`
    ${typography};
    ${space};

    font-family: "Rubik";
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
    margin: 0;
`

export const H2 = styled.h2<TypographyProps & SpaceProps>`
    ${typography};
    ${space};

    font-family: "Rubik";
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 0;
`

export const H3 = styled.h3<TypographyProps & SpaceProps>`
    ${typography};
    ${space};

    font-family: "Rubik";
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    margin: 0;
   
`

export const H4 = styled.h4<TypographyProps & SpaceProps>`
    ${typography};
    ${space};

    font-family: "Rubik";
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0; 
`