export const SET_SELECTED_PRODUCT = '@transaction/SET_SELECTED_PRODUCT';
export const SET_SELECTED_TRANSACTION_PRODUCT = '@transaction/SET_SELECTED_TRANSACTION_PRODUCT'
export const SET_SELECTED_TRANSACTION = '@transaction/SET_SELECTED_TRANSACTION';

export const SET_SHOW_TRANSACTION_MODAL_DETAIL = '@transaction/SET_SHOW_TRANSACTION_MODAL_DETAIL'

export const UPDATE_TRANSACTION_PRODUCT_LIST = '@transaction/UPDATE_TRANSACTION_PRODUCT_LIST'
export const UPDATE_TRANSACTION_LIST = '@transaction/UPDATE_TRANSACTION_LIST'

export const GET_TRANSACTIONS_REQUEST = '@transaction/GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = '@transaction/GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = '@transaction/GET_TRANSACTIONS_FAILURE'

export const CREATE_TRANSACTION_REQUEST = '@transaction/CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = '@transaction/CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_FAILURE = '@transaction/CREATE_TRANSACTION_FAILURE'

export const GET_TRANSACTION_DETAIL_REQUEST = '@transaction/GET_TRANSACTION_DETAIL_REQUEST';
export const GET_TRANSACTION_DETAIL_SUCCESS = '@transaction/GET_TRANSACTION_DETAIL_SUCCESS'
export const GET_TRANSACTION_DETAIL_FAILURE = '@transaction/GET_TRANSACTION_DETAIL_FAILURE'

export const UPDATE_PRODUCT_TRANSACTION_REQUEST = '@transaction/UPDATE_PRODUCT_TRANSACTION_REQUEST'
export const UPDATE_PRODUCT_TRANSACTION_SUCCESS = '@transaction/UPDATE_PRODUCT_TRANSACTION_SUCCESS'
export const UPDATE_PRODUCT_TRANSACTION_FAILURE = '@transaction/UPDATE_PRODUCT_TRANSACTION_FAILURE'

export const ADD_PRODUCT_TRANSACTION_REQUEST = '@transaction/ADD_PRODUCT_TRANSACTION_REQUEST'
export const ADD_PRODUCT_TRANSACTION_SUCCESS = '@transaction/ADD_PRODUCT_TRANSACTION_SUCCESS'
export const ADD_PRODUCT_TRANSACTION_FAILURE = '@transaction/ADD_PRODUCT_TRANSACTION_FAILURE'

export const DELETE_PRODUCT_TRANSACTION_REQUEST = '@transaction/DELETE_PRODUCT_TRANSACTION_REQUEST'
export const DELETE_PRODUCT_TRANSACTION_SUCCESS = '@transaction/DELETE_PRODUCT_TRANSACTION_SUCCESS'
export const DELETE_PRODUCT_TRANSACTION_FAILURE = '@transaction/DELETE_PRODUCT_TRANSACTION_FAILURE'

export const DELETE_TRANSACTION_REQUEST = '@transaction/DELETE_TRANSACTION_REQUEST'
export const DELETE_TRANSACTION_SUCCESS = '@transaction/DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAILURE = '@transaction/DELETE_TRANSACTION_FAILURE'


