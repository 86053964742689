import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// redux action
import { getUpdateStockDetailAsync } from "../../../reducers/stock/stock.action";

// component
import {
  Wrapper,
  BodyPage,
  Box,
  Flex,
  Overlay,
  Modal,
  Text,
  RotatedBox,
  H3,
} from "../../../component/Styles";
import { Spinner } from "../../../component/Loading";
import TopBar from "../../../component/TopBar";
import Header from "../../../component/Header/Header";

// related component
import InventoryDetailBatchList from "../InventoryDetailBatchList";

// type
import { States } from "../../../types/general";
import { InventoryBatch } from "../../../types/inventory";

// utils
import { getURLPathID } from "../../../utils/getURLPathID";
import { getQtyOperator } from "../utils/getQtyOperator";

// Asset
import { ArrowBackIcon } from "../../../asset/icon";
import { nominalFormat } from "../../../utils/nominalFormat";
import { getProductDetailAsync } from "../../../reducers/catalog/catalog.action";

export type Props = {
  /** GET - inventoryID */
  inventoryID: string;
};

const InventoryUpdateStock = (props: Props) => {
  const dispatch = useDispatch();

  const { inventoryID } = props;

  // Redux Store
  const location = useSelector((state: States) => state.router.location);
  const isLoading = useSelector((state: States) => state.stock.isLoading);
  const productDetail = useSelector(
    (state: States) => state.catalog.productDetail
  );
  const stockDetail = useSelector((state: States) => state.stock.stockDetail);

  // state
  const [activeFilter, setActiveFilter] = useState({
    sorted_by: "batch_no",
    order_by: "asc",
  });

  const getUpdateStockID = useMemo(() => {
    const updateStockID = getURLPathID(location.pathname, 4);
    const path = getURLPathID(location.pathname, 3);

    if (path === "update-stock") {
      return updateStockID;
    }

    return null;
  }, [location]);

  const handleChangeActiveFilter = (value: any) => {
    setActiveFilter(value);
  };

  const getTotalBeforeUpdate = useMemo(() => {
    let total = 0;

    (stockDetail?.stock_list || []).forEach((s) => {
      total += s.batch_qty || 0;
    });

    return total;
  }, [stockDetail?.stock_list]);

  const getTotal = useMemo(() => {
    let total = 0;
    (stockDetail?.stock_list || []).forEach((s) => {
      total += s.updated_qty || 0;
    });

    return total;
  }, [stockDetail?.stock_list, productDetail]);

  const getTotalChanges = useMemo(() => {
    return getTotal - getTotalBeforeUpdate;
  }, [getTotal, productDetail]);

  /**
   * get batch list, override current stock if the batch is already edited
   */
  const getBatchList = useMemo(() => {
    let list: InventoryBatch[] = (stockDetail?.stock_list || []).map((s) => {
      return {
        id: s.id,
        ED: s.ED!,
        batch_no: s.batch_no,
        batch_qty: s.batch_qty!,
        date_purchase: s.date_purchase!,
        unit: s.unit,
        current_qty: s.updated_qty,
      };
    });

    return list;
  }, [stockDetail?.stock_list]);

  const emptyBatch = useMemo(() => {
    if (!getBatchList.length) {
      return true;
    }

    return false;
  }, [getBatchList]);

  useEffect(() => {
    if (inventoryID && !isNaN(+inventoryID) && getUpdateStockID) {
      dispatch(
        getProductDetailAsync.request({
          productID: inventoryID,
        })
      );

      dispatch(
        getUpdateStockDetailAsync.request({
          productID: inventoryID,
          stockID: getUpdateStockID,
        })
      );
    }
  }, [inventoryID, getUpdateStockID]);

  return (
    <>
      <Overlay isVisible>
        {/* Modal for update stock based on batch or unit */}
        <Modal isVisible>
          <TopBar title={productDetail?.name || ""} isModal />
          <Wrapper
            p="2"
            pb="5"
            overflowY="scroll"
            maxHeight="calc(100vh - 52px - 52px - 4rem)"
            height="calc(100vh - 52px - 52px - 4rem )"
          >
            <BodyPage>
              <Header title="Update stok" />
              {isLoading ? (
                <Flex mt={3} justifyContent="center">
                  <Spinner />
                </Flex>
              ) : (
                <>
                  {" "}
                  <Box mt={1}>
                    <Text variant="rg">
                      {stockDetail?.created_at
                        ? moment(stockDetail.created_at).format(
                            "DD MMM YYYY, HH:MM"
                          )
                        : "-"}
                    </Text>
                    <Text variant="rg">
                      {stockDetail?.created_at
                        ? `Oleh ${stockDetail.created_by}`
                        : "-"}
                    </Text>
                  </Box>
                  <Flex mt={2}>
                    <Flex flexDirection="column" flex="4">
                      <Text fontSize="2" lineHeight="2">
                        Dari
                      </Text>
                      <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                        {getTotalBeforeUpdate} {productDetail?.unit}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" flex="6">
                      <Flex alignItems="center">
                        <RotatedBox direction="flip-x">
                          <img
                            src={ArrowBackIcon}
                            width="24"
                            height="24"
                            alt="arrow"
                          />
                        </RotatedBox>
                        <Flex flexDirection="column" flex="4" ml="1">
                          <Text fontSize="2" lineHeight="2">
                            Menjadi
                          </Text>
                          <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                            <span
                              className={
                                getTotal > productDetail?.qty!
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {nominalFormat(getTotal)}
                            </span>{" "}
                            {productDetail?.unit}
                          </Text>
                          <Text fontSize="1" lineHeight="1">
                            <span
                              className={
                                getTotalChanges > 0 ? "success" : "danger"
                              }
                            >
                              {getQtyOperator(getTotalChanges)}
                              {nominalFormat(getTotalChanges)}
                            </span>{" "}
                            {productDetail?.unit}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  {isLoading ? (
                    <Box p={3} mt={2}>
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      <Box mt={4}>
                        <H3>Sisa batch</H3>
                        <Text variant="sm">
                          {(getBatchList || []).length} batch
                        </Text>
                      </Box>
                      <Box
                        mt={1}
                        borderTop={!emptyBatch ? "1px solid" : null}
                        borderColor="blackLighter"
                      >
                        {/* render existing batch */}
                        <InventoryDetailBatchList
                          list={getBatchList}
                          activeFilter={activeFilter}
                          isModal
                          onClickEdit={(stock) => {}}
                          setActiveFilter={handleChangeActiveFilter}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </BodyPage>
          </Wrapper>
        </Modal>
      </Overlay>
    </>
  );
};

export default InventoryUpdateStock;
