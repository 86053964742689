import React from "react";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";
import { push } from "connected-react-router";
import styled from "styled-components/macro";

// redux action
import { setShowSidebar } from "../../reducers/navigation/navigation.action";

// constant
import theme from "../../constant/styledTheme";

// icons
import HamburgerIcon from "../../asset/icon/menu.svg";
import ArrowBackIcon from "../../asset/icon/arrow-back.svg";
import CloseIcon from "../../asset/icon/close.svg";

// component
import { TouchableButton } from "../Button";
import TapInput from "../TapInput";
import { H3 } from "../Styles";

type HeaderNavProps = {
  title?: string;
  fullRightHeader?: boolean;
  leftHeaderType?: "menu" | "back" | "close";
  isModal?: boolean;
  companyHeader?: boolean;
  /** make custom header by props header component */
  header?: () => any;
  /** by default left header will be back button */
  leftHeader?: () => any;
  rightHeader?: () => any;
  leftHeaderFunction?: () => any;
  disabled?: boolean;
};

const HeaderNav = (props: HeaderNavProps) => {
  const dispatch = useDispatch();

  const {
    title,
    leftHeaderType,
    fullRightHeader,
    isModal,
    companyHeader,
    disabled,
    header,
    leftHeader,
    rightHeader,
    leftHeaderFunction,
  } = props;

  const handleShowSidebar = () => {
    if (leftHeaderFunction) {
      leftHeaderFunction();
      return;
    }
    dispatch(setShowSidebar(true));
  };

  const onClickBack = () => {
    if (disabled) {
      return;
    }

    if (leftHeaderFunction) {
      leftHeaderFunction();
      return;
    }

    dispatch(goBack());
  };

  const renderHeaderLeft = () => {
    if (leftHeaderType === "close") {
      return (
        <TouchableButton
          icon={CloseIcon}
          withFeedback
          alt="close"
          onClick={leftHeaderFunction!}
        />
      );
    }

    if (leftHeaderType === "menu" || companyHeader) {
      return (
        <TouchableButton
          icon={HamburgerIcon}
          withFeedback
          alt="menu"
          onClick={handleShowSidebar}
        />
      );
    }

    if (leftHeader === undefined || leftHeaderType === "back") {
      return (
        <TouchableButton
          icon={ArrowBackIcon}
          withFeedback
          alt="back"
          onClick={onClickBack}
        />
      );
    }

    if (!leftHeader) {
      return null;
    }

    return leftHeader();
  };

  const renderHeaderRight = () => {
    if (!rightHeader) {
      return null;
    }

    return <div className="header__right"> {rightHeader()}</div>;
  };

  const renderTitle = () => {
    if (companyHeader) {
      const handleNavigateCompany = () => {
        dispatch(push("/company"));
      };

      return (
        <div className="header__select-company">
          <TapInput type="company" noBorder onTap={handleNavigateCompany} />
        </div>
      );
    }

    if (title) {
      return (
        <div className="header__title">
          <H3>{title}</H3>
        </div>
      );
    }

    return null;
  };

  const renderHeader = () => {
    if (!header) {
      return (
        <Container fullRightHeader={!!fullRightHeader} isModal={!!isModal}>
          <div className="header__left"> {renderHeaderLeft()} </div>
          {renderTitle()}
          {renderHeaderRight()}
        </Container>
      );
    }

    return <div>{header()}</div>;
  };

  // render main component
  return renderHeader();
};

export type ContainerProps = {
  fullRightHeader: boolean;
  isModal: boolean;
};

export const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  display: flex;

  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  max-height: 52px;
  padding: 10px 0px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;

  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.15);

  .header__select-company {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 0px 14px;
  }

  .header__title {
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 0px 50px;
    word-break: break-all;
    max-width: calc(100% - 100px);

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .header__left {
    display: flex;
    align-items: center;
    padding-left: 10px;
    z-index: 2;

    .header__arrow-back {
      cursor: pointer;
    }

    @media (min-width: ${theme.breakpoints[3]}) {
      display: ${({ isModal }) => (isModal ? "block" : " none")};
    }
  }

  .header__right {
    width: ${({ fullRightHeader }) => (fullRightHeader ? "100%" : "auto")};
    z-index: 2;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media (min-width: ${theme.breakpoints[3]}) {
      margin-left: 20px;
    }
  }

  @media (min-width: ${theme.breakpoints[3]}) {
    background-color: #ffffff;
  }
`;

export default HeaderNav;
