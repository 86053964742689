import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components/macro";

//asset
import ChevronIcon from "../../asset/icon/chevron-next.svg";

// utils
import { nominalFormat } from "../../utils/nominalFormat";

// constant
import { colors } from "../../constant/theme";
import { device } from "../../constant/media";
import { TransactionSummary } from "../../types/transaction";

export type HomeSummaryTransactionProps = {
  summary: TransactionSummary | null;
};

const HomeSummaryTransaction = (props: HomeSummaryTransactionProps) => {
  const { summary } = props;

  const dispatch = useDispatch();

  const navigateToHistory = () => {
    dispatch(push("/history"));
  };

  return (
    <Container>
      <div className="total-container__sales">
        <div className="total__sales">
          Rp{nominalFormat(summary?.total_basket_size)}
        </div>
        <div className="total__subtitle text">Total penjualan</div>
      </div>
      <div className="transaction__detail">
        <div className="total__transaction" onClick={() => navigateToHistory()}>
          <div className="transaction__number">
            {nominalFormat(summary?.total_transaction)}
          </div>
          <div className="total__subtitle">
            <div className="total__text text">Transaksi</div>
            <div className="total__icon">
              <img src={ChevronIcon} alt="detail" height="12px" />
            </div>
          </div>
        </div>
        <div className="average__transaction">
          <div className="average__title">
            {!(summary?.total_basket_size || 0)
              ? `Rp0`
              : `Rp${nominalFormat(
                  (
                    (summary?.total_basket_size || 0) /
                    (summary?.total_transaction || 0)
                  ).toFixed(0)
                )}`}
          </div>
          <div className="average__subtitle text">Rata-rata transaksi</div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    flex-direction: row;
  }

  .total-container__sales {
    flex: 3;
    padding: 0.5rem 0rem;
    border-bottom: 1px solid ${colors.secondary2};

    .total__sales {
      font-size: 2rem;
    }
  }

  .transaction__detail {
    flex: 2;
    display: flex;

    padding: 0.5rem 0rem;
    border-bottom: 1px solid ${colors.secondary2};

    font-size: 1.5rem;
    line-height: 2rem;

    > .total__transaction {
      flex: 5;
      cursor: pointer;

      .total__subtitle {
        display: flex;
        align-items: center;

        .total__icon {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    .average__transaction {
      flex: 5;
    }
  }
`;

export default HomeSummaryTransaction;
