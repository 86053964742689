import React from "react";
import styled from "styled-components/macro";

// component
import { Flex, Text } from "../../component/Styles";

// type
import { Product } from "../../types/product";

// constant
import { nominalFormat } from "../../utils/nominalFormat";

export type Props = {
  product: Product;
  onClick: (item: Product) => void;
};

const CatalogItem = (props: Props) => {
  const { product, onClick } = props;

  const handleClick = (item: Product) => {
    onClick(item);
  };

  return (
    <Container
      onClick={() => handleClick(product)}
      flexDirection="column"
      borderBottom="1px solid"
      borderColor="blackLighter"
      py={1}
    >
      <Text
        textAlign="left"
        fontSize={2}
        lineHeight={2}
        color="blueDark"
        fontWeight="700"
      >
        {product.name}
      </Text>
      <Text fontSize={1} lineHeight={1}>
        {product.price || product.price === 0
          ? `Rp${nominalFormat(product.price)}`
          : "-"}{" "}
        / {product.unit}
      </Text>
    </Container>
  );
};

const Container = styled(Flex)`
  cursor: pointer;
`;

export default CatalogItem;
