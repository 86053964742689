import React, { useEffect, useMemo, useState, useRef } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { goBack } from "connected-react-router";
import styled from "styled-components/macro";

// redux action
import {
  getTransactionDetailAsync,
  getTransactionListAsync,
} from "../../reducers/transaction/transaction.action";
import { showToast } from "../../reducers/toast/toast.action";

// asset
import CloseIcon from "../../asset/icon/close.svg";
import AlertIcon from "../../asset/icon/alert.svg";

// component
import Layout from "../../component/Layout/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput/TextInput";
import { Button, TouchableButton } from "../../component/Button";
import { Wrapper } from "../../component/Styles";
import PrintLayoutTransaction from "../../component/PrintLayoutTransaction/PrintLayoutTransaction";

// type
import { general } from "../../types";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { handleCheckoutAsync } from "./utils/handleCheckout";

const Checkout = () => {
  const printRef: any = useRef();
  const dispatch = useDispatch();

  // redux state
  const router = useSelector((state: general.States) => state.router.location);
  const transactionDetail = useSelector(
    (state: general.States) => state.transaction.transactionDetail
  );
  const transactionLoading = useSelector(
    (state: general.States) => state.transaction.isLoading
  );

  const profile = useSelector((state: general.States) => state.profile.profile);

  // state
  const [payment, setPayment] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleNavBack = () => {
    dispatch(goBack());
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => handleNavBack()}
      />
    );
  };

  const getTransactionID = useMemo(() => {
    const path = router.pathname;
    const splitPath = path.split("/");
    return splitPath[2];
  }, [router]);

  const handleCountPayment = useMemo(() => {
    if (+(transactionDetail?.total_basket_size || 0) > payment) {
      return 0;
    }

    return payment - +(transactionDetail?.total_basket_size || 0);
  }, [transactionDetail, payment]);

  const pageStyle = `
    @page {
      size: 58mm auto;
    }`;

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => printRef.current,
    onAfterPrint: () => {
      setIsLoading(false);
      dispatch(push("/transaction"));
    },
    onPrintError: () => {
      setIsLoading(false);
      dispatch(
        showToast.request({
          message: "Gagal mencetak struk",
          duration: 5000,
          type: "error",
        })
      );
    },
  });

  const handleCheckout = async () => {
    setError("");
    setIsLoading(true);

    if (payment >= (transactionDetail?.total_basket_size || 0)) {
      const payload = {
        transactionID: getTransactionID,
        customerPaid: payment,
        customerChange: +payment - +(transactionDetail?.total_basket_size || 0),
      };

      try {
        await handleCheckoutAsync(payload);
        dispatch(
          showToast.request({
            message: "Transaksi berhasil dilakukan",
            duration: 5000,
            type: "success",
          })
        );
        dispatch(getTransactionListAsync.request());
        handlePrint!();
        setIsLoading(false);
        return;
      } catch {
        dispatch(
          showToast.request({
            message: "Transaksi gagal dilakukan",
            duration: 5000,
            type: "error",
          })
        );
      }
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setError("Jumlah tunai harus sama atau lebih besar dari transaksi");
  };

  useEffect(() => {
    if (getTransactionID) {
      dispatch(
        getTransactionDetailAsync.request({
          transactionID: getTransactionID,
        })
      );
    }
  }, [getTransactionID, dispatch]);

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintLayoutTransaction
          ref={printRef}
          detail={transactionDetail}
          profile={profile}
          customerPaid={payment}
          changes={handleCountPayment}
        />
      </div>

      <Layout>
        <TopBar
          title={`Rp${nominalFormat(
            transactionDetail?.total_basket_size || 0
          )}`}
          leftHeader={renderLeftHeader}
        />
        <Wrapper p={2}>
          <Body>
            <div>
              <TextInput
                type="number"
                label={"Tunai"}
                inputLabel={"Rp"}
                value={payment}
                onChange={(value) => setPayment(value ? +value : 0)}
                disabled={isLoading}
              />
              {error ? (
                <div className="checkout--error">
                  <img src={AlertIcon} alt={"alert"} />
                  <div> {error} </div>
                </div>
              ) : null}
            </div>

            <div className="checkout__changes-container">
              <div className="checkout__changes-label">Kembali</div>
              <div className="checkout__changes">
                Rp{nominalFormat(handleCountPayment)}
              </div>
            </div>

            <Button
              isLoading={isLoading}
              title={isLoading || transactionLoading ? "Tunggu" : "Selesai"}
              onClick={() => handleCheckout()}
              disabled={isLoading || transactionLoading}
            />
          </Body>
        </Wrapper>
      </Layout>
    </>
  );
};

export default Checkout;

const Body = styled.div`
  width: 100%;
  max-width: 1400px;

  .checkout__changes-container {
    margin-top: 16px;
    margin-bottom: 24px;
    font-weight: bold;

    .checkout__changes-label {
      font-size: 12px;
      line-height: 20px;
    }

    .checkout__changes {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .checkout--error {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    line-height: 16px;
    margin-top: 5px;

    color: #eb5757;

    > img {
      padding: 5px;
      margin-right: 5px;
    }
  }
`;
