import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { setSelectedCompany } from "../../reducers/company/company.action";
import { getUserProfileAsync } from "../../reducers/profile/profile.action";

// type
import { Company } from "../../types/company";

// component
import { Flex, Text } from "../../component/Styles";
import { TouchableButton, Button } from "../../component/Button";

// constant
import theme from "../../constant/styledTheme";

// utils
import LocalStorageService from "../../utils/localStorageService";
import { CoralDeleteIcon } from "../../asset/icon";

export type CompanyItemProps = {
  /** GET - detail of pharmacy */
  company: Company;
  /** GET - index */
  index: number;

  onClickItem: (type: "request" | "inprogress" | "delete", c: Company) => void;
};

const CompanyItem = (props: CompanyItemProps) => {
  const { company, index, onClickItem } = props;
  const dispatch = useDispatch();

  const handleNavigate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (company.status === "inactive") {
      return;
    }

    if (company.status === "inprogress") {
      onClickItem("inprogress", company);
      return;
    }

    LocalStorageService.setCompanyID(company.id);
    dispatch(setSelectedCompany(company));
    dispatch(getUserProfileAsync.request());
  };

  const renderRole = () => {
    if (company.role === "owner") {
      return "Pemilik";
    }
    return "Pegawai";
  };

  const renderStatus = () => {
    switch (company.status) {
      case "inactive":
        return "Nonaktif";
      case "active":
        return "Aktif";
      case "inprogress":
        return "Dalam proses";
      default:
        break;
    }
  };

  return (
    <Card href="">
      <Flex
        flexDirection="column"
        onClick={handleNavigate}
        borderBottom="1px solid"
        borderColor="blackLighter"
        p={0}
        pb={1}
      >
        <Flex>
          <Flex flex="8" flexDirection="column">
            <Text variant="rg" fontWeight="bold" color="blueDark">
              {company.name}, {company.city}
            </Text>
            <Text variant="rg">{renderRole()}</Text>
          </Flex>
          <Flex flex="2" justifyContent="flex-end" alignItems="flex-start">
            <Text
              variant="rg"
              color={company.status === "active" ? "green" : "coral"}
              fontWeight="bold"
            >
              {renderStatus()}
            </Text>
          </Flex>
        </Flex>

        {company.status === "inprogress" ? (
          <Flex flexDirection="column" mt={1}>
            <Text variant="rg" fontWeight="bold">
              Dalam proses aktivasi
            </Text>
            <Text variant="rg">
              Kami akan segera menghubungimu untuk proses aktivasi.
            </Text>
            <Text variant="rg">
              Untuk bantuan, kontak bantuan@farmacare.id.
            </Text>
          </Flex>
        ) : null}

        {company.status === "inactive" ? (
          <Flex justifyContent="space-between" mt="1">
            <TouchableButton
              icon={CoralDeleteIcon}
              alt="delete"
              onClick={() => onClickItem("delete", company)}
            />
            <Button
              title="Ajukan aktivasi"
              onClick={() => onClickItem("request", company)}
            />
          </Flex>
        ) : null}
      </Flex>
    </Card>
  );
};

const Card = styled.a`
  text-decoration: none;
  color: ${theme.colors.black};
`;

export default CompanyItem;
