import React, { Component } from "react";
import logo from "../asset/img/logo.png";

class Header extends Component {
  render() {
    return (
      <div>
        <div className="text-center mt-3">
          <img src={logo} alt="logo-farmacare" width="148px" height="24px" />
        </div>
      </div>
    );
  }
}

export default Header;
