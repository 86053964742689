import React, { useState } from "react";
import { push } from "connected-react-router";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

// redux action
import { changeEmailAsync } from "../../reducers/auth/auth.action";

//asset
import FarmacareLogo from "../../asset/img/logo.png";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import { Button } from "../../component/Button";
import { Body, Flex, Box, Form, Row } from "../../component/Styles";

// constant
import { device } from "../../constant/media";
import { colors } from "../../constant/theme";

// utils
import { validateEmail } from "../../utils/validateEmail";

// type
import { States } from "../../types/general";
import { showToast } from "../../reducers/toast/toast.action";

const ConfirmationChangeEmail = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: States) => state.auth.isLoading);
  const userIDObject = useSelector(
    (state: States) => state.auth.changeEmailPayload
  );

  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState({ newEmail: "", password: "" });

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  const passwordValidation = (pass: string) => {
    let isValid = false;
    if (/[0-9]/g.test(pass) && /[a-zA-Z]/g.test(pass) && pass.length > 7) {
      isValid = true;
    }

    return isValid;
  };

  const checkValidation = () => {
    let isValid = true;

    const checkEmail = validateEmail(newEmail);
    // name only support alphabet, and dash
    const checkPassword = passwordValidation(password);

    const validationForm = {
      newEmail: "",
      password: "",
    };

    setValidation(validationForm);

    if (!newEmail) {
      validationForm.newEmail = "Tolong periksa kolom email";
      isValid = false;
    }

    if (!checkEmail) {
      validationForm.newEmail = "Format email tidak sesuai";
      isValid = false;
    }

    if (!password || !checkPassword) {
      validationForm.password =
        "Tolong periksa kolom password, harus terdiri dari 8 karakter, kombinasi huruf dan angka";
      isValid = false;
    }

    setValidation(validationForm);

    return isValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!checkValidation()) {
      return;
    }

    const { userID } = userIDObject!;

    if (!userIDObject || !userID) {
      dispatch(
        showToast.request({
          message: "Sesi invalid, anda tidak dapat meubah email",
          duration: 5000,
          type: "error",
        })
      );

      return;
    }

    dispatch(
      changeEmailAsync.request({ email: newEmail, password, user_id: userID })
    );
  };

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <BodyPage>
        <Flex flexDirection="column" width="100%" maxWidth="962px">
          <h1>Ganti email</h1>
          <div className="text">masukkan email anda yang baru.</div>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Flex flexDirection="column" mt={1}>
              <TextInput
                label="Email baru"
                value={newEmail}
                onChange={(text) => setNewEmail(text || "")}
                placeholder="Masukkan alamat email yang baru"
                subtitle={validation.newEmail}
                subtitleType="error"
              />

              <Row mt={1}>
                <TextInput
                  label="Password baru"
                  value={password}
                  onChange={(text) => setPassword(text || "")}
                  placeholder="Masukkan password baru"
                  type="password"
                  subtitle={
                    validation.password
                      ? validation.password
                      : "Minimal 8 karakter, terdiri dari huruf dan angka"
                  }
                  subtitleType={validation.password ? "error" : undefined}
                />
              </Row>

              <Box mt={3}>
                <Button title="Ganti email" fullWidth isLoading={isLoading} />
              </Box>
            </Flex>
          </Form>

          <Flex
            mt={3}
            py={3}
            borderTop="1px solid"
            borderColor="secondary1"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="text"> Kembali ke halaman utama?</div>
            <Button
              title="Kembali"
              onClick={() => handleNavigate("/")}
              secondary
            />
          </Flex>
        </Flex>
      </BodyPage>
    </Layout>
  );
};

const BodyPage = styled(Body)`
  @media ${device.lg} {
    display: flex;
    overflow-y: scroll;
    justify-content: center;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media ${device.lg} {
    border-bottom: 1px solid ${colors.secondary2};
  }
`;

export default ConfirmationChangeEmail;
