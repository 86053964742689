import React from "react";
import styled from "styled-components/macro";

// constant
import { colors } from "../../constant/theme";

export type TouchableButtonProps = {
  icon: any;
  alt: string;
  withFeedback?: boolean;
  noBackground?: boolean;
  lightIcon?: boolean;
  height?: number;
  width?: number;
  onClick?: () => void;
};

const TouchableButton = (props: TouchableButtonProps) => {
  const {
    icon,
    withFeedback,
    alt,
    noBackground,
    lightIcon,
    height,
    width,
    onClick,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!onClick) {
      return;
    }

    onClick();
  };

  return (
    <Button
      withFeedback={!!withFeedback}
      onClick={(e) => handleClick(e)}
      noBackground={!!noBackground}
      lightIcon={!!lightIcon}
      width={width}
      height={height}
    >
      <div className="touchable--feedback">
        <img src={icon} alt={alt} width={width || 24} height={height || 24} />
      </div>
    </Button>
  );
};

type StyleProps = {
  withFeedback: boolean;
  noBackground: boolean;
  lightIcon: boolean;
  width?: number;
  height?: number;
};

const Button = styled.button<StyleProps>`
  height: ${({ height }) => (height ? `${height + 16}px` : "40px")};
  width: ${({ width }) => (width ? `${width + 16}px` : "40px")};
  margin: 0;
  padding: 0;
  outline: 0;
  background: ${({ noBackground }) =>
    noBackground ? "transparent" : "#ffffff"};
  border: none;
  cursor: pointer;

  :focus {
    outline: 0;
  }

  :active {
    outline: 0;
  }

  .touchable--feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => (height ? `${height + 16}px` : "40px")};
    width: ${({ width }) => (width ? `${width + 16}px` : "40px")};
    border-radius: 20px;
    cursor: pointer;

    > img {
      cursor: pointer;
      filter: ${({ lightIcon }) => (lightIcon ? "brightness(100)" : "none")};
    }

    :active {
      background: ${({ withFeedback }) =>
        withFeedback ? colors.secondary2 : "none"};
      transition: 0.2s ease;
    }
  }
`;
export default TouchableButton;
