import {  createAsyncAction, action } from "typesafe-actions";

// action types
import * as types from "./stock.actionTypes";

// type
import {  DetailStock, InventoryDetail, InventoryUpdateStockPayload } from "../../types/inventory";
import { QueryParams } from "../../types/general";

export const getBatchNonEmptyAsync = createAsyncAction(
  types.GET_BATCH_NON_EMPTY_REQUEST,
  types.GET_BATCH_NON_EMPTY_SUCCESS,
  types.GET_BATCH_NON_EMPTY_FAILURE
)<{productID: string} & QueryParams , InventoryDetail, undefined>();

export const getBatchListAsync = createAsyncAction(
  types.GET_BATCH_LIST_REQUEST,
  types.GET_BATCH_LIST_SUCCESS,
  types.GET_BATCH_LIST_FAILURE
)<{productID: string } & QueryParams , InventoryDetail, undefined>();

export const getUpdateStockDetailAsync = createAsyncAction(
  types.GET_UPDATE_STOCK_DETAIL_REQUEST,
  types.GET_UPDATE_STOCK_DETAIL_SUCCESS,
  types.GET_UPDATE_STOCK_DETAIL_FAILURE
)<{productID: string, stockID: string } & QueryParams , DetailStock, undefined>();

export const updateStocksAsync = createAsyncAction(
  types.UPDATE_STOCKS_REQUEST,
  types.UPDATE_STOCKS_SUCCESS,
  types.UPDATE_STOCKS_FAILURE
)<{productID: string, payload: InventoryUpdateStockPayload} , undefined, undefined>();

export const setShowUpdateStockModal = (visible: boolean) => action(types.SET_SHOW_UPDATE_STOCK_MODAL, { visible })


