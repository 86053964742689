import React, { useState, useEffect, useMemo } from "react";
import { goBack, push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { Location } from "history";
import moment from "moment";

// redux action
import {
  getPBFListAsync,
  showEditModalPBF,
  setSelectedPBF as onSelectPBF,
} from "../../reducers/pbf/pbf.action";
import {
  addInvoiceAsync,
  updateInvoiceAsync,
  getInvoiceDetailAsync,
  deleteInvoiceAsync,
} from "../../reducers/invoice/invoice.action";

// related component
import InvoiceProductList from "./InvoiceProductList";
import InvoiceAddProduct from "./InvoiceAddProduct";
import InvoiceSearchProduct from "./InvoiceSearchProduct";
import AddPBFModal from "../PBF/PBFEditModal";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import Select from "../../component/Select";
import DatePicker from "../../component/DatePicker";
import { Button, TouchableButton } from "../../component/Button";
import {
  Flex,
  H3,
  Box,
  Text,
  StickyBottomContainer,
  Wrapper,
  BodyPage,
} from "../../component/Styles";
import RouteLeavingGuard from "../../component/RouteLeavingGuard/RouteLeavingGuard";
import ModalReason from "../../component/Modal/ModalReason";
import Header from "../../component/Header/Header";
import Modal from "../../component/Modal/Modal";

// type
import { Option, States } from "../../types/general";
import { ExtendProductInvoice, Product } from "../../types/product";
import { Invoice, DiscountTypes } from "../../types/invoice";

// asset
import { AddIcon, AlertIcon, DeleteIcon } from "../../asset/icon";
import {
  discountType,
  paymentOptions,
  includeVATOptions,
} from "./data/templateOptions";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { getURLPathID } from "../../utils/getURLPathID";
import { getValidDate } from "./utils/getValidDate";
import { calculateDiscount } from "./utils/calculateDiscount";

const InvoiceEdit = () => {
  const dispatch = useDispatch();

  const today = new Date();

  // redux state
  const pbfList = useSelector((state: States) => state.pbf.list);
  const isLoading = useSelector((state: States) => state.invoice.isLoading);
  const invoiceDetail = useSelector((state: States) => state.invoice.detail);
  const location = useSelector((state: States) => state.router.location);
  const showAddPBFModal = useSelector(
    (state: States) => state.pbf.visibleModal
  );
  const selectedPBF = useSelector((state: States) => state.pbf.selectedPBF);

  // state
  const [
    selectedExistingProduct,
    setSelectedExistingProduct,
  ] = useState<ExtendProductInvoice | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [creditDueDate, setCreditDueDate] = useState<Date | null>(null);
  const [discCashAmount, setDiscCashAmount] = useState<number>(0);
  const [stampPrice, setStampPrice] = useState<number | undefined>(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState({
    label: "%",
    value: "percentage",
  });
  const [noInvoice, setNoInvoice] = useState("");
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(today);
  const [receiptDate, setReceiptDate] = useState<Date | null>(today);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Option>({
    label: "Kredit",
    value: "credit",
  });
  const [productList, setProductList] = useState<ExtendProductInvoice[]>([]);
  const [validationError, setValidationError] = useState({
    selectedPBF: "",
    productList: "",
    creditDueDate: "",
    noInvoice: "",
    receiptDate: "",
  });
  const [deleteProductID, setDeletedProductID] = useState<number[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [vatAmount, setVATAmount] = useState<number | undefined>(10);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedIncludedVAT, setSelectedIncludedVAT] = useState<Option>({
    label: "Belum, tambahkan PPN di akhir",
    value: false,
  });

  // state show modal
  const [showSearchProductModal, setShowSearchProductModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showModalLeavingPage, setShowModalLeavingPage] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showAlertEditable, setShowAlertEditable] = useState(false);

  const setSelectedPBF = (pbf: Option | null) => {
    dispatch(onSelectPBF(pbf));
  };

  const pbfOptions = useMemo(() => {
    return pbfList.map((p) => {
      return { label: p.supplier_name, value: p.id };
    });
  }, [pbfList]);

  const getInvoiceID = useMemo(() => {
    const ID = getURLPathID(location.pathname);
    return ID;
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.includes("/invoice") &&
      !isNaN(+getInvoiceID) &&
      (!invoiceDetail || invoiceDetail?.id !== +getInvoiceID)
    ) {
      dispatch(getInvoiceDetailAsync.request({ invoiceID: getInvoiceID }));
    }
  }, [getInvoiceID, location]);

  useEffect(() => {
    /**
     *  set selected invoice value when do edit invoice
     */
    if (!isNaN(+getInvoiceID) && invoiceDetail) {
      const getSelectedPBF: any = pbfList.find(
        (p) => +p.id === invoiceDetail.supplier_id
      );

      setProductList(invoiceDetail.product_list);
      setNoInvoice(invoiceDetail.invoice_number);
      setVATAmount(invoiceDetail.vat_amount);
      setStampPrice(invoiceDetail.stamp_price);
      setSelectedPBF(
        getSelectedPBF
          ? { label: getSelectedPBF.supplier_name, value: getSelectedPBF.id }
          : null
      );

      setCreditDueDate(getValidDate(invoiceDetail.due_date));
      setInvoiceDate(getValidDate(invoiceDetail.invoice_date)!);
      setReceiptDate(getValidDate(invoiceDetail.receipt_date));

      const vatOption = includeVATOptions.find(
        (v) => v.value === !!invoiceDetail.include_vat
      );
      setSelectedIncludedVAT(vatOption!);

      const paymentLabel =
        invoiceDetail.payment_method === "credit" ? "Kredit" : "Tunai";
      setSelectedPaymentMethod({
        label: paymentLabel,
        value: invoiceDetail.payment_method,
      });
    }
  }, [invoiceDetail, pbfList]);

  useEffect(() => {
    /**
     * reset cash discount
     */
    if (selectedPaymentMethod.value === 0) {
      setDiscCashAmount(0);
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    /**
     * get pbf list
     */
    dispatch(getPBFListAsync.request({}));
    setSelectedPBF(null);
  }, []);

  const getIsEditable = useMemo(() => {
    if (invoiceDetail && getInvoiceID !== "new") {
      let editable = true;
      const productList = invoiceDetail.product_list;

      for (let i = 0; i < productList.length; i++) {
        const p = productList[i];
        if (!p.is_editable) {
          editable = false;
          break;
        }
      }

      if (!editable) {
        return false;
      }
    }
    return true;
  }, [invoiceDetail]);

  useEffect(() => {
    if (!getIsEditable && getInvoiceID !== "new") {
      setShowAlertEditable(true);
    }
  }, [getIsEditable]);

  const errorValidation = () => {
    let isValid = true;

    const validation = {
      selectedPBF: "",
      noInvoice: "",
      creditDueDate: "",
      productList: "",
      receiptDate: "",
    };

    if (!selectedPBF) {
      isValid = false;
      validation.selectedPBF = "Nama PBF wajib diisi.";
    }

    if (selectedPaymentMethod.value === "credit") {
      if (moment(creditDueDate).isBefore(invoiceDate)) {
        isValid = false;
        validation.creditDueDate =
          "Cek tanggal jatuh tempo, harus lebih dari tanggal faktur.";
      }

      if (!creditDueDate) {
        isValid = false;
        validation.creditDueDate = "Tanggal jatuh tempo tidak boleh kosong";
      }
    }

    if (!noInvoice) {
      isValid = false;
      validation.noInvoice = "Nomor faktur wajib diisi.";
    }

    if (!receiptDate) {
      isValid = false;
      validation.receiptDate = "Tanggal penerimaan wajib diisi";
    }

    if (receiptDate) {
      if (moment(receiptDate).isAfter(moment())) {
        isValid = false;
        validation.receiptDate =
          "Tanggal penerimaan tidak boleh lebih dari hari ini.";
      }
    }

    if (invoiceDate && moment(invoiceDate).isAfter(moment(receiptDate))) {
      isValid = false;
      validation.receiptDate =
        "Tanggal faktur tidak boleh lebih dari tanggal terima barang.";
    }

    if (!productList.length) {
      isValid = false;
      validation.productList = "Belum ada barang pada faktur ini.";
    }

    setValidationError(validation);

    return isValid;
  };

  const getEditStatus = useMemo(() => {
    const isStatusEdit =
      invoiceDetail &&
      !isNaN(+getInvoiceID) &&
      invoiceDetail.id === +getInvoiceID;

    return isStatusEdit;
  }, [invoiceDetail, getInvoiceID]);

  const onClickSave = () => {
    if (!errorValidation()) {
      return;
    }

    if (getEditStatus) {
      setShowReasonModal(true);
      return;
    }

    handleSave();
  };

  const handleSave = (reason?: string) => {
    const filteredProductList = productList.filter(
      (p) => !p.is_deleted && p.is_editable
    );

    const payload: Invoice = {
      vat_amount: selectedIncludedVAT.value ? 0 : 10,
      supplier_id: selectedPBF?.value,
      invoice_number: noInvoice,
      invoice_date: invoiceDate ? moment(invoiceDate).format("YYYY-MM-DD") : "",
      receipt_date: receiptDate ? moment(receiptDate).format("YYYY-MM-DD") : "",
      payment_method: selectedPaymentMethod.value,
      due_date: creditDueDate ? moment(creditDueDate).format("YYYY-MM-DD") : "",
      include_vat: selectedIncludedVAT.value,
      stamp_price: stampPrice || 0,
      subtotal: getSubtotalVAT,
      discount_type: selectedDiscountType.value as DiscountTypes,
      discount_amount: discCashAmount,
      total: getTotal,
      product_list: filteredProductList,
    };

    setShowReasonModal(false);
    setHasChanges(false);

    if (getEditStatus) {
      payload.message_reason = reason || "";
      payload.deleted_product_id = deleteProductID || [];

      dispatch(
        updateInvoiceAsync.request({
          invoiceID: invoiceDetail?.id!,
          ...payload,
        })
      );
      return;
    }

    dispatch(addInvoiceAsync.request(payload));
  };

  const handleSelectProduct = (product: Product) => {
    setHasChanges(true);
    setSelectedProduct(product);
    setShowAddProductModal(true);
    setShowSearchProductModal(false);
  };

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false);
    setShowSearchProductModal(false);
    setSelectedProduct(null);
    setSelectedExistingProduct(null);
  };

  const handleDeleteProduct = (index: number) => {
    setHasChanges(true);
    const clonedProductList = productList.slice();

    const deletedProduct = clonedProductList[index];
    clonedProductList.splice(index, 1);

    /**
     * save deleted product ID, will be use when update invoice
     */
    if (deletedProduct.id!) {
      const clonedDeletedProductID = deleteProductID.slice();
      clonedDeletedProductID.push(deletedProduct.id!);

      setDeletedProductID(clonedDeletedProductID);
    }

    setProductList(clonedProductList);
    setSelectedExistingProduct(null);
    setShowAddProductModal(false);
  };

  const handleAddProduct = (productInvoice: ExtendProductInvoice) => {
    setHasChanges(true);
    const clonedProductList = productList.slice();
    setProductList([...clonedProductList, productInvoice]);
  };

  /** Edit product detail and will update product list */
  const handleEditProduct = (product: ExtendProductInvoice) => {
    setHasChanges(true);
    const clonedProductList = productList.slice();
    clonedProductList.splice(product.index!, 1, product);
    setProductList(clonedProductList);
  };

  /** Select product which product will edit */
  const handleSelectEditProduct = (product: ExtendProductInvoice) => {
    setSelectedExistingProduct(product);
    setShowAddProductModal(true);
  };

  const handleBack = () => {
    dispatch(goBack());
  };

  const handleChangePaymentMethod = (option: Option) => {
    if (option.value === "cash") {
      setCreditDueDate(null);
    }

    /** reset validation error for credit due date */
    setValidationError({ ...validationError, creditDueDate: "" });

    setSelectedPaymentMethod(option);
    setHasChanges(true);
  };

  /** get subtotal per product */
  const getProductSubtotal = (p: ExtendProductInvoice) => {
    let discount = p.discount_amount;
    const total_buy =
      p.price_buy * p.qty * (p.package_buy_detail?.smallest_qty || 1);

    if (p.discount_type_buy === "percentage") {
      discount = (p.discount_amount / 100) * total_buy;
    }

    return total_buy - discount;
  };

  /** get subtotal all product */
  const getSubtotal = useMemo(() => {
    let total = 0;

    productList.forEach((p) => {
      total += getProductSubtotal(p) || 0;
    });

    return Math.ceil(total);
  }, [productList]);

  const getSubtotalVAT = useMemo(() => {
    let total = getSubtotal;

    if (!selectedIncludedVAT.value) {
      total = getSubtotal + calculateDiscount("percentage", 10, getSubtotal);
    }

    return total;
  }, [getSubtotal, selectedIncludedVAT]);

  const getTotal = useMemo(() => {
    let total = 0;

    let discount = calculateDiscount(
      selectedDiscountType.value,
      discCashAmount,
      getSubtotal
    );

    return Math.ceil(getSubtotalVAT + total + (stampPrice || 0) - discount);
  }, [
    getSubtotal,
    stampPrice,
    discCashAmount,
    selectedDiscountType,
    vatAmount,
    getSubtotalVAT,
  ]);

  useEffect(() => {
    setValidationError({ ...validationError, productList: "" });
  }, [productList]);

  const navigate = (path: string) => {
    dispatch(push(path));
  };

  const shouldBlockNavigation = (location: Location<unknown>) => {
    return true;
  };

  const customHandleNavigate = useMemo(() => {
    return showAddProductModal ? handleCloseAddProductModal : undefined;
  }, [showAddProductModal]);

  const handleDeleteInvoice = (reason: string) => {
    if (getEditStatus && deleteMode) {
      dispatch(
        deleteInvoiceAsync.request({
          invoiceID: +getInvoiceID,
          messageReason: reason,
        })
      );
    }
    setDeleteMode(false);
    setShowReasonModal(false);
  };

  const setShowPBFModal = (visible: boolean) => {
    dispatch(showEditModalPBF(visible));
  };

  const handleChangeIncludeVAT = (option: Option) => {
    setHasChanges(true);
    setSelectedIncludedVAT(option);
  };

  const renderRightHeader = () => {
    if (!!getEditStatus) {
      return (
        <TouchableButton
          icon={DeleteIcon}
          alt="delete"
          onClick={() => {
            setDeleteMode(true);
            setShowReasonModal(true);
          }}
        />
      );
    }
    return;
  };

  const isDisable = useMemo(() => {
    return (
      showSearchProductModal ||
      showAddProductModal ||
      showModalLeavingPage ||
      showReasonModal ||
      showAddProductModal ||
      showAddPBFModal
    );
  }, [
    showSearchProductModal,
    showAddProductModal,
    showModalLeavingPage,
    showReasonModal,
    showAddProductModal,
    showAddPBFModal,
  ]);

  return (
    <>
      {showAddPBFModal ? (
        <AddPBFModal
          onCloseModal={() => setShowPBFModal(false)}
          PBFList={pbfList}
        />
      ) : null}

      {showAlertEditable ? (
        <Modal
          isVisible={showAlertEditable}
          title="Perhatian"
          subtitle="Faktur ini memiliki barang yang stoknya sudah diupdate sehingga tanggal penerimaan faktur dan data barang tersebut tidak bisa diubah."
          footer={
            <Flex justifyContent="flex-end" mt={2}>
              <Button title="Oke" onClick={() => setShowAlertEditable(false)} />
            </Flex>
          }
        />
      ) : null}

      <Layout>
        {/* Modal for add reason when do edit invoice */}
        {showReasonModal ? (
          <ModalReason
            isVisible={true}
            id="edit-invoice-modal-reason"
            title="Simpan perubahan?"
            subtitle="Tuliskan alasan mengapa perubahan ini dilakukan."
            onCancel={() => {
              setShowReasonModal(false);
              setDeleteMode(false);
            }}
            onSave={(text) => {
              if (deleteMode) {
                handleDeleteInvoice(text);
              } else {
                handleSave(text);
              }
            }}
          />
        ) : null}

        {/* show confirmation modal when user leave the edit invoice page */}
        <RouteLeavingGuard
          when={hasChanges}
          navigate={navigate}
          shouldBlockNavigation={(location) => shouldBlockNavigation(location)}
          setModalVisibility={(status: boolean) =>
            setShowModalLeavingPage(status)
          }
          customHandleNavigate={customHandleNavigate}
        />

        {showAddProductModal && (selectedProduct || selectedExistingProduct) ? (
          <InvoiceAddProduct
            includeVAT={selectedIncludedVAT.value}
            isEdit={!!getEditStatus}
            onDeleteProduct={(index) => handleDeleteProduct(index)}
            onAddProduct={handleAddProduct}
            onEditProduct={handleEditProduct}
            onCloseModal={() => handleCloseAddProductModal()}
            selectedProduct={selectedProduct}
            selectedExistingProduct={selectedExistingProduct}
            showModalLeavingPage={showModalLeavingPage}
          />
        ) : null}

        {showSearchProductModal && !selectedExistingProduct ? (
          <InvoiceSearchProduct
            onCloseModal={() => {
              setShowSearchProductModal(false);
              setSelectedProduct(null);
            }}
            onSelectProduct={(product) => handleSelectProduct(product)}
            isVisible
          />
        ) : null}

        <TopBar
          title={getEditStatus ? "Edit faktur" : "Catat faktur"}
          leftHeaderFunction={handleBack}
          rightHeader={renderRightHeader}
        />

        <Wrapper>
          <BodyPage>
            <Box p="24px" pb={5}>
              <Header
                backFunc={handleBack}
                backTitle="Penerimaan stok"
                disabled={isDisable}
              />
              <Box mt={0}>
                <Select
                  label="Nama PBF"
                  options={pbfOptions}
                  onSelect={(option) => {
                    setSelectedPBF(option);
                    setHasChanges(true);
                  }}
                  selectedOption={selectedPBF}
                  isSearchable
                  createAction={() => setShowPBFModal(true)}
                  isDisabled={isDisable}
                />
                {/* handle error  */}
                {validationError.selectedPBF ? (
                  <Flex mt={0} alignItems="center">
                    <img src={AlertIcon} height={14} width={14} />{" "}
                    <Text fontSize="1" lineHeight="1" color="coralDark" ml="0">
                      {validationError.selectedPBF}
                    </Text>
                  </Flex>
                ) : null}
              </Box>
              <Box mt="1">
                <TextInput
                  isError={!!validationError.noInvoice}
                  label="Nomor faktur"
                  onChange={(text) => {
                    setNoInvoice(text);
                    setHasChanges(true);
                  }}
                  value={noInvoice}
                  subtitle={validationError.noInvoice}
                  subtitleType="error"
                  disabled={isDisable}
                />
              </Box>
              <Flex
                width="100%"
                mt="1"
                flexDirection={["column", "column", "row"]}
              >
                <Box>
                  <DatePicker
                    label="Tanggal faktur"
                    selectedDate={invoiceDate}
                    onChange={(date) => {
                      setInvoiceDate(date);
                      setHasChanges(true);
                    }}
                    disabled={isDisable}
                  />
                </Box>
                <Box ml={["none", "none", 1]}>
                  <Box>
                    <DatePicker
                      label="Tanggal diterima"
                      selectedDate={receiptDate}
                      onChange={(date) => {
                        setReceiptDate(date);
                        setHasChanges(true);
                      }}
                      disabled={isDisable || !getIsEditable}
                    />
                  </Box>
                </Box>
              </Flex>
              {validationError.receiptDate ? (
                <Flex mt={0} alignItems="center">
                  <img src={AlertIcon} height={14} width={14} />{" "}
                  <Text fontSize="1" lineHeight="1" color="coralDark" ml="0">
                    {validationError.receiptDate}
                  </Text>
                </Flex>
              ) : null}

              <Box mt="3">
                <H3> Pembayaran</H3>
                <Flex mt="4px">
                  <Select
                    label="Metode"
                    options={paymentOptions}
                    selectedOption={selectedPaymentMethod}
                    onSelect={(option) => {
                      handleChangePaymentMethod(option);
                    }}
                    width={120}
                    isDisabled={isDisable}
                  />
                  <Box ml="1">
                    <DatePicker
                      disabled={
                        selectedPaymentMethod.value === "cash" || isDisable
                      }
                      label="Jatuh tempo"
                      selectedDate={creditDueDate}
                      onChange={(date) => {
                        setCreditDueDate(date);
                        setHasChanges(true);
                      }}
                    />
                  </Box>
                </Flex>
                {validationError.creditDueDate ? (
                  <Flex alignItems="center" mt={0}>
                    <img src={AlertIcon} height="16" width="16" alt="alert" />
                    <Text fontSize={2} lineHeight={2} color="coralDark" ml={0}>
                      {validationError.creditDueDate}
                    </Text>
                  </Flex>
                ) : null}
              </Box>

              <Box mt={3}>
                <H3> Penerimaan</H3>

                <Flex mt="0">
                  <Select
                    label="Harga sudah termasuk PPN?"
                    options={includeVATOptions}
                    selectedOption={selectedIncludedVAT}
                    onSelect={(option) => handleChangeIncludeVAT(option)}
                    width={220}
                    isDisabled={isDisable}
                  />
                </Flex>
                {productList.length ? (
                  <Box mt={0}>
                    <InvoiceProductList
                      productList={productList}
                      onSelectProduct={(product) =>
                        handleSelectEditProduct(product)
                      }
                      includeVAT={!!selectedIncludedVAT.value}
                    />

                    <Flex
                      backgroundColor="whiteDark"
                      p={0}
                      borderBottom="1px solid"
                      borderColor="blackLighter"
                    >
                      <Button
                        title="Barang"
                        icon={AddIcon}
                        inverse
                        onClick={() => {
                          setShowSearchProductModal(true);
                        }}
                        disabled={isDisable}
                      />
                    </Flex>
                  </Box>
                ) : null}
              </Box>

              {/* handle error when empty product submitted */}
              {validationError.productList ? (
                <Flex
                  background="#FFEDEB"
                  p="0"
                  borderRadius="4px"
                  alignItems="center"
                >
                  <img src={AlertIcon} width="16" height="16" />
                  <Text fontSize="2" lineHeight="2" color="coralDark" ml="0">
                    Belum ada barang pada faktur ini.
                  </Text>
                </Flex>
              ) : null}

              {!productList.length ? (
                <Flex
                  borderRadius="4px"
                  border="1px solid"
                  borderColor="blackLighter"
                  mt={0}
                  py="2"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text fontSize="2" lineHeight="2">
                    Belum ada barang pada faktur ini.
                  </Text>

                  <Box mt="0">
                    <Button
                      icon={AddIcon}
                      title="Barang"
                      secondary
                      onClick={() => setShowSearchProductModal(true)}
                      disabled={isDisable}
                    />
                  </Box>
                </Flex>
              ) : null}

              <Flex justifyContent={"flex-end"} width="100%">
                <Box width={["100%", "100%", "100%", "400px"]}>
                  {!selectedIncludedVAT.value ? (
                    <>
                      <Flex
                        justifyContent="space-between"
                        p={0}
                        borderBottom="1px solid"
                        borderBottomColor="blackLighter"
                        borderTop="1px solid"
                        borderTopColor="black"
                      >
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          DPP
                        </Text>
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          {nominalFormat(getSubtotal)}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        p={0}
                      >
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          PPN
                        </Text>
                        <Text variant="rg">10%</Text>
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          {nominalFormat(
                            calculateDiscount("percentage", 10, getSubtotal)
                          )}
                        </Text>
                      </Flex>
                    </>
                  ) : null}
                  <Flex
                    justifyContent="space-between"
                    p={0}
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                    borderTop="1px solid"
                    borderTopColor="black"
                  >
                    <Text fontWeight="bold" fontSize="2" lineHeight="2">
                      Subtotal
                    </Text>
                    <Text fontWeight="bold" fontSize="2" lineHeight="2">
                      {nominalFormat(getSubtotalVAT)}
                    </Text>
                  </Flex>

                  {selectedPaymentMethod.value === "cash" ? (
                    <Flex
                      p="0"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom="1px solid"
                      borderColor="blackLighter"
                    >
                      <Flex width="30%">
                        <Text fontSize="2" lineHeight="2">
                          Diskon tunai
                        </Text>
                      </Flex>
                      <Flex width="100%" justifyContent="flex-end">
                        <Select
                          options={discountType}
                          selectedOption={selectedDiscountType}
                          onSelect={(option) => setSelectedDiscountType(option)}
                          width={72}
                        />
                        <Flex ml="0">
                          <TextInput
                            type="number"
                            value={discCashAmount}
                            onChange={(value) => setDiscCashAmount(+value)}
                            unitLabel={
                              selectedDiscountType.value === "percentage"
                                ? "%"
                                : null
                            }
                            disabled={isDisable}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : null}

                  {/* <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={0}
                  >
                    <Text fontSize="2" lineHeight="2">
                      PPN
                    </Text>
                    <TextInput
                      inputLabel="Rp"
                      type="number"
                      value={vatAmount}
                      onChange={(value) =>
                        setVATAmount(value ? +value : undefined)
                      }
                      allowNegative={false}
                      decimalScale={2}
                    />
                  </Flex> */}

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={0}
                  >
                    <Text fontSize="2" lineHeight="2">
                      Biaya materai
                    </Text>
                    <TextInput
                      inputLabel="Rp"
                      type="number"
                      value={stampPrice}
                      decimalScale={2}
                      onChange={(value) =>
                        setStampPrice(value ? +value : undefined)
                      }
                      disabled={isDisable}
                    />
                  </Flex>

                  <Flex
                    p="0"
                    justifyContent="space-between"
                    borderY="1px solid"
                    borderColor="black"
                    borderBottomColor="blackLighter"
                    backgroundColor="whiteDark"
                  >
                    <Text fontSize="2" lineHeight="2" fontWeight="bold">
                      Total faktur
                    </Text>
                    <Text fontSize="2" lineHeight="2" fontWeight="bold">
                      {nominalFormat(getTotal)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </BodyPage>
          <StickyBottomContainer
            position="absolute"
            bottom="0"
            width={["100%", "100%", "100%", "100%", "calc(100% - 200px)"]}
            maxHeight="52px"
            height="52px"
          >
            <Flex
              justifyContent="space-between"
              px="2"
              alignItems="center"
              width="100%"
            >
              <Text fontSize="2" lineHeight="2" fontFamily="Rubik">
                <span className="bold">Total</span> Rp
                {nominalFormat(getTotal)}
              </Text>
              <Button
                title="Simpan"
                onClick={onClickSave}
                disabled={!selectedPBF?.value || !noInvoice || isDisable}
                isLoading={isLoading}
              />
            </Flex>
          </StickyBottomContainer>
        </Wrapper>
      </Layout>
    </>
  );
};

export default InvoiceEdit;
