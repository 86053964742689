export const colors = {
  primary: "#1693A5",
  primary1: "#00C1D4",
  primary2: "#7DE1E8",
  primary3: "#D5F6F6",
  secondary: '#333333',
  secondary1: '#CCCCCC',
  secondary2: '#EFEFEF',
  danger: '#CC2200',
  danger1: "#FF6C47",
  danger2: '#FEA492',
  danger3: '#FEDFDB',
  success: '#007527',
  success1: '#00C65E',
  success2: '#82E3B2',
  success3: '#BFF1D7',
  
};
