import React from "react";
import styled, { keyframes } from "styled-components/macro";

// asset
import SpinnerIcon from "../../asset/icon/loading.svg";

export type LoadingSpinnerProps = {
  light?: boolean;
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { light } = props;

  return (
    <Container light={!!light}>
      <img src={SpinnerIcon} alt="Loading" />
    </Container>
  );
};

const rotating = keyframes`
from {
  -ms-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
to {
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;

const Container = styled.div<{ light: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    filter: ${({ light }) => (light ? "brightness(100)" : "none")};
  }

  animation: ${rotating} 2s linear infinite;
`;

export default LoadingSpinner;
