import { createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./profile.actionTypes";

// type
import { User} from "../../types/user";

export const getUserProfileAsync = createAsyncAction(
  types.GET_PROFILE_REQUEST,
  types.GET_PROFILE_SUCCESS,
  types.GET_PROFILE_FAILURE
)<undefined, User, { error: string }>();
