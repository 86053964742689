import { put, takeLeading } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from '../auth/auth.action'

// related redux
import * as actions from "./product.action";
import * as types from "./product.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

function* getProductList(
  action: ReturnType<typeof actions.getProductListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const { search, page } = action.payload;

    const query: { [key: string]: string | number } = {};

    if (search) {
      query.search = search;
    }

    if (page) {
      query.p = page;
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/product`;

    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      const catalog = response.data.product;

      yield put(
        actions.getProductListAsync.success({
          catalog,
          totalData: response.data.total_data,
        })
      );
    }
  } catch (err) {
    yield put(actions.getProductListAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_PRODUCT_LIST_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* transactionSaga() {
  yield takeLeading(types.GET_PRODUCT_LIST_REQUEST, getProductList);
}
