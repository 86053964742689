import { createReducer, ActionType } from "typesafe-actions";

// actions
import * as actions from "./transaction.action";

// actions types
import * as types from "./transaction.actionTypes";

// type
import { product, transaction } from "../../types/index";
export type TransactionActions = ActionType<typeof actions>;

export type TransactionState = {
  historyDetail: transaction.HistoryDetail | null;
  transactionDetail: transaction.TransactionDetail | null;
  selectedProduct: product.Product | null;
  selectedTransactionProduct: product.TransactionProduct | null;
  selectedTransaction: transaction.Transaction | null;
  transactionProductList: {
    [transactionID: string]: product.Product[];
  };
  transactionList: transaction.Transaction[];
  history: transaction.History[];
  isLoading: boolean;
  modalVisible: boolean;
};

const initialState: TransactionState = {
  historyDetail: null,
  transactionDetail: null,
  selectedProduct: null,
  selectedTransactionProduct: null,
  selectedTransaction: null,
  transactionProductList: {},
  transactionList: [],
  history: [],
  isLoading: false,
  modalVisible: false
};

export const transactionReducer = createReducer<
  TransactionState,
  TransactionActions
>(initialState)
  .handleAction(
    actions.getTransactionListAsync.request,
    (
      state: TransactionState,
      action: ActionType<typeof actions.getTransactionListAsync.request>
    ) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.getTransactionListAsync.success,
    (
      state: TransactionState,
      action: ActionType<typeof actions.getTransactionListAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      transactionList: action.payload.data,
    })
  )
  .handleAction(
    actions.getTransactionListAsync.failure,
    (
      state: TransactionState,
      action: ActionType<typeof actions.getTransactionListAsync.failure>
    ) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.getTransactionDetailAsync.request,
    (
      state: TransactionState,
      action: ActionType<typeof actions.getTransactionDetailAsync.request>
    ) => ({
      ...state,
      isLoading: true,
      transactionDetail: null
    })
  )
  .handleAction(
    actions.getTransactionDetailAsync.success,
    (
      state: TransactionState,
      action: ActionType<typeof actions.getTransactionDetailAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      transactionDetail: action.payload.data,
    })
  )
  .handleAction(
    actions.getTransactionDetailAsync.failure,
    (state: TransactionState) => ({
      ...state,
      isLoading: false,
      transactionDetail: null
    })
  )

  // update transaction
  .handleAction(
    actions.updateProductTransactionAsync.request,
    (
      state: TransactionState,
      action: ActionType<typeof actions.updateProductTransactionAsync.request>
    ) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.updateProductTransactionAsync.success,
    (
      state: TransactionState,
      action: ActionType<typeof actions.updateProductTransactionAsync.success>
    ) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.updateProductTransactionAsync.failure,
    (state: TransactionState) => ({
      ...state,
      isLoading: false,
    })
  )

  .handleAction(
    actions.deleteTransactionAsync.request,
    (
      state: TransactionState,
      action: ActionType<typeof actions.deleteTransactionAsync.request>
    ) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.deleteTransactionAsync.success,
    (
      state: TransactionState,
      action: ActionType<typeof actions.deleteTransactionAsync.success>
    ) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.deleteTransactionAsync.failure,
    (state: TransactionState) => ({
      ...state,
      isLoading: false,
    })
  )

  // add product
  .handleAction(
    actions.addProductTransactionAsync.request,
    (
      state: TransactionState,
      action: ActionType<typeof actions.addProductTransactionAsync.request>
    ) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.addProductTransactionAsync.success,
    (
      state: TransactionState,
      action: ActionType<typeof actions.addProductTransactionAsync.success>
    ) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.addProductTransactionAsync.failure,
    (state: TransactionState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    types.SET_SHOW_TRANSACTION_MODAL_DETAIL as any,
    (
      state: TransactionState,
      action: ActionType<typeof actions.setShowTransactionModalDetail>
    ) => ({
      ...state,
      modalVisible: action.payload.visible
    })
  )
  .handleAction(
    types.SET_SELECTED_TRANSACTION as any,
    (
      state: TransactionState,
      action: ActionType<typeof actions.setSelectedTransaction>
    ) => ({
      ...state,
      selectedTransaction: action.payload.transaction,
    })
  )
  .handleAction(
    types.SET_SELECTED_TRANSACTION as any,
    (
      state: TransactionState,
      action: ActionType<typeof actions.setSelectedTransaction>
    ) => ({
      ...state,
      selectedTransaction: action.payload.transaction,
    })
  )
  .handleAction(
    types.SET_SELECTED_TRANSACTION_PRODUCT as any,
    (
      state: TransactionState,
      action: ActionType<typeof actions.setSelectedTransactionProduct>
    ) => ({ ...state, selectedTransactionProduct: action.payload.product })
  );
