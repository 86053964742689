import React, { useMemo } from "react";
import styled from "styled-components/macro";
import Select from "react-select";
import isEqual from "lodash/isEqual";

// constant
import theme from "../../constant/styledTheme";

// component
import { Text } from "../../component/Styles";

// asset
import { AddIcon, SuccessMiniIcon } from "../../asset/icon";

import { Option } from "../../types/general";

export type OptionType = Option;

const SelectComponent = (props: {
  autoFocus?: boolean;
  width?: number;
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  selectedOption?: OptionType | null;
  options: OptionType[];
  isSearchable?: boolean;
  onInputChange?: (text: string) => void;
  onFocus?: () => void;
  createAction?: () => void;
  onSelect: (Option: OptionType) => void;
  onMenuOpen?: () => void;
}) => {
  const {
    autoFocus,
    label,
    isDisabled,
    options,
    selectedOption,
    isSearchable,
    isLoading,
    onInputChange,
    createAction,
    onFocus,
    onSelect,
    onMenuOpen,
  } = props;

  const getBoxShadow = (isDisabled: boolean, isSearchable: boolean) => {
    if (isSearchable) {
      return "inset 0px 2px 4px rgba(51, 51, 51, 0.15)";
    }

    if (isDisabled) {
      return "none";
    }

    return "0px 1px 2px rgba(51, 51, 51, 0.15), inset 0px 1px 2px rgba(255, 255, 255, 0.15), inset 0px -1px 2px rgba(51, 51, 51, 0.15)";
  };

  const getBackgroundColor = (isDisabled: boolean, isSearchable: boolean) => {
    if (isDisabled || isSearchable) {
      return "#fff";
    }

    return theme.colors.whiteDark;
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.black,
      width: props.width || undefined,
      background: "#ffffff",
      padding: "6px 8px",
      fontSize: 14,
      "&:hover": {
        background: theme.colors.blueLighter,
      },
    }),
    control: (provided: any, state: any) => ({
      display: "flex",
      fontSize: 14,
      width: props.width || undefined,
      height: "36px",
      background: getBackgroundColor(!!state.isDisabled, !!isSearchable),
      border: "1px solid",
      borderColor: theme.colors.blackLighter,
      borderRadius: "4px",
      boxShadow: getBoxShadow(!!state.isDisabled, !!isSearchable),
    }),
  };

  const CustomSelectOption = (props: any) => {
    if (props.data.value === "__loading__") {
      return <CreateBox>Memuat...</CreateBox>;
    }
    if (props.data.status === "create-new") {
      return (
        <CreateBox
          {...props}
          onClick={() => {
            props.selectProps?.onMenuClose();
            createAction!();
          }}
        >
          {props.data.icon ? (
            <img
              src={props.data.icon}
              alt={`${props.data.value}`}
              width="16"
              height="16"
            />
          ) : null}
          {props.data.label}
        </CreateBox>
      );
    }

    return (
      <OptionBox
        {...props}
        onClick={() => {
          props.selectProps?.onMenuClose();
          onSelect({
            label: props.data.label,
            value: props.data.value,
            status: props.data.status,
          });
        }}
      >
        {props.data.label}
        {props.data.icon ? (
          <img
            src={props.data.icon}
            alt={`${props.data.value}`}
            width="16"
            height="16"
          />
        ) : null}
      </OptionBox>
    );
  };

  const handleSelect = (option: Option) => {
    if (option.status === "create-new") {
      createAction!();
      return;
    }

    onSelect(option);
  };

  const handleInputChange = (text: string) => {
    if (onInputChange) {
      onInputChange(text);
    }
  };

  const renderOptionList = useMemo(() => {
    if (isLoading) {
      return [
        { label: "Memuat", value: "__loading__", status: null, icon: "" },
      ];
    }

    const list = options.map((o) => {
      return {
        value: o.value,
        label: o.label,
        status: o.status || null,
        icon:
          selectedOption?.value === o.value ||
          isEqual(selectedOption?.value, o.value)
            ? SuccessMiniIcon
            : null,
      };
    });

    if (createAction) {
      list.push({
        value: null,
        label: "Buat baru",
        status: "create-new",
        icon: AddIcon,
      });
    }

    return list;
  }, [options, selectedOption, isLoading]);

  const filterOption = (candidate: any, input: any) => {
    return candidate.label.includes(input) || candidate.label === "Buat baru";
  };

  return (
    <Container>
      <Text fontSize="14px" lineHeight="24px">
        {label}
      </Text>
      <Select
        key={`select-component-${isLoading}`}
        autoFocus={autoFocus}
        onFocus={() => (onFocus ? onFocus() : {})}
        filterOption={filterOption}
        closeMenuOnSelect={true}
        styles={customStyles}
        isDisabled={isDisabled}
        options={renderOptionList}
        value={selectedOption as any}
        isLoading={isLoading}
        isSearchable={isSearchable || false}
        components={{
          IndicatorSeparator: () => null,
          Option: CustomSelectOption,
        }}
        onChange={(option: any) => {
          handleSelect(option);
        }}
        onInputChange={(text) => handleInputChange(text)}
        onMenuOpen={() => (onMenuOpen ? onMenuOpen() : {})}
        openMenuOnFocus={true}
      ></Select>
    </Container>
  );
};

const Container = styled.div``;

const OptionBox = styled.div`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.5rem;

  :hover {
    background: ${theme.colors.blueLighter};
    transition: 0.1s ease-in;
  }
`;

const CreateBox = styled.div`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.5rem;
  background: ${theme.colors.whiteDark};
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${theme.colors.blackLighter};
  color: ${theme.colors.blueDark};
  font-weight: bold;

  > img {
    margin-right: 0.25rem;
  }

  :hover {
    background: ${theme.colors.blueLighter};
    transition: 0.1s ease-in;
  }
`;

export default SelectComponent;
