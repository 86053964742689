import styled from "styled-components/macro";

import theme from "../../constant/styledTheme";

const Table = styled.table`
  thead {
    background-color: ${theme.colors.whiteDark};
    font-weight: 700;
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }

  th {
    position: sticky;
    top: 0;
    background-color: ${theme.colors.whiteDark};
  }

  tbody,
  td {
    padding: 8px;
  }

  thead,
  th {
    padding: 8px;
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }

  thead > tr th {
    border-bottom: 1px solid ${theme.colors.blackLighter};
    text-align: left;
  }

  tbody > tr td {
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }
`;

export default Table;
