import { fork, all } from "redux-saga/effects";

import companySagaWatcher from '../reducers/company/company.actionSaga'
import transactionSagaWatcher from '../reducers/transaction/transaction.actionSaga';
import productSagaWatcher from '../reducers/product/product.actionSaga';
import catalogSagaWatcher from '../reducers/catalog/catalog.actionSaga';
import authSagaWatcher from '../reducers/auth/auth.actionSaga'
import toastSagaWatcher from '../reducers/toast/toast.actionSaga'
import employeeSagaWatcher from '../reducers/employee/employee.actionSaga'
import historySagaWatcher from '../reducers/history/history.actionSaga'
import profileSagaWatcher from '../reducers/profile/profile.actionSaga'
import homeSagaWatcher from '../reducers/home/home.actionSaga'
import pbfSagaWatcher from '../reducers/pbf/pbf.actionSaga'
import packageSagaWatcher from '../reducers/package/package.actionSaga'
import invoiceSagaWatcher from '../reducers/invoice/invoice.actionSaga'
import inventorySagas from '../reducers/inventory/inventory.actionSaga'
import stockSagas from '../reducers/stock/stock.actionSaga'

export function* rootSaga() {
  yield all([
    fork(transactionSagaWatcher),
    fork(productSagaWatcher),
    fork(catalogSagaWatcher),
    fork(authSagaWatcher),
    fork(toastSagaWatcher),
    fork(employeeSagaWatcher),
    fork(historySagaWatcher),
    fork(profileSagaWatcher),
    fork(homeSagaWatcher),
    fork(companySagaWatcher),
    fork(pbfSagaWatcher),
    fork(packageSagaWatcher),
    fork(invoiceSagaWatcher),
    fork(inventorySagas),
    fork(stockSagas)
  ]);
}
