import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components/macro";

// redux action
import { sendInvitation } from "../../reducers/employee/employee.action";
import { showToast } from "../../reducers/toast/toast.action";

// asset
import CloseIcon from "../../asset/icon/close.svg";

// utils
import { validateEmail } from "../../utils/validateEmail";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import RadioButton from "../../component/RadioButton";
import { Button, TouchableButton } from "../../component/Button";

// type
import { States } from "../../types/general";

const EmployeeInvite = () => {
  const dispatch = useDispatch();

  const [validation, setValidation] = useState<{ email: boolean }>({
    email: true,
  });
  const [isOwner, setIsOwner] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");

  const isLoading = useSelector((state: States) => state.employee.isLoading);

  const handleGoBack = () => {
    dispatch(push("/employee"));
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        onClick={() => handleGoBack()}
        alt="close"
      />
    );
  };

  const getRole = (status: boolean) => {
    switch (status) {
      case true:
        return "owner";
      case false:
        return "employee";
    }
  };

  const handleInvite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidation({ email: true });

    if (!validateEmail(email)) {
      dispatch(
        showToast.request({
          duration: 5000,
          type: "error",
          message: "Mohon cek email anda",
        })
      );
      setValidation({ email: false });
      return;
    }

    dispatch(
      sendInvitation.request({ email, role: getRole(isOwner), type: "default" })
    );
  };

  return (
    <Layout>
      <TopBar title="Undang Pegawai" leftHeader={renderLeftHeader} />
      <Body>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleInvite(e)}
        >
          <div className="invite__row">
            <TextInput
              label="Email"
              placeholder="mail@emailmu.com"
              value={email}
              onChange={(text) => setEmail(text || "")}
              isError={!validation.email}
            />
          </div>
          <div className="invite__row">
            <div className="text">Posisi</div>
            <div className="invite__option">
              <RadioButton
                checked={isOwner}
                onClick={() => setIsOwner(true)}
                label="Pemilik"
              />
            </div>
            <div className="invite__option">
              <RadioButton
                checked={!isOwner}
                onClick={() => setIsOwner(false)}
                label="Pegawai"
              />
            </div>
          </div>
          <Button
            title="Undang"
            fullWidth
            disabled={!email}
            type="submit"
            isLoading={isLoading}
          />
        </form>
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  padding: 24px;

  form {
    .invite__row {
      :not(:first-child) {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }

  .invite__option {
    margin-top: 5px;
  }
`;

export default EmployeeInvite;
