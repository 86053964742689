import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";

// redux action
import { addCompanyAsync } from "../../reducers/company/company.action";
import { showToast } from "../../reducers/toast/toast.action";

// constant
import { colors } from "../../constant/theme";
import { device } from "../../constant/media";

//asset
import { CloseIcon, AlertIcon } from "../../asset/icon";

// component
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import { Button, TouchableButton } from "../../component/Button";
import { Modal, Overlay, Box, Text, Flex } from "../../component/Styles";
import Select from "../../component/Select";

// type
import { Option, States } from "../../types/general";
import { City, Province } from "../../types/company";

// utils
import { getRegionOptions } from "./utils/getRegionOptions";

export type CompanyFormProps = {
  /** GET - visible modal to add company */
  isVisible: boolean;
  /** handle close modal */
  onClose: () => void;
};

const CompanyForm = (props: CompanyFormProps) => {
  const dispatch = useDispatch();
  const { isVisible, onClose } = props;

  // state
  const [apotekName, setApotekName] = useState("");
  const [regionLoading, setRegionLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedProvince, setSelectedProvince] = useState<Option | null>(null);
  const [focused, setFocused] = useState<null | "city" | "province">(null);
  const [errorValidation, setErrorValidation] = useState({
    name: "",
    city: "",
    province: "",
  });

  // redux state
  const isLoading = useSelector((state: States) => state.company.isLoading);
  const companyList = useSelector((state: States) => state.company.list);

  const renderLeftHeader = () => {
    return (
      <TouchableButton icon={CloseIcon} alt="close" onClick={() => onClose()} />
    );
  };

  function stringToSlug(str: string) {
    str = str.replace(/^\s+|\s+$/g, "");
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    return str;
  }

  const trimName = () => {
    return apotekName.trim();
  };

  const checkDuplicateCompany = () => {
    const name = trimName();
    const isDuplicate = companyList.find((el) => el.name === name);

    return !!isDuplicate;
  };

  const checkValidation = () => {
    let isValid = true;

    const validationData = {
      name: "",
      city: "",
      province: "",
    };

    if (!apotekName) {
      isValid = false;
      validationData.name = "Nama apotik tidak boleh kosong";
    }

    if (!selectedCity) {
      isValid = false;
      validationData.city = "Kota tidak boleh kosong";
    }

    if (!selectedProvince) {
      isValid = false;
      validationData.province = "Provinsi tidak boleh kosong";
    }

    setErrorValidation(validationData);

    return isValid;
  };

  const handleAddApotek = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (!checkValidation()) {
      return;
    }

    if (checkDuplicateCompany()) {
      dispatch(
        showToast.request({
          message: "Nama apotek sudah terdaftar",
          type: "error",
          duration: 5000,
        })
      );
      return;
    }

    if (!apotekName) {
      dispatch(
        showToast.request({
          message: "Tolong cek data anda",
          type: "error",
          duration: 5000,
        })
      );
      return;
    }

    const payload = {
      name: apotekName,
      subdomain: stringToSlug(apotekName),
      city_id: selectedCity?.value.id,
      province_id: selectedProvince?.value,
    };

    dispatch(addCompanyAsync.request(payload));
  };

  const fetchCities = async (
    text: string,
    type: "city" | "province",
    provinceID?: number
  ) => {
    setRegionLoading(true);
    setErrorValidation({
      ...errorValidation,
      city: "",
    });
    const result = await getRegionOptions(text, type, provinceID);

    if (result) {
      const { cities, provinces } = result;

      if (type === "city" && (!cities || !cities.length)) {
        setErrorValidation({
          ...errorValidation,
          city: "Kota tidak ditermukan",
        });
      }

      const cityOptions = (cities || []).map((c: City) => {
        return { value: c, label: c.name };
      });
      setCities(cityOptions);

      const provinceOptions = provinces.map((p: Province) => {
        return { value: p.id, label: p.name };
      });

      if (!provinceID) {
        setProvinces(provinceOptions);
        if (provinceOptions.length === 1) {
          setSelectedProvince(provinceOptions[0]);
        } else {
          setSelectedProvince(null);
        }
      }

      // const isCityAlreadySet = cityOptions.length
      //   ? cityOptions[0].value?.province_id === selectedProvince?.value.id
      //   : false;

      // if (!isCityAlreadySet) {
      //   handleSelectCity(cityOptions[0] || null);
      //   const findProvince = provinceOptions.find(
      //     (p: Option) => p.value === cityOptions[0].value?.province_id
      //   );
      //   setSelectedProvince(findProvince || null);
      // } else {
      //   setSelectedCity(null);
      // }
    }

    setRegionLoading(false);
  };

  const getProvinceOptions = useMemo(() => {
    const clonedProvinces = cloneDeep(provinces);

    if (selectedCity) {
      const filtered = clonedProvinces.filter(
        (p: Option) => p.value === selectedCity.value.province_id
      );

      return filtered;
    }

    return clonedProvinces;
  }, [selectedCity, provinces]);

  const handleChangeCityFilter = debounce(async (text: string) => {
    if (text) {
      fetchCities(text, "city");
    }
  }, 800);

  const handleChangeProvinceFilter = debounce(async (text: string) => {
    if (text) {
      setSelectedCity(null);
      fetchCities(text, "province");
    }
  }, 800);

  const handleSelectProvince = (o: Option) => {
    if (selectedCity && selectedCity.value?.province_id !== o.value) {
      setSelectedCity(null);
    }
    fetchCities("", "city", o.value);
    setSelectedProvince(o);
  };

  const handleSelectCity = (o: Option) => {
    const relatedProvince = (provinces || []).find(
      (p: Option) => p.value === o?.value.province_id
    );
    setSelectedCity(o);

    if (relatedProvince) {
      setSelectedProvince(relatedProvince);
    }
  };

  return (
    <Overlay isVisible={isVisible}>
      <ExtendModal isVisible={isVisible}>
        <TopBar title="Buat Apotek" leftHeader={renderLeftHeader} isModal />
        <Body>
          <form onSubmit={handleAddApotek}>
            <TextInput
              label="Nama Apotekmu"
              value={apotekName}
              placeholder="Apotek Merdeka"
              onChange={(text) => setApotekName(text || "")}
            />

            <Box mt={1}>
              <Select
                onFocus={() => {
                  setSelectedCity(null);
                  setFocused("city");
                }}
                onMenuOpen={() => {
                  setSelectedCity(null);
                }}
                isLoading={regionLoading}
                options={cities}
                isSearchable
                onSelect={(o) => handleSelectCity(o)}
                label="Kabupaten/Kota"
                selectedOption={selectedCity}
                onInputChange={(city) => handleChangeCityFilter(city)}
                autoFocus={focused === "city"}
              />
              {errorValidation.city ? (
                <Flex mt="4px" alignItems="center">
                  <img width="14" height="14" src={AlertIcon} alt="alert" />
                  <Text color="coral" variant="sm" ml={0}>
                    {errorValidation.city}
                  </Text>
                </Flex>
              ) : null}
            </Box>

            <Box mt={1}>
              <Select
                options={getProvinceOptions}
                isSearchable
                isLoading={regionLoading}
                isDisabled={regionLoading || getProvinceOptions.length === 1}
                selectedOption={selectedProvince}
                onSelect={(p) => handleSelectProvince(p)}
                label="Provinsi"
                onInputChange={(province) =>
                  handleChangeProvinceFilter(province)
                }
                onFocus={() => {
                  setSelectedProvince(null);
                  setFocused("province");
                }}
                onMenuOpen={() => {
                  setSelectedProvince(null);
                }}
                autoFocus={focused === "province"}
              />
              {errorValidation.province ? (
                <Flex mt="4px" alignItems="center">
                  <img width="14" height="14" src={AlertIcon} alt="alert" />
                  <Text color="coral" variant="sm" ml="0">
                    {errorValidation.province}
                  </Text>
                </Flex>
              ) : null}
            </Box>

            <Button
              title="Buat Apotek"
              fullWidth
              isLoading={isLoading}
              type="submit"
              disabled={!apotekName || !selectedCity || !selectedProvince}
            />

            <div className="company-form__info">
              <h3 className="info__title">Bukan pemilik apotek?</h3>
              <div className="info__subtitle text">
                Minta pemilik apotek tempat kamu bekerja untuk mengundang kamu
                ke sistem Apotek di Farmacare.
              </div>
            </div>
          </form>
        </Body>
      </ExtendModal>
    </Overlay>
  );
};

const ExtendModal = styled(Modal)`
  @media ${device.lg} {
    flex-direction: column;
  }
`;

const Body = styled.div`
  padding: 24px;

  form > button {
    margin-top: 24px;
  }

  .company-form__info {
    margin-top: 38px;
    text-align: center;

    padding-top: 36px;
    border-top: 1px solid ${colors.secondary1};
  }
`;

export default CompanyForm;
