import { put, takeLeading } from "redux-saga/effects";
import moment from "moment";
import axios from "../../config/axios";

// action
import { handleLogout } from "../auth/auth.action";
import { showToast } from "../toast/toast.action";

// related redux
import * as actions from "./history.action";
import * as types from "./history.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// type
import { transaction } from "../../types";

function* getTransactionHistory(
  action: ReturnType<typeof actions.getTransactionHistoryAsync.request>
) {
  try {
    const query = action.payload;
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction-history`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      const historyList: transaction.History[] = response.data.transaction;

      const groupedData: { [key: string]: transaction.History[] } = {};

      historyList.forEach((el) => {
        const date = moment(el.end_at).format("DD-MM-YYYY");

        if (!groupedData[date]) {
          groupedData[date] = [];
        }

        groupedData[date].push(el);
      });

      yield put(
        actions.getTransactionHistoryAsync.success({
          data: historyList,
          groupedData: groupedData,
          isEmpty: !historyList.length,
        })
      );
    }
  } catch (err) {

    yield put(
      actions.getTransactionHistoryAsync.failure({ error: "Gagal memuat" })
    );

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.GET_TRANSACTION_HISTORY_REQUEST, action.payload);
  }
}

function* getTransactionHistoryDetail(
  action: ReturnType<typeof actions.getTransactionHistoryDetailAsync.request>
) {
  try {
    const { transactionID } = action.payload;

    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction-history/${transactionID}`;

    const response = yield axios.get(url);

    if (response && response.status === 200) {
      yield put(
        actions.getTransactionHistoryDetailAsync.success({
          data: response.data.data,
        })
      );
    }
  } catch (err) {
    yield put(
      actions.getTransactionHistoryDetailAsync.failure({
        error: "Failed to add data",
      })
    );

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(
      err,
      types.GET_TRANSACTION_HISTORY_DETAIL_REQUEST,
      action.payload
    );
  }
}

export default function* historySaga() {
  yield takeLeading(
    types.GET_TRANSACTION_HISTORY_REQUEST,
    getTransactionHistory
  );
  yield takeLeading(
    types.GET_TRANSACTION_HISTORY_DETAIL_REQUEST,
    getTransactionHistoryDetail
  );
}
