import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import debounce from "lodash/debounce";
import { useSelector, useDispatch } from "react-redux";

// redux action
import { getProductListAsync } from "../../reducers/product/product.action";
import { setShowAddCatalogModal } from "../../reducers/catalog/catalog.action";

// asset
import { CloseIcon, HelpIcon } from "../../asset/icon";

// component
import {
  Modal,
  Overlay,
  BodyPage,
  Text,
  Flex,
  H2,
  Wrapper,
} from "../../component/Styles";
import TopBar from "../../component/TopBar";
import { Button, TouchableButton } from "../../component/Button";
import ProductItem from "./InvoiceSearchProductItem";
import SearchBar from "../../component/SearchBar";
import Pagination from "../../component/Pagination";

// related component
import AddNewProductModal from "../Catalog/CatalogAdd";

// type
import { States } from "../../types/general";
import { Product } from "../../types/product";

// utils
import { Spinner } from "../../component/Loading";

export type InvoiceSearchProductProps = {
  /** Handle close modal */
  onCloseModal: () => void;
  /** Handle select product */
  onSelectProduct: (product: Product) => void;
  isVisible: boolean;
};

const InvoiceSearchProduct = (props: InvoiceSearchProductProps) => {
  const { onCloseModal, onSelectProduct, isVisible } = props;
  const dispatch = useDispatch();

  const [searchFilter, setSearchFilter] = useState("");

  const productList = useSelector((state: States) => state.product.list);
  const isLoading = useSelector((state: States) => state.product.isLoading);
  const currentPage = useSelector((state: States) => state.product.pageIndex);
  const totalData = useSelector((state: States) => state.product.totalData);
  const showAddNewProductModal = useSelector(
    (state: States) => state.catalog.visibleAddCatalogModal
  );

  const renderList = useMemo(() => {
    return productList.map((p, index) => {
      return (
        <ProductItem
          product={p}
          key={`product-item-${index}`}
          onClick={(item) => onSelectProduct(item)}
        />
      );
    });
  }, [productList]);

  const renderState = useMemo(() => {
    if (isLoading) {
      return (
        <Flex mt={3} justifyContent="center">
          <Spinner />
        </Flex>
      );
    }

    if (!totalData) {
      return (
        <Flex mt={3} justifyContent="center">
          <H2>Data tidak ditemukan</H2>
        </Flex>
      );
    }

    return;
  }, [isLoading, totalData]);

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => onCloseModal()}
      />
    );
  };

  const handleFilterChange = debounce((filter: string) => {
    setSearchFilter(filter);
  }, 1000);

  const handleChangePage = (pageIndex: number) => {
    dispatch(
      getProductListAsync.request({ page: pageIndex, search: searchFilter })
    );
  };

  useEffect(() => {
    dispatch(getProductListAsync.request({ page: 1, search: searchFilter }));
  }, [searchFilter]);

  useEffect(() => {
    dispatch(getProductListAsync.request({ page: 1 }));
  }, []);

  const renderRightHeader = () => {
    return (
      <SearchBar
        onChange={(text) => handleFilterChange(text)}
        placeholder="Cari barang"
        autoFocus
      />
    );
  };

  const handleVisibleAddNewProductModal = (visible: boolean) => {
    dispatch(setShowAddCatalogModal(visible));
  };

  return (
    <>
      {showAddNewProductModal ? (
        <AddNewProductModal
          onCloseModal={() => handleVisibleAddNewProductModal(false)}
          onSelectNewProduct={(product) => onSelectProduct(product)}
        />
      ) : null}

      <Overlay isVisible={!showAddNewProductModal && isVisible}>
        <Modal isVisible={!showAddNewProductModal && isVisible}>
          <TopBar
            leftHeader={renderLeftHeader}
            rightHeader={renderRightHeader}
            fullRightHeader
            isModal
          />
          <Wrapper
            px={2}
            pb={5}
            pt={1}
            overflowY={"scroll"}
            maxHeight="calc(100vh - 52px - 100px)"
          >
            {!totalData ? (
              <Flex
                width="100%"
                alignItems="center"
                border="1px solid"
                borderColor="blackLighter"
                flexDirection="column"
                borderRadius="4px"
                py={3}
              >
                <TouchableButton icon={HelpIcon} alt="info" />
                <Text variant="rg" textAlign="center" my={0}>
                  Barang {searchFilter} tidak ditemukan
                </Text>
                <Button
                  title="Buat barang"
                  secondary
                  onClick={() => handleVisibleAddNewProductModal(true)}
                />
              </Flex>
            ) : (
              <BodyPage>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="1px solid"
                  borderColor="blackLighter"
                  py="1"
                >
                  <Text variant="rg">{totalData} barang</Text>
                  <Button
                    title="Buat barang"
                    secondary
                    onClick={() => handleVisibleAddNewProductModal(true)}
                  />
                </Flex>
                {renderState ? (
                  renderState
                ) : (
                  <>
                    <Flex flexDirection="column">
                      {renderList}
                      {searchFilter ? (
                        <Flex
                          backgroundColor="whiteDark"
                          py="1"
                          px="0"
                          width="100%"
                          borderBottom="1px solid"
                          borderColor="blackLighter"
                        >
                          <Text variant="rg">Tidak ketemu ? &nbsp;</Text>

                          <ClickText
                            color="blueDark"
                            variant="rg"
                            fontWeight="bold"
                            onClick={() =>
                              handleVisibleAddNewProductModal(true)
                            }
                          >
                            Buat barang baru
                          </ClickText>
                        </Flex>
                      ) : null}
                    </Flex>
                  </>
                )}

                {isLoading || !totalData ? null : (
                  <Flex width="100%" justifyContent="center">
                    <Pagination
                      currentPage={currentPage}
                      totalPage={Math.ceil(totalData / 50)}
                      isLoading={isLoading}
                      onChangePage={handleChangePage}
                    />
                  </Flex>
                )}
              </BodyPage>
            )}
          </Wrapper>
        </Modal>
      </Overlay>
    </>
  );
};

const ClickText = styled(Text)`
  cursor: pointer;
`;

export default InvoiceSearchProduct;
