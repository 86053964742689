import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { getTransactionHistoryDetailAsync } from "../../reducers/history/history.action";

// type
import { States } from "../../types/general";

// component
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout/Layout";
import TransactionItem from "../TransactionDetail/PurchaseItem";

// utils
import { nominalFormat } from "../../utils/nominalFormat";

const TransactionHistoryDetail = () => {
  const dispatch = useDispatch();

  // redux state
  const router = useSelector((state: States) => state.router.location);
  const historyDetail = useSelector((state: States) => state.history.detail);

  const getTransactionID = useMemo(() => {
    const path = router.pathname;
    const splitPath = path.split("/");
    return splitPath[2];
  }, [router]);

  const renderTransactionList = useMemo(() => {
    return historyDetail?.list_product.map((p, index) => {
      return (
        <TransactionItem
          transactionID={getTransactionID}
          product={p}
          key={`history-detail-item-${index}`}
          disabled={true}
        />
      );
    });
  }, [historyDetail?.list_product]);

  useEffect(() => {
    if (getTransactionID) {
      dispatch(
        getTransactionHistoryDetailAsync.request({
          transactionID: getTransactionID,
        })
      );
    }
  }, [getTransactionID]);

  const getChanges = useMemo(() => {
    const change =
      (historyDetail?.customer_paid || 0) -
      (historyDetail?.total_basket_size || 0);

    if (change < 0) {
      return 0;
    }

    return change;
  }, [historyDetail]);

  return (
    <Layout>
      <TopBar title={`#${historyDetail?.transaction_id}`} isModal />
      <Body>
        <div className="history__header">
          <div className="total">
            Rp{nominalFormat(historyDetail?.total_basket_size)}
          </div>
          <div className="date">{historyDetail?.end_at}</div>
        </div>
        <div className="history-detail">
          <div className="title">Detil Transaksi</div>
          <div className="list">{renderTransactionList}</div>
          <div className="detail-container bold">
            <div>Total</div>
            <div>Rp{nominalFormat(historyDetail?.total_basket_size)}</div>
          </div>
          <div className="detail-container">
            <div>Tunai</div>
            <div>Rp{nominalFormat(historyDetail?.customer_paid)} </div>
          </div>
          <div className="detail-container">
            <div>Kembali</div>
            <div>Rp{nominalFormat(getChanges)}</div>
          </div>
        </div>
      </Body>
    </Layout>
  );
};

const Body = styled.div`
  .bold {
    font-weight: bold;
  }

  .history__header {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px;
    border-bottom: 1px solid #e0e0e0;

    > .total {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }

    > .date {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .history-detail {
    height: 100%;
    padding: 24px 20px;

    > .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }

    > .list {
      margin-top: 16px;
    }
  }

  .detail-container {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export default TransactionHistoryDetail;
