import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './auth.action'

// type
export type AuthActions = ActionType<typeof actions>;

export type AuthState = {
    isLoading: boolean;
    isError: boolean;
    changeEmailPayload: {
        userID: string
    } | null
}

const initialState: AuthState = {
    isLoading: false,
    isError: false,
    changeEmailPayload: null

}

export const authReducer = createReducer<AuthState, AuthActions>(initialState)
    .handleAction(actions.setNewPasswordAsync.request, (state: AuthState, action: ActionType<typeof actions.setNewPasswordAsync.request>) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.setNewPasswordAsync.success, (state: AuthState, action: ActionType<typeof actions.setNewPasswordAsync.success>) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.setNewPasswordAsync.failure, (state: AuthState, action: ActionType<typeof actions.setNewPasswordAsync.failure>) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.loginAsync.request, (state: AuthState, action: ActionType<typeof actions.loginAsync.request>) => ({ 
        ...state,
        isLoading: true,
        isError: false
    }))
    .handleAction(actions.loginAsync.success, (state: AuthState, action: ActionType<typeof actions.loginAsync.success>) => ({ 
        ...state,
        isLoading: false,
        isError: false
    }))
    .handleAction(actions.loginAsync.failure, (state: AuthState, action: ActionType<typeof actions.loginAsync.failure>) => ({ 
        ...state,
        isLoading: false,
        isError: true
    }))
    .handleAction(actions.signUpAsync.request, (state: AuthState, action: ActionType<typeof actions.signUpAsync.request>) => ({ 
        ...state,
        isLoading: true,
        isError: false
    }))
    .handleAction(actions.signUpAsync.success, (state: AuthState, action: ActionType<typeof actions.signUpAsync.success>) => ({ 
        ...state,
        isLoading: false,
        isError: false,
        changeEmailPayload: { userID: action.payload.userID }
    }))
    .handleAction(actions.signUpAsync.failure, (state: AuthState, action: ActionType<typeof actions.signUpAsync.failure>) => ({ 
        ...state,
        isLoading: false,
        isError: true
    }))
    .handleAction(actions.changeEmailAsync.request, (state: AuthState, action: ActionType<typeof actions.changeEmailAsync.request>) => ({ 
        ...state,
        isLoading: true,
        isError: false
    }))
    .handleAction(actions.changeEmailAsync.success, (state: AuthState, action: ActionType<typeof actions.changeEmailAsync.success>) => ({ 
        ...state,
        isLoading: false,
        isError: false
    }))
    .handleAction(actions.changeEmailAsync.failure, (state: AuthState, action: ActionType<typeof actions.changeEmailAsync.failure>) => ({ 
        ...state,
        isLoading: false,
        isError: true
    }))
  
  



