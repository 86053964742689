import { put, takeLeading } from 'redux-saga/effects';

// config
import axios from '../../config/axios'

// redux action
import { showToast } from '../toast/toast.action'
import { handleLogout } from '../auth/auth.action'

// related redux
import * as actions from './company.action';
import * as types from './company.actionTypes';

// constant
import { BASE_URL_BETA } from '../../constant/api';

// utils
import errorReporter from '../../utils/sentryErrorReporter'


function* getCompanyList(action: ReturnType<typeof actions.getCompanyListASync.request>) { 
    try {
        const url = `${BASE_URL_BETA}/company`;
        const response = yield (axios.get(url ));

        if (response && response.status === 200) { 
            yield put(actions.getCompanyListASync.success(response.data.company ));
        }

    } catch (err) { 
        yield put(actions.getCompanyListASync.failure({ error: "Failed get company list" } ));

        if(err.response.status === 401){
            yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
            yield put(handleLogout())
            return;
        }

        if(err.response.status === 451){
            yield put(showToast.request({ message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id", duration: 5000, type: "error" }));
            yield put(handleLogout())
            return;
        }

        errorReporter(err,  types.GET_COMPANY_LIST_REQUEST)
        yield put(showToast.request({ message: "Gagal memuat", type: "error", duration: 5000 }))
    }
}

function* addCompany(action: ReturnType<typeof actions.addCompanyAsync.request>) { 
    try {
        const { payload }  = action

        const url = `${BASE_URL_BETA}/company`
        const response = yield (axios.post(url, payload));

        if (response && response.status === 201) { 
            yield put(actions.getCompanyListASync.request());
            yield put(actions.addCompanyAsync.success({ company: response.data.data }))
            yield put(actions.showAddCompanyModal(false))
            yield put(showToast.request({ type: 'success', duration: 5000, message: "Berhasil membuat apotek baru..." }))
        }

    } catch (err) { 
        yield put(actions.addCompanyAsync.failure({error: "Failed create apotek"}))

        if(err.response.status === 401){
            yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
            yield put(handleLogout())
            return;
        }

        if(err.response.status === 451){
            yield put(showToast.request({ message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id", duration: 5000, type: "error" }));
            yield put(handleLogout())
            return;
          }

        if(err.response.status === 409){
            yield put(showToast.request({ message: "Nama apotek sudah terdaftar", type: "error", duration: 5000 }))
            yield put(actions.addCompanyAsync.failure({error: "Failed create apotek"}))
            return
        }

        errorReporter(err,  types.ADD_COMPANY_REQUEST, action.payload)
        yield put(showToast.request({ message: "Gagal membuat apotek", type: "error", duration: 5000 }))
    }
}

export default function* companySaga() {
    yield takeLeading(types.GET_COMPANY_LIST_REQUEST, getCompanyList);
    yield takeLeading(types.ADD_COMPANY_REQUEST, addCompany)
}
