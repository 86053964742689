import { put, takeLeading } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";
import {
  getStockListAsync,
  getInventoryDetailAsync,
} from "../inventory/inventory.action";

// related redux
import * as actions from "./stock.action";
import * as types from "./stock.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import { generateSortFilterQuery } from "../../utils/generateSortFilterQuery";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// type
import { DetailStock } from "../../types/inventory";

function* getBatchList(
  action: ReturnType<typeof actions.getBatchListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { productID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const query = generateSortFilterQuery(action.payload);
    const url = `${BASE_URL_BETA}/company/${companyID}/inventory/${productID}`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(actions.getBatchListAsync.success(response.data.inventory));
    }
  } catch (err) {
    yield put(actions.getBatchListAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_BATCH_LIST_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* getNonEmptyBatchList(
  action: ReturnType<typeof actions.getBatchNonEmptyAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { productID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const rawQuery = action.payload;
    // rawQuery["is_empty"] = false

    const query = generateSortFilterQuery(rawQuery);
    const url = `${BASE_URL_BETA}/company/${companyID}/inventory/${productID}`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(actions.getBatchNonEmptyAsync.success(response.data.inventory));
    }
  } catch (err) {
    yield put(actions.getBatchNonEmptyAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_BATCH_NON_EMPTY_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* getUpdateStockDetail(
  action: ReturnType<typeof actions.getUpdateStockDetailAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { stockID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const rawQuery = action.payload;

    const query = generateSortFilterQuery(rawQuery);
    const url = `${BASE_URL_BETA}/company/${companyID}/stock-detail/${stockID}`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      const data: DetailStock = {
        created_at: response.data.created_at,
        created_by: response.data.created_by,
        stock_list: response.data.stock,
      };
      yield put(actions.getUpdateStockDetailAsync.success(data));
    }
  } catch (err) {
    yield put(actions.getUpdateStockDetailAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.GET_UPDATE_STOCK_DETAIL_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* updateStock(
  action: ReturnType<typeof actions.updateStocksAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { productID, payload } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/stock/${productID}`;
    const response = yield axios.put(url, payload);

    if (response && response.status === 201) {
      yield put(actions.updateStocksAsync.success());
      yield put(
        showToast.request({
          message: "Berhasil mengupdate stok",
          duration: 5000,
          type: "success",
        })
      );
      yield put(getStockListAsync.request({ productID }));
      yield put(getInventoryDetailAsync.request({ productID }));
      yield put(actions.setShowUpdateStockModal(false));
    }
  } catch (err) {
    yield put(actions.updateStocksAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 422) {
      yield put(actions.updateStocksAsync.failure());
      yield put(
        showToast.request({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
        })
      );
      return;
    }

    if (err.response.status === 409) {
      yield put(actions.updateStocksAsync.failure());
      yield put(
        showToast.request({
          message: "Batch sudah terdaftar",
          type: "error",
          duration: 5000,
        })
      );
      return;
    }

    errorReporter(err, types.UPDATE_STOCKS_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal update stock",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* stockSaga() {
  yield takeLeading(types.GET_BATCH_LIST_REQUEST, getBatchList);
  yield takeLeading(types.GET_BATCH_NON_EMPTY_REQUEST, getNonEmptyBatchList);
  yield takeLeading(types.UPDATE_STOCKS_REQUEST, updateStock);
  yield takeLeading(
    types.GET_UPDATE_STOCK_DETAIL_REQUEST,
    getUpdateStockDetail
  );
}
