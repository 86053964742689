import { createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./product.actionTypes";

// type
import { Product } from "../../types/product";

export const getProductListAsync = createAsyncAction(
  types.GET_PRODUCT_LIST_REQUEST,
  types.GET_PRODUCT_LIST_SUCCESS,
  types.GET_PRODUCT_LIST_FAILURE
)<{ page?: number, search?: string }, {catalog: Product[], totalData: number }, undefined>();
