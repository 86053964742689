import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import {
  addPackageAsync,
  editPackageAsync,
} from "../../reducers/package/package.action";
import { showToast } from "../../reducers/toast/toast.action";

// component
import {
  Overlay,
  Modal,
  Body,
  Flex,
  Box,
  Text,
  StickyBottomContainer,
} from "../../component/Styles";
import { Button, TouchableButton } from "../../component/Button";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import Select from "../../component/Select";
import { Modal as ModalComponent } from "../../component/Modal";

// asset
import { CloseIcon, DeleteIcon, AlertIcon } from "../../asset/icon";

// type
import { States, Option } from "../../types/general";
import { Package } from "../../types/package";
import { Product } from "../../types/product";

export type CatalogAddPackageProps = {
  /** GET - packages product list */
  packages: Partial<Package>[];
  /** GET - list options of product units */
  unitOptions: Option[];
  /** GET - selected package detail */
  selectedPackage?: Partial<Package> | null;
  /** GET - selected product detail */
  selectedCatalog: Product | null;
  /** Handle close modal */
  onCloseModal: () => void;
  /** Handle add package locally when product not registered yet */
  handleAddPackage: (pack: Partial<Package>) => void;
  /** Handle delete package */
  handleDeletePackage: () => void;
};

const CatalogAddPackage = (props: CatalogAddPackageProps) => {
  const dispatch = useDispatch();

  const {
    handleDeletePackage,
    handleAddPackage,
    onCloseModal,
    packages,
    unitOptions,
    selectedPackage,
    selectedCatalog,
  } = props;

  const isLoading = useSelector((state: States) => state.package.isLoading);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [unitAlias, setUnitAlias] = useState("");
  const [qty, setQty] = useState<number | undefined>(0);
  const [selectedUnit, setSelectedUnit] = useState<Option | null>(null);
  const [errorValidation, setErrorValidation] = useState({
    packageName: "",
    unitAlias: "",
    qty: "",
  });

  const handleChangeAlias = (alias: string) => {
    const clonedAlias = alias.trim();

    setHasChanges(true);
    setUnitAlias(clonedAlias.slice(0, 3));
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => handleCloseModal()}
        withFeedback
      />
    );
  };

  const renderRightHeader = () => {
    if (!selectedPackage) {
      return null;
    }

    return (
      <TouchableButton
        icon={DeleteIcon}
        alt="delete"
        withFeedback
        onClick={() => setShowDeleteModal(true)}
      />
    );
  };

  /**
   * Validate data before save make sure not duplicate and data clear
   */
  const validateAddPackage = () => {
    let isValid = true;
    const validation = {
      packageName: "",
      unitAlias: "",
      qty: "",
    };

    let isDuplicateCode = false;
    let isDuplicateName = false;

    const isDefaultValue =
      selectedPackage?.unit_code === unitAlias.trim() &&
      selectedPackage.unit_name === packageName.trim();

    const smallestUnit = unitOptions.find((o) => o.status === "default");

    if (smallestUnit?.label === packageName && !isDefaultValue) {
      isValid = false;
      validation.packageName =
        "Nama kemasan tidak boleh sama dengan nama satuan.";
    }

    if (smallestUnit?.value.code === unitAlias && !isDefaultValue) {
      isValid = false;
      validation.packageName =
        "Singkatan kemasan tidak boleh sama dengan singkatan satuan.";
    }

    packages.forEach((p) => {
      if (
        p.unit_name === packageName &&
        !(selectedPackage?.unit_name! === packageName)
      ) {
        isDuplicateName = true;
      }

      if (
        p.unit_code === unitAlias &&
        !(selectedPackage?.unit_code! === unitAlias)
      ) {
        isDuplicateCode = true;
      }
    });

    if (isDuplicateName && !isDefaultValue) {
      isValid = false;
      validation.packageName = "Nama kemasan sudah terdaftar pada barang ini.";
    }

    if (isDuplicateCode && !isDefaultValue) {
      isValid = false;
      validation.unitAlias = "Satuan kemasan sudah terdaftar pada barang ini.";
    }

    if (!packageName) {
      isValid = false;
      validation.packageName = "Nama kemasan tidak boleh kosong.";
    }

    if (!unitAlias) {
      isValid = false;
      validation.unitAlias = "Nama singkatan kemasan tidak boleh kosong.";
    }

    if (!qty) {
      isValid = false;
      validation.unitAlias = "Kuantitas kemasan tidak boleh kosong.";
    }

    setErrorValidation(validation);

    return { isValid, isDefaultValue };
  };

  const handleSave = () => {
    /**
     * isDefault true when user not change package name or unit code
     * and show already success change
     */
    if (validateAddPackage().isDefaultValue) {
      dispatch(
        showToast.request({
          message: "Berhasil memperbaharui kemasan.",
          duration: 5000,
          type: "success",
        })
      );

      onCloseModal();
      return;
    }

    if (!validateAddPackage().isValid) {
      dispatch(
        showToast.request({
          message: "Mohon cek data anda.",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    setErrorValidation({ packageName: "", unitAlias: "", qty: "" });
    setHasChanges(false);

    /**
     * trim to make sure data clean from whitespace
     */
    const clonedPackageName = packageName.trim();
    const clonedUnitCode = unitAlias.trim();

    // if product not registered yet
    // handle add package to local state first and will upload to database when add product
    if (!selectedCatalog) {
      // check if selected unit is smallest unit
      const isSmallestUnit = selectedUnit?.status === "default";

      const newPackage: any = {
        unit_name: clonedPackageName,
        unit_code: clonedUnitCode,
        conversion_qty: qty || 0,
        conversion_unit: selectedUnit?.value?.unit,
        conversion_package_id: isSmallestUnit ? -1 : selectedUnit?.value.id,
        smallest_qty: (qty || 0) * selectedUnit?.value.qty,
        is_smallest_unit: isSmallestUnit,
      };

      if (selectedPackage) {
        newPackage.index = selectedPackage.index;
      }

      handleAddPackage(newPackage);

      onCloseModal();
      return;
    }

    // handle update package if already exist
    if (selectedPackage) {
      dispatch(
        editPackageAsync.request({
          productID: selectedCatalog?.id!,
          packageID: selectedPackage?.id!,
          unitName: clonedPackageName,
          unitCode: clonedUnitCode,
          conversionPackageID: selectedUnit?.value.id!,
        })
      );
      return;
    }

    // handle add package
    const smallestUnit = unitOptions.find((o) => o.status === "default");

    dispatch(
      addPackageAsync.request({
        product_id: selectedCatalog?.id,
        unit_name: clonedPackageName,
        unit_code: clonedUnitCode,
        conversion_qty: qty,
        conversion_unit: selectedUnit?.value?.unit,
        conversion_package_id: selectedUnit?.value.id || null,
        smallest_qty: selectedUnit?.value.qty * (qty || 0),
        unit: smallestUnit?.value.unit,
      })
    );
  };

  // Set selected unit to smallest unit
  useEffect(() => {
    const defaultUnit = unitOptions.find((u) => u.status === "default");
    setSelectedUnit(defaultUnit!);
  }, [unitOptions]);

  useEffect(() => {
    if (selectedPackage) {
      setPackageName(selectedPackage?.unit_name!);
      setQty(selectedPackage?.conversion_qty!);
      setUnitAlias(selectedPackage?.unit_code!);

      const conversionUnit = selectedPackage.conversion_unit;
      const selectedUnit = unitOptions.find((u) => u.label === conversionUnit);
      setSelectedUnit(selectedUnit!);
    }
  }, [selectedPackage]);

  const handleCloseModal = () => {
    if (!hasChanges) {
      onCloseModal();
      return;
    }

    setShowConfirmExitModal(true);
  };

  const onDeletePackage = () => {
    handleDeletePackage();
    setShowDeleteModal(false);
  };

  const handleChangePackageName = (name: string) => {
    setHasChanges(true);
    setPackageName(name);

    const clonedName = name.trim();
    setUnitAlias(clonedName.slice(0, 3));
  };

  return (
    <Overlay isVisible={true}>
      {/* Confirmation exit modal */}
      <ModalComponent
        title="Perubahan belum disimpan"
        isVisible={showConfirmExitModal && hasChanges}
        subtitle="Jiika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
        maxWidth="320px"
        destructiveTitle="Keluar"
        destructiveFunction={() => onCloseModal()}
        acceptTitle={"Batal"}
        acceptFunction={() => {
          setShowConfirmExitModal(false);
        }}
      />

      {/* Confirmation delete modal */}
      <ModalComponent
        title={`Hapus  ${selectedPackage?.unit_name}(${selectedPackage?.smallest_qty} ${selectedPackage?.conversion_unit})`}
        isVisible={showDeleteModal}
        subtitle="Kemasan yang sudah dihapus tidak dapat dikembalikan lagi."
        destructiveFunction={() => onDeletePackage()}
        destructiveTitle="Hapus"
        acceptFunction={() => setShowDeleteModal(false)}
        acceptTitle="Batal"
      />

      <Modal isVisible={true}>
        <Box>
          <TopBar
            title={selectedPackage ? "Edit kemasan" : "Tambah kemasan"}
            isModal
            leftHeader={renderLeftHeader}
            rightHeader={renderRightHeader}
          />
          <Box p="2">
            <Flex>
              <Flex>
                <TextInput
                  autoFocus
                  label="Nama kemasan"
                  value={packageName}
                  onChange={(text) => {
                    handleChangePackageName(text || "");
                  }}
                  isError={!!errorValidation.packageName}
                />
              </Flex>
              <Flex ml={2} alignItems="flex-end">
                <Flex alignItems="center">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Text fontSize={2} lineHeight={2}>
                      Singkatan
                    </Text>
                    <Text fontSize={1} lineHeight={1}>
                      (3 huruf)
                    </Text>
                  </Flex>
                  <Box maxWidth="70px" ml="1">
                    <TextInput
                      value={unitAlias}
                      onChange={(text) => handleChangeAlias(text || "")}
                      isError={!!errorValidation.unitAlias}
                    />
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {errorValidation.packageName || errorValidation.unitAlias ? (
              <Flex mt={0}>
                <img src={AlertIcon} width="16" height="16" alt="alert" />
                <Text fontSize="1" lineHeight="1" ml="0" color="coralDark">
                  {errorValidation.packageName || errorValidation.unitAlias}
                </Text>
              </Flex>
            ) : null}

            <Flex mt={1}>
              <Flex width="20%">
                <TextInput
                  label="Isi"
                  type="number"
                  value={qty}
                  onChange={(value) => {
                    setQty(value ? +value : undefined);
                    setHasChanges(true);
                  }}
                  disabled={!!selectedPackage}
                />
              </Flex>
              <Flex alignItems="flex-end" ml="1">
                <Select
                  width={100}
                  label="Satuan"
                  options={unitOptions}
                  selectedOption={selectedUnit!}
                  isDisabled={!!selectedPackage}
                  onSelect={(option) => {
                    setSelectedUnit(option);
                    setHasChanges(true);
                  }}
                />
              </Flex>
            </Flex>
          </Box>

          <StickyBottomContainer
            px="2"
            py="1"
            background="white"
            position="absolute"
            width="calc(100% - 3rem)"
          >
            <Button
              title={selectedPackage ? "Simpan" : "Tambahkan"}
              fullWidth
              disabled={!packageName || !qty || !unitAlias}
              onClick={handleSave}
              isLoading={isLoading}
            />
          </StickyBottomContainer>
        </Box>
      </Modal>
    </Overlay>
  );
};

export const BodyPage = styled(Body)`
  padding: 0px;
  position: relative;
  display: block;
`;

export default CatalogAddPackage;
