import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import styled from "styled-components/macro";

// redux action
import { visiblePackageModal } from "../../../reducers/package/package.action";

// component
import TopBar from "../../../component/TopBar";
import TextInput from "../../../component/TextInput";
import Header from "../../../component/Header/Header";
import DatePicker from "../../../component/DatePicker";
import Select from "../../../component/Select";
import { Button, TouchableButton } from "../../../component/Button";
import {
  Overlay,
  Modal,
  BodyPage,
  Flex,
  Table,
  Text,
  StickyBottomContainer,
  Wrapper,
  Box,
} from "../../../component/Styles";

// related component
import InventoryUpdateStockEditPackageList, {
  Option,
} from "./InventoryUpdateStockEditPackageList";

// asset
import { AlertIcon, CloseIcon, DeleteIcon } from "../../../asset/icon";
import { ProductDetail } from "../../../types/product";
import { InventoryBatch } from "../../../types/inventory";
import { NewBatch, Stock } from "./type";
import { getValidDate } from "../../../utils/getValidDate";

export type Props = {
  isVisible?: boolean;
  product: ProductDetail | null;
  inventoryBatch: InventoryBatch | null;
  selectedNewBatch: NewBatch | null;
  handleDeleteAddBatch: (index: number) => void;
  onCloseModal: () => void;
  onAddNewBatch: (value: {
    data: NewBatch;
    index?: number;
    type: "save" | "add";
  }) => void;
};

const InventoryUpdateStockAddBatch = (props: Props) => {
  const dispatch = useDispatch();

  const {
    isVisible,
    product,
    inventoryBatch,
    selectedNewBatch,
    handleDeleteAddBatch,
    onCloseModal,
    onAddNewBatch,
  } = props;

  // state
  const [selectedPackageBuy, setSelectedPackageBuy] = useState<null | Option>(
    null
  );
  const [batchNumber, setBatchNumber] = useState("");
  const [ED, setED] = useState<Date | null>();
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [priceBuy, setPriceBuy] = useState(0);
  const [buyDate, setBuyDate] = useState<Date | null>(null);

  const [errorValidation, setErrorValidation] = useState({
    batchNumber: "",
    ED: "",
    stock: "",
  });

  /**
   * Validate data before save make sure not duplicate and data clear
   */
  const validateAddBatch = () => {
    let isValid = true;
    const validation = {
      batchNumber: "",
      ED: "",
      stock: "",
    };

    // remove validation
    // if (!batchNumber) {
    //   isValid = false;
    //   validation.batchNumber = "Nomor batch wajib diisi.";
    // }

    // if (!ED) {
    //   isValid = false;
    //   validation.ED = "Tanggal ED wajib diisi.";
    // }

    setErrorValidation(validation);
    return { isValid };
  };

  const handleSave = () => {
    let validation = validateAddBatch();

    if (!validation.isValid) {
      return;
    }

    const data: NewBatch = {
      batch_no: batchNumber,
      ED: ED ? moment(ED).format("YYYY-MM-DD") : null,
      buy_date: buyDate ? moment(buyDate).format("YYYY-MM-DD") : null,
      qty: getTotalStock,
      price_buy: priceBuy,
      package_id_buy: selectedPackageBuy?.value.id!,
      stocks: stocks,
      unit: selectedPackageBuy?.label!,
    };

    if (selectedNewBatch) {
      onAddNewBatch({ data: data, type: "save", index: inventoryBatch?.index });
    } else {
      onAddNewBatch({ data: data, type: "add" });
    }

    onCloseModal();
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton icon={CloseIcon} alt="close" onClick={onCloseModal} />
    );
  };

  const setShowAddPackageModal = (visible: boolean) => {
    dispatch(visiblePackageModal(visible));
  };

  const renderRightHeader = () => {
    if (!selectedNewBatch) {
      return;
    }

    return (
      <TouchableButton
        icon={DeleteIcon}
        alt="delete"
        onClick={() => handleDeleteAddBatch(inventoryBatch?.index!)}
      />
    );
  };

  const renderValidationAlert = (message: string) => {
    return message ? (
      <Flex mt={0}>
        <img src={AlertIcon} width="16" height="16" alt="alert" />
        <Text fontSize="1" lineHeight="1" ml="0" color="coralDark">
          {message}
        </Text>
      </Flex>
    ) : (
      ""
    );
  };

  const getPackageOptions = useMemo(() => {
    const packages = (product?.package || []).map((p) => {
      return {
        label: p.unit_name,
        value: p,
      };
    });
    packages.unshift({
      label: product?.unit!,
      value: {
        id: "-1",
        product_id: product?.id!,
        unit_name: product?.unit!,
        unit_code: product?.unit_code!,
        conversion_qty: 1,
        conversion_unit: product?.unit!,
        smallest_qty: 1,
      },
    });

    if (!stocks.length) {
      setStocks([
        {
          stock: inventoryBatch?.batch_qty!,
          selectedUnit: product?.unit_code!,
          smallestQty: 1,
        },
      ]);
    }

    return packages;
  }, [product]);

  const getTotalStock = useMemo(() => {
    let total = 0;

    stocks.forEach((s) => {
      total += (s.stock || 0) * s.smallestQty;
    });

    return total;
  }, [stocks, getPackageOptions]);

  useEffect(() => {
    if (product) {
      setSelectedPackageBuy({
        label: product?.unit! || "",
        value: {
          id: "-1",
          product_id: product?.id!,
          unit_name: product?.unit!,
          unit_code: product?.unit_code!,
          conversion_qty: 1,
          conversion_unit: product?.unit!,
          smallest_qty: 1,
        },
      });
    }
  }, [product]);

  useEffect(() => {
    if (selectedNewBatch) {
      setBatchNumber(selectedNewBatch?.batch_no);
      setBuyDate(getValidDate(selectedNewBatch.buy_date));
      setED(getValidDate(selectedNewBatch.ED));
      setBuyDate(getValidDate(selectedNewBatch.buy_date));
      setPriceBuy(selectedNewBatch.price_buy || 0);
      setStocks(selectedNewBatch.stocks || []);

      const getSelectedPackageBuy = getPackageOptions.find(
        (p) => p.value.id === selectedNewBatch.package_id_buy
      );

      setSelectedPackageBuy(
        getSelectedPackageBuy ? (getSelectedPackageBuy as Option) : null
      );
    }
  }, [selectedNewBatch]);

  return (
    <Overlay isVisible={!!isVisible}>
      <Modal isVisible={!!isVisible}>
        <TopBar
          title="Update stok"
          leftHeader={renderLeftHeader}
          rightHeader={renderRightHeader}
          isModal
        />
        <Wrapper
          p={2}
          pb={5}
          overflowY="scroll"
          height="calc(100vh - 52px - 52px)"
          maxHeight="calc(100vh - 52px - 52px - 9rem)"
        >
          <BodyPage>
            <Header title="Tambah batch" />

            <Box mt={2}>
              <TextInput
                label="Nomor batch"
                value={batchNumber}
                onChange={(text) => {
                  setBatchNumber(text.toUpperCase() || "");
                }}
                isError={!!errorValidation.batchNumber}
              />
            </Box>
            {renderValidationAlert(errorValidation.batchNumber)}

            <Box mt={0}>
              <DatePicker
                label="Tanggal ED"
                selectedDate={ED}
                onChange={(date) => setED(date)}
              />
              {renderValidationAlert(errorValidation.ED)}
            </Box>

            {/* render package list */}
            <InventoryUpdateStockEditPackageList
              product={product}
              inventoryBatch={inventoryBatch}
              stocks={stocks}
              setStocks={setStocks}
            />

            {/* <Flex mt={3} flexDirection="column">
              <Text fontSize={3}>
                <span className="bold"> Informasi pembelian</span> (opsional)
              </Text>

              <Flex mt={1} flexDirection="column" width="100%">
                <DatePicker
                  label="Tanggal pembelian"
                  onChange={(date) => setBuyDate(date)}
                  selectedDate={buyDate}
                />
                <Flex mt={0} width="100%">
                  <Flex flex="1">
                    <TextInput
                      label="Harga beli"
                      type="number"
                      inputLabel={"Rp"}
                      allowNegative={false}
                      value={priceBuy}
                      onChange={(value) => setPriceBuy(+value)}
                    />
                  </Flex>
                  <Flex ml="0" width="100%" flex="1">
                    <Box width="100%">
                      <Select
                        label="Satuan beli"
                        options={getPackageOptions}
                        onSelect={setSelectedPackageBuy}
                        selectedOption={selectedPackageBuy}
                        createAction={() => setShowAddPackageModal(true)}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex> */}
          </BodyPage>
        </Wrapper>

        <StickyBottomContainer
          position="absolute"
          bottom="0"
          p={1}
          width="calc(100% - 2rem)"
        >
          <Flex width="100%">
            <Button title="Simpan" fullWidth onClick={handleSave} />
          </Flex>
        </StickyBottomContainer>
      </Modal>
    </Overlay>
  );
};

const ExtendTable = styled(Table)``;

export default InventoryUpdateStockAddBatch;
