import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { encryptTransform } from "redux-persist-transform-encrypt";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { makeRootReducer } from "./rootReducers";
import { rootSaga } from "./rootSagas";

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

const persistedReducer = persistReducer(
  {
    transforms: [
      encryptTransform({
        secretKey: "my-super-secret-key",
        onError: function (error) {
          // Handle the error.
        },
      }),
    ],
    key: "root",
    storage,
    blacklist: ['router', 'auth', 'catalog', 'invoice', 'toast', 'transaction', 'invoice', 'stock', 'inventory', 'history'],
  },

  makeRootReducer(history)
);

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk, routeMiddleware];



const store = createStore(persistedReducer, applyMiddleware(...middlewares));

/**
 * SAGA MIDDLEWARE
 */
sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  // console.log(store.getState());
});


const persistor = persistStore(store);

export { store, history, persistor };
