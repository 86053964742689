import React, { Suspense } from "react";

const SignUp = React.lazy(() => import("./SignUp"));

export const SignUpPage = () => {
  return (
    <Suspense fallback="Memuat...">
      <SignUp />
    </Suspense>
  );
};
