import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./auth.actionTypes";

export const submitForgotPasswpordAsync = createAsyncAction(
  types.POST_FORGOT_PASSWORD_REQUEST,
  types.POST_FORGOT_PASSWORD_SUCCESS,
  types.POST_FORGOT_PASSWORD_FAILURE
)<{ identifier: string }, undefined, { error: string }>();

export const submitNewPasswordForgotAsync = createAsyncAction(
  types.POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_REQUEST,
  types.POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_SUCCESS,
  types.POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_FAILURE
)<
  { username: string; newPassword: string; code: string },
  undefined,
  { error: string }
>();

export const setNewPasswordAsync = createAsyncAction(
  types.SET_NEW_PASSWORD_REQUEST,
  types.SET_NEW_PASSWORD_SUCCESS,
  types.SET_NEW_PASSWORD_FAILURE
)<{ password: string, username: string, name: string, phoneNumber: string }, undefined, { error: string }>();

export const loginAsync= createAsyncAction(
  types.LOGIN_REQUEST,
  types.LOGIN_SUCCESS,
  types.LOGIN_FAILURE
)<{ identifier: string, password: string }, undefined, undefined>();

export const signUpAsync = createAsyncAction(
  types.SIGN_UP_REQUEST,
  types.SIGN_UP_SUCCESS,
  types.SIGN_UP_FAILURE
)<{ name: string, email: string, password: string,  phoneNumber: string }, {userID: string}, undefined>();

export const changeEmailAsync = createAsyncAction(
  types.HANDLE_CHANGE_EMAIL_REQUEST,
  types.HANDLE_CHANGE_EMAIL_SUCCESS,
  types.HANDLE_CHANGE_EMAIL_FAILURE
)<{ email: string, password: string, user_id: string}, undefined, undefined>();


export const handleLogout = () => action(types.LOGOUT)
