import { put, takeLeading } from "redux-saga/effects";
import { push } from "connected-react-router";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./profile.action";
import * as types from "./profile.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

function* getUserProfile(
  action: ReturnType<typeof actions.getUserProfileAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/profile`;
    const response = yield axios.get(url, {
      params: { company_id: companyID },
    });

    if (response && response.status === 200) {
      const profile = response.data.profile;
      yield put(actions.getUserProfileAsync.success(profile));
      localStorage.setItem("bio", JSON.stringify(profile));
      yield put(push("/"));
    }
  } catch (err) {
    actions.getUserProfileAsync.failure({ error: err.message });

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_PROFILE_REQUEST);

    yield put(
      showToast.request({
        message: "Gagal memuat profile",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* profileSaga() {
  yield takeLeading(types.GET_PROFILE_REQUEST, getUserProfile);
}
