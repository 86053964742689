import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import {
  postCatalogAsync,
  updateCatalogAsync,
  deleteCatalogAsync,
  getProductDetailAsync,
} from "../../reducers/catalog/catalog.action";
import {
  visiblePackageModal,
  deletePackageAsync,
} from "../../reducers/package/package.action";

// component
import TopBar from "../../component/TopBar";
import Button from "../../component/Button/Button";
import TextInput from "../../component/TextInput/TextInput";
import {
  Box,
  Body,
  Text,
  Flex,
  H2,
  Modal,
  Overlay,
  StickyBottomContainer,
  Table,
} from "../../component/Styles";
import Select from "../../component/Select";
import { TouchableButton } from "../../component/Button";
import { Modal as ModalComponent } from "../../component/Modal";
import { Spinner } from "../../component/Loading";
import Alert from "../../component/Alert";

// related component
import CatalogAddPackage from "./CatalogAddPackage";

// constant
import theme from "../../constant/styledTheme";

// asset
import AddIcon from "../../asset/icon/add.svg";
import { AlertIcon, CloseIcon, DeleteIcon } from "../../asset/icon";

// type
import { Product, ProductDetail } from "../../types/product";
import { States, Option } from "../../types/general";
import { Package } from "../../types/package";
import { nominalFormat } from "../../utils/nominalFormat";
import { showToast } from "../../reducers/toast/toast.action";

// utils
import { postNewProduct } from "../../utils/postNewProduct";
import { cloneDeep } from "lodash";

export type CatalogAddProps = {
  onSelectNewProduct?: (p: Product) => void;
  /** Handle close edit/add product modal*/
  onCloseModal: () => void;
  /** GET - selected product from list, null if new product */
  selectedCatalog?: Product | null;
};

const CatalogAdd = (props: CatalogAddProps) => {
  const { selectedCatalog, onCloseModal, onSelectNewProduct } = props;

  const dispatch = useDispatch();

  // Redux state
  const isLoading = useSelector((state: States) => state.catalog.isLoading);
  const packagesLoading = useSelector(
    (state: States) => state.catalog.isLoading
  );
  const productDetail = useSelector(
    (state: States) => state.catalog.productDetail
  );
  const visibleAddPackageModal = useSelector(
    (state: States) => state.package.visiblePackageModal
  );
  const profile = useSelector((state: States) => state.profile.profile);

  // State
  const [localLoading, setLocalLoading] = useState(false);
  const [packages, setPackages] = useState<Partial<Package>[]>([]);
  const [showDeleteCatalogModal, setShowDeleteCatalogModal] = useState(false);
  const [showDeletePackageModal, setShowDeletePackageModal] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [
    selectedProductDetail,
    setSelectedProductDetail,
  ] = useState<ProductDetail | null>(null);
  const [
    selectedPackage,
    setSelectedPackage,
  ] = useState<Partial<Package> | null>(null);
  const [name, setName] = useState<string>("");
  const [priceByUnit, setPriceByUnit] = useState<number | undefined>(0);
  const [smallestUnit, setSmallestUnit] = useState<string>("butir");
  const [unitCode, setUnitCode] = useState<string>("btr");
  const [price, setPrice] = useState<number | undefined>(0);
  const [unitOptions, setUnitOptions] = useState<Option[]>([
    {
      label: "butir",
      value: "butir",
      status: "default",
    },
  ]);

  const [selectedUnit, setSelectedUnit] = useState<Option>({
    label: "butir",
    value: "butir",
    status: "default",
  });

  // State for validation error
  const [error, setError] = useState<{
    unit: string;
    smallestUnit: string;
    unitCode: string;
    price: string;
    name: string;
  }>({ unit: "", smallestUnit: "", unitCode: "", price: "", name: "" });

  const setVisibleAddPackageModal = (visible: boolean) => {
    dispatch(visiblePackageModal(visible));
    setSelectedPackage(null);
  };

  const validateAddProduct = () => {
    let isValid = true;
    const isAliasDuplicate = false;
    const validation = {
      unit: "",
      smallestUnit: "",
      unitCode: "",
      price: "",
      name: "",
    };

    const clonedSmallestUnit = smallestUnit.trim();
    const clonedUnitCode = smallestUnit.trim();
    const clonedName = name.trim();

    if (!clonedSmallestUnit) {
      isValid = false;
      validation.smallestUnit = "Satuan terkecil wajib diisi.";
    }

    if (!clonedUnitCode) {
      isValid = false;
      validation.unitCode = "Singkatan satuan wajib diisi.";
    }

    if (!clonedName) {
      isValid = false;
      validation.name = "Nama barang wajib diisi.";
    }

    if (isAliasDuplicate) {
      isValid = false;
      validation.unitCode = "Singkatan satuan sudah digunakan di kemasan lain.";
    }

    if (price === undefined) {
      isValid = false;
      validation.price = "Harga tidak boleh kosong.";
    }

    setError(validation);

    return isValid;
  };

  const handleChangeAlias = (alias: string) => {
    setHasChanges(true);

    const clonedAlias = alias.trim();
    setUnitCode(clonedAlias.slice(0, 3));
  };

  const handleSave = async () => {
    const isValid = validateAddProduct();

    if (!isValid) {
      return;
    }

    setHasChanges(false);

    const clonedSmallestUnit = smallestUnit.trim();
    const clonedUnitCode = smallestUnit.trim();
    const clonedName = name.trim();

    // Handle add new product
    if (!selectedCatalog) {
      const clonedPackages = [...packages];
      // prevent discrepancy smallest unit when add new product
      clonedPackages.map((p) => {
        if (p.is_smallest_unit) {
          p.conversion_unit = clonedSmallestUnit;
          delete p.is_smallest_unit;
          delete p.index;
          return p;
        }

        delete p.is_smallest_unit;
        delete p.index;
        return p;
      });

      const payload = {
        name: clonedName,
        unit: clonedSmallestUnit,
        unit_code: clonedUnitCode,
        price,
        package_list: clonedPackages,
      };

      if (onSelectNewProduct) {
        try {
          setLocalLoading(true);
          const product = await postNewProduct(payload);
          onSelectNewProduct(product);
        } catch (err) {
          dispatch(
            showToast.request({
              message: "Gagal menambahkan barang",
              type: "error",
              duration: 5000,
            })
          );
        }
        setLocalLoading(false);
        return;
      }

      dispatch(postCatalogAsync.request(payload));
      return;
    }

    // Handle update product
    dispatch(
      updateCatalogAsync.request({
        id: selectedCatalog.id,
        name: clonedName,
        unit: clonedSmallestUnit,
        unit_code: clonedUnitCode,
        price,
      })
    );
  };

  const handleShowSelectedPackage = (p: Partial<Package>, index: number) => {
    setVisibleAddPackageModal(true);
    setSelectedPackage({ ...p, index });
  };

  const handleClosePackageModal = () => {
    setVisibleAddPackageModal(false);
    setSelectedPackage(null);
  };

  const handleSelectUnit = (unit: Option) => {
    setSelectedUnit(unit);
    setPriceByUnit(unit.value.qty * (price || 0));
  };

  const handleSetPriceByUnit = (price: string | undefined) => {
    setHasChanges(true);
    setError({ ...error, price: "" });
    setPrice(+(price || 0) / selectedUnit.value.qty);
    setPriceByUnit(+(price || 0));
  };

  const handleCloseModal = () => {
    if (!hasChanges) {
      onCloseModal();
      return;
    }
    setShowConfirmExitModal(true);
  };

  const handleDeleteCatalog = () => {
    dispatch(deleteCatalogAsync.request({ id: selectedCatalog?.id! }));
  };

  const handleDeletePackage = () => {
    //  Handle delete package locally
    if (!selectedCatalog) {
      const clonedPackages = packages.slice();
      clonedPackages.splice(selectedPackage?.index || 0, 1);

      setPackages(clonedPackages);
      setShowDeletePackageModal(false);
      setVisibleAddPackageModal(false);

      dispatch(
        showToast.request({
          message: "Berhasil menghapus kemasan",
          type: "success",
          duration: 5000,
        })
      );
      return;
    }

    // handle delete if catalog is exist
    if (selectedPackage && selectedCatalog) {
      dispatch(
        deletePackageAsync.request({
          packageID: selectedPackage?.id!,
          productID: selectedCatalog?.id!,
        })
      );
      setShowDeletePackageModal(false);
    }
  };

  const packageOptions = useMemo(() => {
    return packages.map((p) => {
      if (p.conversion_package_id === -1) {
        return { ...p, conversion_unit: smallestUnit };
      }

      return p;
    });
  }, [packages, smallestUnit, unitCode]);

  // Render package table
  const renderPackageTable = useMemo(() => {
    return (packageOptions || []).map((p, index) => {
      return (
        <tr
          key={`product-package-table-item-${index}`}
          onClick={() => handleShowSelectedPackage(p, index)}
        >
          <td>
            <Text color="blueDark" fontSize="2" lineHeight="2">
              {p.unit_name} ({p.unit_code})
            </Text>
          </td>
          <td>
            <Text fontSize="2" lineHeight="2">
              {p.conversion_qty} {p.conversion_unit}
            </Text>
          </td>
          <td>
            <TouchableButton
              icon={DeleteIcon}
              alt="delete"
              onClick={() => {
                setSelectedPackage(p);
                setShowDeletePackageModal(true);
              }}
            />
          </td>
        </tr>
      );
    });
  }, [packageOptions]);

  // Handle add packages when product not registered yet
  const handleAddPackagesLocal = (pack: Partial<Package>) => {
    // Make sure product is already registered
    if (!selectedCatalog && !pack.index) {
      setPackages([...packages, pack]);
    }

    if (pack.index || pack.index === 0) {
      const clonedPackages = cloneDeep(packages);
      clonedPackages.splice(pack.index, 1, pack);
      setPackages(clonedPackages);
      return;
    }
  };

  const handleChangeSmallestUnitName = (name: string) => {
    setHasChanges(true);
    setSmallestUnit(name);

    const clonedName = name.trim();
    setUnitCode(clonedName.slice(0, 3));
  };

  const renderRightHeader = () => {
    if (selectedCatalog) {
      return (
        <TouchableButton
          icon={DeleteIcon}
          alt="delete"
          onClick={() => setShowDeleteCatalogModal(true)}
        />
      );
    }
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => handleCloseModal()}
        withFeedback
      />
    );
  };

  useEffect(() => {
    // Get smallest unit index
    const defaultSmallestUnitIndex = unitOptions.findIndex(
      (u) => u.status === "default"
    );

    const options = unitOptions.slice();

    // Handle change unit options when user input new smallest index
    options.splice(defaultSmallestUnitIndex, 1, {
      label: smallestUnit,
      value: { unit: smallestUnit, qty: 1, code: unitCode, id: -1 },
      status: "default",
    });

    setUnitOptions(options);

    // If selected unit is smallest,  change value of selected unit by value from user
    if (selectedUnit.status === "default") {
      setSelectedUnit({
        label: smallestUnit,
        value: { unit: smallestUnit, qty: 1, code: unitCode, id: -1 },
        status: "default",
      });
    }
  }, [unitCode, smallestUnit]);

  useEffect(() => {
    if (
      selectedCatalog &&
      selectedProductDetail &&
      selectedCatalog.id === selectedProductDetail.id
    ) {
      setName(selectedProductDetail.name);

      const price =
        selectedProductDetail.price === null
          ? undefined
          : selectedProductDetail.price;

      setPrice(price);
      setPriceByUnit(price);

      // Cut unit code name if more than 3 character
      const unitCode = (selectedProductDetail.unit_code || "").slice(0, 3);

      setUnitCode(unitCode || "");
      setSmallestUnit(selectedProductDetail.unit);
    }
  }, [selectedProductDetail, selectedCatalog]);

  useEffect(() => {
    // Update product detail to update product detail form fetch
    if (productDetail && selectedCatalog) {
      setSelectedProductDetail((prevDetail) => {
        if (
          (prevDetail?.name !== productDetail.name ||
            prevDetail?.price !== productDetail.price) &&
          prevDetail?.id === productDetail.id &&
          name &&
          priceByUnit
        ) {
          const clonedDetail = cloneDeep(prevDetail);
          clonedDetail.name = name;
          clonedDetail.price = priceByUnit;
          return clonedDetail;
        }

        return productDetail;
      });
      setPackages(productDetail?.package);
    }
  }, [productDetail]);

  useEffect(() => {
    if (packages) {
      const options: Option[] = packages.map((o) => {
        return {
          label: o.unit_name,
          value: {
            unit: o.unit_name,
            qty: o.smallest_qty,
            code: o.unit_code,
            id: o.id,
          },
          status: null,
        };
      });

      options.unshift({
        label: smallestUnit,
        value: { unit: smallestUnit, qty: 1, code: unitCode, id: -1 },
        status: "default",
      });
      setUnitOptions(options);
    }
  }, [packages]);

  console.log(visibleAddPackageModal);

  // Fetch product detail for existing product
  useEffect(() => {
    setVisibleAddPackageModal(false);

    if (selectedCatalog) {
      dispatch(
        getProductDetailAsync.request({ productID: selectedCatalog.id })
      );
    } else {
      setPackages([]);
    }

    return () => {
      setVisibleAddPackageModal(false);
      onCloseModal();
    };
  }, []);

  return (
    <>
      {visibleAddPackageModal ? (
        <CatalogAddPackage
          packages={packages}
          unitOptions={unitOptions}
          selectedPackage={selectedPackage}
          selectedCatalog={selectedCatalog!}
          handleAddPackage={handleAddPackagesLocal}
          onCloseModal={() => handleClosePackageModal()}
          handleDeletePackage={() => handleDeletePackage()}
        />
      ) : null}
      <Overlay isVisible={!visibleAddPackageModal}>
        {/* component to edit product package */}

        {/* modal for leaving confirmation */}
        <ModalComponent
          title="Perubahan belum disimpan"
          isVisible={showConfirmExitModal && hasChanges}
          subtitle="Jiika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
          maxWidth="320px"
          destructiveTitle="Keluar"
          destructiveFunction={() => onCloseModal()}
          acceptTitle={"Batal"}
          acceptFunction={() => {
            setShowConfirmExitModal(false);
          }}
        />

        {/* modal for delete package confirmation */}
        <ModalComponent
          title={`Hapus  ${selectedPackage?.unit_name}(${selectedPackage?.smallest_qty} ${selectedPackage?.conversion_unit})`}
          isVisible={showDeletePackageModal}
          subtitle="Kemasan yang sudah dihapus tidak dapat dikembalikan lagi."
          destructiveFunction={() => handleDeletePackage()}
          destructiveTitle="Hapus"
          acceptFunction={() => setShowDeletePackageModal(false)}
          acceptTitle="Batal"
        />

        {/* modal for delete catalog confirmation */}
        <ModalComponent
          title={`Hapus ${selectedCatalog?.name} ?`}
          isVisible={showDeleteCatalogModal}
          subtitle="Barang yang sudah dihapus tidak dapat dikembalikan lagi."
          destructiveFunction={() => handleDeleteCatalog()}
          destructiveTitle="Hapus"
          acceptFunction={() => setShowDeleteCatalogModal(false)}
          acceptTitle="Batal"
        />

        <Modal isVisible={true}>
          <TopBar
            isModal
            title={selectedCatalog ? "Edit barang" : "Tambah barang"}
            leftHeader={renderLeftHeader}
            rightHeader={renderRightHeader}
          />
          <BodyPage>
            {isLoading ? (
              <Box padding="2" pb="4">
                <Box mt={3}>
                  <Spinner />
                </Box>
              </Box>
            ) : (
              <Box padding="2" pb="4">
                <TextInput
                  label="Nama barang"
                  autoFocus
                  value={name}
                  onChange={(text) => {
                    setName(text || "");
                    setHasChanges(true);
                  }}
                  isError={!!error.name}
                  subtitle={error.name}
                  subtitleType="error"
                />
                <Box mt={3}>
                  <H2>Harga jual</H2>
                  {profile?.role !== "owner" ? (
                    <Box mt={1}>
                      <Alert
                        type="info"
                        title="Harga jual hanya bisa diubah oleh pemilik."
                      />
                    </Box>
                  ) : (
                    <>
                      <Text fontSize="3" lineHeight="3" mt="0.25rem">
                        Harga otomatis dikonversi ke semua satuan & kemasan.
                      </Text>
                      <Flex mt={1}>
                        <Flex flex="4">
                          {selectedUnit.status === "default" ? (
                            <TextInput
                              hidePlaceholder
                              inputLabel={"Rp"}
                              type="number"
                              value={price || ""}
                              onChange={(value) => {
                                setPrice(value ? +value : undefined);
                                setError({ ...error, price: "" });
                                setHasChanges(true);
                              }}
                            />
                          ) : (
                            <TextInput
                              hidePlaceholder
                              inputLabel={"Rp"}
                              type="number"
                              value={priceByUnit || ""}
                              onChange={(value) => handleSetPriceByUnit(value)}
                            />
                          )}
                        </Flex>
                        <Flex flex="6" ml="1">
                          <Select
                            width={100}
                            onSelect={(option) => handleSelectUnit(option)}
                            selectedOption={selectedUnit}
                            options={unitOptions}
                            isDisabled={
                              packagesLoading || isLoading || localLoading
                            }
                          />
                        </Flex>
                      </Flex>
                      <Box mt="4px">
                        {error.price ? (
                          <Flex alignItems="center">
                            <img src={AlertIcon} height="14" width="14" />
                            <Text color="coral" variant="rg" ml={0}>
                              Harga tidak boleh kosong.
                            </Text>
                          </Flex>
                        ) : (
                          <Text fontSize="1" lineHeight="1">
                            harga satuan{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {price ? `Rp${nominalFormat(price)}` : "-"} per{" "}
                              {smallestUnit}
                            </span>
                          </Text>
                        )}
                      </Box>
                    </>
                  )}

                  <Box mt={3}>
                    <H2>Satuan dan kemasan</H2>
                    <Text fontSize="3" lineHeight="3" mt="0.25rem">
                      Satuan terkecil
                    </Text>
                    <Text fontSize="2" lineHeight="2">
                      Satuan penjualan yang tidak bisa dibagi lagi.
                    </Text>
                    <Text fontSize="2" lineHeight="2">
                      Contoh: <i>pil, kapsul, tablet.</i>
                    </Text>
                    <Flex mt="4px">
                      <>
                        <Box width="40%" maxWidth="300px">
                          <TextInput
                            onChange={(text) => {
                              handleChangeSmallestUnitName(text || "");
                            }}
                            value={smallestUnit}
                            isError={!!error.smallestUnit}
                          />
                        </Box>
                        <Box ml={2}>
                          <Flex alignItems="center">
                            <Flex
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-end"
                            >
                              <Text fontSize={2} lineHeight={2}>
                                Singkatan
                              </Text>
                              <Text fontSize={1} lineHeight={1}>
                                (3 huruf)
                              </Text>
                            </Flex>
                            <Box maxWidth="70px" ml="1">
                              <TextInput
                                value={unitCode}
                                onChange={(text) =>
                                  handleChangeAlias(text || "")
                                }
                                isError={!!error.unitCode}
                              />
                            </Box>
                          </Flex>
                        </Box>
                      </>
                    </Flex>

                    {!!error.smallestUnit || !!error.unitCode ? (
                      <Flex
                        alignItems="center"
                        justifyContent="flex-start"
                        mt="4px"
                      >
                        <img
                          src={AlertIcon}
                          alt="alert"
                          height="16px"
                          width="16px"
                        />
                        <Text
                          ml="5px"
                          fontSize="1"
                          lineHeight="1"
                          color="coralDark"
                        >
                          {error.smallestUnit || error.unitCode}
                        </Text>
                      </Flex>
                    ) : null}
                  </Box>

                  <Box mt={2}>
                    <Text fontSize="3" lineHeight="3">
                      Kemasan
                    </Text>
                    <Text fontSize="2" lineHeight="2">
                      Contoh: <i>strip isi 12 tablet, kotak isi 12 botol.</i>
                    </Text>
                  </Box>

                  {!packages.length && !isLoading ? (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      border="1px solid"
                      borderColor="blackLighter"
                      borderRadius="4px"
                      py="4"
                      mt={1}
                    >
                      <Flex flexDirection="column" alignItems="center">
                        <Text mb={1}>Belum ada satuan kemasan.</Text>
                        <Button
                          secondary
                          title="Kemasan"
                          icon={AddIcon}
                          onClick={() => setVisibleAddPackageModal(true)}
                        />
                      </Flex>
                    </Flex>
                  ) : null}
                </Box>

                {packagesLoading ? (
                  <Flex width="100%" justifyContent="center" py="2">
                    <Spinner />
                  </Flex>
                ) : null}

                {!!packages.length && !packagesLoading ? (
                  <Box mt={2} mb={3}>
                    <TableExtend>
                      <thead>
                        <tr>
                          <th className="table-package__name">Nama</th>
                          <th className="table-package__qty">Isi</th>
                          <th className="table-package--delete"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderPackageTable}
                        <tr className="package__add">
                          <td colSpan={3}>
                            <Button
                              icon={AddIcon}
                              title="Kemasan"
                              inverse
                              onClick={() => setVisibleAddPackageModal(true)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </TableExtend>
                  </Box>
                ) : null}
              </Box>
            )}
          </BodyPage>
          <StickyBottomContainer
            width="calc(100% - 3rem)"
            position="absolute"
            bottom="0px"
            alignItems="center"
            background="#ffffff"
            px="2"
            py="1"
          >
            <Button
              title={selectedCatalog ? "Simpan" : "Tambahkan"}
              fullWidth
              disabled={!name}
              isLoading={isLoading || packagesLoading || localLoading}
              onClick={handleSave}
            />
          </StickyBottomContainer>
        </Modal>
      </Overlay>
    </>
  );
};

const TableExtend = styled(Table)`
  width: 100%;
  font-size: 0.875rem;

  .table-package__name {
    width: 40%;
  }

  .table-package__qty {
    width: calc(100% - 40% - 50px);
  }

  .table-package--delete {
    width: 50px;
  }

  tbody > tr {
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }

  .package__add {
    background: ${theme.colors.whiteDark};
  }
`;

const BodyPage = styled(Body)`
  padding: 0px;
  position: relative;
  height: 100%;
  overflow-y: scroll;
`;

export default CatalogAdd;
