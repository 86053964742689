import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./package.actionTypes";

// type
import { Package } from "../../types/package";

export const getPackagesAsync = createAsyncAction(
  types.GET_PACKAGES_REQUEST,
  types.GET_PACKAGES_SUCCESS,
  types.GET_PACKAGES_FAILURE
)<{ productID: string }, { data: Package[] }, undefined>();

export const editPackageAsync = createAsyncAction(
  types.EDIT_PACKAGE_REQUEST,
  types.EDIT_PACKAGE_SUCCESS,
  types.EDIT_PACKAGE_FAILURE
)<{ unitName: string; unitCode: string, packageID: string, productID: string, conversionPackageID: number }, undefined, undefined>();

export const deletePackageAsync = createAsyncAction(
    types.DELETE_PACKAGE_REQUEST,
    types.DELETE_PACKAGE_SUCCESS,
    types.DELETE_PACKAGE_FAILURE
  )<{ packageID: string, productID: string }, undefined, undefined>();

export const addPackageAsync = createAsyncAction(
  types.ADD_PACKAGE_REQUEST,
  types.ADD_PACKAGE_SUCCESS,
  types.ADD_PACKAGE_FAILURE
)<Partial<Package> & { unit: string }, undefined, undefined >();

export const visiblePackageModal = (visible: boolean) => action(types.VISIBLE_PACKAGE_MODAL, { visible })

export const resetPackageState = () => action(types.RESET_PACKAGE_STATE)

