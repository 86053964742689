export const ADD_INVOICE_REQUEST = '@invoice/ADD_INVOICE_REQUEST';
export const ADD_INVOICE_SUCCESS = '@invoice/ADD_INVOICE_SUCCESS'
export const ADD_INVOICE_FAILURE = '@invoice/ADD_INVOICE_FAILURE'

export const UPDATE_INVOICE_REQUEST = '@invoice/UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = '@invoice/UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_FAILURE = '@invoice/UPDATE_INVOICE_FAILURE'

export const DELETE_INVOICE_REQUEST = '@invoice/DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = '@invoice/DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_FAILURE = '@invoice/DELETE_INVOICE_FAILURE'

export const GET_INVOICE_DETAIL_REQUEST = '@invoice/GET_INVOICE_DETAIL_REQUEST';
export const GET_INVOICE_DETAIL_SUCCESS = '@invoice/GET_INVOICE_DETAIL_SUCCESS';
export const GET_INVOICE_DETAIL_FAILURE = '@invoice/GET_INVOICE_DETAIL_FAILURE';

export const GET_INVOICE_LIST_REQUEST = '@invoice/GET_INVOICE_LIST_REQUEST';
export const GET_INVOICE_LIST_SUCCESS = '@invoice/GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = '@invoice/GET_INVOICE_LIST_FAILURE';
