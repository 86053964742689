import React from "react";
import styled from "styled-components/macro";

//asset
import CloseIcon from "../../asset/icon/close.svg";

// constant
import { colors } from "../../constant/theme";

export type ChipProps = {
  label: string;
  onClick: () => void;
};

const Chip = (props: ChipProps) => {
  const { label, onClick } = props;

  return (
    <Container>
      <div className="text">{label}</div>
      <div className="chip--close" onClick={() => onClick()}>
        <img src={CloseIcon} alt="close" width={24} height={24} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 6px 8px;
  background: ${colors.secondary2};
  border-radius: 4px;
  flex: 0 1 auto;

  .chip--close {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
`;

export default Chip;
