import { put, takeLeading, select } from "redux-saga/effects";
import moment from "moment";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./home.action";
import * as types from "./home.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// type
import { States } from "../../types/general";

function* getDashboardSummary(
  action: ReturnType<typeof actions.getDashboardSummaryAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    const state: States = yield select();
    const filterDate = state.home.filterDate;
    const filterEmployee = state.home.employeeFilter;

    const userIDs: string[] = [];

    Object.keys(filterEmployee).forEach((userKey) => {
      if (filterEmployee[userKey]) {
        userIDs.push(userKey);
      }
    });

    const query: { [key: string]: string | null } = {
      start_date: filterDate.startDate
        ? moment(filterDate.startDate).format("YYYY-MM-DD")
        : null,
      end_date: filterDate.endDate
        ? moment(filterDate.endDate).format("YYYY-MM-DD")
        : null,
    };

    if (userIDs.length) {
      query["user_id"] = userIDs.join(",");
    }

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction-summary`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(
        actions.getDashboardSummaryAsync.success(response.data.summary)
      );
    }
  } catch (err) {
    yield put(actions.getDashboardSummaryAsync.failure())

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* transactionSaga() {
  yield takeLeading(types.GET_DASHBOARD_SUMMARY_REQUEST, getDashboardSummary);
}
