export const sortingOptions = [
  {
    label: "Batch A-Z",
    value: {
      sorted_by: "batch_no",
      order_by: "asc",
    },
  },
  {
    label: "Batch Z-A",
    value: {
      sorted_by: "batch_no",
      order_by: "desc",
    },
  },
  {
    label: "Stok tersedikit",
    value: {
      sorted_by: "stock_qty",
      order_by: "asc",
    },
  },
  {
    label: "Stok terbanyak",
    value: {
      sorted_by: "stock_qty",
      order_by: "desc",
    },
  },
  {
    label: "Tanggal diterima terdekat",
    value: {
      sorted_by: "receipt_date",
      order_by: "asc",
    },
  },
  {
    label: "Tanggal diterima terjauh",
    value: {
      sorted_by: "receipt_date",
      order_by: "desc",
    },
  },
  {
    label: "ED terdekat",
    value: {
      sorted_by: "ED",
      order_by: "asc",
    },
  },
  {
    label: "ED terjauh",
    value: {
      sorted_by: "ED",
      order_by: "desc",
    },
  },
];
