import React from "react";
import styled from "styled-components/macro";

// constant
import { colors } from "../../constant/theme";

// component
import Checkbox from "../../component/Checkbox";

// types
import { User } from "../../types/user";

export type EmployeeItemProps = {
  employee: User;
  isChecked: boolean;
  onClick: (id: string) => void;
};

const EmployeeItem = (props: EmployeeItemProps) => {
  const { employee, isChecked, onClick } = props;

  const handleClick = (id: string) => {
    onClick(id);
  };

  return (
    <Container onClick={() => handleClick(employee.id)}>
      <div className="employee-item__icon">
        <Checkbox
          onClick={() => handleClick(employee.id)}
          checked={isChecked}
        />
      </div>
      <div className="employee-item__detail">
        <div className="employee-item__title text">{employee.name}</div>
        <div className="employee-item__subtitle subtitle">
          {employee.status === "owner" ? "Pemilik" : "Pegawai"}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.secondary2};
  padding: 8px 4px;

  .employee-item__detail {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .employee-item__title {
    word-break: break-all;
  }

  .employee-item__subtitle {
  }
`;

export default EmployeeItem;
