// config
import axios from '../config/axios'

// constant
import {BASE_URL_BETA} from '../constant/api'

// utils
import localStorageService from './localStorageService'
import errorReporter from './sentryErrorReporter'

// type
import {Package} from '../types/package'

export type Payload = {
    name: string;
    unit: string;
    unit_code: string;
    price: number | undefined;
    package_list:Partial<Package>[]
  }

export const postNewProduct = async (payload: Payload) => {
   
        try {
         
          const companyID = localStorageService.getCompanyID();

          if (!companyID) {
            throw new Error("Company ID not found");
          }
          
          const url = `${BASE_URL_BETA}/company/${companyID}/product`;
          const response = await axios.post(url, payload)
      
          if (response && response.status === 201) {
            const product = response.data.data
           return product
          }
        } catch (err) {
          errorReporter(err,  'POST_NEW_PRODUCT', payload)
            throw new Error(err)
       
        }
      
}