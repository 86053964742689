import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import closeEye from "../asset/img/eye-closedicon.png";
import openEye from "../asset/img/eye-openicon.png";

// components
import Header from "../component/Header";

// redux thunk 
import { doChangePassword } from '../reducers/user/user.actionThunk'

// redux actions
import { handleChangeInputUser, handleLogout } from '../reducers/user/user.action'

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      newPasswordType: "password",
      oldPasswordType: "password",
    };
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //old password
    if (!fields["oldPassword"]) {
      formIsValid = false;
      errors["oldPassword"] = "password lama tidak boleh kosong";
    }

    //new password
    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "password baru tidak boleh kosong";
    }

    if (typeof fields["newPassword"] !== "undefined") {
      if (
        !fields["newPassword"].match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      ) {
        formIsValid = false;
        errors["newPassword"] = "Password tidak sesuai";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
    } else {
      alert("Cek error");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    this.props.changeInput(e);
  }

  postSignout = async () => {
    await this.props.doLogout();
    const is_login = this.props.logout;
    if (!is_login) {
      this.props.history.push("/signin");
    }
  };
  handleClickNewPassword = () =>
    this.setState(({ newPasswordType }) => ({
      newPasswordType: newPasswordType === "text" ? "password" : "text",
    }));

  handleClickOldPassword = () =>
    this.setState(({ oldPasswordType }) => ({
      oldPasswordType: oldPasswordType === "text" ? "password" : "text",
    }));

  postChangePassword = async () => {
    await this.props.doChangePassword();
    // this.props.history.push("/");
  };

  render() {
    const data = JSON.parse(localStorage.getItem("bio"));
    if (!this.props.dataUser.is_login) {
      return (
        <Redirect
          to={{
            pathname: "/signin",
          }}
        />
      );
    } else {
      return (
        <div className="container">
          <Header />
          <header>
            <nav>
              <ul>
                <li>
                  <Link to="/">Beranda</Link>
                </li>
                <li>
                  <Link to="/account">Akun</Link>
                </li>
                <li>
                  <p onClick={this.postSignout}>Logout</p>
                </li>
              </ul>
            </nav>
            <div className="poppin-text gray-color my-3">Informasi Akun</div>
          </header>
          <div>
            <div className="row poppin-text p15">
              <div className="col-3">Nama</div>
              <div className="col-9">: {data.name}</div>
            </div>
            <div className="row poppin-text p15">
              <div className="col-3">Email</div>
              <div className="col-9">: {data.email}</div>
            </div>
            <div className="row poppin-text p15">
              <div className="col-3">Username</div>
              <div className="col-9">: {data.username}</div>
            </div>
            <form onSubmit={this.contactSubmit.bind(this)}>
              <section id="changePassword">
                <div className="poppin-text gray-color my-3">Ubah Password</div>
                <div className="form-group">
                  <label className="p12 w700 gray-color" htmlFor="newPassword">
                    Password baru
                  </label>
                  <div class="input-group">
                    <input
                      type={this.state.newPasswordType}
                      className="form-control right-border-white"
                      id="newPassword"
                      onChange={this.handleChange.bind(this, "newPassword")}
                      value={this.state.fields["newPassword"]}
                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text left-border-white white-bg">
                        <div
                          className="password__show"
                          onClick={this.handleClickNewPassword}
                        >
                          {this.state.newPasswordType === "text" ? (
                            <img src={closeEye} alt="close-eye" height="20px" />
                          ) : (
                            <img src={openEye} alt="close-eye" height="20px" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p10 red-color">
                    {this.state.errors["newPassword"]}
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <small>Minimal 8 huruf dan angka.</small>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="p12 w700 gray-color" htmlFor="password">
                      Password lama
                    </label>
                    <div class="input-group">
                      <input
                        type={this.state.oldPasswordType}
                        className="form-control right-border-white"
                        id="oldPassword"
                        onChange={this.handleChange.bind(this, "oldPassword")}
                        value={this.state.fields["oldPassword"]}
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text left-border-white white-bg">
                          <div
                            className="password__show"
                            onClick={this.handleClickOldPassword}
                          >
                            {this.state.oldPasswordType === "text" ? (
                              <img
                                src={closeEye}
                                alt="close-eye"
                                height="20px"
                              />
                            ) : (
                              <img
                                src={openEye}
                                alt="close-eye"
                                height="20px"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p10 red-color">
                      {this.state.errors["oldPassword"]}
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={() => {
                      if (this.handleValidation()) {
                        this.postChangePassword();
                      }
                    }}
                    className="btn btn-primary"
                  >
                    Ubah
                  </button>
                </div>
              </section>
            </form>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    dataUser: state.user,
    login: state.user.isLogin,
    
  };
};

const mapDispatchToProps = {
  changeInput: (e) => handleChangeInputUser(e),
  doChangePassword,
  doLogout: handleLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

// export default Register;
