import React, { useMemo, useState } from "react";
import moment from "moment";
import styled from "styled-components/macro";

// constant
import theme from "../../constant/styledTheme";

// component
import { Table, Text, Box, Flex } from "../../component/Styles";
import InvoiceDetailProductItem from "./InvoiceDetailProductItem";

// type
import { ExtendProductInvoice } from "../../types/product";
import { DiscountTypes } from "../../types/invoice";
import { nominalFormat } from "../../utils/nominalFormat";
import { Package } from "../../types/package";
import { getValidDate } from "../../utils/getValidDate";

// utils
import { getMargin as calculateMargin } from "./utils/getMargin";

export type Props = {
  productList: ExtendProductInvoice[];
  includeVAT: boolean;
  onSelectProduct?: (product: ExtendProductInvoice) => void;
};

const InvoiceProductList = (props: Props) => {
  const { productList, includeVAT, onSelectProduct } = props;

  const [activeSort, setActiveSort] = useState({
    orderBy: "asc",
    sortedBy: "name",
  });

  const handleSelectProduct = (product: ExtendProductInvoice) => {
    if (!onSelectProduct || product.is_deleted || !product.is_editable) {
      return;
    }

    onSelectProduct(product);
  };

  const getList = useMemo(() => {
    const sortedList = productList;

    return sortedList;
  }, [productList, activeSort]);

  const getSubtotal = (p: ExtendProductInvoice) => {
    let discount = p.discount_amount;
    const total_buy =
      p.price_buy * p.qty * (p.package_buy_detail?.smallest_qty || 1);

    if (p.discount_type_buy === "percentage") {
      discount = (p.discount_amount / 100) * total_buy;
    }

    return Math.ceil(total_buy - discount);
  };

  const renderDiscount = (discType: DiscountTypes, discAmount: number) => {
    if (!discAmount) {
      return "-";
    }

    if (discType === "cash") {
      return `-${nominalFormat(+discAmount.toFixed(2))}`;
    }

    return `-${nominalFormat(+discAmount.toFixed(2))}%`;
  };

  const getMargin = (p: ExtendProductInvoice) => {
    const result = calculateMargin(p, includeVAT);
    return result;
  };

  const handleActiveSort = () => {
    if (activeSort.orderBy === "asc") {
      setActiveSort({ sortedBy: "name", orderBy: "desc" });
      return;
    }

    setActiveSort({ sortedBy: "name", orderBy: "asc" });
  };

  const getConversionLabel = (p: Package) => {
    if (p.unit_name === p.conversion_unit) {
      return p.unit_code;
    }

    return `${p.unit_code}(${p.smallest_qty} ${p.conversion_unit})`;
  };

  const renderList = useMemo(() => {
    return getList.map((p: ExtendProductInvoice, index: number) => {
      return (
        <InvoiceDetailProductItem
          product={p}
          subtotal={getSubtotal(p)}
          key={`invoice-product-list-item-${index}`}
          onClick={(product) => {
            handleSelectProduct({ ...product, index });
          }}
          includeVAT={includeVAT}
        />
      );
    });
  }, [getList]);

  const renderInfoText = (p: ExtendProductInvoice) => {
    if (!p.is_editable) {
      return "(barang telah di-update)";
    }

    if (p.is_deleted) {
      return "(dihapus)";
    }

    return null;
  };

  const renderProductListTable = useMemo(() => {
    const list: any[] = [];

    getList.forEach((p: ExtendProductInvoice, index: number) => {
      list.push(
        <tr
          key={`invoice-product-table-item-${index}`}
          id={`invoice-product-table-item-${index}`}
          onClick={() => handleSelectProduct({ ...p, index })}
          className={p.is_deleted || !p.is_editable ? "disabled" : "null"}
        >
          <td className="table-invoice-detail__product">
            <Text
              fontSize="2"
              lineHeight="2"
              color="blueDark"
              fontWeight="bold"
            >
              {p.product_name}{" "}
              <span className="danger">{renderInfoText(p)}</span>
            </Text>
          </td>
          <td className="table-invoice-detail__batch">
            <Text fontSize="2" lineHeight="2">
              {p.batch_no || "-"}
            </Text>
          </td>
          <td className="table-invoice-detail__ED">
            <Text fontSize="2" lineHeight="2">
              {getValidDate(p.ED) ? moment(p.ED).format("DD MMM YYYY") : "-"}
            </Text>
          </td>
          <td className="table-invoice-detail__qty">
            <Text fontSize="2" lineHeight="2">
              {nominalFormat(p.qty)}
            </Text>
          </td>
          <td className="table-invoice-detail__unit">
            <Text fontSize="2" lineHeight="2">
              {p.package_buy_detail?.unit_name}
            </Text>
          </td>
          <td className="table-invoice-detail__buy-price">
            <Box>
              <Text fontSize="2" lineHeight="2">
                {nominalFormat(
                  p.price_buy * (p.package_buy_detail?.smallest_qty! || 1)
                )}
                /{getConversionLabel(p.package_buy_detail!)}
              </Text>
            </Box>
          </td>
          <td className="table-invoice-detail__sell-price">
            <Flex flexDirection="column" justifyContent="flex-end">
              <Text fontSize="2" lineHeight="2">
                {nominalFormat(
                  p.price_sell * (p.package_sell_detail?.smallest_qty! || 1)
                )}
                /{getConversionLabel(p.package_sell_detail!)}
              </Text>
              <Text fontSize="1" lineHeight="1">
                Margin {getMargin(p)}%
              </Text>
            </Flex>
          </td>
          <td className="table-invoice-detail__discount">
            <Text fontSize="2" lineHeight="2">
              {renderDiscount(p.discount_type_buy, p.discount_amount)}
            </Text>
          </td>
          <td className="table-invoice-detail__subtotal">
            <Text fontSize="2" lineHeight="2">
              {nominalFormat(getSubtotal(p))}
            </Text>
          </td>
        </tr>
      );
    });

    return (
      <ExtendTable width="100%" data-in>
        <thead>
          <tr>
            <th className="table-invoice-detail__product">
              <Text fontSize="2" lineHeight="2">
                Barang
              </Text>
            </th>
            <th className="table-invoice-detail__batch">
              <Text fontSize="2" lineHeight="2">
                Batch
              </Text>
            </th>
            <th className="table-invoice-detail__ED">
              <Text fontSize="2" lineHeight="2">
                ED
              </Text>
            </th>
            <th className="table-invoice-detail__qty">
              <Text fontSize="2" lineHeight="2">
                Jumlah
              </Text>
            </th>
            <th className="table-invoice-detail__unit">
              <Text fontSize="2" lineHeight="2">
                Satuan
              </Text>
            </th>
            <th className="table-invoice-detail__buy-price">
              <Text fontSize="2" lineHeight="2">
                Harga beli
              </Text>
            </th>
            <th className="table-invoice-detail__sell-price">
              <Text fontSize="2" lineHeight="2">
                Harga jual
              </Text>
            </th>
            <th className="table-invoice-detail__discount">
              <Text fontSize="2" lineHeight="2">
                Diskon
              </Text>
            </th>
            <th className="table-invoice-detail__subtotal">
              <Text fontSize="2" lineHeight="2">
                Subtotal
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{list}</tbody>
      </ExtendTable>
    );
  }, [getList, activeSort]);

  return (
    <Box>
      <Box mt={1}>
        <Box mt={0} display={["none", "none", "block"]}>
          {renderProductListTable}
        </Box>
        <Box
          mt={0}
          display={["block", "block", "none"]}
          borderTop={productList.length ? "1px solid" : null}
          borderTopColor={"blackLighter"}
        >
          {renderList}
        </Box>
      </Box>
    </Box>
  );
};

const ExtendTable = styled(Table)`
  .table-invoice-detail__product {
  }

  .table-invoice-detail__batch {
  }

  .table-invoice-detail__ED {
  }

  .table-invoice-detail__qty {
    text-align: right;
  }

  .table-invoice-detail__buy-price {
    text-align: right;
  }

  .table-invoice-detail__sell-price {
    text-align: right;
  }

  .table-invoice-detail__discount {
    text-align: right;
  }

  .table-invoice-detail__subtotal {
    text-align: right;
  }

  tr {
    cursor: pointer;
  }

  tr.disabled {
    background: ${theme.colors.whiteDarker};
    cursor: not-allowed !important;
  }
`;

const Clickable = styled(Flex)`
  cursor: pointer;
`;

export default InvoiceProductList;
