import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./pbf.actionTypes";

// type
import { Option } from '../../types/general'
import { PBF } from "../../types/pbf";

export const getPBFListAsync = createAsyncAction(
  types.GET_PBF_LIST_REQUEST,
  types.GET_PBF_LIST_SUCCESS,
  types.GET_PBF_LIST_FAILURE
)<{sortedBy?: string, orderBy?: string,  search?: string, page?: number}, PBF[], undefined>();

export const addPBFAsync = createAsyncAction(
  types.ADD_PBF_REQUEST,
  types.ADD_PBF_SUCCESS,
  types.ADD_PBF_FAILURE
)<{ name: string }, undefined, undefined>();

export const editPBFAsync = createAsyncAction(
  types.UPDATE_PBF_REQUEST,
  types.UPDATE_PBF_SUCCESS,
  types.UPDATE_PBF_FAILURE
)<{ name: string; PBFid: string }, undefined, undefined>();

export const deletePBFAsync = createAsyncAction(
  types.DELETE_PBF_REQUEST,
  types.DELETE_PBF_SUCCESS,
  types.DELETE_PBF_FAILURE
)<{ PBFid: string }, undefined, undefined>();

export const showEditModalPBF = (visible: boolean) => action(types.SHOW_EDIT_MODAL_PBF, { visible })
export const setSelectedPBF = (selectedPBF: Option | null) => action(types.SET_SELECTED_PBF, { selectedPBF })
