import React, { useState, useMemo } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

//asset
import FarmacareLogo from "../../asset/img/logo.png";

// constant
import theme from "../../constant/styledTheme";

//component
import { Button } from "../../component/Button";
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import { Wrapper, H1, Text, Box, Flex } from "../../component/Styles";
import TextInput from "../../component/TextInput";

//types
import { general } from "../../types/index";

//action
import { submitNewPasswordForgotAsync } from "../../reducers/auth/auth.action";
import BodyPage from "../../component/Styles/Body";

const CreateNewPassword = () => {
  const dispatch = useDispatch();

  // redux state
  const router = useSelector((state: general.States) => state.router.location);
  const isLoading = useSelector(
    (state: general.States) => state.auth.isLoading
  );

  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSubmit = () => {
    setError("");
    if (password.length <= 0) {
      setError("Kolom tidak boleh kosong");
    }
    if (isPasswordValid(password)) {
      dispatch(
        submitNewPasswordForgotAsync.request({
          username: getUsername,
          newPassword: password,
          code: getCode,
        })
      );
    } else {
      setError("Minimal 8 huruf dan angka.");
    }
  };

  const getUsername = useMemo(() => {
    const newRouter = router as any;
    const email = newRouter?.query?.username;
    return email;
  }, [router]);

  const getCode = useMemo(() => {
    const newRouter = router as any;
    const code = newRouter?.query?.code;
    return code;
  }, [router]);

  const isPasswordValid = (password: string) => {
    const passwordExp = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    const match = passwordExp.test(password);
    return match;
  };

  const handleBack = () => {
    dispatch(push("/signin"));
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  if (getCode === undefined || getUsername === undefined) {
    return (
      <Layout>
        <TopBar header={renderHeader} />
        <Wrapper p={2}>
          <Body>
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <H1>Halaman tidak ditemukan.</H1>
              <Text mt={0}>Kembali ke halaman login.</Text>

              <Box mt={1}>
                <Button title="Kembali" onClick={() => handleBack()} />
              </Box>
            </Flex>
          </Body>
        </Wrapper>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <TopBar header={renderHeader} />
        <Wrapper p={2}>
          <Body>
            <H1>Buat password baru</H1>
            <Text mt={0}>
              Masukkan password baru yang ingin digunakan untuk masuk ke akun
              anda
            </Text>
            <Box mt={2}>
              <TextInput
                label="Password baru"
                placeholder="Masukkan password"
                onChange={(text) => setPassword(text)}
                type="password"
                isError={!!error}
                subtitle={error}
                subtitleType="error"
              />
            </Box>
            <Box mt={1}>
              <Button
                title="Simpan"
                onClick={() => handleSubmit()}
                fullWidth
                isLoading={isLoading}
              />
            </Box>
          </Body>
        </Wrapper>
      </Layout>
    );
  }
};

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (min-width: ${theme.breakpoints[3]}) {
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }
`;

const Body = styled(BodyPage)`
  width: 100%;
  max-width: 962px;
`;

export default CreateNewPassword;
