export const POST_CATALOG_REQUEST = "@catalog/POST_CATALOG_REQUEST";
export const POST_CATALOG_SUCCESS = "@catalog/POST_CATALOG_SUCCESS";
export const POST_CATALOG_FAILURE = "@catalog/POST_CATALOG_FAILURE";

export const UPDATE_CATALOG_REQUEST = "@catalog/UPDATE_CATALOG_REQUEST";
export const UPDATE_CATALOG_SUCCESS = "@catalog/UPDATE_CATALOG_SUCCESS";
export const UPDATE_CATALOG_FAILURE = "@catalog/UPDATE_CATALOG_FAILURE";

export const GET_PRODUCT_DETAIL_REQUEST = "@catalog/GET_PRODUCT_DETAIL_REQUEST";
export const GET_PRODUCT_DETAIL_SUCCESS = "@catalog/GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAILURE = "@catalog/GET_PRODUCT_DETAIL_FAILURE";

export const DELETE_CATALOG_REQUEST = "@catalog/DELETE_CATALOG_REQUEST";
export const DELETE_CATALOG_SUCCESS = "@catalog/DELETE_CATALOG_SUCCESS";
export const DELETE_CATALOG_FAILURE = "@catalog/DELETE_CATALOG_FAILURE";

export const UPLOAD_CSV_REQUEST = "@catalog/UPLOAD_CSV_REQUEST";
export const UPLOAD_CSV_SUCCESS = '@catalog/UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAILURE = '@catalog/UPLOAD_CSV_FAILURE';

export const PATCH_PRODUCT_PRICE_REQUEST = "@catalog/PATCH_PRODUCT_PRICE_REQUEST";
export const PATCH_PRODUCT_PRICE_SUCCESS = '@catalog/PATCH_PRODUCT_PRICE_SUCCESS';
export const PATCH_PRODUCT_PRICE_FAILURE = '@catalog/PATCH_PRODUCT_PRICE_FAILURE';

export const SET_SELECTED_CATALOG = "@catalog/SET_SELECTED_CATALOG";
export const SET_SHOW_UPLOAD_CSV_MODAL = '@catalog/SET_SHOW_UPLOAD_CSV_MODAL';
export const REMOVE_FAILED_FILE_LINK = '@catalog/REMOVE_FAILED_FILE_LINK'
export const SET_CREATED_PRODUCT_ID = '@catalog/SET_CREATED_PRODUCT_ID'
export const SET_SHOW_ADD_CATALOG_MODAL = '@catalog/SET_SHOW_ADD_CATALOG_MODAL'
export const SET_SEARCH_FILTER = '@catalog/SET_SEARCH_FILTER'
export const SET_VISIBLE_ADD_PRICE_MODAL = '@catalog/SET_VISIBLE_ADD_PRICE_MODAL'
