export const paymentOptions = [
  { label: "Tunai", value: "cash" },
  { label: "Kredit", value: "credit" },
];

export const includeVATOptions = [
  { label: "Ya, sudah termasuk PPN", value: true },
  { label: "Belum, tambahkan PPN di akhir", value: false },
];

export const discountType = [
  { label: "%", value: "percentage" },
  { label: "Rp", value: "cash" },
];
