import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components/macro";
import debounce from "lodash/debounce";
import { useSelector, useDispatch } from "react-redux";

// redux action
import { getProductListAsync } from "../../reducers/product/product.action";
import {
  setShowUploadCSVModal,
  removeFileFailedLink,
  setShowAddCatalogModal,
  onChangeSearchFilter,
} from "../../reducers/catalog/catalog.action";
import {
  visiblePackageModal,
  resetPackageState,
} from "../../reducers/package/package.action";

// type
import { general } from "../../types";
import { Product } from "../../types/product";

// components
import CatalogAdd from "./CatalogAdd";
import UploadCSVModal from "./CatalogUploadCSV";
import CatalogItem from "./CatalogItem";

import Layout from "../../component/Layout/Layout";
import TopBar from "../../component/TopBar";
import Button from "../../component/Button/Button";
import SearchBar from "../../component/SearchBar";
import {
  Flex,
  Box,
  Table,
  Text,
  H2,
  Wrapper,
  BodyPage,
} from "../../component/Styles";
import Pagination from "../../component/Pagination";

// asset
import AddIcon from "../../asset/icon/add.svg";
import UploadIcon from "../../asset/icon/upload.svg";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { Spinner } from "../../component/Loading";
import Header from "../../component/Header/Header";

const CatalogList = () => {
  const dispatch = useDispatch();

  // state
  const [selectedCatalog, setSelectedCatalog] = useState<Product | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  // redux state
  const searchFilter = useSelector(
    (state: general.States) => state.catalog.searchFilter
  );
  const location = useSelector(
    (state: general.States) => state.router.location
  );
  const productList = useSelector(
    (state: general.States) => state.product.list
  );
  const totalData = useSelector(
    (state: general.States) => state.product.totalData
  );
  const isLoading = useSelector(
    (state: general.States) => state.product.isLoading
  );
  const isModalCSVVisible = useSelector(
    (state: general.States) => state.catalog.visibleCSVModal
  );
  const showAddCatalogModal = useSelector(
    (state: general.States) => state.catalog.visibleAddCatalogModal
  );

  const onShowAddCatalogModal = (visible: boolean) => {
    dispatch(setShowAddCatalogModal(visible));
  };

  const handleSelectCatalogItem = (item: Product | null) => {
    onShowAddCatalogModal(true);
    setSelectedCatalog(item);
  };

  const setSearchFilter = (text: string) => {
    dispatch(onChangeSearchFilter(text));
  };

  /**
   * Memorize catalog list
   * and list will change every change search filter
   */
  const getCatalogList = useMemo(() => {
    const list: any[] = [];

    let index = 0;
    while (index < productList.length) {
      const p = productList[index];
      const matchRegex = new RegExp(searchFilter, "gi");
      const isMatchProduct = matchRegex.test(p.name);

      if (isMatchProduct) {
        list.push(p);
      }
      index++;
    }

    return list;
  }, [productList, searchFilter]);

  const renderTableBody = useMemo(() => {
    return getCatalogList.map((p, index) => {
      return (
        <tr
          key={`table-item-catalog-${index}`}
          onClick={() => handleSelectCatalogItem(p)}
        >
          <td className="table-catalog__product">
            <Text variant="rg" color="blueDark" fontWeight="bold">
              {p.name}
            </Text>
          </td>
          <td className="table-catalog__price">
            <Text fontSize={2} lineHeight={2}>
              {p.price || p.price === 0 ? `Rp${nominalFormat(p.price)}` : "-"} /{" "}
              {p.unit}
            </Text>
          </td>
          <td className="table-catalog__unit">
            <Text fontSize={2} lineHeight={2}>
              {p.unit}
            </Text>
          </td>
        </tr>
      );
    });
  }, [getCatalogList]);

  /**
   * Open add product modal
   */
  const handleClickAddCatalog = () => {
    onShowAddCatalogModal(true);
    setSelectedCatalog(null);

    // make sure package list is empty
    dispatch(resetPackageState());
  };

  const renderStateCondition = useMemo(() => {
    if (isLoading) {
      return (
        <Flex justifyContent="center" alignItems="center" height="100%" mt={4}>
          <Spinner />
        </Flex>
      );
    }

    if (!productList.length) {
      return (
        <Flex justifyContent="center" alignItems="center" height="100%" mt={4}>
          <H2> Data tidak ditemukan</H2>
        </Flex>
      );
    }

    return;
  }, [isLoading, productList]);

  const handleShowImportModal = (value: boolean) => {
    dispatch(setShowUploadCSVModal(value));
    dispatch(removeFileFailedLink());
  };

  /**
   * handle change search filter
   * use debounce to prevent render every state change
   */
  const handleChangeSearchFilter = debounce(
    (text) => setSearchFilter(text),
    1200
  );

  const handleChangePage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
    dispatch(
      getProductListAsync.request({ search: searchFilter, page: pageIndex })
    );
  };

  useEffect(() => {
    setSearchFilter("");
    dispatch(visiblePackageModal(false));
    dispatch(setShowAddCatalogModal(false));
    setCurrentPage(1);
    dispatch(getProductListAsync.request({}));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    dispatch(getProductListAsync.request({ search: searchFilter, page: 1 }));
  }, [searchFilter]);

  useEffect(() => {
    const clonedLocation: any = location;
    const { productID } = clonedLocation.query;
    if (productID) {
      // TODO: handle fetch and select product ID
    }
  }, [location]);

  const renderList = useMemo(() => {
    return getCatalogList.map((c, index) => {
      return (
        <CatalogItem
          product={c}
          key={`product-item-${index}`}
          onClick={(item) => handleSelectCatalogItem(item)}
        />
      );
    });
  }, [productList]);

  const disabled = useMemo(() => {
    return isModalCSVVisible || showAddCatalogModal;
  }, [isModalCSVVisible, showAddCatalogModal]);

  return (
    <Layout>
      {isModalCSVVisible ? (
        <UploadCSVModal
          isVisible={isModalCSVVisible}
          onClickClose={() => handleShowImportModal(false)}
        />
      ) : null}
      {showAddCatalogModal ? (
        <CatalogAdd
          onCloseModal={() => onShowAddCatalogModal(false)}
          selectedCatalog={selectedCatalog}
        />
      ) : null}
      <TopBar companyHeader />
      <Wrapper p={2} pb={5}>
        <Body>
          <Header
            title="Barang"
            actionButton={
              <Flex alignItems="center">
                <Box>
                  <Button
                    title={"Impor"}
                    secondary
                    icon={UploadIcon}
                    onClick={() => handleShowImportModal(true)}
                    disabled={disabled}
                  />
                </Box>
                <Box ml={0}>
                  <Button
                    title={"Barang"}
                    onClick={() => handleClickAddCatalog()}
                    icon={AddIcon}
                    lightIcon
                    disabled={disabled}
                  />
                </Box>
              </Flex>
            }
          />
          <Flex mt={2} mb={1} width="100%" maxWidth="400px">
            <SearchBar
              placeholder="Cari barang"
              onChange={(text) => handleChangeSearchFilter(text)}
            />
          </Flex>

          <Box display={["none", "none", "block"]}>
            {renderStateCondition ? (
              renderStateCondition
            ) : (
              <TableExtend cellSpacing="0">
                <thead>
                  <tr>
                    <th className="table-catalog__product">
                      <Text fontSize="2" lineHeight="2">
                        Barang
                      </Text>
                    </th>
                    <th className="table-catalog__price">
                      <Text fontSize="2" lineHeight="2">
                        Harga jual
                      </Text>
                    </th>
                    <th className="table-catalog__unit">
                      <Text fontSize="2" lineHeight="2">
                        Satuan
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableBody}</tbody>
              </TableExtend>
            )}
          </Box>

          <Box display={["block", "block", "none"]} height="100%">
            {renderStateCondition ? renderStateCondition : renderList}
          </Box>

          {totalData && !isLoading ? (
            <Flex width="100%" justifyContent="center">
              <Pagination
                currentPage={currentPage}
                onChangePage={(pageIndex: number) =>
                  handleChangePage(pageIndex)
                }
                totalPage={Math.ceil(totalData / 50)}
                isLoading={isLoading}
                disabled={disabled}
              />
            </Flex>
          ) : null}
        </Body>
      </Wrapper>
    </Layout>
  );
};

const TableExtend = styled(Table)`
  width: 100%;

  tbody > tr td {
    cursor: pointer;
  }

  .table-catalog__unit {
    width: 160px;
  }

  .table-catalog__price {
    width: 100px;
  }

  .table-catalog__product {
    width: calc(100% - 160px -100px);
  }
`;

const Body = styled(BodyPage)`
  .catalog__top {
    display: flex;
    justify-content: space-between;

    > .top__right {
      display: flex;

      > button:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .product-list__state {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default CatalogList;
