import { History } from 'history'
import { StateType } from 'typesafe-actions';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// reducers
import { authReducer } from '../reducers/auth/auth.reducer'
import { userReducer } from '../reducers/user/user.reducer';
import { navigationReducer } from '../reducers/navigation/navigation.reducer'
import { historyReducer} from '../reducers/history/history.reducer'
import { transactionReducer } from '../reducers/transaction/transaction.reducer'
import { productReducer } from '../reducers/product/product.reducer'
import { employeeReducer } from '../reducers/employee/employee.reducer'
import { toastReducer } from '../reducers/toast/toast.reducer'
import { homeReducer } from '../reducers/home/home.reducer'
import { companyReducer } from '../reducers/company/company.reducer'
import { profileReducer } from '../reducers/profile/profile.reducer'
import { catalogReducer} from '../reducers/catalog/catalog.reducer'
import { PBFReducer } from '../reducers/pbf/pbf.reducer'
import { packageReducer} from '../reducers/package/package.reducer'
import { invoiceReducer } from '../reducers/invoice/invoice.reducer'
import {inventoryReducer} from '../reducers/inventory/inventory.reducer'
import { stockReducer } from '../reducers/stock/stock.reducer'

export const makeRootReducer = (history: History) => { 
    return combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        user: userReducer,
        navigation: navigationReducer,
        transaction: transactionReducer,
        product: productReducer,
        toast: toastReducer,
        employee: employeeReducer,
        history: historyReducer,
        home: homeReducer,
        company: companyReducer,
        profile: profileReducer,
        catalog: catalogReducer,
        pbf: PBFReducer,
        package: packageReducer,
        invoice: invoiceReducer,
        inventory: inventoryReducer,
        stock: stockReducer
    })
}

  
  export type RootState = StateType<ReturnType<typeof makeRootReducer>>;