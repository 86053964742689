import axios from '../../../config/axios'

// constant
import {BASE_URL_BETA} from '../../../constant/api'

// utils
import errorReporter from '../../../utils/sentryErrorReporter'

export const deleteCompanyAsync = async(companyID: number) => {
    try {
        const url = `${BASE_URL_BETA}/company/${companyID}`;

        const response = await axios.delete(url) ;

        if (response && response.status === 200) {
            return;
        }
    }
    catch(err){
        errorReporter(err)
        throw (err)
    }
}