import { createReducer, ActionType } from "typesafe-actions";

// actions
import * as actions from "./catalog.action";

// actions types
import * as types from "./catalog.actionTypes";

// type
import { ProductDetail } from "../../types/product";
export type CatalogActions = ActionType<typeof actions>;

export type CatalogState = {
  productDetail: ProductDetail | null;
  visibleAddCatalogModal: boolean;
  visibleCSVModal: boolean;
  visibleAddPriceModal: boolean;
  createdProductID: string;
  failedFileLink: string;
  isLoading: boolean;
  patchPriceLoading: boolean;
  searchFilter: string;
};

const initialState: CatalogState = {
  productDetail: null,
  visibleAddCatalogModal: false,
  visibleCSVModal: false,
  visibleAddPriceModal: false,
  failedFileLink: "",
  createdProductID: "",
  isLoading: false,
  patchPriceLoading: false,
  searchFilter: ""
};

export const catalogReducer = createReducer<CatalogState, CatalogActions>(
  initialState
)
  .handleAction(
    actions.getProductDetailAsync.request,
    (state: CatalogState) => ({
      ...state,
      isLoading: true,
      productDetail: null,
    })
  )
  .handleAction(
    actions.getProductDetailAsync.success,
    (state: CatalogState, action: ActionType<typeof actions.getProductDetailAsync.success>) => ({
      ...state,
      isLoading: false,
      productDetail: action.payload,
    })
  )
  .handleAction(
    actions.getProductDetailAsync.failure,
    (state: CatalogState) => ({
      ...state,
      isLoading: false,
      productDetail: null,
    })
  )
  .handleAction(actions.uploadCSVAsync.request, (state: CatalogState) => ({
    ...state,
    isLoading: true,
    failedFileLink: "",
  }))
  .handleAction(actions.uploadCSVAsync.success, (state: CatalogState) => ({
    ...state,
    isLoading: false,
    failedFileLink: "",
  }))
  .handleAction(
    actions.uploadCSVAsync.failure,
    (
      state: CatalogState,
      action: ActionType<typeof actions.uploadCSVAsync.failure>
    ) => ({
      ...state,
      isLoading: false,
      failedFileLink: action.payload.link,
    })
  )
  .handleAction(actions.patchProductPriceAsync.request, (state: CatalogState) => ({
    ...state,
    patchPriceLoading: true,
  }))
  .handleAction(actions.patchProductPriceAsync.success, (state: CatalogState) => ({
    ...state,
    patchPriceLoading: false,
    visibleAddPriceModal: false
  }))
  .handleAction(
    actions.patchProductPriceAsync.failure,
    (
      state: CatalogState,
    ) => ({
      ...state,
      patchPriceLoading: false,
      visibleAddPriceModal: false
    })
  )
  .handleAction(
    types.SET_SHOW_UPLOAD_CSV_MODAL as any,
    (
      state: CatalogState,
      action: ActionType<typeof actions.setShowUploadCSVModal>
    ) => ({
      ...state,
      isLoading: false,
      failedFileLink: "",
      visibleCSVModal: action.payload.visible,
    })
  )
  .handleAction(
    types.REMOVE_FAILED_FILE_LINK as any,
    (state: CatalogState) => ({
      ...state,
      isLoading: false,
      failedFileLink: "",
    })
  )
  .handleAction(
    types.SET_SHOW_ADD_CATALOG_MODAL as any,
    (
      state: CatalogState,
      action: ActionType<typeof actions.setShowAddCatalogModal>
    ) => ({
      ...state,
      visibleAddCatalogModal: action.payload.visible,
    })
  )
  .handleAction(
    types.SET_SEARCH_FILTER as any,
    (
      state: CatalogState,
      action: ActionType<typeof actions.onChangeSearchFilter>
    ) => ({
      ...state,
     searchFilter: action.payload.text
    })
  )
  .handleAction(
    types.SET_VISIBLE_ADD_PRICE_MODAL as any,
    (
      state: CatalogState,
      action: ActionType<typeof actions.setShowChangePriceModal>
    ) => ({
      ...state,
     visibleAddPriceModal: action.payload.visible
    })
  )



