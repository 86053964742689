import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components/macro";

// constant
import theme from "../../constant/styledTheme";

// type
import { States } from "../../types/general";

// assets
import SuccessIcon from "../../asset/icon/success-light.svg";
import ErrorIcon from "../../asset/icon/alert-light.svg";
import InfoIcon from "../../asset/icon/info-light.svg";
import DeleteIcon from "../../asset/icon/delete-mini.svg";

// redux action
import { showToast } from "../../reducers/toast/toast.action";
import { TouchableButton } from "../Button";
import { CloseIcon } from "../../asset/icon";

const Toast = () => {
  const dispatch = useDispatch();
  const toastContent = useSelector((state: States) => state.toast);

  const renderBackground = useMemo(() => {
    switch (toastContent.type) {
      case "success":
        return theme.colors.green;
      case "info":
        return theme.colors.blue;
      case "error":
        return theme.colors.coral;
      case "deleted":
        return theme.colors.whiteDarker;
      default:
        return theme.colors.green;
    }
  }, [toastContent.type]);

  const renderIcon = useMemo(() => {
    switch (toastContent.type) {
      case "success":
        return <img src={SuccessIcon} alt="success" height="16" width="16" />;
      case "info":
        return <img src={InfoIcon} alt="info" height="16" width="16" />;
      case "error":
        return <img src={ErrorIcon} alt="error" height="16" width="16" />;
      default:
        return <img src={DeleteIcon} alt="delete" height="16" width="16" />;
    }
  }, [toastContent.type]);

  const textColor = useMemo(() => {
    switch (toastContent.type) {
      case "deleted":
        return theme.colors.black;
      default:
        return "#fff";
    }
  }, [toastContent.type]);

  const timingBoxColor = useMemo(() => {
    switch (toastContent.type) {
      case "deleted":
        return theme.colors.blackLighter;
      default:
        return "#fff";
    }
  }, [toastContent.type]);

  const onCloseToast = () => {
    dispatch(showToast.success());
  };

  return (
    <>
      <Container
        isVisible={toastContent.isVisible}
        background={renderBackground}
        textColor={textColor}
        close={!toastContent.isVisible}
      >
        <div className="toast__container">
          <div className="toast__icon">{renderIcon}</div>
          <div className="toast__text text">{toastContent.message}</div>
        </div>
        <div className="toast--close">
          <TouchableButton
            icon={CloseIcon}
            lightIcon={toastContent.type === "deleted" ? false : true}
            noBackground
            alt="close"
            onClick={() => onCloseToast()}
          />
        </div>
        <TimingBox backgroundColor={timingBoxColor} />
      </Container>
    </>
  );
};

export type ContainerProps = {
  isVisible: boolean;
  background: string;
  textColor: string;
  close: boolean;
};

const slideInAnimation = keyframes`
  100% { transform: translateY(0%); }   
`;

const timingAnimation = keyframes`
 0% { width: 0%};
 100% {width: 100%}
`;

const TimingBox = styled.div<{ backgroundColor: string }>`
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  animation: ${timingAnimation} 5s forwards;
`;

const Container = styled.div<ContainerProps>`
  z-index: 9999;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  min-height: 56px;
  width: 100%;

  align-items: center;
  background: ${({ background }) => background};
  cursor: pointer;

  transform: translateY(-100%);
  animation: ${slideInAnimation} 0.5s forwards;

  .toast__container {
    display: flex;
    width: 100%;
    padding: 1rem 1rem;
  }

  .toast__icon {
    display: flex;
    align-items: center;
  }

  .toast--close {
    margin-right: 0.5rem;
  }

  .toast__text {
    max-width: calc(100% - 20px - 14px - 10px);
    font-size: 0.875rem;
    font-weight: bold;
    margin-left: 10px;
    color: ${({ textColor }) => textColor};
    word-break: break-word;
  }
`;

export default Toast;
