import { createReducer, ActionType } from "typesafe-actions";
import moment from 'moment';

// actions
import * as actions from "./home.action";

// actions types
import * as types from "./home.actionTypes";

// type
import { SelectedDate, PeriodType } from '../../types/filter';
import { TransactionSummary } from '../../types/transaction'
export type HomeActions = ActionType<typeof actions>;

export type HomeState = {
  filterDate: SelectedDate;
  filterPeriod: PeriodType;
  employeeFilter: { [key: string]: boolean };
  summary: TransactionSummary | null;
  isLoading: boolean;
};

const today = new Date();

const initialState: HomeState = {
  filterDate: { startDate: today, endDate: today },
  filterPeriod: "today",
  employeeFilter: {},
  summary: null, 
  isLoading: false,
};

export const homeReducer = createReducer<HomeState, HomeActions>(
  initialState
).handleAction(
  types.SET_EMPLOYEE_FILTER as any,
  (state: HomeState, action: ActionType<typeof actions.setEmployeeFilter>) => ({
    ...state,
    employeeFilter: action.payload.filter,
  })
)
.handleAction(
  types.SET_SELECTED_DATE as any,
  (state: HomeState, action: ActionType<typeof actions.setSelectedDate>) => ({
    ...state,
    filterDate: action.payload.date
  })
)
.handleAction(
  types.SET_PERIOD_TYPE as any,
  (state: HomeState, action: ActionType<typeof actions.setPeriodType>) => ({
    ...state,
    filterPeriod: action.payload.period
  })
)
.handleAction(actions.getDashboardSummaryAsync.request, (state: HomeState, action: ActionType<typeof actions.getDashboardSummaryAsync.request>) => ({ 
  ...state,
  isLoading: true
}))
.handleAction(actions.getDashboardSummaryAsync.success, (state: HomeState, action: ActionType<typeof actions.getDashboardSummaryAsync.success>) => ({ 
  ...state,
  isLoading: false,
  summary: action.payload
}))
.handleAction(actions.getDashboardSummaryAsync.failure, (state: HomeState, action: ActionType<typeof actions.getDashboardSummaryAsync.failure>) => ({ 
  ...state,
  isLoading: false,
}))
