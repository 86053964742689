import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './product.action'

// actions types
import * as types from './product.actionTypes'

// type
import { product } from '../../types/index'
export type ProductActions = ActionType<typeof actions>;

export type ProductState = {
    list: product.Product[],
    totalData: number,
    isLoading: boolean;
    pageIndex: number;
}

const initialState: ProductState = {
    list: [],
    totalData: 0,
    isLoading: false,
    pageIndex: 1
}

export const productReducer = createReducer<ProductState, ProductActions>(initialState)
    .handleAction(actions.getProductListAsync.request, (state: ProductState, action: ActionType<typeof actions.getProductListAsync.request>) => ({ 
        ...state,
        isLoading: true,
        pageIndex: action.payload.page || 1
    }))
    .handleAction(actions.getProductListAsync.success, (state: ProductState, action: ActionType<typeof actions.getProductListAsync.success>) => ({ 
        ...state,
        isLoading: false,
        list: action.payload.catalog,
        totalData: action.payload.totalData
    }))
    .handleAction(actions.getProductListAsync.failure, (state: ProductState, action: ActionType<typeof actions.getProductListAsync.failure>) => ({ 
        ...state,
        isLoading: false,
    }))
  



