import React, { Suspense } from "react";
import { LazyLoading } from "../../component/Loading";

const PBF = React.lazy(() => import("./PBF"));

export const PBFPage = () => {
  return (
    <Suspense fallback={LazyLoading}>
      <PBF />
    </Suspense>
  );
};
