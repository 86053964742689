export const phoneValidation = (p: string) => {
    let isValid = false;

    const startString = p[0];
    let minLength = 10;

    if (+startString === 0) {
      minLength = 10;
    }

    if (+startString === 6) {
      minLength = 11;
    }

    if (+startString === 8) {
      minLength = 9;
    }

    if (
      /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/g.test(p) &&
      p.length >= minLength &&
      p.length <= 13
    ) {
      isValid = true;
    }

    return isValid;
}