import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import styled from "styled-components";

// redux-action
import {
  getCompanyListASync,
  setSelectedCompany,
  showAddCompanyModal,
  setShowInprogressModal as handleShowInProgressModal,
} from "../../reducers/company/company.action";

// constant
import theme from "../../constant/styledTheme";

// asset
import FarmaCareLogo from "../../asset/img/logo.png";
import CloseIcon from "../../asset/icon/close.svg";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import Header from "../../component/Header/Header";
import CompanyItem from "./CompanyItem";
import CompanyForm from "./CompanyForm";
import { Flex, BodyPage, Wrapper, Text, Box } from "../../component/Styles";
import { Button, TouchableButton } from "../../component/Button";
import { device } from "../../constant/media";
import { Modal } from "../../component/Modal";
import { Spinner } from "../../component/Loading";

// type
import { States } from "../../types/general";
import { Company } from "../../types/company";
import { AddIcon, CoralDeleteIcon } from "../../asset/icon";
import { showToast } from "../../reducers/toast/toast.action";

// utils
import { deleteCompanyAsync } from "./utils/deleteCompany";
import { activationCompanyAsync } from "./utils/activationCompany";
import Placeholder from "../../component/Placeholder";

/**
 * company mean store of pharmacy
 */
const CompanyComponent = () => {
  // redux state
  const dispatch = useDispatch();
  const companyList = useSelector((state: States) => state.company.list);
  const isShowAddCompanyModal = useSelector(
    (state: States) => state.company.showAddCompanyModal
  );
  const showInProgressModal = useSelector(
    (state: States) => state.company.showInProgressModal
  );
  const router = useSelector((state: States) => state.router.location);
  const selectedCompany = useSelector(
    (state: States) => state.company.selectedCompany
  );
  const fetchListLoading = useSelector(
    (state: States) => state.company.isLoading
  );

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showRequestActivationModal, setShowRequestActivationModal] = useState(
    false
  );
  const [showDeletePharmacyModal, setShowDeletePharmacyModal] = useState(false);

  const setSelectedPharmacy = (c: Company | null) => {
    dispatch(setSelectedCompany(c));
  };

  const setShowInProgressModal = (visible: boolean) => {
    dispatch(handleShowInProgressModal(visible));
  };

  const handleShowAddCompanyModal = (visible: boolean) => {
    dispatch(showAddCompanyModal(visible));
  };

  const handleBack = () => {
    dispatch(goBack());
  };

  /**
   * get origin from where user before access this company page
   * if from login page, user must select the company first
   * if from other page user can close company page , and just user current company
   */
  const getOrigin = useMemo(() => {
    const newRouter = router as any;
    const origin = newRouter?.query?.origin;
    return origin;
  }, [router]);

  useEffect(() => {
    dispatch(getCompanyListASync.request());
    setShowInProgressModal(false);
    setShowRequestActivationModal(false);
  }, []);

  const renderHeader = () => {
    return (
      <HeaderContainer>
        {!getOrigin ? (
          <div className="company--close">
            <TouchableButton
              icon={CloseIcon}
              alt="close"
              onClick={() => {
                handleBack();
              }}
            />
          </div>
        ) : null}
        <img src={FarmaCareLogo} alt="Farmacare" width="128" />
      </HeaderContainer>
    );
  };

  const handleSelectRequestActivation = (c: Company) => {
    setShowRequestActivationModal(true);
  };

  const handleSelectDeleteCompany = (c: Company) => {
    setShowDeletePharmacyModal(true);
  };

  const handleSelectInProgressCompany = (c: Company) => {
    setShowInProgressModal(true);
  };

  const handleClickItem = (
    type: "request" | "inprogress" | "delete",
    c: Company
  ) => {
    setSelectedPharmacy(c);
    setSelectedCompany(c);

    switch (type) {
      case "request":
        handleSelectRequestActivation(c);
        break;
      case "inprogress":
        handleSelectInProgressCompany(c);
        break;
      case "delete":
        handleSelectDeleteCompany(c);
        break;
      default:
        break;
    }
  };

  const handleDeleteCompanyAsync = async () => {
    try {
      setIsLoading(true);

      if (!selectedCompany?.id) {
        dispatch(
          showToast.request({
            message: "Apotek tidak ditemukan",
            duration: 5000,
            type: "error",
          })
        );
        return;
      }

      await deleteCompanyAsync(selectedCompany?.id!);
      dispatch(getCompanyListASync.request());

      dispatch(
        showToast.request({
          message: "Berhasil menghapus apotek",
          duration: 5000,
          type: "deleted",
        })
      );

      setIsLoading(false);
      setShowDeletePharmacyModal(false);
    } catch (err) {
      dispatch(
        showToast.request({
          message: "Gagal menghapus apotek",
          duration: 5000,
          type: "error",
        })
      );
    }
    setIsLoading(false);
  };

  const handleActivationCompany = async (type: "cancel" | "request") => {
    try {
      setIsLoading(true);

      if (!selectedCompany?.id) {
        dispatch(
          showToast.request({
            message: "Apotek tidak ditemukan",
            duration: 5000,
            type: "error",
          })
        );
        return;
      }

      await activationCompanyAsync(type, selectedCompany?.id!);
      dispatch(getCompanyListASync.request());

      const message =
        type === "cancel"
          ? "Berhasil membatalkan aktivasi"
          : "Berhasil mengajukan aktivasi";

      dispatch(
        showToast.request({
          message: message,
          duration: 5000,
          type: "success",
        })
      );

      setIsLoading(false);
      setShowInProgressModal(false);
      setShowRequestActivationModal(false);
    } catch (err) {
      if (err.response.status === 406) {
        dispatch(
          showToast.request({
            message: "Apotek anda telah diaktivasi",
            duration: 5000,
            type: "success",
          })
        );
        setShowRequestActivationModal(false);
        dispatch(getCompanyListASync.request());
        setIsLoading(false);
        setSelectedCompany(null);
        return;
      }

      const message =
        type === "cancel"
          ? "Gagal membatalkan aktivasi"
          : "Gagal mengajukan aktivasi";
      dispatch(
        showToast.request({
          message: message,
          duration: 5000,
          type: "error",
        })
      );
    }
    setIsLoading(false);
  };

  const renderCompanyList = useMemo(() => {
    return companyList.map((c, index) => {
      return (
        <CompanyItem
          company={c}
          key={`company-item-${index}`}
          index={index}
          onClickItem={handleClickItem}
        />
      );
    });
  }, [companyList]);

  const onCloseModal = () => {
    handleShowAddCompanyModal(false);
  };

  const renderContent = useMemo(() => {
    if (isLoading || fetchListLoading) {
      return (
        <Flex mt={3} justifyContent="center">
          <Spinner />
        </Flex>
      );
    }

    if (!companyList.length) {
      return (
        <Box mt={2}>
          <Placeholder
            title="Belum ada apotek"
            subtitle="Jika kamu pemilik apotek, tekan tombol dibawah untuk membuat sistem apotekmu."
            action={
              <Button
                title="Apotek"
                icon={AddIcon}
                onClick={() => handleShowAddCompanyModal(true)}
              />
            }
          />
        </Box>
      );
    }

    return <Box mt={2}>{renderCompanyList}</Box>;
  }, [isLoading, companyList, fetchListLoading]);

  const handleCloseInProgressModal = () => {
    dispatch(getCompanyListASync.request());
    setShowInProgressModal(false);
  };

  return (
    <>
      <Modal
        isVisible={showRequestActivationModal}
        title={`${selectedCompany?.name} belum diaktivasi`}
        subtitle="Ajukan proses aktivasi untuk membuka akses ke sistem apotekmu."
        footer={
          <Flex mt="1" flexDirection="column">
            <Text variant="rg">
              Untuk bantuan, kontak{" "}
              <a href="mailto:bantuan@farmacare.id">
                <u className="link">bantuan@farmacare.id</u>
              </a>
            </Text>
            <Flex mt="1" justifyContent="flex-end">
              <Button
                isLoading={isLoading}
                title="Ajukan aktivasi"
                onClick={() => handleActivationCompany("request")}
              />
              <Box ml="0">
                <Button
                  isLoading={isLoading}
                  title="Batal"
                  secondary
                  onClick={() => setShowRequestActivationModal(false)}
                />
              </Box>
            </Flex>
          </Flex>
        }
      />

      <Modal
        isVisible={showInProgressModal}
        title={`${selectedCompany?.name} sedang dalam proses aktivasi`}
        subtitle="Kami akan segera menghubungimu untuk proses aktivasi."
        footer={
          <Flex mt={0} flexDirection="column">
            <Text variant="rg">
              Untuk bantuan, kontak{" "}
              <a href="mailto:bantuan@farmacare.id">
                <u className="link">bantuan@farmacare.id</u>
              </a>
            </Text>
            <Flex justifyContent="space-between" mt={1}>
              <Button
                isLoading={isLoading}
                title="Batalkan aktivasi"
                inverse
                danger
                onClick={() => handleActivationCompany("cancel")}
              />
              <Button
                isLoading={isLoading}
                title="Oke"
                onClick={() => handleCloseInProgressModal()}
              />
            </Flex>
          </Flex>
        }
      />

      <Modal
        isVisible={showDeletePharmacyModal}
        title={`Hapus ${selectedCompany?.name}?`}
        subtitle="Apotek yang sudah dihapus tidak dapat dikembalikan lagi."
        footer={
          <Flex justifyContent="space-between" mt="1">
            <Button
              isLoading={isLoading}
              title="Hapus"
              inverse
              danger
              icon={CoralDeleteIcon}
              defaultIcon
              onClick={handleDeleteCompanyAsync}
            />
            <Button
              isLoading={isLoading}
              title="Batal"
              secondary
              onClick={() => {
                setShowDeletePharmacyModal(false);
                setSelectedPharmacy(null);
              }}
            />
          </Flex>
        }
      />

      <Layout>
        <TopBar header={renderHeader} />

        {isShowAddCompanyModal ? (
          <CompanyForm
            isVisible={isShowAddCompanyModal}
            onClose={() => onCloseModal()}
          />
        ) : null}

        <Wrapper p={2} mt={4}>
          <Header
            title="Daftar apotekmu"
            actionButton={
              <Button
                secondary
                title="+ Apotek"
                onClick={() => handleShowAddCompanyModal(true)}
              />
            }
          />
          <BodyPage pb={5}>{renderContent}</BodyPage>
        </Wrapper>
      </Layout>
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: absolute;
  top: 0;

  width: 100%;
  height: 60px;

  @media ${device.lg} {
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }

  .company--close {
    position: absolute;
    left: 14px;
    top: 12px;
  }
`;

export default CompanyComponent;
