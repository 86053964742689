export const getAccessToken = () => { 
    return localStorage.getItem('accessToken')
}

export const setToken = (token: string) => {
    return localStorage.setItem('token', token)
}

export const setAccessToken = (token: string) => {
    return localStorage.setItem('accessToken', token)
}

export const getToken = () => { 
    return localStorage.getItem('token')
}

export const getRefreshToken = () => { 
    return localStorage.getItem('refreshToken')
}

export const getCompanyID = () => { 
    return localStorage.getItem('companyID')
}

export const setCompanyID = (companyID: number) => {
    return localStorage.setItem('companyID', JSON.stringify(companyID))
}

export const setSession = (sessionID: string) => {
    localStorage.setItem('session', sessionID)
}

export const getSessionID = () => {
    return localStorage.getItem('session')
}

export const clearSessionID = () => {
    localStorage.removeItem('session')
}

export const removeSessionID = () => {
    localStorage.removeItem('session')
}

export const clearToken = () => { 
    localStorage.removeItem('is_login')
    localStorage.removeItem('token')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('bio')
    localStorage.removeItem('companyID')
    localStorage.removeItem("persist:root")
}

export const setRefreshToken = (token: string) => {
    localStorage.setItem('accessToken', token)
}

const LocalStorageService = {
    getAccessToken,
    getToken,
    getCompanyID,
    clearToken,
    setSession,
    getSessionID, 
    clearSessionID,
    setToken,
    setAccessToken,
    setRefreshToken,
    setCompanyID,
    removeSessionID
}

export default LocalStorageService;