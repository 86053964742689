import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    color: #333333;
  }

  h1 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.875rem;
  }

  h2 {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  h3 {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  h4 {
    font-weight: bold;
    font-size: 0.625rem;
    line-height: 1.5rem;
  }

  .text {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .subtitle {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .image-action {
    cursor: pointer
  }

  table { 
    border-spacing: 0;
    border-collapse: collapse;
  }

 
`;

export default GlobalStyle;
