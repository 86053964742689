import React, { useMemo } from "react";
import { push, goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

//asset
import FarmacareLogo from "../../asset/img/logo.png";
import { ArrowBackIcon } from "../../asset/icon";

// constant
import theme from "../../constant/styledTheme";

//component
import Layout from "../../component/Layout/Layout";
import { TouchableButton, Button } from "../../component/Button";
import { Wrapper, H1, Text, Flex, BodyPage } from "../../component/Styles";
import TopBar from "../../component/TopBar";

// type
import { general } from "../../types/index";

const ForgotPassSent = () => {
  const dispatch = useDispatch();

  // redux state
  const router = useSelector((state: general.States) => state.router.location);

  const handleClick = () => {
    dispatch(push("/forgot-password"));
  };

  const getEmail = useMemo(() => {
    const newRouter = router as any;
    const email = newRouter?.query?.email;
    return email;
  }, [router]);

  const handleBack = () => {
    dispatch(goBack());
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <div className="forgot-pass--back">
          <TouchableButton
            icon={ArrowBackIcon}
            alt="back"
            onClick={handleBack}
          />
        </div>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <Wrapper p={2}>
        <Body>
          <H1>Buka emailmu</H1>
          <Text mt={0}>
            Petunjuk untuk membuat ulang password sudah kami kirimkan ke{" "}
            <span className="bold">{getEmail}</span>
          </Text>

          <Flex
            pt={2}
            mt={3}
            justifyContent="space-between"
            borderTop="1px solid"
            borderColor="blackLighter"
            alignItems="center"
          >
            <Text variant="md">Alamat email salah?</Text>
            <Button
              onClick={() => handleClick()}
              secondary
              title={"Ganti Email"}
            />
          </Flex>
        </Body>
      </Wrapper>
    </Layout>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;

  .forgot-pass--back {
    position: absolute;
    left: 14px;
    top: 12px;
  }

  @media (min-width: ${theme.breakpoints[3]}) {
    border-bottom: 1px solid ${theme.colors.blackLighter};
  }
`;

const Body = styled(BodyPage)`
  max-width: 962px;
`;

export default ForgotPassSent;
