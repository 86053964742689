import { action, createAsyncAction } from 'typesafe-actions';

// type 
import { transaction, product } from '../../types/index'

// action types
import * as types from './transaction.actionTypes'

export type PurchasePayload = { transactionID: string, qty: number, productID: string, discAmount: number, discType: 'cash' | 'percentage' }

export const getTransactionListAsync = createAsyncAction(
    types.GET_TRANSACTIONS_REQUEST,
    types.GET_TRANSACTIONS_SUCCESS,
    types.GET_TRANSACTIONS_FAILURE
)<undefined, { data: transaction.Transaction[] }, { error: string }>();

export const getTransactionDetailAsync = createAsyncAction(
    types.GET_TRANSACTION_DETAIL_REQUEST,
    types.GET_TRANSACTION_DETAIL_SUCCESS,
    types.GET_TRANSACTION_DETAIL_FAILURE
)<{ transactionID: string,  }, { data: transaction.TransactionDetail }, { error: string }>();

export const createTransactionAsync = createAsyncAction(
    types.CREATE_TRANSACTION_REQUEST,
    types.CREATE_TRANSACTION_SUCCESS,
    types.CREATE_TRANSACTION_FAILURE
)<undefined, undefined, { error: string }>();

export const updateProductTransactionAsync = createAsyncAction(
    types.UPDATE_PRODUCT_TRANSACTION_REQUEST,
    types.UPDATE_PRODUCT_TRANSACTION_SUCCESS,
    types.UPDATE_PRODUCT_TRANSACTION_FAILURE
)<PurchasePayload, undefined, { error: string }>();

export const addProductTransactionAsync = createAsyncAction(
    types.ADD_PRODUCT_TRANSACTION_REQUEST,
    types.ADD_PRODUCT_TRANSACTION_SUCCESS,
    types.ADD_PRODUCT_TRANSACTION_FAILURE
)<PurchasePayload, undefined, { error: string }>();


export const deleteProductTransactionAsync = createAsyncAction(
    types.DELETE_PRODUCT_TRANSACTION_REQUEST,
    types.DELETE_PRODUCT_TRANSACTION_SUCCESS,
    types.DELETE_PRODUCT_TRANSACTION_FAILURE
)<{productID: string, transactionID: string }, undefined, { error: string }>();

export const deleteTransactionAsync = createAsyncAction(
    types.DELETE_TRANSACTION_REQUEST,
    types.DELETE_TRANSACTION_SUCCESS,
    types.DELETE_TRANSACTION_FAILURE
)<{ transactionID: string }, undefined, { error: string }>();


export const setShowTransactionModalDetail = (visible: boolean) => action(types.SET_SHOW_TRANSACTION_MODAL_DETAIL, { visible })
export const setSelectedTransactionProduct = (product: product.TransactionProduct | null) => action(types.SET_SELECTED_TRANSACTION_PRODUCT, {product})
export const setSelectedTransaction = (transaction: transaction.Transaction) => action(types.SET_SELECTED_TRANSACTION, {transaction})

