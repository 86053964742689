import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './toast.action'

// actions types
import { ToastType } from '../../types/general'

// type
export type ToastActions = ActionType<typeof actions>;

export type ToastState = {
    isVisible: boolean,
    type: ToastType,
    message: string;
}

const initialState: ToastState = {
    isVisible : false,
    type: 'success',
    message: ''
}

export const toastReducer = createReducer<ToastState, ToastActions>(initialState)
    .handleAction(actions.showToast.request, (state: ToastState, action: ActionType<typeof actions.showToast.request>) => ({ 
        ...state,
        isVisible: true,
        type: action.payload.type,
        message: action.payload.message
    }))
    .handleAction(actions.showToast.success, (state: ToastState, action: ActionType<typeof actions.showToast.success>) => ({ 
        ...state,
        isVisible: false,
        message: ''
       
    }))
    .handleAction(actions.showToast.failure, (state: ToastState, action: ActionType<typeof actions.showToast.failure>) => ({ 
        ...state,
        isVisible: false,
    }))
  



