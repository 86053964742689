import axios from "axios";

import  localStorageService from '../utils/localStorageService';

import { BASE_URL_BETA } from "../constant/api";
import LocalStorageService from "../utils/localStorageService";


// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
        const token = localStorage.getItem("token");
       if (token) {
           config.headers['Authorization'] = token;
       }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
    function (error) {
    const originalRequest = error.config;

    if(!error.response){
      return Promise.reject(error);
    }

    if (error.response.status === 422) {
      return Promise.reject(error);
    }

      if (error.response.status === 401) {
        const refreshToken = localStorage.getItem("refreshToken");
     
        return axios
          .post(
            `${BASE_URL_BETA}/refresh-token`,
            {
              refresh_token: refreshToken,
            }
          ).then((res) => {
          if (res.status === 200) {

            localStorageService.setToken(res.data.AuthenticationResult.IdToken)
            localStorageService.setAccessToken(res.data.AuthenticationResult.AccessToken)
            localStorageService.setRefreshToken(res.data.AuthenticationResult.RefreshToken)
            
            axios.defaults.headers.common[
              "Authorization"
            ] = LocalStorageService.getToken();

            return axios(originalRequest);
          }
        })
        .catch((err) => {
          return Promise.reject(error);
        })
    }
    return Promise.reject(error);
  }
);

export default axios
