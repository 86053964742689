import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";

// asset
import { ArrowBackIcon } from "../../../asset/icon";

// component
import { Button } from "../../../component/Button";
import {
  Overlay,
  Modal,
  BodyPage,
  StickyBottomContainer,
  Flex,
  Text,
  RotatedBox,
  Wrapper,
} from "../../../component/Styles";
import TopBar from "../../../component/TopBar";
import Header from "../../../component/Header/Header";

// related component
import InventoryUpdateStockEditPackageList from "./InventoryUpdateStockEditPackageList";

// type
import { InventoryBatch } from "../../../types/inventory";
import { ProductDetail } from "../../../types/product";
import { Stock, SavedStock } from "./type";

// utils
import { getQtyOperator } from "../utils/getQtyOperator";
import { nominalFormat } from "../../../utils/nominalFormat";
import { getValidDate } from "../../../utils/getValidDate";

export type Props = {
  isVisible?: boolean;
  product: ProductDetail | null;
  changesBatchStocks: SavedStock;
  inventoryBatch: InventoryBatch | null;
  handleChangeStockBatch: (
    batchID: string,
    currentStock: number,
    diffChanges: number,
    stock: Stock[],
    batchDetail: InventoryBatch
  ) => void;
  onCloseModal: () => void;
};

export type Option = { label: string; value: { code: string; qty: number } };

const InventoryUpdateStockPackage = (props: Props) => {
  const {
    isVisible,
    changesBatchStocks,
    product,
    inventoryBatch,
    onCloseModal,
    handleChangeStockBatch,
  } = props;

  const [stocks, setStocks] = useState<Stock[]>([]);

  const getCalculatedChangesStock = useMemo(() => {
    let total = 0;

    stocks.forEach((s) => {
      total += (s.stock || 0) * s.smallestQty;
    });

    return total;
  }, [stocks]);

  const getDiffChanges = useMemo(() => {
    return getCalculatedChangesStock - (inventoryBatch?.batch_qty || 0);
  }, [getCalculatedChangesStock]);

  const handleSave = () => {
    handleChangeStockBatch(
      inventoryBatch?.id! + "",
      getCalculatedChangesStock,
      getDiffChanges,
      stocks,
      inventoryBatch!
    );

    onCloseModal();
  };

  useEffect(() => {
    if (changesBatchStocks[inventoryBatch?.id!]?.stockPackages) {
      setStocks(changesBatchStocks[inventoryBatch?.id!].stockPackages);
    }
  }, []);

  return (
    <>
      <Overlay isVisible={!!isVisible}>
        <Modal isVisible={!!isVisible}>
          <TopBar
            title="Update stok"
            isModal
            leftHeaderFunction={() => onCloseModal()}
          />

          <Wrapper
            p={2}
            pb={6}
            overflowY="scroll"
            height="calc(100vh - 52px - 52px)"
            maxHeight="calc(100vh - 52px - 52px - 3rem)"
          >
            <BodyPage pb={5}>
              <Header title={inventoryBatch?.batch_no} />
              <Text fontSize="2" lineHeight="2" mt={1}>
                ED{" "}
                {getValidDate(inventoryBatch?.ED)
                  ? moment(getValidDate(inventoryBatch?.ED)).format("MMM YYYY")
                  : "-"}
              </Text>

              <Flex mt={2}>
                <Flex flexDirection="column" flex="4">
                  <Text fontSize="2" lineHeight="2">
                    Dari
                  </Text>
                  <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                    {inventoryBatch?.batch_qty} {inventoryBatch?.unit}
                  </Text>
                </Flex>
                <Flex flexDirection="column" flex="6">
                  <Flex alignItems="center">
                    <RotatedBox direction="flip-x">
                      <img
                        src={ArrowBackIcon}
                        width="24"
                        height="24"
                        alt="arrow"
                      />
                    </RotatedBox>
                    <Flex flexDirection="column" flex="4" ml="1">
                      <Text fontSize="2" lineHeight="2">
                        Menjadi
                      </Text>
                      <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                        {nominalFormat(getCalculatedChangesStock)}{" "}
                        {product?.unit}
                      </Text>
                      <Text fontSize="1" lineHeight="1">
                        <span
                          className={getDiffChanges > 0 ? "success" : "danger"}
                        >
                          {getQtyOperator(getDiffChanges)}
                          {nominalFormat(getDiffChanges)}
                        </span>{" "}
                        {product?.unit}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <InventoryUpdateStockEditPackageList
                product={product}
                inventoryBatch={inventoryBatch}
                stocks={stocks}
                setStocks={setStocks}
              />
            </BodyPage>
          </Wrapper>
          <StickyBottomContainer
            position="absolute"
            bottom="0"
            p={1}
            width="calc(100% - 2rem)"
          >
            <Flex width="100%">
              <Button title="Simpan" fullWidth onClick={handleSave} />
            </Flex>
          </StickyBottomContainer>
        </Modal>
      </Overlay>
    </>
  );
};

export default InventoryUpdateStockPackage;
