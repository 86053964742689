import React, { Suspense } from "react";

const Employee = React.lazy(() => import("./Employee"));

export const EmployeePage = () => {
  return (
    <Suspense fallback={<div>Memuat...</div>}>
      <Employee />
    </Suspense>
  );
};
