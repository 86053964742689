import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { maxWidth } from "styled-system";

// component
import { Button } from "../../component/Button";
import { H2, Overlay } from "../../component/Styles";

export type ModalProps = {
  title: string;
  subtitle: string;
  // for danger button
  destructiveTitle?: string;
  // for common button
  acceptTitle?: string;
  isVisible: boolean;
  maxWidth?: string;
  footer?: any;
  acceptFunction?: (val?: any | undefined) => void;
  destructiveFunction?: (val?: any | undefined) => void;
};

const Modal = (props: ModalProps) => {
  const {
    title,
    subtitle,
    isVisible,
    destructiveTitle,
    acceptTitle,
    maxWidth,
    footer,
    acceptFunction,
    destructiveFunction,
  } = props;

  const handleDestructive = () => {
    if (destructiveFunction) {
      destructiveFunction();
    }
  };

  const handleAccept = () => {
    if (acceptFunction) {
      acceptFunction();
    }
  };

  return (
    <ExtendOverlay isVisible={isVisible === undefined ? true : !!isVisible}>
      <Container maxWidth={maxWidth}>
        <H2 className="modal__title">{title}</H2>
        <div className="modal__subtitle text">{subtitle}</div>
        {footer ? (
          footer
        ) : (
          <div className="button__container">
            <>
              {destructiveTitle ? (
                <div className="action-button">
                  <Button
                    danger
                    inverse
                    title={destructiveTitle}
                    onClick={() => handleDestructive()}
                  />
                </div>
              ) : null}
              {acceptTitle ? (
                <div className="action-button">
                  <Button
                    secondary
                    title={acceptTitle}
                    onClick={() => handleAccept()}
                  />
                </div>
              ) : null}
            </>
          </div>
        )}
      </Container>
    </ExtendOverlay>
  );
};

const ExtendOverlay = styled(Overlay)`
  z-index: 100;
`;

const fadeInAnimation = keyframes`
  100% { opacity: 1 }   
`;

const Container = styled.div<{ maxWidth: string | undefined }>`
  opacity: 0;
  position: absolute;
  background: #fff;
  padding: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 320px;
  width: 80%;
  word-break: break-all;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  animation: ${fadeInAnimation} 0.4s forwards;

  .modal__subtitle {
    margin-top: 0.5rem;
    font-size: 0.825rem;
    font-family: Nunito;
  }

  .button__container {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
  }

  .action-button {
    :first-child {
      margin-right: 10px;
    }
  }
`;

export default Modal;
