import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./company.actionTypes";

// type
import { Company } from "../../types/company";

export const getCompanyListASync = createAsyncAction(
  types.GET_COMPANY_LIST_REQUEST,
  types.GET_COMPANY_LIST_SUCCESS,
  types.GET_COMPANY_LIST_FAILURE
)<undefined, Company[], { error: string }>();

export const addCompanyAsync = createAsyncAction(
    types.ADD_COMPANY_REQUEST,
    types.ADD_COMPANY_SUCCESS,
    types.ADD_COMPANY_FAILURE
  )<Partial<Company> & { city_id: number, province_id: number }, { company: Company }, { error: string }>();


export const setSelectedCompany = (company: Company | null) => action(types.SET_SELECTED_COMPANY, { company }) 

export const showAddCompanyModal = (visible: boolean) => action(types.SHOW_ADD_COMPANY_MODAL, { visible })

export const setShowInprogressModal = (visible: boolean) => action(types.SHOW_IN_PROGRESS_MODAL, { visible })

export const setSelectedPharmacy = (id: string) => action(types.SET_SELECTED_PHARMACY, { id })
