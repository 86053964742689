import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { goBack, push } from "connected-react-router";

// utils
import { getURLPathID } from "../../utils/getURLPathID";
import { calculateDiscount } from "./utils/calculateDiscount";

// redux action
import { getInvoiceDetailAsync } from "../../reducers/invoice/invoice.action";

// component
import TopBar from "../../component/TopBar";
import Header from "../../component/Header/Header";
import Layout from "../../component/Layout";
import { Button } from "../../component/Button";
import { Flex, Box, BodyPage, Text, H2, Wrapper } from "../../component/Styles";
import InvoiceProductList from "./InvoiceProductList";

// type
import { States } from "../../types/general";
import { PaymentMethod } from "../../types/invoice";
import { nominalFormat } from "../../utils/nominalFormat";
import { ExtendProductInvoice } from "../../types/product";
import { Spinner } from "../../component/Loading";

const InvoiceDetail = () => {
  const dispatch = useDispatch();

  const location = useSelector((state: States) => state.router.location);
  const isLoading = useSelector((state: States) => state.invoice.isLoading);
  const invoice = useSelector((state: States) => state.invoice.detail);

  const getPaymentMethod = (paymentMethod: PaymentMethod) => {
    if (paymentMethod === "cash") {
      return "Tunai";
    }
    return "Kredit";
  };

  const getInvoiceID = useMemo(() => {
    const pathname = location.pathname;

    return getURLPathID(pathname);
  }, [location]);

  const handleBack = () => {
    dispatch(goBack());
  };

  /** get from which page access this invoice detail
   * information can get from URL path
   */
  const getOrigin = () => {
    const clonedLocation: any = location;
    const { origin, originID } = clonedLocation.query;

    if (origin) {
      return `?origin=${origin}&originID=${originID}`;
    }

    return "";
  };

  const handleNavEdit = () => {
    if (invoice?.id) {
      dispatch(push(`/invoice/edit/${invoice?.id}${getOrigin()}`));
    }
  };

  const getSubtotalPartial = (p: ExtendProductInvoice) => {
    let discount = p.discount_amount;
    const total_buy =
      p.price_buy * p.qty * (p.package_buy_detail?.smallest_qty || 1);

    if (p.discount_type_buy === "percentage") {
      discount = (p.discount_amount / 100) * total_buy;
    }

    return Math.ceil(total_buy - discount);
  };

  const getSubtotal = useMemo(() => {
    let total = 0;

    const productList = invoice?.product_list || [];

    productList.forEach((p) => {
      total += getSubtotalPartial(p);
    });

    return Math.ceil(total);
  }, [invoice?.product_list]);

  const getSubtotalVAT = useMemo(() => {
    let total = getSubtotal;

    if (!invoice?.include_vat) {
      total = getSubtotal + calculateDiscount("percentage", 10, getSubtotal);
    }

    return total;
  }, [getSubtotal, invoice]);

  const getTotal = useMemo(() => {
    let total = 0;

    const discAmount = invoice?.discount_amount;
    const discType = invoice?.discount_type;
    const stampPrice = invoice?.stamp_price || 0;

    let discount = discAmount;
    if (discAmount) {
      if (discType === "percentage") {
        discount = (discAmount / 100) * getSubtotal;
      }
    }

    return getSubtotalVAT + total + stampPrice - (discount || 0);
  }, [getSubtotal, invoice]);

  const getFinalDiscount = useMemo(() => {
    if (invoice?.discount_type === "percentage") {
      return `-${invoice.discount_amount}%`;
    }

    return `-${invoice?.discount_amount}`;
  }, [invoice?.discount_amount, invoice?.discount_type]);

  useEffect(() => {
    if (
      getInvoiceID &&
      !isNaN(+getInvoiceID) &&
      location.pathname.includes("/invoice")
    ) {
      dispatch(getInvoiceDetailAsync.request({ invoiceID: getInvoiceID }));
    }
  }, [getInvoiceID]);

  return (
    <Layout>
      <TopBar title="Penerimaan stok" leftHeaderType="back" />

      <Wrapper p={2} pb={4}>
        <BodyPage>
          {isLoading ? (
            <Flex height="100%" alignItems="center" justifyContent="center">
              <Spinner />
            </Flex>
          ) : (
            <>
              <Header
                title={`Faktur ${invoice?.invoice_number}`}
                actionButton={
                  <Button
                    secondary
                    title="Edit Faktur"
                    onClick={handleNavEdit}
                  />
                }
                backTitle={"faktur"}
                backFunc={handleBack}
              />

              {/* PBF Detail */}
              <Flex flexDirection={["column", "column", "row"]} mt={2}>
                <Flex flexDirection="column" flex={[1, 6]}>
                  <Flex
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                  >
                    <Flex flex="4" backgroundColor="whiteDarker" p="0">
                      <Text fontSize="2" lineHeight="2">
                        Nama PBF
                      </Text>
                    </Flex>
                    <Flex flex="6" p="0">
                      <Text fontSize="2" lineHeight="2">
                        {invoice?.supplier_name}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                  >
                    <Flex flex="4" backgroundColor="whiteDarker" p="0">
                      <Text fontSize="2" lineHeight="2">
                        No. Faktur
                      </Text>
                    </Flex>
                    <Flex flex="6" p="0">
                      <Text fontSize="2" lineHeight="2">
                        {invoice?.invoice_number}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                  >
                    <Flex flex="4" backgroundColor="whiteDarker" p="0">
                      <Text fontSize="2" lineHeight="2">
                        Tanggal faktur
                      </Text>
                    </Flex>
                    <Flex flex="6" p="0">
                      <Text fontSize="2" lineHeight="2">
                        {moment(invoice?.invoice_date).format("DD MMM YYYY")}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                  >
                    <Flex flex="4" backgroundColor="whiteDarker" p="0">
                      <Text fontSize="2" lineHeight="2">
                        Tanggal diterima
                      </Text>
                    </Flex>
                    <Flex flex="6" p="0">
                      <Text fontSize="2" lineHeight="2">
                        {invoice?.receipt_date !== "None"
                          ? moment(invoice?.receipt_date).format("DD MMM YYYY")
                          : "-"}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                {/* Payment detail */}
                <Flex
                  flexDirection="column"
                  flex={[1, 1, 4]}
                  mt={[2, 2, 0]}
                  ml={["0px", "0px", 2]}
                >
                  <H2>Pembayaran</H2>

                  <Flex flexDirection="column" mt={0}>
                    <Flex
                      borderBottom="1px solid"
                      borderBottomColor="blackLighter"
                    >
                      <Flex flex="4" backgroundColor="whiteDarker" p="0">
                        <Text fontSize="2" lineHeight="2">
                          Metode
                        </Text>
                      </Flex>
                      <Flex flex="6" p="0">
                        <Text fontSize="2" lineHeight="2">
                          {getPaymentMethod(invoice?.payment_method!)}
                        </Text>
                      </Flex>
                    </Flex>
                    {invoice?.payment_method !== "cash" ? (
                      <Flex
                        borderBottom="1px solid"
                        borderBottomColor="blackLighter"
                      >
                        <Flex flex="4" backgroundColor="whiteDarker" p="0">
                          <Text fontSize="2" lineHeight="2">
                            Jatuh tempo
                          </Text>
                        </Flex>
                        <Flex flex="6" p="0">
                          <Text fontSize="2" lineHeight="2">
                            {moment(invoice?.due_date).format("DD MMM YYYY")}
                          </Text>
                        </Flex>
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>
              </Flex>

              {/* List Product */}
              <Box mt={2}>
                <H2> Pembelian</H2>
                <Text variant="rg">
                  {" "}
                  {!!invoice?.include_vat
                    ? "Harga barang termasuk PPN."
                    : "Harga barang belum termasuk PPN"}
                </Text>

                <InvoiceProductList
                  productList={invoice?.product_list! || []}
                  includeVAT={!!invoice?.include_vat}
                />
              </Box>

              <Flex justifyContent={"flex-end"} width="100%">
                <Box width={["100%", "100%", "100%", "400px"]}>
                  {!invoice?.include_vat ? (
                    <>
                      <Flex
                        justifyContent="space-between"
                        p={0}
                        borderBottom="1px solid"
                        borderBottomColor="blackLighter"
                        borderTop="1px solid"
                        borderTopColor="black"
                      >
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          DPP
                        </Text>
                        <Text fontWeight="bold" fontSize="2" lineHeight="2">
                          {nominalFormat(getSubtotal)}
                        </Text>
                      </Flex>
                      {!invoice?.include_vat ? (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          p={0}
                        >
                          <Text fontWeight="bold" fontSize="2" lineHeight="2">
                            PPN
                          </Text>
                          <Text variant="rg">10%</Text>
                          <Text fontWeight="bold" variant="rg" color="coral">
                            {nominalFormat(
                              calculateDiscount("percentage", 10, getSubtotal)
                            )}
                          </Text>
                        </Flex>
                      ) : null}
                    </>
                  ) : null}
                  <Flex
                    justifyContent="space-between"
                    p={0}
                    borderBottom="1px solid"
                    borderBottomColor="blackLighter"
                    borderTop="1px solid"
                    borderTopColor="black"
                  >
                    <Text fontWeight="bold" fontSize="2" lineHeight="2">
                      Subtotal
                    </Text>
                    <Text fontWeight="bold" variant="rg">
                      {nominalFormat(getSubtotalVAT)}
                    </Text>
                  </Flex>

                  {invoice?.payment_method === "cash" ? (
                    <Flex
                      p="0"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom="1px solid"
                      borderColor="blackLighter"
                    >
                      <Flex width="30%">
                        <Text fontSize="2" lineHeight="2">
                          Diskon tunai
                        </Text>
                      </Flex>
                      <Flex width="100%" justifyContent="flex-end">
                        <Text fontSize="2" lineHeight="2">
                          {getFinalDiscount}
                        </Text>
                      </Flex>
                    </Flex>
                  ) : null}

                  {/* <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={0}
                  >
                    <Text fontSize="2" lineHeight="2">
                      PPN
                    </Text>
                    <Text fontSize="2" lineHeight="2">
                      Rp{nominalFormat(invoice?.vat_amount || 0)}
                    </Text>
                  </Flex> */}

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={0}
                  >
                    <Text fontSize="2" lineHeight="2">
                      Biaya materai
                    </Text>
                    <Text fontSize="2" lineHeight="2">
                      {nominalFormat(invoice?.stamp_price)}
                    </Text>
                  </Flex>

                  <Flex
                    background="#f7f7f7"
                    p="0"
                    justifyContent="space-between"
                    borderY="1px solid"
                    borderColor="black"
                    borderBottomColor="blackLighter"
                  >
                    <Text fontSize="2" lineHeight="2" fontWeight="bold">
                      Total faktur
                    </Text>
                    <Text fontSize="2" lineHeight="2" fontWeight="bold">
                      {nominalFormat(getTotal)}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default InvoiceDetail;
