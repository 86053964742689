import React, { useEffect, useMemo, useState } from "react";

// asset
import { ChevronNext, ChevronPrev } from "../../asset/icon";

// component
import { Flex, Box, Text } from "../Styles";
import TextInput from "../../component/TextInput";
import { Button } from "../../component/Button";
import { disable } from "workbox-navigation-preload";

export type Props = {
  /** Handle change page */
  onChangePage?: (index: number) => void;
  /** GET - active current page */
  currentPage: number;
  /** GET - total page (optional, can select pass total page or total data) */
  totalPage?: number;
  /** GET - loading state */
  isLoading: boolean;
  /** GET - total data or total list */
  totalData?: number;
  /** GET - maximum number per table , by default is 50 row  */
  rowPerTable?: number;
  /** GET status disabled */
  disabled?: boolean;
};

const Pagination = (props: Props) => {
  const {
    currentPage,
    disabled,
    totalPage,
    isLoading,
    totalData,
    rowPerTable,
    onChangePage,
  } = props;

  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const getTotalPage = useMemo(() => {
    if (totalPage) {
      return totalPage;
    }

    const row = rowPerTable || 50;

    if (totalData && row) {
      return Math.ceil(totalData / row);
    }

    return 1;
  }, [totalPage, totalData, rowPerTable]);

  const disabledPrevButton = useMemo(() => {
    if (getTotalPage === 1 || !getTotalPage) {
      return true;
    }

    if (currentPage === 1) {
      return true;
    }

    return false;
  }, [currentPage, getTotalPage]);

  const disabledNextButton = useMemo(() => {
    if (getTotalPage === 1 || !getTotalPage) {
      return true;
    }

    if (currentPage === getTotalPage) {
      return true;
    }

    return false;
  }, [currentPage, getTotalPage]);

  const handleClickPrev = () => {
    const pageIndex = +currentPage - 1;
    if (pageIndex <= 0) {
      return;
    }

    if (onChangePage) {
      onChangePage(pageIndex);
    }
  };

  const handleClickNext = () => {
    const pageIndex = +currentPage + 1;

    if (pageIndex > getTotalPage) {
      return;
    }

    if (onChangePage) {
      onChangePage(pageIndex);
    }
  };

  /**
   * Handle change page when onBlur
   * @param e
   */
  const onBlur = (e: React.FocusEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as any;
    if (onChangePage) {
      if (+currentPage !== +target.value) {
        onChangePage(target.value as any);
      }
    }
    return;
  };

  const handleChangePage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onChangePage) {
      onChangePage(page);
    }
  };

  /**
   * Change page local state, to handle when press enter
   * @param value
   */
  const handleChangePageState = (value: string | undefined) => {
    const numberValue = value ? +value : 1;

    if (numberValue! <= 0) {
      setPage(1);
      return;
    }

    if (numberValue! > getTotalPage) {
      setPage(getTotalPage);
      return;
    }

    setPage(numberValue!);
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="500px"
      maxWidth="500px"
      py="1"
    >
      <Box display={["none", "none", "block"]}>
        <Button
          title="Balik"
          secondary
          disabled={disabledPrevButton || isLoading || disabled}
          icon={ChevronPrev}
          onClick={handleClickPrev}
        />
      </Box>
      <Box display={["block", "block", "none"]}>
        <Button
          secondary
          disabled={disabledPrevButton || isLoading || disabled}
          icon={ChevronPrev}
          onClick={handleClickPrev}
        />
      </Box>

      <Text fontSize={2} lineHeight={2}>
        Halaman
      </Text>

      {getTotalPage === 1 || !getTotalPage ? (
        <Text fontSize={2} lineHeight={2}>
          {getTotalPage}
        </Text>
      ) : (
        <form onSubmit={(e) => handleChangePage(e)}>
          <TextInput
            width={60}
            value={page}
            onBlur={onBlur}
            onChange={(text) => handleChangePageState(text)}
            type="number"
            textAlign="center"
            disabled={isLoading}
          />
        </form>
      )}

      <Text fontSize={2} lineHeight={2}>
        dari {getTotalPage || 1}
      </Text>

      <Box display={["none", "none", "block"]}>
        <Button
          title="Lanjut"
          secondary
          disabled={disabledNextButton || isLoading || disabled}
          rightIcon={ChevronNext}
          onClick={handleClickNext}
        />
      </Box>
      <Box display={["block", "block", "none"]}>
        <Button
          secondary
          disabled={disabledNextButton || isLoading || disabled}
          rightIcon={ChevronNext}
          onClick={handleClickNext}
        />
      </Box>
    </Flex>
  );
};

export default Pagination;
