import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

// asset
import TrashIcon from "../../asset/icon/delete.svg";

// redux action
import {
  getTransactionDetailAsync,
  deleteTransactionAsync,
  setShowTransactionModalDetail,
} from "../../reducers/transaction/transaction.action";
import {
  setShowChangePriceModal,
  patchProductPriceAsync,
} from "../../reducers/catalog/catalog.action";

// component
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout/Layout";
import { Button, TouchableButton } from "../../component/Button";
import { Box, Flex, Wrapper, H3, Text, BodyPage } from "../../component/Styles";
import Header from "../../component/Header/Header";
import Placeholder from "../../component/Placeholder";
import TextInput from "../../component/TextInput";
import { Modal } from "../../component/Modal";

// related component
import PurchaseItem from "./PurchaseItem";
import ProductListModal from "./ProductListModal";
import PurchaseDetail from "./PurchaseDetailModal";

// type
import { States } from "../../types/general";
import { nominalFormat } from "../../utils/nominalFormat";
import { Spinner } from "../../component/Loading";
import { Product, TransactionProduct } from "../../types/product";

const TransactionDetail = () => {
  const dispatch = useDispatch();

  // redux state
  const router = useSelector((state: States) => state.router.location);
  const transactionDetail = useSelector(
    (state: States) => state.transaction.transactionDetail
  );
  const isLoading = useSelector((state: States) => state.transaction.isLoading);
  const showPurchaseDetailModal = useSelector(
    (state: States) => state.transaction.modalVisible
  );
  const showAddPriceModal = useSelector(
    (state: States) => state.catalog.visibleAddPriceModal
  );
  const patchPriceLoading = useSelector(
    (state: States) => state.catalog.patchPriceLoading
  );

  // state
  const [showAddSearchModal, setShowAddSearchModal] = useState(false);
  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<TransactionProduct | null>(null);
  const [productPrice, setProductPrice] = useState<number | undefined>(
    undefined
  );

  const setShowPurchaseDetailModal = (visible: boolean) => {
    dispatch(setShowTransactionModalDetail(visible));
  };

  const getTransactionID = useMemo(() => {
    const path = router.pathname;
    const splitPath = path.split("/");
    return splitPath[2];
  }, [router]);

  const handleNavCheckout = () => {
    dispatch(push(`/checkout/${getTransactionID}`));
  };

  const handleDeleteTransaction = () => {
    dispatch(
      deleteTransactionAsync.request({ transactionID: getTransactionID })
    );
  };

  const handleBack = () => {
    dispatch(push(`/transaction`));
  };

  const handleChangePrice = (price: string) => {
    if (price) {
      setProductPrice(+price);
      return;
    }

    setProductPrice(undefined);
  };

  const renderRightHeader = () => {
    return (
      <TouchableButton
        icon={TrashIcon}
        alt="delete"
        onClick={() => {
          handleDeleteTransaction();
        }}
        withFeedback
      />
    );
  };

  const handleSelectItem = (p: TransactionProduct | null) => {
    setShowPurchaseDetailModal(true);
    setSelectedProduct(p);
  };

  const renderTransactionState = useMemo(() => {
    if (isLoading) {
      return (
        <div className="transaction--loading">
          <Spinner />
        </div>
      );
    }

    return;
  }, [isLoading, transactionDetail]);

  const renderPurchaseItemList = useMemo(() => {
    return transactionDetail?.list_product.map((p, index) => {
      return (
        <PurchaseItem
          product={p}
          key={`transaction-item-${index}`}
          transactionID={getTransactionID}
          setSelectedExistingProduct={handleSelectItem}
        />
      );
    });
  }, [transactionDetail?.list_product]);

  const onVisibleAddPriceModal = (visible: boolean) => {
    dispatch(setShowChangePriceModal(visible));
  };

  const handleSelectProduct = (product: Product) => {
    const transactionProduct: TransactionProduct = {
      product_id: product.id,
      product_name: product.name,
      qty: 0,
      price: product.price,
      subtotal: product.price,
      unit: product.unit,
      discount_amount: 0,
      discount_type: "percentage",
    };

    setSelectedProduct(transactionProduct);
    setShowAddSearchModal(false);

    if (product.price || product.price === 0) {
      setShowPurchaseDetailModal(true);
      return;
    }

    onVisibleAddPriceModal(true);
    setProductPrice(undefined);
  };

  const onClickChangePrice = () => {
    if (productPrice && selectedProduct) {
      dispatch(
        patchProductPriceAsync.request({
          productID: selectedProduct?.product_id!,
          price: productPrice,
        })
      );
    }
  };

  const onCancelAddPrice = () => {
    setProductPrice(undefined);
    onVisibleAddPriceModal(false);
  };

  useEffect(() => {
    if (getTransactionID) {
      dispatch(
        getTransactionDetailAsync.request({
          transactionID: getTransactionID,
        })
      );
    }
  }, [getTransactionID]);

  const disabled = useMemo(() => {
    return isLoading || showPurchaseDetailModal;
  }, [isLoading, showPurchaseDetailModal]);

  return (
    <>
      {showPurchaseDetailModal ? (
        <PurchaseDetail
          transactionDetail={transactionDetail}
          product={selectedProduct}
          onCloseModal={() => {
            setShowPurchaseDetailModal(false);
            setProductPrice(undefined);
          }}
        />
      ) : null}

      {showAddSearchModal ? (
        <ProductListModal
          onCloseModal={() => {
            setShowAddSearchModal(false);
            setProductPrice(undefined);
          }}
          onSelectProduct={(p) => handleSelectProduct(p)}
          isVisible={showAddSearchModal && !showPurchaseDetailModal}
        />
      ) : null}

      {showAddPriceModal ? (
        <Modal
          isVisible={showAddPriceModal}
          title="Masukkan harga jual barang untuk melanjutkan"
          subtitle="Pastikan harga yang kamu masukkan adalah harga sebelum diskon. Setelah disimpan, hanya pemilik yang bisa mengubah harga."
          footer={
            <Flex flexDirection="column" alignItems="flex-end" mt={0}>
              <TextInput
                label="Harga jual"
                inputLabel="Rp"
                type="number"
                value={productPrice || ""}
                onChange={(price) => handleChangePrice(price)}
                fullWidth
              />

              <Flex mt={1}>
                <Box>
                  <Button
                    isLoading={patchPriceLoading}
                    title="Lanjut"
                    disabled={!productPrice && productPrice !== 0}
                    onClick={() => onClickChangePrice()}
                  />
                </Box>
                <Box ml="0">
                  <Button
                    isLoading={patchPriceLoading}
                    title="Batal"
                    secondary
                    onClick={() => onCancelAddPrice()}
                  />
                </Box>
              </Flex>
            </Flex>
          }
        />
      ) : null}

      <Layout>
        <TopBar
          title={`#${transactionDetail?.unique_id || ""}`}
          rightHeader={renderRightHeader}
          leftHeaderFunction={handleBack}
          isModal
        />
        <Wrapper p={2} pb={5}>
          <Body>
            {renderTransactionState ? (
              renderTransactionState
            ) : (
              <>
                <Header
                  title={`Rp${nominalFormat(
                    transactionDetail?.total_basket_size || 0
                  )}`}
                  disabled={disabled}
                />
                <Flex mt={2}>
                  <Button
                    fullWidth
                    title="Bayar"
                    disabled={
                      !(transactionDetail?.list_product || []).length ||
                      disabled
                    }
                    onClick={() => handleNavCheckout()}
                  />
                </Flex>

                <Flex
                  justifyContent="space-between"
                  borderBottom={
                    transactionDetail?.list_product.length ? "1px solid" : null
                  }
                  borderColor="blackLighter"
                  mt={3}
                  py={1}
                >
                  <Flex flexDirection="column">
                    <H3>
                      {nominalFormat(transactionDetail?.total_item || 0)} barang
                    </H3>
                    <Text variant="rg">
                      {nominalFormat(transactionDetail?.kinds_item || 0)} jenis
                      barang
                    </Text>
                  </Flex>
                  <Button
                    title="+ Barang"
                    onClick={() => setShowAddSearchModal(true)}
                    inverse
                    disabled={disabled}
                  />
                </Flex>
                <Box>
                  {transactionDetail?.list_product.length ? (
                    <>
                      {renderPurchaseItemList}
                      <Flex
                        borderBottom="1px solid"
                        borderColor="blackLighter"
                        backgroundColor="whiteDark"
                        p="0"
                      >
                        <Button
                          title="+ Barang"
                          onClick={() => setShowAddSearchModal(true)}
                          inverse
                          disabled={disabled}
                        />
                      </Flex>
                    </>
                  ) : (
                    <Placeholder
                      title="Belum ada barang"
                      subtitle="Tambahkan barang pada transaksi ini."
                      action={
                        <Button
                          title="+ Barang"
                          onClick={() => setShowAddSearchModal(true)}
                          inverse
                          disabled={disabled}
                        />
                      }
                    />
                  )}
                </Box>
              </>
            )}
          </Body>
        </Wrapper>
      </Layout>
    </>
  );
};

const Body = styled(BodyPage)`
  height: 100%;
  width: 100%;
  max-width: 1400px;

  .transaction--loading {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default TransactionDetail;
