import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

// redux action
import { getUserListAsync } from "../../reducers/employee/employee.action";
import {
  getDashboardSummaryAsync,
  setEmployeeFilter,
  setSelectedDate,
  setPeriodType,
} from "../../reducers/home/home.action";

// component
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout/Layout";
import EmployeeModalFilter from "./HomeEmployeeModalFilter";
import PeriodModalFilter from "./HomeModalPeriodFilter";
import TapInput from "../../component/TapInput";
import ProductItem from "./HomeProductItem";
import Summary from "./HomeSummary";
import Table from "../../component/Styles/Table";
import Chip from "../../component/Chip";
import { Wrapper } from "../../component/Styles";

// constant
import { device } from "../../constant/media";
import theme from "../../constant/styledTheme";

// type
import { States } from "../../types/general";
import { User } from "../../types/user";
import { nominalFormat } from "../../utils/nominalFormat";

const Home = () => {
  const dispatch = useDispatch();

  const summary = useSelector((state: States) => state.home.summary);
  const selectedDate = useSelector((state: States) => state.home.filterDate);
  const userList = useSelector((state: States) => state.employee.list);
  const userFilter = useSelector((state: States) => state.home.employeeFilter);

  const [visibleEmployeeFilter, setVisibleEmployeeFilter] = useState(false);
  const [visiblePeriodFilter, setVisiblePeriodFilter] = useState(false);

  useEffect(() => {
    const today = new Date();

    // set default date is today
    dispatch(setPeriodType("today"));
    dispatch(setSelectedDate({ startDate: today, endDate: today }));
    dispatch(setEmployeeFilter({}));

    dispatch(getDashboardSummaryAsync.request());
    dispatch(getUserListAsync.request());
  }, []);

  const renderProductList = useMemo(() => {
    if (!summary?.list_product.length) {
      return (
        <div className="center__container">
          <h3>Belum ada transaksi</h3>
        </div>
      );
    }

    return summary?.list_product.map((p, index) => {
      return <ProductItem product={p} key={`summary-product-item-${index}`} />;
    });
  }, [summary?.list_product]);

  const renderTableList = useMemo(() => {
    if (!summary?.list_product.length) {
      return (
        <tr>
          <td className="product-table__empty text" colSpan={4}>
            Belum ada transaksi
          </td>
        </tr>
      );
    }

    return summary?.list_product.map((p, index) => {
      return (
        <tr key={`summary-product-table-item-${index}`}>
          <td className="product-table__name text">{p.name}</td>
          <td className="product-table__qty text"> {nominalFormat(p.qty)} </td>
          <td className="product-table__unit text"> {p.unit} </td>
          <td className="product-table__subtotal text">
            Rp{nominalFormat(p.subtotal)}
          </td>
        </tr>
      );
    });
  }, [summary?.list_product]);

  const renderDateFilter = useMemo(() => {
    const today = moment().format("YYYY-MM-DD");

    if (moment(selectedDate.startDate).isSame(selectedDate.endDate)) {
      if (moment(selectedDate.startDate).format("YYYY-MM-DD") === today) {
        return "Hari ini";
      }

      return moment(selectedDate.startDate).format("DD MMM YYYY");
    }

    return `${moment(selectedDate.startDate).format("DD MMM YYYY")} - ${moment(
      selectedDate.endDate
    ).format("DD MMM YYYY")}`;
  }, [selectedDate]);

  const renderListFilterUser = useMemo(() => {
    const validUserFilter: User[] = [];

    // TODO: refactor get user, avoid loop
    Object.keys(userFilter).forEach((key) => {
      if (userFilter[key]) {
        const user = userList.find((u) => +u.id === +key);
        if (user) {
          validUserFilter.push(user!);
        }
      }
    });

    return validUserFilter.map((u, index) => {
      return (
        <Chip
          label={`@${u.name}`}
          key={`filter-user-item-${index}`}
          onClick={() => handleRemoveEmployeeFilter(u.id)}
        />
      );
    });
  }, [userList, userFilter]);

  const handleRemoveEmployeeFilter = (id: string) => {
    dispatch(setEmployeeFilter({ ...userFilter, [id]: false }));
    dispatch(getDashboardSummaryAsync.request());
  };

  const getProfileName = () => {
    return JSON.parse(localStorage.getItem("bio")!)?.name;
  };

  return (
    <Layout>
      <EmployeeModalFilter
        isVisible={visibleEmployeeFilter}
        onCloseModal={() => setVisibleEmployeeFilter(false)}
      />
      <PeriodModalFilter
        isVisible={visiblePeriodFilter}
        onCloseModal={() => setVisiblePeriodFilter(false)}
      />
      <TopBar companyHeader />
      <Wrapper p="2">
        <Body>
          <h2 className="home__subtitle">Hai {getProfileName()}</h2>
          <h1 className="home__subtitle">Ini data apotekmu</h1>
          <div className="home-filter__container">
            <div className="filter__period">
              <TapInput
                value={"Pilih Periode"}
                onTap={() => setVisiblePeriodFilter(true)}
              />
            </div>
            <div className="filter__employee">
              <TapInput
                value={"Pegawai"}
                onTap={() => setVisibleEmployeeFilter(true)}
              />
            </div>
          </div>
          <div className="home-detail__transaction">
            <h2 className="detail-today__title">{renderDateFilter}</h2>
            <div className="home-filter__user">{renderListFilterUser}</div>

            {!summary?.total_basket_size ? (
              <div className="center__container">
                <h2>Belum ada transaksi</h2>
              </div>
            ) : (
              <Summary summary={summary} />
            )}
          </div>
          <div className="home-detail__product">
            <h3 className="detail-product__title">Barang terjual</h3>
            <div className="detail-product__list">{renderProductList}</div>
            <div className="detail-product__table">
              <ExtendTable>
                <thead>
                  <tr>
                    <th className="product-table__name text"> Barang </th>
                    <th className="product-table__qty text"> Jumlah </th>
                    <th className="product-table__unit text"> Satuan </th>
                    <th className="product-table__subtotal text">
                      Total penjualan
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableList}</tbody>
              </ExtendTable>
            </div>
          </div>
        </Body>
      </Wrapper>
    </Layout>
  );
};

const ExtendTable = styled(Table)`
  width: 100%;

  .table-product {
    font-size: 1rem;
  }

  .product-table__name {
    width: calc(100% - 100px - 125px);
  }

  .product-table__qty {
    width: 93px;
  }

  .product-table__unit {
    width: 100px;
  }

  .product-table__empty {
    width: 100%;
    text-align: center;
  }

  .product-table__subtotal {
    width: 125px;
  }
`;

const Body = styled.div`
  padding: 2rem;
  width: 100%;
  max-width: 1400px;

  .home-detail__transaction {
    margin-top: 40px;
  }

  .home-detail__product {
    margin-top: 40px;
  }

  .home__subtitle {
    font-weight: 700;
  }

  .home__title {
    font-weight: 700;
  }

  .home-filter__container {
    display: flex;
    margin-top: 18px;

    .filter__period {
      flex: 6;
    }

    .filter__employee {
      margin-left: 5px;
      flex: 4;
    }
  }

  .home-filter__user {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > div {
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .detail-product__list {
    display: block;

    @media (min-width: ${theme.breakpoints[3]}) {
      display: none;
    }
  }

  .detail-product__table {
    display: none;

    @media (min-width: ${theme.breakpoints[3]}) {
      display: block;
      margin-top: 16px;
    }
  }

  .center__container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default Home;
