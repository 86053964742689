import React, { useMemo, useEffect, useState } from "react";
import { goBack } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// redux action
import { getInventoryDetailAsync } from "../../reducers/inventory/inventory.action";

// component
import { Wrapper, BodyPage, Box, Flex } from "../../component/Styles";
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout";
import InventoryDetailBatchList from "./InventoryDetailBatchList";
import { Spinner } from "../../component/Loading";
import Header from "../../component/Header/Header";
import Select from "../../component/Select";
import Pagination from "../../component/Pagination";

// type
import { States } from "../../types/general";

// utils
import { getURLPathID } from "../../utils/getURLPathID";

// data
import { sortingOptions } from "./data/batchSortOption";

const InventoryBatchHistory = () => {
  const dispatch = useDispatch();

  const location = useSelector((state: States) => state.router.location);
  const inventoryDetail = useSelector(
    (state: States) => state.inventory.detail
  );
  const isLoading = useSelector((state: States) => state.inventory.isLoading);
  const currentPage = useSelector(
    (state: States) => state.inventory.batchPageIndex
  );

  const [activeFilter, setActiveFilter] = useState({
    sorted_by: "batch_no",
    order_by: "asc",
  });

  const getInventoryID = useMemo(() => {
    return getURLPathID(location.pathname, 2);
  }, [location]);

  const handleChangeActiveFilter = (value: any) => {
    dispatch(
      getInventoryDetailAsync.request({
        productID: getInventoryID,
        sorted_by: value.sorted_by,
        order_by: value.order_by,
        history: true,
      })
    );
    setActiveFilter(value);
  };

  const getSelectedOption = useMemo(() => {
    const selectedOption = sortingOptions.find(
      (o) =>
        o.value.order_by === activeFilter.order_by &&
        o.value.sorted_by === activeFilter.sorted_by
    );

    return selectedOption;
  }, [activeFilter]);

  const handleChangePage = (pageIndex: number) => {
    getInventoryDetailAsync.request({
      productID: getInventoryID,
      sorted_by: activeFilter.sorted_by,
      order_by: activeFilter.order_by,
      page: pageIndex,
    });
  };

  useEffect(() => {
    if (getInventoryID && !isNaN(+getInventoryID)) {
      dispatch(
        getInventoryDetailAsync.request({
          productID: getInventoryID,
          history: true,
        })
      );
    }
  }, [getInventoryID]);

  return (
    <Layout>
      <TopBar leftHeaderType="back" title={inventoryDetail?.name || ""} />
      <Wrapper p="2">
        <Header
          title="Riwayat batch"
          backTitle={inventoryDetail?.name}
          backFunc={() => dispatch(goBack())}
        />
        <BodyPage>
          {isLoading ? (
            <Box p={3} mt={2}>
              <Spinner />
            </Box>
          ) : (
            <>
              <Box display={["block", "block", "none", "none"]} mt={2}>
                <Select
                  options={sortingOptions}
                  selectedOption={getSelectedOption}
                  onSelect={(option) => handleChangeActiveFilter(option.value)}
                  width={160}
                />
              </Box>

              <Box mt={1} borderTop="1px solid" borderColor="blackLighter">
                <InventoryDetailBatchList
                  list={inventoryDetail?.batch_list || []}
                  activeFilter={activeFilter}
                  setActiveFilter={handleChangeActiveFilter}
                />
              </Box>
            </>
          )}

          {!isLoading && !inventoryDetail?.total_batch ? null : (
            <Flex justifyContent="center">
              <Pagination
                currentPage={currentPage}
                onChangePage={handleChangePage}
                totalPage={Math.ceil((inventoryDetail?.total_batch || 1) / 50)}
                isLoading={isLoading}
              />
            </Flex>
          )}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default InventoryBatchHistory;
