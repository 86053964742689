import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// constant
import { device } from "../../constant/media";
import theme from "../../constant/styledTheme";

// type
import { general } from "../../types/index";

// redux action
import { setShowSidebar } from "../../reducers/navigation/navigation.action";
import { handleLogout } from "../../reducers/auth/auth.action";

export type SidebarItemProps = {
  item: general.Option;
  isActive: boolean;
};

const SidebarItem = (props: SidebarItemProps) => {
  const dispatch = useDispatch();
  const { item, isActive } = props;

  const handleNavigate = (route: string) => {
    if (route === "logout") {
      dispatch(handleLogout());
      return;
    }

    dispatch(setShowSidebar(false));
    dispatch(push(`/${route}`));
  };

  return (
    <Item onClick={(e) => handleNavigate(item.value)} isActive={isActive}>
      {item.label}
    </Item>
  );
};

const Item = styled.div<{ isActive: boolean }>`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ isActive }) => (isActive ? theme.colors.blueDark : "#ffffff")};
  background: ${({ isActive }) =>
    isActive ? "#ffffff" : theme.colors.blueDark};
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;

  :not(:first-child) {
    margin-top: 0.5rem;
  }

  @media ${device.md} {
    font-size: 1rem;
  }
`;

export default SidebarItem;
