import React from "react";
import styled from "styled-components/macro";

// component
import { H1 } from "../../component/Styles";

const LazyLoading = () => {
  return (
    <Container>
      <H1>Memuat...</H1>;
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;

  justify-content: center;
  align-items: center;
  justify-content: center;
`;

export default LazyLoading;
