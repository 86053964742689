import { createReducer, ActionType } from "typesafe-actions";
import moment from "moment";

// actions
import * as actions from "./history.action";

// actions types
import * as types from "./history.actionTypes";

// type
import { transaction, history } from "../../types/index";
export type HistoryActions = ActionType<typeof actions>;

const today = new Date();

export type HistoryState = {
  filterDate: history.SelectedDate;
  filterPeriod: history.PeriodType | null;
  detail: transaction.HistoryDetail | null;
  list: transaction.History[];
  groupedByDate: { [key: string]: transaction.History[] };
  isLoading: boolean;
  isEmpty: boolean;
};

const initialState: HistoryState = {
  filterDate: { startDate: today, endDate: today },
  filterPeriod: "today",
  detail: null,
  list: [],
  groupedByDate: {},
  isLoading: false,
  isEmpty: false,
};

export const historyReducer = createReducer<HistoryState, HistoryActions>(
  initialState
)
  // list transaction history
  .handleAction(
    actions.getTransactionHistoryAsync.request,
    (
      state: HistoryState,
      action: ActionType<typeof actions.getTransactionHistoryAsync.request>
    ) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.getTransactionHistoryAsync.success,
    (
      state: HistoryState,
      action: ActionType<typeof actions.getTransactionHistoryAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      list: action.payload.data,
      groupedByDate: action.payload.groupedData,
      isEmpty: action.payload.isEmpty
    })
  )
  .handleAction(
    actions.getTransactionHistoryAsync.failure,
    (state: HistoryState) => ({
      ...state,
      isLoading: false,
      list: [],
      groupedByDate: {},
    })
  )

  // transaction history detail
  .handleAction(
    actions.getTransactionHistoryDetailAsync.request,
    (state: HistoryState) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.getTransactionHistoryDetailAsync.success,
    (
      state: HistoryState,
      action: ActionType<
        typeof actions.getTransactionHistoryDetailAsync.success
      >
    ) => ({
      ...state,
      isLoading: false,
      detail: action.payload.data,
    })
  )
  .handleAction(
    actions.getTransactionHistoryDetailAsync.failure,
    (state: HistoryState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    types.SET_SELECTED_DATE as any,
    (
      state: HistoryState,
      action: ActionType<typeof actions.setSelectedDate>
    ) => ({ ...state, filterDate: action.payload.date })
  )
  .handleAction(
    types.SET_PERIOD_TYPE as any,
    (
      state: HistoryState,
      action: ActionType<typeof actions.setPeriodType>
    ) => ({ ...state, filterPeriod: action.payload.period })
  )
  .handleAction(
    types.CLEAR_HISTORY_LIST as any,
    (state: HistoryState) => ({...state, list: [], groupedByDate:  {} })
  )
