import axios from '../../../config/axios'

// constant
import { BASE_URL_BETA } from '../../../constant/api'

// utils
import localStorageService from '../../../utils/localStorageService'

export type CheckoutProps = { transactionID: string,  customerPaid: number, customerChange: number }

export const handleCheckoutAsync = async(data:CheckoutProps ) => {
    try {
        const {  transactionID, customerPaid, customerChange} = data
        const companyID = localStorageService.getCompanyID()

        if (!companyID) { 
            throw new Error("Company ID not found")
        }

        const url = `${BASE_URL_BETA}/company/${companyID}/checkout/${transactionID}`;
        const payload = {
            customer_paid: +customerPaid,
            customer_change: customerChange 
        }
     
        const response = await (axios.put(url,  payload ))
     
        if (response && response.status === 200) {
            return 
        } else {
            throw new Error("Transaction failed");
        }
    } catch (err) { 
        throw err(err)
    }
}

