import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { space, SpaceProps } from "styled-system";

// component
import { Spinner } from "../../component/Loading";

// constant
import { colors } from "../../constant/theme";
import theme from "../../constant/styledTheme";

export type ButtonProps = {
  disabled?: boolean;
  title?: string;
  secondary?: boolean;
  danger?: boolean;
  icon?: any;
  inverse?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  noBackground?: boolean;
  lightIcon?: boolean;
  rightIcon?: any;
  defaultIcon?: boolean;
  type?: "submit" | "default";
  onClick?: () => void;
};

const ButtonComponent = (props: ButtonProps) => {
  const {
    defaultIcon,
    danger,
    disabled,
    title,
    secondary,
    icon,
    inverse,
    fullWidth,
    isLoading,
    rightIcon,
    type,
    lightIcon,
    noBackground,
    onClick,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (type !== "submit") {
      e.stopPropagation();
      e.preventDefault();
    }

    if (onClick && !disabled) {
      onClick();
    }

    return;
  };

  const renderIcon = () => {
    if (icon) {
      return <img src={icon} alt="button-icon" className="icon-left" />;
    }
    return;
  };

  const renderRightIcon = () => {
    if (rightIcon) {
      return <img src={rightIcon} alt="button-icon" className="icon-right" />;
    }
    return;
  };

  const renderBoxShadow = useMemo(() => {
    switch (true) {
      case secondary:
        return "0px 1px 2px rgba(51, 51, 51, 0.15), inset 0px 1px 2px rgba(255, 255, 255, 0.15), inset 0px -1px 2px rgba(51, 51, 51, 0.15);";
      default:
        return "0px 1px 2px rgba(51, 51, 51, 0.15), inset 0px 1px 2px rgba(255, 255, 255, 0.15), inset 0px -1px 2px rgba(51, 51, 51, 0.15);";
    }
  }, [secondary]);

  const renderBorderColor = useMemo(() => {
    switch (true) {
      case disabled:
        return colors.secondary1;
      case secondary:
        return theme.colors.blackLighter;
      case danger && inverse:
        return colors.danger1;
      case inverse:
        return theme.colors.blueDark;
      case isLoading:
        // 80 is hexa number for opacity
        return colors.primary1 + "80";
      default:
        return colors.primary1;
    }
  }, [secondary, disabled, danger, inverse, isLoading]);

  const renderBackgroundColor = useMemo(() => {
    switch (true) {
      case disabled:
        return colors.secondary2;
      case secondary && isLoading:
        return colors.secondary2 + "80";
      case secondary:
        return colors.secondary2;
      case danger && inverse:
        return "#fff";
      case isLoading:
        return colors.primary1 + "80";
      case inverse:
        return "#fff";
      default:
        return colors.primary1;
    }
  }, [secondary, disabled, danger, inverse, isLoading]);

  const renderTextColor = useMemo(() => {
    switch (true) {
      case disabled:
        return colors.secondary1;
      case secondary:
        return colors.secondary;
      case danger && inverse:
        return colors.danger1;
      case inverse:
        return theme.colors.blueDark;
      default:
        return "#fff";
    }
  }, [secondary, disabled, danger, inverse]);

  const isLightIcon = useMemo(() => {
    switch (true) {
      case secondary:
        return false;
      case inverse:
        return false;
      default:
        return true;
    }
  }, [secondary, inverse]);

  const filterIcon = useMemo(() => {
    if (defaultIcon) {
      return;
    }

    if (isLightIcon) {
      return "brightness(100)";
    }

    if (disabled) {
      return "invert(84%) sepia(0%) saturate(1%) hue-rotate(193deg) brightness(100%) contrast(88%);";
    }

    if (inverse) {
      return "invert(27%) sepia(53%) saturate(2353%) hue-rotate(162deg) brightness(87%) contrast(101%);";
    }

    return;
  }, [disabled, inverse, secondary, defaultIcon]);

  return (
    <Button
      onClick={(e) => handleClick(e)}
      secondary={secondary}
      fullWidth={fullWidth}
      textColor={renderTextColor}
      background={renderBackgroundColor}
      borderColor={renderBorderColor}
      boxShadow={renderBoxShadow}
      disabled={isLoading || disabled}
      isLightIcon={isLightIcon}
      hasTitle={!!title}
      iconColor={filterIcon}
    >
      {isLoading ? (
        <div className="button--loading">
          <Spinner light />
        </div>
      ) : (
        <>
          {renderIcon()} {title} {renderRightIcon()}
        </>
      )}
    </Button>
  );
};

export type ButtonStyleProps = {
  disabled?: boolean;
  textColor: string;
  secondary?: boolean;
  fullWidth?: boolean;
  background: string;
  borderColor: string;
  boxShadow: string;
  isLightIcon: boolean;
  hasTitle: boolean;
  iconColor: string | undefined;
};

const Button = styled.button<ButtonStyleProps & SpaceProps>`
  ${space};
  cursor: pointer;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  color: ${({ textColor }) => textColor};
  max-height: 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  box-shadow: ${({ boxShadow }) => boxShadow};
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ background }) => background};
  border-radius: 4px;
  height: 36px;
  padding: 0px 1rem;

  white-space: nowrap;
  outline: none;

  .icon-left {
    filter: ${({ iconColor }) => (iconColor ? iconColor : "none")};
  }

  .icon-right {
    filter: ${({ iconColor }) => (iconColor ? iconColor : "none")};
  }

  :focus {
    outline: 2px solid ${colors.primary};
    -moz-outline-radius: 5px;
  }

  .button--loading {
    display: flex;
    justify-content: center;

    > .loading__title {
      margin-left: 10px;
    }
  }

  .button__children {
    width: 100%;
  }
`;

export default ButtonComponent;
