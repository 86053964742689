import { action, createAsyncAction } from "typesafe-actions";

// action types
import * as types from "./invoice.actionTypes";

// type
import { Invoice, InvoiceDetail, InvoiceResponse } from "../../types/invoice";
import { QueryParams } from '../../types/general'

export const getListInvoiceAsync = createAsyncAction(
  types.GET_INVOICE_LIST_REQUEST,
  types.GET_INVOICE_LIST_SUCCESS,
  types.GET_INVOICE_LIST_FAILURE
)<QueryParams, { data: InvoiceResponse[], totalData: number }, undefined>();

export const getInvoiceDetailAsync = createAsyncAction(
  types.GET_INVOICE_DETAIL_REQUEST,
  types.GET_INVOICE_DETAIL_SUCCESS,
  types.GET_INVOICE_DETAIL_FAILURE
)<{ invoiceID: string }, InvoiceDetail, undefined>();

export const addInvoiceAsync = createAsyncAction(
  types.ADD_INVOICE_REQUEST,
  types.ADD_INVOICE_SUCCESS,
  types.ADD_INVOICE_FAILURE
)<Partial<Invoice>, { invoiceID: string }, undefined>();

export const updateInvoiceAsync = createAsyncAction(
  types.UPDATE_INVOICE_REQUEST,
  types.UPDATE_INVOICE_SUCCESS,
  types.UPDATE_INVOICE_FAILURE
)<Partial<Invoice> & {invoiceID: number }, undefined, undefined>();

export const deleteInvoiceAsync = createAsyncAction(
  types.DELETE_INVOICE_REQUEST,
  types.DELETE_INVOICE_SUCCESS,
  types.DELETE_INVOICE_FAILURE
)<{ invoiceID: number, messageReason: string}, undefined, undefined>();

