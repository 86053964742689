import Raven from 'raven-js';
import localStorageService from './localStorageService'

const errorReporter = (err: any, action?: string, data?: any) => {
  const companyID = localStorageService.getCompanyID();

  const payload = data ? {...data, companyID} : {companyID}

    Raven.captureException(err, { // send to crash reporting tool
        extra: {
          action: action || '',
          data: payload 
        }
    });
}

export default errorReporter