import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import styled from "styled-components/macro";

// asset
import { AddIcon, DeleteIcon } from "../../../asset/icon";

// component
import { Button, TouchableButton } from "../../../component/Button";
import Select from "../../../component/Select";
import { Flex, Text, Table } from "../../../component/Styles";
import TextInput from "../../../component/TextInput";

// related component
import AddPackageModal from "../../Catalog/CatalogAddPackageSharingModal";

// type
import { InventoryBatch } from "../../../types/inventory";
import { ProductDetail } from "../../../types/product";
import { Stock } from "./type";
import { States } from "../../../types/general";

// redux state
import { visiblePackageModal } from "../../../reducers/package/package.action";
import { Package } from "../../../types/package";

export type Props = {
  product: ProductDetail | null;
  inventoryBatch: InventoryBatch | null;
  stocks: Stock[];
  setStocks: (stocks: Stock[]) => void;
};

export type Option = {
  label: string;
  value: Package;
  status?: null | "default";
};

const InventoryUpdateStockPackage = (props: Props) => {
  const dispatch = useDispatch();

  const { product, inventoryBatch, stocks, setStocks } = props;

  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const showAddPackageModal = useSelector(
    (state: States) => state.package.visiblePackageModal
  );

  const getPackageOptions = useMemo(() => {
    const packages = (product?.package || []).map((p) => {
      return {
        label: p.unit_name,
        value: p,
        status: null,
      };
    });

    packages.unshift({
      label: product?.unit!,
      value: {
        id: "-1",
        product_id: product?.id!,
        unit_name: product?.unit!,
        unit_code: product?.unit_code!,
        conversion_qty: 1,
        conversion_unit: product?.unit!,
        smallest_qty: 1,
      },
      status: "default" as any,
    });

    if (!stocks.length) {
      setStocks([
        {
          stock: inventoryBatch?.batch_qty!,
          selectedUnit: product?.unit_code!,
          smallestQty: 1,
        },
      ]);
    }

    return packages;
  }, [product]);

  const handleAddPackage = () => {
    if (!getAvailablePackageOption[0]) {
      return;
    }

    setStocks([
      ...stocks,
      {
        stock: 0,
        selectedUnit: getAvailablePackageOption[0].value.unit_code,
        smallestQty: getAvailablePackageOption[0].value.smallest_qty,
      },
    ]);
  };

  const getUsedKeyPackageOption = useMemo(() => {
    let keys: { [key: string]: boolean } = {};

    stocks.forEach((s) => {
      keys[s.selectedUnit] = true;
    });

    return keys;
  }, [stocks, getPackageOptions]);

  const getAvailablePackageOption = useMemo(() => {
    return getPackageOptions.filter((o) => {
      if (!getUsedKeyPackageOption[o.value.unit_code]) {
        return true;
      }
      return false;
    });
  }, [stocks, getPackageOptions, product]);

  const getFilteredOptions = (stock: Stock) => {
    return getPackageOptions.filter((o) => {
      if (
        !getUsedKeyPackageOption[o.value.unit_code] ||
        stock.selectedUnit === o.value.unit_code
      ) {
        return true;
      }
      return false;
    });
  };

  const handleVisibleAddPackageModal = (
    visible: boolean,
    index: number | null
  ) => {
    if (index || index === 0) {
      setFocusedIndex(index);
    }

    dispatch(visiblePackageModal(visible));
  };

  const handleChangePackage = (o: Option, i: number) => {
    const stock = stocks[i];
    stock.selectedUnit = o.value.unit_code;
    stock.smallestQty = o.value.smallest_qty;
    stocks.splice(i, 1, stock);

    setStocks(stocks.slice());
  };

  const handleChangeUnitValue = debounce((val: string, i: number) => {
    const stock = stocks[i];
    stock.stock = +val || undefined;

    stocks.splice(i, 1, stock);
    setStocks(stocks.slice());
  }, 800);

  const handleDeleteRow = (i: number) => {
    const clonedStocks = stocks.slice();
    clonedStocks.splice(i, 1);
    setStocks(clonedStocks);
  };

  const handleNewCreatedPackage = (option: Option) => {
    if (focusedIndex || focusedIndex === 0) {
      const list = stocks.slice();
      list[focusedIndex].selectedUnit = option.value.unit_code;
      list[focusedIndex].smallestQty = option.value.smallest_qty;

      setStocks(list);
    }
  };

  const renderTable = useMemo(() => {
    const renderTableBody = () => {
      return stocks.map((s, index) => {
        return (
          <tr key={`update-stock-input-${index}`}>
            <td className="table-stock-update__qty">
              <TextInput
                value={s.stock}
                type="number"
                onChange={(val) => handleChangeUnitValue(val, index)}
                textAlign="center"
                allowNegative={false}
              />
            </td>
            <td className="table-stock-update__package">
              <Select
                options={getFilteredOptions(s)}
                selectedOption={getPackageOptions.find(
                  (o) => o.value.unit_code === s.selectedUnit
                )}
                onSelect={(option) => handleChangePackage(option, index)}
                createAction={() => handleVisibleAddPackageModal(true, index)}
              />
            </td>
            <td className="table-stock-update__action">
              <TouchableButton
                icon={DeleteIcon}
                alt="delete"
                onClick={() => handleDeleteRow(index)}
              />
            </td>
          </tr>
        );
      });
    };

    return (
      <ExtendTable width="100%">
        <thead>
          <tr>
            <th>
              <Text fontSize="2" lineHeight="2">
                Stok
              </Text>
            </th>
            <th colSpan={2}>
              <Text fontSize="2" lineHeight="2">
                Satuan/kemasan
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </ExtendTable>
    );
  }, [stocks, getUsedKeyPackageOption]);

  return (
    <>
      {showAddPackageModal ? (
        <AddPackageModal
          unitOptions={getPackageOptions}
          packages={product?.package || []}
          selectedCatalog={product}
          onCloseModal={() => handleVisibleAddPackageModal(false, null)}
          onSelectNewPackage={handleNewCreatedPackage}
        />
      ) : null}
      <Flex width="100%" mt={3} flexDirection="column">
        {renderTable}
        <Flex
          backgroundColor="whiteDark"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="blackLighter"
          p={0}
        >
          <Button
            title="Kemasan"
            inverse
            icon={AddIcon}
            onClick={handleAddPackage}
            disabled={getAvailablePackageOption.length < 1}
          />
        </Flex>
      </Flex>
    </>
  );
};

const ExtendTable = styled(Table)`
  .table-stock-update__qty {
    min-width: 120px;
  }

  .table-stock-update__package {
    min-width: 120px;
    max-width: calc(100% - 120px - 40px);
  }

  .table-stock-update__action {
    width: 40px;
  }
`;

export default InventoryUpdateStockPackage;
