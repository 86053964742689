import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './invoice.action'

// actions types
import * as types from './invoice.actionTypes'

// type
import { Invoice, InvoiceDetail, InvoiceResponse } from '../../types/invoice'
export type InvoiceActions = ActionType<typeof actions>;

export type InvoiceState = {
    list: InvoiceResponse[],
    detail: InvoiceDetail | null,
    isLoading: boolean;
    totalData: number;
    pageIndex: number
}

const initialState: InvoiceState = {
    list: [],
    detail: null,
    isLoading: false,
    totalData: 0,
    pageIndex: 1
}

export const invoiceReducer = createReducer<InvoiceState, InvoiceActions>(initialState)
    .handleAction(actions.addInvoiceAsync.request, (state: InvoiceState) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.addInvoiceAsync.success, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.addInvoiceAsync.failure, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.updateInvoiceAsync.request, (state: InvoiceState) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.updateInvoiceAsync.success, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.updateInvoiceAsync.failure, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.getListInvoiceAsync.request, (state: InvoiceState, action: ActionType<typeof actions.getListInvoiceAsync.request>) => ({ 
        ...state,
        isLoading: true,
        totalData: 0,
        pageIndex: action.payload?.page || 1
    }))
    .handleAction(actions.getListInvoiceAsync.success, (state: InvoiceState, action: ActionType<typeof actions.getListInvoiceAsync.success>) => ({ 
        ...state,
        isLoading: false,
        list: action.payload.data,
        totalData: action.payload.totalData || 0,
       
    }))
    .handleAction(actions.getListInvoiceAsync.failure, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.getInvoiceDetailAsync.request, (state: InvoiceState) => ({ 
        ...state,
        detail: null, 
        isLoading: true,
        
    }))
    .handleAction(actions.getInvoiceDetailAsync.success, (state: InvoiceState, action: ActionType<typeof actions.getInvoiceDetailAsync.success>) => ({ 
        ...state,
        isLoading: false,
        detail: action.payload,
    }))
    .handleAction(actions.getInvoiceDetailAsync.failure, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
        detail: null
    }))
    .handleAction(actions.deleteInvoiceAsync.request, (state: InvoiceState) => ({ 
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.deleteInvoiceAsync.success, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.deleteInvoiceAsync.failure, (state: InvoiceState) => ({ 
        ...state,
        isLoading: false,
    }))






