export const GET_BATCH_NON_EMPTY_REQUEST = '@stock/GET_BATCH_NON_EMPTY_REQUEST';
export const GET_BATCH_NON_EMPTY_SUCCESS = '@stock/GET_BATCH_NON_EMPTY_SUCCESS';
export const GET_BATCH_NON_EMPTY_FAILURE = '@stock/GET_BATCH_NON_EMPTY_FAILURE';

export const GET_BATCH_LIST_REQUEST = '@stock/GET_BATCH_LIST_REQUEST';
export const GET_BATCH_LIST_SUCCESS = '@stock/GET_BATCH_LIST_SUCCESS';
export const GET_BATCH_LIST_FAILURE = '@stock/GET_BATCH_LIST_FAILURE';

export const GET_UPDATE_STOCK_DETAIL_REQUEST = '@stock/GET_UPDATE_STOCK_DETAIL_REQUEST';
export const GET_UPDATE_STOCK_DETAIL_SUCCESS = '@stock/GET_UPDATE_STOCK_DETAIL_SUCCESS';
export const GET_UPDATE_STOCK_DETAIL_FAILURE = '@stock/GET_UPDATE_STOCK_DETAIL_FAILURE';

export const UPDATE_STOCKS_REQUEST = '@stock/UPDATE_STOCKS_REQUEST';
export const UPDATE_STOCKS_SUCCESS = '@stock/UPDATE_STOCKS_SUCCESS';
export const UPDATE_STOCKS_FAILURE = '@stock/UPDATE_STOCKS_FAILURE';

export const SET_SHOW_UPDATE_STOCK_MODAL = '@stock/SET_SHOW_UPDATE_STOCK_MODAL'

