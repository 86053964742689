import { ExtendProductInvoice } from '../../../types/product'

// utils
import { nominalFormat } from '../../../utils/nominalFormat'

export const getHPP = (p: ExtendProductInvoice, includeVAT: boolean) => {
    const basePrice = p.price_buy || 0;
    const vat = basePrice * 0.1;

    if (!includeVAT) {
      let total = basePrice + vat;
      return total;
    }

    return basePrice;
  };

export const getMargin = (p: ExtendProductInvoice, includeVAT: boolean) => {
    let discount = p.discount_amount;
    const total_buy = getHPP(p, includeVAT) * p.package_buy_detail?.smallest_qty! * p.qty;

    if (p.discount_type_buy === "percentage") {
      discount = +((p.discount_amount / 100) * total_buy);
    }

    const priceBuyDisc =
      (total_buy - discount) /
      p.qty /
      (p.package_buy_detail?.smallest_qty || 1);

    if (priceBuyDisc === 0) {
      return "-";
    }

    const result = ((p.price_sell - priceBuyDisc) / priceBuyDisc) * 100;
    return nominalFormat(+result.toFixed(2));
  };

