import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components/macro";

// asset
import { ChevronDown } from "../../asset/icon";

// component
import { Box, Table, Text, Flex, RotatedBox } from "../../component/Styles";

// related component
import InventoryBatchItem from "./InventoryBatchItem";

// type
import { InventoryBatch } from "../../types/inventory";

// utils
import { getCurrentOrderList } from "../../utils/getCurrentOrderList";
import { getValidDate } from "../../utils/getValidDate";
import { renderDate } from "./utils/renderDate";

export type Props = {
  list: InventoryBatch[];
  activeFilter: { sorted_by: string; order_by: string };
  isEdit?: boolean;
  isNewBatch?: boolean;
  isModal?: boolean;
  onClickEdit?: (stock: InventoryBatch) => void;
  setActiveFilter: (value: { sorted_by: string; order_by: string }) => void;
};

const InventoryDetailBatchList = (props: Props) => {
  const {
    list,
    activeFilter,
    isEdit,
    isNewBatch,
    isModal,
    setActiveFilter,
    onClickEdit,
  } = props;

  const handleChangeSorting = (sortOption: {
    sorted_by: string;
    order_by: string;
  }) => {
    if (activeFilter.sorted_by === sortOption.sorted_by) {
      if (sortOption.order_by === "asc") {
        setActiveFilter({ sorted_by: sortOption.sorted_by, order_by: "desc" });
        return;
      }

      setActiveFilter({ sorted_by: sortOption.sorted_by, order_by: "asc" });
      return;
    }

    setActiveFilter({
      ...sortOption,
      order_by: getCurrentOrderList(sortOption.order_by),
    });
  };

  const getArrowDirection = useMemo(() => {
    const keyDirection = {
      batch_no: { arrow: "up", order_by: "asc" },
      stock_qty: { arrow: "up", order_by: "asc" },
      ED: { arrow: "up", order_by: "asc" },
      receipt_date: { arrow: "up", order_by: "asc" },
    };

    const getDirection = (orderBy: string) => {
      if (orderBy === "asc") {
        return "up";
      }
      return "down";
    };

    switch (true) {
      case activeFilter.sorted_by === "batch_no":
        keyDirection.batch_no = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "stock_qty":
        keyDirection.stock_qty = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "ED":
        keyDirection.ED = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
      case activeFilter.sorted_by === "receipt_date":
        keyDirection.receipt_date = {
          arrow: getDirection(activeFilter.order_by),
          order_by: activeFilter.order_by,
        };
        break;
    }

    return keyDirection;
  }, [activeFilter]);

  const renderTable = useMemo(() => {
    const renderTableBody = () => {
      return list.map((i, index) => {
        return (
          <tr key={`table-batch-item-${index}`}>
            <td className="table-batch__date">
              <Text fontSize="2" lineHeight="2">
                {getValidDate(i.date_purchase)
                  ? moment(getValidDate(i.date_purchase)).format("DD MMM YYYY")
                  : "-"}
              </Text>
            </td>
            <td className="table-batch__batch">
              <Text fontSize="2" lineHeight="2">
                {i.batch_no}
              </Text>
            </td>
            <td className="table-batch__stock">
              <Text fontSize="2" lineHeight="2">
                {i.batch_qty}
              </Text>
            </td>
            <td className="table-batch__unit">
              <Text fontSize="2" lineHeight="2">
                {i.unit}
              </Text>
            </td>
            <td className="table-batch__ed">
              <Text fontSize="2" lineHeight="2">
                {renderDate(i.ED)}
              </Text>
            </td>
          </tr>
        );
      });
    };

    return (
      <ExtendTable width="100%">
        <thead>
          <tr>
            <th className="table-batch__date">
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "receipt_date",
                    order_by: getArrowDirection.receipt_date.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "receipt_date"
                      ? "blueDark"
                      : "black"
                  }
                >
                  Tanggal diterima
                </Text>
                <RotatedBox
                  direction={getArrowDirection.receipt_date.arrow as any}
                >
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className="table-batch__batch">
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "batch_no",
                    order_by: getArrowDirection.batch_no.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "batch_no" ? "blueDark" : "black"
                  }
                >
                  Batch
                </Text>
                <RotatedBox direction={getArrowDirection.batch_no.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className="table-batch__stock">
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "stock_qty",
                    order_by: getArrowDirection.stock_qty.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter.sorted_by === "stock_qty"
                      ? "blueDark"
                      : "black"
                  }
                >
                  Stok
                </Text>
                <RotatedBox
                  direction={getArrowDirection.stock_qty.arrow as any}
                >
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className="table-batch__unit">
              <Text fontSize="2" lineHeight="2">
                Satuan
              </Text>
            </th>
            <th className="table-batch__ed">
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "ED",
                    order_by: getArrowDirection.ED.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={activeFilter.sorted_by === "ED" ? "blueDark" : "black"}
                >
                  ED
                </Text>
                <RotatedBox direction={getArrowDirection.ED.arrow as any}>
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </ExtendTable>
    );
  }, [list]);

  const renderList = useMemo(() => {
    return list.map((inventory, index) => {
      return (
        <InventoryBatchItem
          inventory={isNewBatch ? { ...inventory, index } : inventory}
          key={`inventory-batch-detail-${index}`}
          isEdit={isEdit}
          onClick={(stock) => onClickEdit!(stock!)}
        />
      );
    });
  }, [list]);

  return (
    <>
      <Box display={isModal ? [] : ["block", "block", "none"]}>
        {renderList}
      </Box>

      {/* hide if show as modal */}
      {isModal ? null : (
        <Box display={["none", "none", "block"]}>{renderTable}</Box>
      )}
    </>
  );
};

const ExtendTable = styled(Table)`
  tbody > td {
    cursor: pointer;
  }

  .table-batch__date {
    width: 160px;
  }

  .table-batch__batch {
    width: calc(100% - 80px - 160px - 160px - 160px);
    min-width: 200px;
  }

  .table-batch__stock {
    width: 80px;
  }

  .table-batch__unit {
    width: 160px;
  }

  .table-batch__ed {
    width: 160px;
  }
`;

export default InventoryDetailBatchList;
