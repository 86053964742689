import { action, createAsyncAction } from 'typesafe-actions'
import { responsesAreSame } from 'workbox-broadcast-update'

// action types
import * as Types from './user.actionTypes'




export const handleLogout = () => action(Types.USER_LOGOUT)

export const handleSuccessChangePassword = () => action(Types.USER_SUCCESS_CHANGE_PASSWORD)

export const handleSuccessRegisterCompany = () => action(Types.USER_SUCCESS_REGISTER_COMPANY)

export const handleSuccessRegister = () => action(Types.USER_SUCCESS_REGISTER);

export const handleFailedRegister = () => action(Types.USER_FAILED_REGISTER);

export const handleLogin = () => action(Types.USER_SUCCESS_LOGIN)

export const handleChangeInputUser = (el: any) => action(Types.USER_CHANGE_INPUT_USER, { el })

export const handleGetCompanyList = (list: any) => action(Types.USER_GET_COMPANY_LIST, {list});
