import styled from 'styled-components/macro'

// component
import Box from './Box'

export type Direction = 'up' | 'down' | 'flip-x' 

const calculateRotation = (direction: Direction ) => {
    switch(direction){
        case 'up':
            return "rotate(0deg)"
        case 'down': 
            return "rotate(180deg)"
        case 'flip-x':
            return "rotate(180deg)"
        default:
            return "rotate(0deg)"
    }
}

export default styled(Box)<{direction: Direction }>`
    transform: ${({ direction }) => calculateRotation(direction)};
`