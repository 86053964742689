import styled from "styled-components/macro";

const Overlay = styled.div<{ isVisible: boolean }>`
 display: ${({ isVisible }) => (isVisible ? "block" : "none")};
background: rgb(0,0,0,0.5);
height: 100vh;
width: 100%;
position: absolute;
top: 0;
left: 0;
z-index: 99;
`;

export default Overlay;
