import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import styled from "styled-components/macro";

// redux action
import {
  getTransactionHistoryAsync,
  clearListHistory,
} from "../../reducers/history/history.action";

// asset
import ArrowUp from "../../asset/img/arrow-direction-up.svg";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import SearchBar from "../../component/SearchBar";
import HistoryItem from "./HistoryItem";
import { Spinner } from "../../component/Loading";
import { Wrapper, BodyPage } from "../../component/Styles";

// type
import { general, transaction } from "../../types";

const HistorySearch = () => {
  const dispatch = useDispatch();

  const renderRightHeader = () => {
    return (
      <SearchBar
        placeholder={"#1122350, Tolak angin"}
        onChange={(text) => fetchHistory(text)}
      />
    );
  };

  const groupedHistory = useSelector(
    (states: general.States) => states.history.groupedByDate
  );

  const isLoading = useSelector(
    (states: general.States) => states.history.isLoading
  );

  const isEmpty = useSelector(
    (states: general.States) => states.history.isEmpty
  );

  const renderItemList = (list: transaction.History[]) => {
    return list.map((el, index) => {
      return <HistoryItem item={el} key={`history-item-${index}`} />;
    });
  };

  const renderHistoryList = useMemo(() => {
    const dateList: any = [];

    Object.keys(groupedHistory).forEach((el, index) => {
      dateList.push(
        <div className="grouped__list" key={`history-grouped-list-${index}`}>
          <div className="title">{el}</div>
          <div className="history-list">
            {renderItemList(groupedHistory[el])}
          </div>
        </div>
      );
    });

    return dateList;
  }, [groupedHistory]);

  const fetchHistory = debounce(function (text) {
    const trimmedText = text?.trim();
    dispatch(getTransactionHistoryAsync.request({ search: trimmedText }));
  }, 1500);

  useEffect(() => {
    dispatch(clearListHistory());

    return () => {
      dispatch(clearListHistory());
    };
  }, []);

  return (
    <Layout>
      <TopBar rightHeader={renderRightHeader} fullRightHeader />
      <Wrapper p={2} pb={5}>
        <Body>
          {isLoading ? (
            <div className="history--loading">
              <Spinner />
            </div>
          ) : null}

          {isEmpty && !isLoading ? (
            <div className="history--loading">
              <h2>Transaksi tidak ditemukan</h2>
              <span className="text">
                Coba ubah kata kunci atau nomor transaksi
              </span>
            </div>
          ) : null}

          {!Object.keys(groupedHistory).length && !isLoading && !isEmpty ? (
            <div className="search__placeholder">
              <img src={ArrowUp} height="150" alt="arrow up" />
              <h2 className="placeholder__title">
                Masukkan nomor transaksi atau nama barang
              </h2>
              <div className="placeholder__subtitle">
                Gunakan nomor transaksi atau nama barang untuk mencari transaksi
                yang sesuai.
              </div>
            </div>
          ) : null}

          <div className="search__list">{renderHistoryList}</div>
        </Body>
      </Wrapper>
    </Layout>
  );
};

const Body = styled(BodyPage)`
  padding-top: 0px;

  .search__placeholder {
    padding: 24px 0px;

    > img {
      margin-left: 20%;
      align-self: flex-start;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      padding-bottom: 20px;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .grouped__list {
    > .title {
      font-weight: 700;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .history-list {
    }
  }

  .history--loading {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export default HistorySearch;
