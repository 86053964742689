import React from "react";
import styled from "styled-components/macro";

// component
import { Flex, Text } from "../../component/Styles";
import { Container } from "../../component/TopBar/TopBar";

// type
import { TransactionProduct } from "../../types/product";

// utils
import { nominalFormat } from "../../utils/nominalFormat";

export type PurchaseItemProps = {
  product: TransactionProduct;
  transactionID: string;
  disabled?: boolean;
  setSelectedExistingProduct?: (product: TransactionProduct) => void;
};

const PurchaseItem = (props: PurchaseItemProps) => {
  const { product, disabled, setSelectedExistingProduct } = props;

  const handleClick = () => {
    if (!disabled) {
      setSelectedExistingProduct!(product);
    }
  };

  const renderDiscount = () => {
    if (product.discount_amount) {
      if (product.discount_type === "percentage") {
        return `Diskon ${nominalFormat(product.discount_amount)}%`;
      }

      return `Diskon Rp${nominalFormat(product.discount_amount)}`;
    }
  };

  return (
    <Card
      onClick={() => handleClick()}
      py="1"
      px="0"
      borderBottom="1px solid"
      borderColor="blackLighter"
    >
      <Flex flex="8" flexDirection="column">
        <Text variant="rg" color="blueDark" fontWeight="bold">
          {product.product_name}
        </Text>
        <Text className="item__total" variant="sm">
          Rp{nominalFormat(product.price)} x {product.qty} {product?.unit}
        </Text>
        <Text variant="sm" color="coral">
          {renderDiscount()}
        </Text>
      </Flex>
      <Flex flex="2" justifyContent="flex-end">
        <Text variant="rg" fontWeight="bold">
          {nominalFormat(product.subtotal)}
        </Text>
      </Flex>
    </Card>
  );
};

const Card = styled(Flex)`
  cursor: pointer;
`;
export default PurchaseItem;
