import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

//component
import SidebarItem from "./SidebarItem";

// constant
import theme from "../../constant/styledTheme";
import { device } from "../../constant/media";
import { States } from "../../types/general";
import { APP_VERSION } from "../../constant/version";
import { ENV } from "../../constant/env";
import metadata from "../../constant/metadata.json";

// asset
import SidebarList from "./sidebarList.json";

const Sidebar = () => {
  const router = useSelector((state: States) => state.router.location);
  const profile = useSelector((state: States) => state.profile.profile);

  const sidebarList = SidebarList;
  const bioString = localStorage.getItem("bio");

  const getActiveNav = (path: string, navItem: string) => {
    const splitPath = path.split("/");

    if (splitPath[1] === navItem) {
      return true;
    }

    return false;
  };

  const renderNavList = useMemo(() => {
    const bio = profile;
    const path = router.pathname;

    const isOwner = bio?.role === "owner";
    const sidebarItems = sidebarList.map((option, index) => {
      return (
        <SidebarItem
          item={option}
          key={`sidebar-item-${index}`}
          isActive={getActiveNav(path, `${option.value}`)}
        />
      );
    });

    const employeeIndex = sidebarList.findIndex(
      (el) => el.value === "employee"
    );

    if (!isOwner) {
      sidebarItems.splice(employeeIndex, 1);
    }

    return sidebarItems;
  }, [bioString]);

  const visibleSidebar = () => {
    const path = router.pathname;

    const hideList: { [key: string]: boolean } = {
      "/company": true,
      "/create-password": true,
      "/register": true,
      "/signin": true,
      "/account": true,
      "/confirm": true,
      "/forgot-password": true,
      "/forgot-password/sent": true,
      "/forgot-password/new": true,
      "/change-email": true,
    };

    if (hideList[path]) {
      return false;
    }

    return true;
  };

  return (
    <Wrapper isVisible={visibleSidebar()}>
      <Container>
        <div className="sidebar__list">{renderNavList}</div>

        <div className="sidebar__bottom">
          <SidebarItem
            item={{ label: "Logout", value: "logout" }}
            isActive={false}
          />
          <div className="sidebar__version">Farmacare {APP_VERSION}</div>
          {ENV === "development" || ENV === "test" ? (
            <div className="sidebar__version">
              Build {metadata.buildDate}.{metadata.buildMajor}.
              {metadata.buildMinor}.{metadata.buildRevision}/
              {metadata.buildNumber}
            </div>
          ) : null}
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isVisible: boolean }>`
  display: none;
  height: 100vh;
  width: 200px;
  box-sizing: border-box;

  @media (min-width: ${theme.breakpoints[3]}) {
    display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-width: 200px;
  width: 30%;
  padding: 0.5rem;
  background: #fff;
  z-index: 3;
  background: ${theme.colors.blueDark};

  .sidebar__bottom {
    position: absolute;
    width: calc(200px - 32px);
    bottom: 20px;

    .sidebar__version {
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      margin-left: 8px;

      :not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;

export default Sidebar;
