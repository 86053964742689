import { createReducer, ActionType  } from 'typesafe-actions'

// actions
import * as actions from './pbf.action'

// actions types
import * as types from './pbf.actionTypes'

// type
import { PBF} from '../../types/pbf'
import { Option } from '../../types/general'
export type PBFActions = ActionType<typeof actions>;

export type PBFState = {
    list: PBF[],
    isLoading: boolean;
    visibleModal: boolean;
    pageIndex: number;
    selectedPBF: Option | null
}

const initialState: PBFState = {
    list: [],
    isLoading: false,
    visibleModal: false,
    pageIndex: 1,
    selectedPBF: null
}

export const PBFReducer = createReducer<PBFState, PBFActions>(initialState)
    .handleAction(actions.getPBFListAsync.request, (state: PBFState, action: ActionType<typeof actions.getPBFListAsync.request>) => ({ 
        ...state,
        isLoading: true,
        pageIndex: action.payload.page || 1
    }))
    .handleAction(actions.getPBFListAsync.success, (state: PBFState, action: ActionType<typeof actions.getPBFListAsync.success>) => ({ 
        ...state,
        isLoading: false,
        list: action.payload
    }))
    .handleAction(actions.getPBFListAsync.failure, (state: PBFState, ) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.addPBFAsync.request, (state: PBFState) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.addPBFAsync.success, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.addPBFAsync.failure, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction( actions.editPBFAsync.request , (state: PBFState) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.editPBFAsync.success, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.editPBFAsync.failure, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction( actions.deletePBFAsync.request , (state: PBFState) => ({ 
        ...state,
        isLoading: true
    }))
    .handleAction(actions.deletePBFAsync.success, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.deletePBFAsync.failure, (state: PBFState) => ({ 
        ...state,
        isLoading: false,
    }))
    .handleAction(types.SHOW_EDIT_MODAL_PBF as any, (state: PBFState, action: ActionType<typeof actions.showEditModalPBF>) => ({ 
        ...state,
        isLoading: false,
        visibleModal: action.payload.visible
    }))
    .handleAction(types.SET_SELECTED_PBF as any, (state: PBFState, action: ActionType<typeof actions.setSelectedPBF>) => ({ 
        ...state,
        selectedPBF: action.payload.selectedPBF
    }))
  
  



