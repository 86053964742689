import styled from 'styled-components/macro';

import Flex from './Flex'

// constant
import theme from '../../constant/styledTheme'

const StickyBottomContainer = styled(Flex)`
 bottom: 0px;
 box-shadow: 0px -2px 4px rgba(51, 51, 51, 0.15);
 z-index: 10;
 background: #fff;
`

export default StickyBottomContainer