import { createReducer, ActionType } from "typesafe-actions";

// actions
import * as actions from "./stock.action";

// actions types
import * as types from "./stock.actionTypes";

// type
import {  DetailStock, InventoryDetail } from "../../types/inventory";
export type StockActions = ActionType<typeof actions>;

export type StockState = {
  isLoading: boolean;
  pageIndex: number;
  nonEmptyDetail: InventoryDetail | null;
  detail: InventoryDetail | null,
  showUpdateStockModal: boolean
  stockDetail: DetailStock | null
};

const initialState: StockState = {
  isLoading: false,
  pageIndex: 1,
  nonEmptyDetail: null,
  detail: null,
  showUpdateStockModal: false,
  stockDetail: null
};

export const stockReducer = createReducer<StockState, StockActions>(
  initialState
)
  .handleAction(
    actions.getBatchListAsync.request,
    (state: StockState,  action: ActionType<typeof actions.getBatchListAsync.request>) => ({
      ...state,
      isLoading: true,
      pageIndex: action.payload?.page || 1
    })
  )
  .handleAction(
    actions.getBatchListAsync.success,
    (
      state: StockState,
      action: ActionType<typeof actions.getBatchListAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      detail: action.payload
    })
  )
  .handleAction(
    actions.getBatchListAsync.failure,
    (state: StockState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.getBatchNonEmptyAsync.request,
    (state: StockState) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.getBatchNonEmptyAsync.success,
    (
      state: StockState,
      action: ActionType<typeof actions.getBatchNonEmptyAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      nonEmptyDetail: action.payload
    })
  )
  .handleAction(
    actions.getBatchNonEmptyAsync.failure,
    (state: StockState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.updateStocksAsync.request,
    (state: StockState) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.updateStocksAsync.success,
    (
      state: StockState,
    ) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.updateStocksAsync.failure,
    (state: StockState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.getUpdateStockDetailAsync.request,
    (state: StockState) => ({
      ...state,
      isLoading: true,
    })
  )
  .handleAction(
    actions.getUpdateStockDetailAsync.success,
    (
      state: StockState,
      action: ActionType<typeof actions.getUpdateStockDetailAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      stockDetail: action.payload
    })
  )
  .handleAction(
    actions.getUpdateStockDetailAsync.failure,
    (state: StockState ,) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    types.SET_SHOW_UPDATE_STOCK_MODAL as any,
    (state: StockState,  action: ActionType<typeof actions.setShowUpdateStockModal >) => ({
      ...state,
      showUpdateStockModal: action.payload.visible,
    })
  )
  
 
 
 