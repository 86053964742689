import axios from "axios";
import { push} from 'connected-react-router'

// actions 
import * as actions from './user.action'

// constant
import { BASE_URL_BETA } from '../../constant/api';

// utils
import { setSession, removeSessionID} from '../../utils/localStorageService'
import { showToast } from "../toast/toast.action";


export const doLogin = () => {
  return async (dispatch: any, getState: any) => {
      const bodyRequest = {
        identifier: getState().user.identifier,
        password: getState().user.password,
      };

      const myJSON = JSON.stringify(bodyRequest);
      try {
        const response = await axios.post(BASE_URL_BETA + "/signin", myJSON, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
          },
        });

        if (response.status === 200) {
          dispatch(actions.handleLogin);
          localStorage.setItem(
            "token",
            response.data.data.AuthenticationResult?.IdToken
          );
          localStorage.setItem(
            "accessToken",
            response.data.data.AuthenticationResult?.AccessToken
          );

          localStorage.setItem(
            "refreshToken",
            response.data.data.AuthenticationResult?.RefreshToken
          )

          if(response.data.data?.Session){
            setSession(response.data.data?.Session)
          } else {
            removeSessionID()
          }

          localStorage.removeItem("is_login");
          localStorage.setItem("is_login", 'true');
  
          if(response.data.data.AuthenticationResult?.IdToken){
            dispatch(push('/company'))
          }
        }
      } catch (error) {
        if(error.response.status === 422){
         dispatch(push('/signin'))
         return
        }

        dispatch(showToast.request({ message: "Gagal login, cek email atau passwordmu", duration: 5000, type: 'error' }))
      }
    };
  };
  
  export const doRegister = (props: any) => {
    return async (dispatch: any, getState: any) => {
     

      const bodyRequest = {
        name: getState().user.name,
        username: getState().user.username,
        email: getState().user.email,
        password: getState().user.password,
      };

      const myJSON = JSON.stringify(bodyRequest);
      try {
        const response = await axios.post(BASE_URL_BETA + "/signup", myJSON, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
          },
        });
       
        if (response.data.statusCode === 200) {
          dispatch(actions.handleSuccessRegister);
          alert("berhasil daftar")
          dispatch(push('/confirm'))
        } else {
          dispatch(actions.handleFailedRegister);
          alert(response.data.body.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
  };
  
  export const doRegisterCompany = (props: any) => {
    return async (dispatch: any, getState:any) => {
      const slug = string_to_slug(getState().user.companyName)
      const token = localStorage.getItem("token");
      const bodyRequest = {
        name: getState().user.companyName,
        subdomain: slug,
      };
      const myJSON = JSON.stringify(bodyRequest);
      try {
        await axios.post(BASE_URL_BETA + "/company", myJSON, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(actions.handleSuccessRegisterCompany);
        alert("berhasil daftar")
        dispatch(push('/company'))
      } catch (error) {
        console.log(error);
      }
    };
  };

  export const getCompany = (props: any) => {
    return async (dispatch: any, getState: any) => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(BASE_URL_BETA + "/company", {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
        });
       
        dispatch(actions.handleGetCompanyList(response.data.company));
      } catch (error) {
        console.log(error);
      }
    };
  };
  
  export const doChangePassword = (props: any) => {
    return async (dispatch: any, getState: any) => {
      const accessToken = localStorage.getItem("accessToken");
      const bodyRequest = {
        access_token: accessToken,
        old_password: getState().user.oldPassword,
        new_password: getState().user.newPassword,
      };
      const myJSON = JSON.stringify(bodyRequest);
      try {
        const response = await axios.post(BASE_URL_BETA + "/change-password", myJSON, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json; charset=utf-8",
          },
        });
        if (response.data.status_code === 200){
          dispatch(actions.handleSuccessChangePassword);
          alert("sukses ganti password")
          dispatch(push('/'))
        } else if (response.data.status_code === 401){
          alert("password lama anda salah, coba lagi")
        }
      } catch (error) {
        console.log(error);
      }
    };
  };
  

  function string_to_slug (str: any) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
}
