import styled from 'styled-components';
import {space, SpaceProps, layout, LayoutProps, border, BorderProps } from 'styled-system';

const Box = styled.div<SpaceProps & LayoutProps & BorderProps >`
    ${space};
    ${layout};
    ${border}

    display: flex;
    align-items: center;
    flex-direction: column;
`

export default Box