import styled from "styled-components/macro";

// constant
import { device } from "../../constant/media";

const Modal = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  overflow: hidden;

  @media ${device.md} {
    display: flex;
    flex-direction: column;
    left: unset;
    right: 0;
    max-width: 600px;
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-box-shadow: 0px 0px 282px -36px rgba(51, 51, 51, 1);
    -moz-box-shadow: 0px 0px 282px -36px rgba(51, 51, 51, 1);
    box-shadow: 0px 0px 282px -36px rgba(51, 51, 51, 1);
  }
`;

export default Modal;
