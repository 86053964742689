import React, { useMemo, useEffect, useState } from "react";
import { push } from "connected-react-router";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";

// redux action
import {
  updateStocksAsync,
  getBatchNonEmptyAsync,
} from "../../../reducers/stock/stock.action";

// component
import {
  Wrapper,
  BodyPage,
  Box,
  Flex,
  Overlay,
  Modal,
  Text,
  RotatedBox,
  StickyBottomContainer,
  H3,
} from "../../../component/Styles";
import { Spinner } from "../../../component/Loading";
import { TouchableButton, Button } from "../../../component/Button";
import TopBar from "../../../component/TopBar";
import Header from "../../../component/Header/Header";
import ModalReason from "../../../component/Modal/ModalReason";
import RouteLeavingGuard from "../../../component/RouteLeavingGuard/RouteLeavingGuard";
import { Modal as ModalComponent } from "../../../component/Modal";

// related component
import InventoryDetailBatchList from "../InventoryDetailBatchList";
import InventoryUpdateStockAmount from "./InventoryUpdateStockAmount";
import InventoryUpdateStockAddBatch from "./InventoryUpdateStockAddBatch";
import InventoryUpdateSearchBatch from "./InventoryUpdateSearchBatch";

// type
import { States } from "../../../types/general";
import { InventoryBatch } from "../../../types/inventory";

// utils
import { getURLPathID } from "../../../utils/getURLPathID";
import { getQtyOperator } from "../utils/getQtyOperator";

// Asset
import { AddIcon, ArrowBackIcon, CloseIcon } from "../../../asset/icon";
import { getProductDetailAsync } from "../../../reducers/catalog/catalog.action";
import { NewBatch, SavedStock, Stock } from "./type";
import { nominalFormat } from "../../../utils/nominalFormat";
import Placeholder from "../../../component/Placeholder";

export type Props = {
  /** Handle close modal */
  onCloseModal: () => void;
};

const InventoryUpdateStock = (props: Props) => {
  const { onCloseModal } = props;
  const dispatch = useDispatch();

  // Redux Store
  const location = useSelector((state: States) => state.router.location);
  const inventoryDetail = useSelector(
    (state: States) => state.stock.nonEmptyDetail
  );
  const isLoading = useSelector((state: States) => state.stock.isLoading);
  const productDetail = useSelector(
    (state: States) => state.catalog.productDetail
  );

  // state
  const [activeFilter, setActiveFilter] = useState({
    sorted_by: "batch_no",
    order_by: "asc",
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedStock, setSelectedStock] = useState<InventoryBatch | null>(
    null
  );
  const [changesBatchStocks, setChangesBatchStock] = useState<SavedStock>({});

  // modal state
  const [showUpdateStockModal, setShowUpdateStockModal] = useState(false);
  const [showAddBatchStockModal, setShowAddBatchStockModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showSearchBatch, setShowSearchBatch] = useState(false);
  const [newBatches, setNewBatches] = useState<NewBatch[]>([]);
  const [showModalLeavingPage, setShowModalLeavingPage] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  const getInventoryID = useMemo(() => {
    return getURLPathID(location.pathname, 2);
  }, [location]);

  const handleCloseModal = () => {
    if (hasChanges) {
      setShowConfirmExitModal(true);
      return;
    }

    onCloseModal();
  };

  const handleChangeActiveFilter = (value: any) => {
    dispatch(
      getBatchNonEmptyAsync.request({
        productID: getInventoryID,
        sorted_by: value.sorted_by,
        order_by: value.order_by,
        history: true,
      })
    );
    setActiveFilter(value);
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => handleCloseModal()}
        withFeedback
      />
    );
  };

  const handleSelectStock = (stock: InventoryBatch) => {
    setHasChanges(true);
    setShowSearchBatch(false);
    setHasChanges(true);
    setSelectedStock(stock);

    if (stock.index !== undefined) {
      setShowAddBatchStockModal(true);
    } else {
      setShowUpdateStockModal(true);
    }
  };

  /**
   *
   * @param batchID
   * @param currentStock
   * @param diffChanges
   * @param stockPackages
   * @param batchDetail
   * handle changes related to batch
   */
  const handleChangeStockBatch = (
    batchID: string,
    currentStock: number,
    diffChanges: number,
    stockPackages: Stock[],
    batchDetail: InventoryBatch
  ) => {
    setHasChanges(true);
    const updatedStock = cloneDeep(changesBatchStocks);
    updatedStock[batchID] = {
      currentStock,
      stockPackages,
      diffChanges,
      batchDetail,
    };
    setChangesBatchStock(updatedStock);
  };

  /**
   * handle save and will trigger save to backend
   */
  const onClickUpdate = () => {
    setShowReasonModal(true);
    return;
  };

  const handleAddNewBatch = (value: {
    data: NewBatch;
    type: "save" | "add";
    index?: number;
  }) => {
    const { data, type, index } = value;
    const clonedBatches = newBatches.slice();
    setHasChanges(true);

    if (type === "save") {
      clonedBatches.splice(index!, 1, data);
      setNewBatches(clonedBatches);
    } else {
      setNewBatches([data, ...clonedBatches]);
    }
  };

  const handleSaveModalReason = (text: string) => {
    setShowReasonModal(false);
    handleSave(text);
  };

  const handleDeleteAddBatch = (index: number) => {
    setHasChanges(true);
    const clonedNewBatches = cloneDeep(newBatches);
    clonedNewBatches.splice(index, 1);

    setNewBatches(clonedNewBatches);
    setShowAddBatchStockModal(false);
  };

  const handleSave = (reason: string) => {
    setHasChanges(false);
    let updatedBatches = getBatchList.map((b) => {
      return {
        updated_qty: b.current_qty || b.batch_qty || 0,
        batch_id: b.id!,
      };
    });

    getChangesBatchExcludeCurrentBatch.forEach((b) => {
      updatedBatches.push({ updated_qty: b.current_qty || 0, batch_id: b.id! });
    });

    const payload = {
      updated: updatedBatches,
      created: newBatches,
      message_reason: reason,
    };

    dispatch(updateStocksAsync.request({ productID: getInventoryID, payload }));
  };

  const handleShowAddBatchModal = () => {
    setSelectedStock(null);
    setHasChanges(true);
    setShowSearchBatch(false);
    setShowAddBatchStockModal(true);
  };

  const getCurrentBatchKey = useMemo(() => {
    let key: { [key: number]: boolean } = {};

    (inventoryDetail?.batch_list || []).forEach((b) => {
      key[b.id!] = true;
    });
    return key;
  }, [inventoryDetail]);

  const getChangesBatchExcludeCurrentBatch = useMemo(() => {
    const list: InventoryBatch[] = [];
    Object.keys(changesBatchStocks).forEach((batchKey) => {
      if (!getCurrentBatchKey[+batchKey]) {
        const changesBatch = changesBatchStocks[batchKey];
        const bDetail = changesBatch.batchDetail;

        bDetail.current_qty = changesBatch.currentStock;
        list.push(bDetail);
      }
    });

    return list;
  }, [changesBatchStocks]);

  /**
   * get batch list, override current stock if the batch is already edited
   */
  const getBatchList = useMemo(() => {
    let list: InventoryBatch[] = [];

    if (inventoryDetail?.batch_list) {
      list = (inventoryDetail.batch_list || []).map((b) => {
        if (changesBatchStocks[b.id!]) {
          b.current_qty = changesBatchStocks[b.id!].currentStock;
        }
        return b;
      });
    }

    return list;
  }, [inventoryDetail?.batch_list, changesBatchStocks]);

  /**
   * get new batch list, convert format to InventoryBatch for new batch list
   */
  const getNewBatchList = useMemo(() => {
    let list: any[] = [];

    if (newBatches) {
      list = (newBatches || []).map((b) => {
        let totalCurrentQty = 0;
        b.stocks.forEach((s) => {
          totalCurrentQty += s.smallestQty * (s.stock || 0);
        });

        const batchData: InventoryBatch = {
          ED: b.ED,
          batch_no: b.batch_no,
          batch_qty: 0,
          date_purchase: b.buy_date,
          unit: b.unit || "",
          current_qty: totalCurrentQty,
          data_new: b,
        };

        return batchData;
      });
    }

    return list;
  }, [newBatches]);

  const navigate = (path: string) => {
    dispatch(push(path));
  };

  const emptyBatch = useMemo(() => {
    if (
      !getBatchList.length &&
      !getNewBatchList.length &&
      !getChangesBatchExcludeCurrentBatch.length
    ) {
      return true;
    }

    return false;
  }, [getBatchList, getNewBatchList, getChangesBatchExcludeCurrentBatch]);

  const getTotal = useMemo(() => {
    let total = +(productDetail?.qty || 0);

    if (!Object.keys(changesBatchStocks).length && !newBatches.length) {
      return +(productDetail?.qty || 0);
    }

    if (+(productDetail?.qty || 0) < 0) {
      total = 0;
    }

    Object.keys(changesBatchStocks).forEach((batchID) => {
      total += changesBatchStocks[batchID].diffChanges;
    });

    newBatches.forEach((b) => {
      b.stocks.forEach((s) => {
        total += s.smallestQty * (s.stock || 0);
      });
    });

    return total;
  }, [changesBatchStocks, productDetail, newBatches]);

  const getTotalChanges = useMemo(() => {
    const qtyBefore =
      (productDetail?.qty || 0) < 0
        ? Math.abs(productDetail?.qty || 0)
        : -(productDetail?.qty! || 0);

    return +getTotal + qtyBefore;
  }, [getTotal, productDetail]);

  useEffect(() => {
    if (getInventoryID && !isNaN(+getInventoryID)) {
      dispatch(
        getBatchNonEmptyAsync.request({
          productID: getInventoryID,
        })
      );
      dispatch(getProductDetailAsync.request({ productID: getInventoryID }));
    }
  }, [getInventoryID]);

  return (
    <>
      <RouteLeavingGuard
        when={hasChanges}
        navigate={navigate}
        shouldBlockNavigation={(location) => {
          return true;
        }}
        setModalVisibility={(status: boolean) =>
          setShowModalLeavingPage(status)
        }
      />

      {/* modal for leaving confirmation */}
      <ModalComponent
        title="Perubahan belum disimpan"
        isVisible={showConfirmExitModal && hasChanges && !showModalLeavingPage}
        subtitle="Jika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
        maxWidth="320px"
        destructiveTitle="Keluar Halaman"
        destructiveFunction={() => onCloseModal()}
        acceptTitle={"Batal"}
        acceptFunction={() => {
          setShowConfirmExitModal(false);
        }}
      />

      <ModalComponent
        title="Perhatian"
        subtitle="Update stok akan mengunci semua data stok yang terjadi sebelumnya. Artinya, kamu tidak akan bisa mengedit, menghapus, mau pun menyelipkan faktur ke tanggal sebelum update stok. Pastikan semua faktur penerimaan barang ini sudah tercatat pada sistem."
        isVisible={showAlert}
        footer={
          <Flex justifyContent="flex-end" mt={1}>
            <Flex>
              <Box>
                <Button title="Oke" onClick={() => setShowAlert(false)} />
              </Box>
              <Box ml={0}>
                <Button
                  title="Batal"
                  secondary
                  onClick={() => onCloseModal()}
                />
              </Box>
            </Flex>
          </Flex>
        }
      />

      {showReasonModal ? (
        <ModalReason
          title={`Update stok dari ${productDetail?.qty} ${productDetail?.unit} menjadi ${getTotal} ${productDetail?.unit} ?`}
          subtitle="Tuliskan alasan mengapa perubahan ini dilakukan."
          label="Alasan update stok"
          id="update-stock-reason"
          onCancel={() => setShowReasonModal(false)}
          onSave={(text) => handleSaveModalReason(text)}
          isVisible={!showModalLeavingPage}
        />
      ) : null}

      {showSearchBatch ? (
        <InventoryUpdateSearchBatch
          onCloseModal={() => setShowSearchBatch(false)}
          onShowAddBatchModal={() => handleShowAddBatchModal()}
          onClickEdit={(stock) => handleSelectStock(stock)}
          isVisible={!showModalLeavingPage}
        />
      ) : null}

      {showUpdateStockModal ? (
        <InventoryUpdateStockAmount
          onCloseModal={() => setShowUpdateStockModal(false)}
          handleChangeStockBatch={handleChangeStockBatch}
          changesBatchStocks={changesBatchStocks}
          product={productDetail}
          inventoryBatch={selectedStock}
          isVisible={!showModalLeavingPage}
        />
      ) : null}

      {showAddBatchStockModal ? (
        <InventoryUpdateStockAddBatch
          product={productDetail}
          inventoryBatch={selectedStock}
          selectedNewBatch={
            selectedStock?.index !== undefined
              ? newBatches[selectedStock.index]
              : null
          }
          handleDeleteAddBatch={(index: number) => handleDeleteAddBatch(index)}
          onCloseModal={() => setShowAddBatchStockModal(false)}
          onAddNewBatch={(value) => handleAddNewBatch(value)}
          isVisible={!showModalLeavingPage}
        />
      ) : null}

      <Overlay
        isVisible={
          !showAddBatchStockModal && !showUpdateStockModal && !showSearchBatch
        }
      >
        {/* Modal for update stock based on batch or unit */}
        <Modal
          isVisible={
            !showAddBatchStockModal && !showUpdateStockModal && !showSearchBatch
          }
        >
          <TopBar
            leftHeader={renderLeftHeader}
            title={inventoryDetail?.name || ""}
            isModal
          />

          <Wrapper
            p="2"
            pb="5"
            overflowY="scroll"
            maxHeight="calc(100vh - 52px - 52px - 4rem)"
            height="calc(100vh - 52px - 52px - 4rem )"
          >
            <BodyPage>
              <Header title="Update stok" />
              <Flex mt={2}>
                <Flex flexDirection="column" flex="4">
                  <Text fontSize="2" lineHeight="2">
                    Dari
                  </Text>
                  <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                    {inventoryDetail?.stock_qty} {inventoryDetail?.stock_unit}
                  </Text>
                </Flex>
                <Flex flexDirection="column" flex="6">
                  <Flex alignItems="center">
                    <RotatedBox direction="flip-x">
                      <img
                        src={ArrowBackIcon}
                        width="24"
                        height="24"
                        alt="arrow"
                      />
                    </RotatedBox>
                    <Flex flexDirection="column" flex="4" ml="1">
                      <Text fontSize="2" lineHeight="2">
                        Menjadi
                      </Text>
                      <Text fontSize="3" lineHeight="3" fontFamily="Rubik">
                        <span
                          className={
                            getTotal > productDetail?.qty!
                              ? "success"
                              : "danger"
                          }
                        >
                          {nominalFormat(getTotal)}
                        </span>{" "}
                        {productDetail?.unit}
                      </Text>
                      <Text fontSize="1" lineHeight="1">
                        <span
                          className={getTotalChanges > 0 ? "success" : "danger"}
                        >
                          {getQtyOperator(getTotalChanges)}
                          {nominalFormat(getTotalChanges)}
                        </span>{" "}
                        {productDetail?.unit}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {isLoading ? (
                <Box p={3} mt={2}>
                  <Spinner />
                </Box>
              ) : (
                <>
                  <Box mt={4}>
                    <H3>Sisa batch</H3>
                    <Text variant="sm">
                      {(getBatchList || []).length + getNewBatchList.length}{" "}
                      batch
                    </Text>
                  </Box>
                  <Box
                    mt={1}
                    borderTop={!emptyBatch ? "1px solid" : null}
                    borderColor="blackLighter"
                  >
                    {/* render new batches  */}
                    <InventoryDetailBatchList
                      list={getNewBatchList}
                      activeFilter={activeFilter}
                      isEdit
                      isNewBatch
                      isModal
                      onClickEdit={(stock) => handleSelectStock(stock)}
                      setActiveFilter={handleChangeActiveFilter}
                    />

                    {/* render edited batch which has zero stock  */}
                    <InventoryDetailBatchList
                      list={getChangesBatchExcludeCurrentBatch}
                      activeFilter={activeFilter}
                      isEdit
                      isModal
                      onClickEdit={(stock) => handleSelectStock(stock)}
                      setActiveFilter={handleChangeActiveFilter}
                    />

                    {/* render existing batch */}
                    <InventoryDetailBatchList
                      list={getBatchList}
                      activeFilter={activeFilter}
                      isEdit
                      isModal
                      onClickEdit={(stock) => handleSelectStock(stock)}
                      setActiveFilter={handleChangeActiveFilter}
                    />

                    {emptyBatch ? (
                      <Placeholder
                        title="Tidak ada batch yang tersedia"
                        subtitle="Tambahkan stok atau cari batchmu sebelumnya di sini."
                        action={
                          <Button
                            inverse
                            title="Batch"
                            icon={AddIcon}
                            onClick={() => setShowSearchBatch(true)}
                          />
                        }
                      />
                    ) : (
                      <Flex
                        p="0"
                        backgroundColor="whiteDark"
                        borderBottom="1px solid"
                        borderColor="blackLighter"
                      >
                        <Button
                          inverse
                          title="Batch"
                          icon={AddIcon}
                          onClick={() => setShowSearchBatch(true)}
                        />
                      </Flex>
                    )}
                  </Box>
                </>
              )}
            </BodyPage>
          </Wrapper>
          <StickyBottomContainer
            position="absolute"
            bottom="0"
            p={1}
            width="calc(100% - 2rem)"
          >
            <Flex width="100%">
              <Button
                fullWidth
                title="Update stok"
                onClick={onClickUpdate}
                isLoading={isLoading}
                disabled={showAlert}
              />
            </Flex>
          </StickyBottomContainer>
        </Modal>
      </Overlay>
    </>
  );
};

export default InventoryUpdateStock;
