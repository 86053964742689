import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import styled from "styled-components/macro";

// asset
import { ChevronDown } from "../../asset/icon";

// component
import InventoryStockCardItem from "./InventoryStockCardItem";
import { Box, Table, Text, Flex, RotatedBox } from "../../component/Styles";

// type
import { InventoryStockCard } from "../../types/inventory";
import { Option, StockActionType } from "../../types/general";

export type Props = {
  list: InventoryStockCard[];
  activeFilter?: Option;
  inventoryID: string | null;
  setActiveFilter: (value: Option) => void;
};

const InventoryDetailStockCardList = (props: Props) => {
  const dispatch = useDispatch();

  const { list = [], activeFilter, inventoryID, setActiveFilter } = props;

  const getArrowDirection = useMemo(() => {
    const keyDirection = {
      created_at: { arrow: "up", order_by: "asc" },
    };

    const getDirection = (orderBy: string) => {
      if (orderBy === "asc") {
        return "up";
      }
      return "down";
    };

    switch (true) {
      case activeFilter?.value.sorted_by === "created_at":
        keyDirection.created_at = {
          arrow: getDirection(activeFilter?.value.order_by),
          order_by: activeFilter?.value.order_by,
        };
        break;
    }

    return keyDirection;
  }, [activeFilter]);

  const handleChangeSorting = (
    value: {
      sorted_by: string;
      order_by: string;
    },
    label: string | undefined = "Urutkan dari transaksi terbaru"
  ) => {
    if (activeFilter?.value?.sorted_by === value.sorted_by) {
      if (value.order_by === "asc") {
        setActiveFilter({
          label: "Urutkan dari transaksi terlama",
          value: { sorted_by: value.sorted_by, order_by: "desc" },
        });
      } else if (value.order_by === "desc") {
        setActiveFilter({
          label: "Urutkan dari transaksi terbaru",
          value: { sorted_by: value.sorted_by, order_by: "asc" },
        });
      }
    }

    return;
  };

  const handleClick = (inventory: InventoryStockCard) => {
    switch (inventory.action_type) {
      case StockActionType.A111:
        dispatch(push(`/history/${inventory.source_id}`));
        break;
      case StockActionType.A211:
      case StockActionType.A221:
      case StockActionType.A222:
        dispatch(
          push(
            `/invoice/${inventory.source_id}?origin=inventory&originID=${inventory.product_id}`
          )
        );
        break;
      case StockActionType.A311:
      case StockActionType.A312:
        dispatch(
          push(`/inventory/${inventoryID}/update-stock/${inventory.id}`)
        );
        break;
      default:
        break;
    }
  };

  /**
   * get quantity with operator plus or minus
   * @param qty
   */
  const getQtyOperator = (qty: number) => (qty > 0 ? `+${qty}` : qty);

  /**
   * render batchs, add suffix if batchs more than 3
   * @param batchs string[]
   */
  const renderBatchs = (inventory: InventoryStockCard) => {
    switch (inventory.action_type) {
      case StockActionType.A111:
      case StockActionType.A211:
      case StockActionType.A221:
      case StockActionType.A222:
      case StockActionType.A232:
      case StockActionType.A311:
      case StockActionType.A312:
        let batchRender = (inventory.batchs || []).slice(0, 3);

        if (batchRender.length === 1) {
          return batchRender[0];
        }

        let suffix =
          inventory.batchs.length > 3
            ? `, +${inventory.batchs.length - 3} lainnya`
            : "";
        return batchRender.join(", ") + suffix;
    }
  };

  const renderTransactionInfo = (inventory: InventoryStockCard) => {
    switch (inventory.action_type) {
      case StockActionType.A111:
        return (
          <>
            <Text variant="rg" color="blueDark" fontWeight="700">
              Penjualan #{inventory.source_number}
            </Text>
          </>
        );
      case StockActionType.A211:
        return (
          <>
            <Text variant="rg" color="blueDark" fontWeight="700">
              Penerimaan Faktur No. {inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.supplier_name}
            </Text>
          </>
        );
      case StockActionType.A221:
      case StockActionType.A222:
        return (
          <>
            <Text variant="rg" color="blueDark" fontWeight="700">
              Edit Faktur No. {inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.supplier_name}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.message_reason
                ? `"${inventory.message_reason}"`
                : null}
            </Text>
          </>
        );
      case StockActionType.A232:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              Hapus Faktur No. {inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.message_reason
                ? `"${inventory.message_reason}"`
                : null}
            </Text>
          </>
        );
      case StockActionType.A311:
      case StockActionType.A312:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              Update Stok
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.message_reason
                ? `"${inventory.message_reason}"`
                : null}
            </Text>
          </>
        );
      default:
        break;
    }
  };

  const renderTable = useMemo(() => {
    const renderTableBody = () => {
      return list.map((i, index) => {
        return (
          <tr key={`table-stock-card-${index}`} onClick={() => handleClick(i)}>
            <td className="table-batch__date">
              <Text fontSize="2" lineHeight="2">
                {moment(i.created_at).format("DD MMM YYYY, HH:mm")}
              </Text>
            </td>
            <td className="table-batch__batch">
              <Text fontSize="2" lineHeight="2">
                {renderTransactionInfo(i)}
              </Text>
            </td>
            <td className="table-batch__stock">
              <Text fontSize="2" lineHeight="2">
                {renderBatchs(i)}
              </Text>
            </td>
            <td className="table-batch__unit">
              <Text
                fontSize="2"
                lineHeight="2"
                textAlign="right"
                color={i.qty > 0 ? "green" : "coral"}
              >
                {getQtyOperator(i.qty)}
              </Text>
            </td>
            <td className="table-batch__ed">
              <Text fontSize="2" lineHeight="2" textAlign="right">
                {i.qty_balance}
              </Text>
            </td>
            <td className="table-batch__ed">
              <Text fontSize="2" lineHeight="2">
                {i.creator}
              </Text>
            </td>
          </tr>
        );
      });
    };

    return (
      <ExtendTable width="100%">
        <thead>
          <tr>
            <th className="table-batch__date">
              <Flex
                onClick={() =>
                  handleChangeSorting({
                    sorted_by: "created_at",
                    order_by: getArrowDirection.created_at.order_by,
                  })
                }
              >
                <Text
                  fontSize="2"
                  lineHeight="2"
                  color={
                    activeFilter?.value.sorted_by === "created_at"
                      ? "blueDark"
                      : "black"
                  }
                >
                  Waktu
                </Text>
                <RotatedBox
                  direction={getArrowDirection.created_at.arrow as any}
                >
                  <img src={ChevronDown} alt="arrow" width="24" height="24" />
                </RotatedBox>
              </Flex>
            </th>
            <th className="table-batch__info">
              <Text fontSize="2" lineHeight="2" color="black">
                Transaksi
              </Text>
            </th>
            <th className="table-batch__batch">
              <Text fontSize="2" lineHeight="2" color="black">
                Batch
              </Text>
            </th>
            <th className="table-batch__amount">
              <Text fontSize="2" lineHeight="2" color="black" textAlign="right">
                Jumlah
              </Text>
            </th>
            <th className="table-batch__balance">
              <Text fontSize="2" lineHeight="2" color="black" textAlign="right">
                Sisa stok
              </Text>
            </th>
            <th className="table-batch__creator">
              <Text fontSize="2" lineHeight="2" color="black">
                Oleh
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </ExtendTable>
    );
  }, [list]);

  const renderList = useMemo(() => {
    return list.map((inventory, index) => {
      const preDate =
        index === 0
          ? ""
          : moment(list[index - 1].created_at).format("DD MMM YYYY");
      const curDate = moment(inventory.created_at).format("DD MMM YYYY");
      return (
        <Box key={`inventory-stock-card-${index}`}>
          {preDate !== curDate ? (
            <Box
              borderBottom="1px solid"
              borderBottomColor="blackLighter"
              mt={2}
              paddingBottom="8px"
            >
              <Text fontSize={1} lineHeight={1} fontWeight="700" color="black">
                {curDate}
              </Text>
            </Box>
          ) : (
            ""
          )}
          <InventoryStockCardItem inventory={inventory} />
        </Box>
      );
    });
  }, [list]);

  return (
    <>
      <Box display={["block", "block", "none"]}>{renderList}</Box>
      <Box display={["none", "none", "block"]}>{renderTable}</Box>
    </>
  );
};

const ExtendTable = styled(Table)`
  td {
    cursor: pointer;
  }

  .table-batch__date {
    width: 160px;
  }

  .table-batch__info {
    width: calc(100% - 160px - 200px - 80px - 80px - 160px);
    min-width: 230px;
  }

  .table-batch__batch {
    width: 200px;
  }

  .table-batch__amount {
    width: 80px;
  }

  .table-batch__balance {
    width: 80px;
  }

  .table-batch__creator {
    width: 160px;
  }
`;

export default InventoryDetailStockCardList;
