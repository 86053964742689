import { createReducer, ActionType, action } from "typesafe-actions";

// actions
import * as actions from "./inventory.action";

// actions types
import * as types from "./inventory.actionTypes";

// type
import { Inventory, InventoryDetail, InventoryStockCard } from "../../types/inventory";
export type InventoryActions = ActionType<typeof actions>;

export type InventoryState = {
  detail: InventoryDetail | null;
  summary: Inventory | null;
  stockList: InventoryStockCard[];
  isLoading: boolean;
  stockLoading: boolean;
  pageIndex: number;
  batchPageIndex: number;
  stockPageIndex: number;
  stockPageTotalData: number
};

const initialState: InventoryState = {
  detail: null,
  summary: null,
  stockList: [],
  isLoading: false,
  stockLoading: false,
  pageIndex: 1,
  batchPageIndex: 1,
  stockPageIndex: 1,
  stockPageTotalData: 1,
};

export const inventoryReducer = createReducer<InventoryState, InventoryActions>(
  initialState
)
  .handleAction(
    actions.getInventoryListAsync.request,
    (state: InventoryState,  action: ActionType<typeof actions.getInventoryListAsync.request>) => ({
      ...state,
      isLoading: true,
      pageIndex: action.payload?.page || 1
    })
  )
  .handleAction(
    actions.getInventoryListAsync.success,
    (
      state: InventoryState,
      action: ActionType<typeof actions.getInventoryListAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      summary: action.payload,
    })
  )
  .handleAction(
    actions.getInventoryListAsync.failure,
    (state: InventoryState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.getInventoryDetailAsync.request,
    (state: InventoryState,   action: ActionType<typeof actions.getInventoryDetailAsync.request>) => ({
      ...state,
      isLoading: true,
      batchPageIndex: action.payload.page || 1
    })
  )
  .handleAction(
    actions.getInventoryDetailAsync.success,
    (
      state: InventoryState,
      action: ActionType<typeof actions.getInventoryDetailAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      detail: action.payload,
    })
  )
  .handleAction(
    actions.getInventoryDetailAsync.failure,
    (state: InventoryState) => ({
      ...state,
      isLoading: false,
    })
  )
  .handleAction(
    actions.getStockListAsync.request,
    (state: InventoryState,   action: ActionType<typeof actions.getStockListAsync.request>) => ({
      ...state,
      stockLoading: true,
      stockPageIndex: action.payload.page || 1
    })
  )
  .handleAction(
    actions.getStockListAsync.success,
    (
      state: InventoryState,
      action: ActionType<typeof actions.getStockListAsync.success>
    ) => ({
      ...state,
      stockLoading: false,
      stockList: action.payload.data,
      stockPageTotalData: action.payload.total_data
    })
  )
  .handleAction(
    actions.getStockListAsync.failure,
    (state: InventoryState) => ({
      ...state,
      stockLoading: false,
    })
  );

