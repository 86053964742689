import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

// redux action
import {
  uploadCSVAsync,
  removeFileFailedLink,
} from "../../reducers/catalog/catalog.action";

// asset
import UploadIcon from "../../asset/icon/upload.svg";
import FileIcon from "../../asset/icon/file.svg";

// component
import { Modal, Overlay, Body } from "../../component/Styles";
import { Button, TouchableButton } from "../../component/Button";
import TopBar from "../../component/TopBar";

// constant
import { colors } from "../../constant/theme";
import { States } from "../../types/general";

export type CatalogUploadCSVProps = {
  /** GET - Upload csv modal is visible */
  isVisible: boolean;
  /** Handle close modal upload CSV */
  onClickClose: () => void;
};

const CatalogUploadCSV = (props: CatalogUploadCSVProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: States) => state.catalog.isLoading);
  const failedFileLink = useSelector(
    (state: States) => state.catalog.failedFileLink
  );

  const { isVisible, onClickClose } = props;
  const [selectedFile, setSelectedFile] = useState<any>(null);

  /**
   * Generate basic template on front end
   */
  const handleDownloadTemplate = () => {
    const rows = [
      ["nama", "harga jual", "satuan terkecil"],
      ["Contoh Obat", "2000", "pcs"],
    ];

    // TODO: change download file name
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri, "_self");
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUploadFile = () => {
    dispatch(uploadCSVAsync.request({ file: selectedFile }));
  };

  const handleDownloadFailedReport = () => {
    window.open(failedFileLink, "_self");
  };

  /**
   * make sure failed file link is removed when click back
   */
  const handleBack = () => {
    dispatch(removeFileFailedLink());
  };

  return (
    <Overlay isVisible={isVisible}>
      <Modal isVisible={isVisible}>
        <TopBar
          title={"Impor"}
          leftHeaderType="close"
          leftHeaderFunction={() => onClickClose()}
          isModal
        />
        <BodyPage>
          {failedFileLink ? (
            <div className="failed-upload__container">
              <h2>Upload gagal</h2>
              <div className="text">
                Ada masalah pada file CSV-mu. Download laporan untuk melihat
                baris yang gagal serta alasannya.
              </div>

              <div className="button__container">
                <Button
                  title="Unduh laporan"
                  onClick={handleDownloadFailedReport}
                  fullWidth
                />
                <Button
                  title="Kembali"
                  secondary
                  fullWidth
                  onClick={handleBack}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="modal-upload-csv__header">
                <div className="header__subttile text">
                  Download format dokumen CSV yang perlu diisi.
                </div>
                <Button
                  secondary
                  title="Download CSV"
                  onClick={handleDownloadTemplate}
                />
              </div>

              <div className="upload-csv__container">
                <h2>Upload daftar barang via CSV</h2>

                {!selectedFile ? (
                  <div className="drop-file__container">
                    <div className="file__content">
                      <div className="upload__icon">
                        <img src={UploadIcon} alt="upload" width="24" />
                      </div>
                      <div>
                        <label
                          htmlFor="filePicker"
                          className="label__select-document text"
                        >
                          Pilih dokumen
                        </label>
                      </div>
                      <input
                        type="file"
                        id="filePicker"
                        onChange={(e) => handleSelectFile(e)}
                        accept=".csv"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="add-document__container">
                    <div className="add-document__title text">
                      Dokumen yang ditambahkan:
                    </div>
                    <div className="file__detail">
                      <div className="detail__left">
                        <TouchableButton icon={FileIcon} alt="file" />
                        <div className="file__name text">
                          {selectedFile?.name}
                        </div>
                      </div>
                      <Button
                        secondary
                        title="Ganti dokumen"
                        onClick={() => setSelectedFile(null)}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="button__container">
                <Button
                  title="Upload dan lanjutkan"
                  disabled={!selectedFile}
                  fullWidth
                  isLoading={isLoading}
                  onClick={handleUploadFile}
                />
              </div>
            </>
          )}
        </BodyPage>
      </Modal>
    </Overlay>
  );
};

const BodyPage = styled(Body)`
  .button__container {
    margin-top: 18px;

    > button:not(:first-child) {
      margin-top: 12px;
    }
  }

  .label__select-document {
    height: 100%;
    padding: 6px 8px;
    font-weight: 700;
    margin: 0;
    background: ${colors.secondary2};
    border-radius: 4px;
    cursor: pointer;
  }

  input[type="file"] {
    visibility: hidden;
  }

  .file__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px;
    margin-top: 8px;
    border-top: 1px solid ${colors.secondary1};
    border-bottom: 1px solid ${colors.secondary1};

    > .detail__left {
      display: flex;
      align-items: center;
      word-break: break-all;
      padding-right: 10px;
    }
  }

  .upload-csv__container {
    margin-top: 20px;

    > h2 {
      margin-bottom: 16px;
    }

    > .drop-file__container {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 4px;
      border: 1px dashed ${colors.primary};
      height: 192px;

      > .file__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .upload__icon {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
      }
    }
  }

  .modal-upload-csv__header {
    padding: 22px 0px;
    border-bottom: 1px solid ${colors.secondary1};

    > button {
      margin-top: 18px;
    }
  }
`;

export default CatalogUploadCSV;
