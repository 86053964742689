export const GET_USER_LIST_REQUEST = '@user/GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = '@user/GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAILURE = '@user/GET_USER_LIST_FAILURE'

export const SEND_INVITATION_REQUEST = '@user/SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = '@user/SEND_INVITATION_SUCCESS'
export const SEND_INVITATION_FAILURE = '@user/SEND_INVITATION_FAILURE'

export const DELETE_USER_REQUEST = '@user/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = '@user/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = '@user/DELETE_USER_FAILURE';

export const SET_SELECTED_USER = '@user/SET_SELECTED_USER'

export const SET_SHOW_EMPLOYEE_DETAIL_MODAL = '@user/SET_SHOW_EMPLOYEE_DETAIL_MODAL';