import React, { useEffect, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Location } from "history";
import { Prompt } from "react-router-dom";

// component
import { Modal } from "../../component/Modal";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  setModalVisibility: (visible: boolean) => void;
  customHandleNavigate?: () => void;
}

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  setModalVisibility,
  customHandleNavigate,
}: Props) => {
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setModalVisibility(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      setModalVisibility(true);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    setModalVisibility(false);

    if (customHandleNavigate) {
      customHandleNavigate();
      setConfirmedNavigation(false);
      return;
    }

    dispatch(push(`${lastLocation?.pathname!}`));
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}
      <Modal
        title="Perubahan belum disimpan"
        subtitle="Jiika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
        destructiveFunction={() => handleConfirmNavigationClick()}
        acceptFunction={() => closeModal()}
        destructiveTitle="Keluar"
        acceptTitle="Batal"
        isVisible={modalVisible}
      />
    </>
  );
};
export default RouteLeavingGuard;
