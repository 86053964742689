import React, { useMemo } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

// asset
import ChevronIcon from "../../asset/icon/chevron-next.svg";

// constant
import { colors } from "../../constant/theme";

// type
import { History } from "../../types/transaction";
import { nominalFormat } from "../../utils/nominalFormat";

export type HistoryItemProps = {
  item: History;
};

const HistoryItem = (props: HistoryItemProps) => {
  const { item } = props;

  const dispatch = useDispatch();

  const renderListProduct = useMemo(() => {
    const list = [];

    for (let i = 0; i < item.list_product.length; i++) {
      list.push(item.list_product[i].product_name);
      if (i === 3) {
        break;
      }
    }

    if (item.list_product.length > 4) {
      list.push(`+ ${item.list_product.length - 4} lainnya...`);
    }

    return list.join(", ");
  }, [item.list_product]);

  const onClickItem = () => {
    dispatch(push(`/history/${item.id}`));
  };

  return (
    <Container onClick={() => onClickItem()}>
      <div className="item__left">
        <div className="item__title">#{item.transaction_id}</div>
        <div className="item__subtitle subtitle">{renderListProduct}</div>
      </div>
      <div className="item__right">
        <div className="item__price text">
          Rp{nominalFormat(item.total_basket_size)}
        </div>
        <div className="item__icon">
          <img src={ChevronIcon} alt="detail" />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.secondary1};
  padding: 8px 2px;

  cursor: pointer;

  .item__left {
    display: flex;
    flex: 6;
    flex-direction: column;

    > .item__subtitle {
      overflow-wrap: break-word;
    }
  }

  .item__right {
    display: flex;
    justify-content: flex-end;
    flex: 4;
    align-items: center;

    > .item__icon {
      margin-left: 10px;
    }
  }
`;

export default HistoryItem;
