import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import styled from "styled-components/macro";

// redux action
import { getBatchListAsync } from "../../../reducers/stock/stock.action";

// component
import {
  Box,
  Flex,
  Overlay,
  Modal,
  Text,
  Wrapper,
  BodyPage,
} from "../../../component/Styles";
import { Button, TouchableButton } from "../../../component/Button";
import Pagination from "../../../component/Pagination";
import SearchBar from "../../../component/SearchBar";
import TopBar from "../../../component/TopBar";
import { Spinner } from "../../../component/Loading";

// type
import { States } from "../../../types/general";
import { InventoryBatch } from "../../../types/inventory";

// utils
import { getURLPathID } from "../../../utils/getURLPathID";
import { HelpIcon } from "../../../asset/icon";

export type Props = {
  /** GET - modal visibility */
  isVisible?: boolean;
  /** Handle close modal */
  onCloseModal: () => void;
  /** handle show add new batch */
  onShowAddBatchModal: () => void;
  /** handle save edited data */
  onClickEdit: (stock: InventoryBatch) => void;
};

const InventoryUpdateSearchBatch = (props: Props) => {
  const dispatch = useDispatch();

  const { onCloseModal, onShowAddBatchModal, onClickEdit, isVisible } = props;

  // state
  const [searchFilter, setSearchFilter] = useState("");

  // redux state
  const inventoryDetail = useSelector((state: States) => state.stock.detail);
  const isLoading = useSelector((state: States) => state.stock.isLoading);
  const location = useSelector((state: States) => state.router.location);
  const pageIndex = useSelector(
    (state: States) => state.inventory.batchPageIndex
  );

  const Item = ({ item }: { item: InventoryBatch }) => {
    return (
      <FlexCursor
        justifyContent="space-between"
        px={0}
        py={1}
        borderBottom="1px solid"
        borderColor="blackLighter"
        onClick={() => onClickEdit(item)}
      >
        <Text fontSize="2" lineHeight="2" color="blueDark" fontWeight="bold">
          {item.batch_no}
        </Text>
        <Text fontSize="2" lineHeight="2">
          {item.batch_qty} {item.unit}
        </Text>
      </FlexCursor>
    );
  };

  /**
   * Get inventory ID from url path
   */
  const getInventoryID = useMemo(() => {
    return getURLPathID(location.pathname, 2);
  }, [location]);

  const renderBatchList = () => {
    return (inventoryDetail?.batch_list || []).map((b, index) => {
      return <Item item={b} key={`batch-list-item-${index}`} />;
    });
  };

  const handleChangeSearchFilter = debounce((text: string) => {
    setSearchFilter(text);
  }, 800);

  const handleChangePage = (page: number) => {
    dispatch(
      getBatchListAsync.request({
        productID: getInventoryID,
        search: searchFilter,
        history: true,
        page,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getBatchListAsync.request({
        productID: getInventoryID,
        search: searchFilter,
        history: true,
      })
    );
  }, [searchFilter]);

  const renderRightHeader = () => {
    return (
      <SearchBar placeholder="Cari batch" onChange={handleChangeSearchFilter} />
    );
  };

  return (
    <Overlay isVisible={!!isVisible}>
      <Modal isVisible={!!isVisible}>
        <TopBar
          isModal
          leftHeaderType="back"
          rightHeader={renderRightHeader}
          leftHeaderFunction={onCloseModal}
        />
        <Wrapper overflowY="scroll" p={2} pb={5}>
          <BodyPage>
            {isLoading ? (
              <Flex pt={3} justifyContent="center">
                <Spinner />
              </Flex>
            ) : !inventoryDetail?.total_batch ? (
              <Flex
                py="3"
                alignItems="center"
                flexDirection="column"
                borderRadius="4px"
                border="1px solid"
                borderColor="blackLighter"
              >
                <TouchableButton icon={HelpIcon} alt="Not found" />
                <Text textAlign="center" mt={0}>
                  Batch {searchFilter} tidak ditemukan
                </Text>
                <Box mt={0}>
                  <Button
                    title="Buat batch"
                    secondary
                    onClick={onShowAddBatchModal}
                  />
                </Box>
              </Flex>
            ) : (
              <>
                <Box>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    pb={1}
                  >
                    <Text fontSize="2" lineHeight="2">
                      {inventoryDetail?.total_batch} batch
                    </Text>
                    <Box>
                      <Button
                        title="Buat batch"
                        secondary
                        onClick={onShowAddBatchModal}
                      />
                    </Box>
                  </Flex>
                  <Box borderTop="solid 1px" borderColor="blackLighter">
                    {renderBatchList()}
                  </Box>
                  <Flex
                    p={0}
                    backgroundColor="whiteDark"
                    borderBottom="1px solid"
                    borderColor="blackLighter"
                  >
                    <Text fontSize="2" lineHeight="2">
                      Tidak ketemu?
                    </Text>{" "}
                    <CursorText
                      fontSize="2"
                      lineHeight="2"
                      color="blueDark"
                      fontWeight="bold"
                      ml={0}
                      onClick={onShowAddBatchModal}
                    >
                      Buat batch baru
                    </CursorText>
                  </Flex>
                </Box>
                <Flex justifyContent="center">
                  <Pagination
                    totalData={inventoryDetail?.batch_list.length}
                    isLoading={false}
                    currentPage={pageIndex}
                    onChangePage={(value) => handleChangePage(value)}
                  />
                </Flex>
              </>
            )}
          </BodyPage>
        </Wrapper>
      </Modal>
    </Overlay>
  );
};

const FlexCursor = styled(Flex)`
  cursor: pointer;
`;

const CursorText = styled(Text)`
  cursor: pointer;
`;

export default InventoryUpdateSearchBatch;
