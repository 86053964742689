import { createReducer, ActionType } from "typesafe-actions";

// actions
import * as actions from "./navigation.action";
import * as types from "./navigation.actionTypes";

// type
import { NavigationState } from "./type";
export type NavAction = ActionType<typeof actions>;

const initialState: NavigationState = {
  isShowSidebar: false,
};

export const navigationReducer = createReducer<NavigationState, NavAction>(
  initialState
).handleAction(
  types.SET_SHOW_SIDEBAR as any,
  (state: NavigationState, action) => ({
    ...state,
    isShowSidebar: action.payload.value,
  })
);


