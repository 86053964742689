import React, { useCallback, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import TagManager from "react-gtm-module";

// redux action
import { showToast } from "./reducers/toast/toast.action";

// route
import MainRoute from "./routes/MainRoute";

// component
import Toast from "./component/Toast";

// constant
import { GTM_ID } from "./constant/env";

// style
import "./App.css";
import GlobalStyle from "./constant/style";

// type
import { States } from "./types/general";

function App() {
  const dispatch = useDispatch();

  const profile = useSelector((state: States) => state.profile.profile);

  const handleOnline = useCallback(() => {
    dispatch(showToast.success());
  }, []);

  const handleOffline = useCallback(() => {
    dispatch(
      showToast.request({
        message: "Kamu tidak dalam jaringan",
        type: "error",
      })
    );
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [handleOnline, handleOffline]);

  useEffect(() => {
    if (GTM_ID) {
      const tagManagerArgs = {
        gtmId: GTM_ID,
        dataLayer: {
          userID: profile?.id || "",
        },
      };

      TagManager.initialize(tagManagerArgs);
    }

    if (profile) {
      const tagManagerArgs = {
        dataLayer: {
          userID: profile.id,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  }, [profile]);

  return (
    <div>
      <Toast />
      <MainRoute />
      <GlobalStyle />
    </div>
  );
}

export default Sentry.withProfiler(App);
