const size = {
  sm: "0px",
  md: "600px",
  lg: "1024px",
};

export const device = {
  sm: `(min-width: ${size.md})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
};
