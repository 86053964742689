import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import styled from "styled-components/macro";

// redux action
import { visiblePackageModal } from "../../reducers/package/package.action";
import { getProductDetailAsync } from "../../reducers/catalog/catalog.action";

// asset
import {
  AddIcon,
  AlertIcon,
  CloseIcon,
  DeleteIcon,
  SubstractIcon,
} from "../../asset/icon";
import { discountType } from "./data/templateOptions";

// component
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import DatePicker from "../../component/DatePicker";
import Select from "../../component/Select";
import { Button, TouchableButton } from "../../component/Button";
import {
  H2,
  H3,
  H4,
  Modal,
  Overlay,
  Body,
  Box,
  Flex,
  Text,
  StickyBottomContainer,
} from "../../component/Styles";
import { Modal as ModalComponent } from "../../component/Modal";
import AddPackageModal from "../Catalog/CatalogAddPackageSharingModal";

// type
import { ExtendProductInvoice, Product } from "../../types/product";
import { Package } from "../../types/package";
import { DiscountTypes } from "../../types/invoice";
import { Option, States } from "../../types/general";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { NumberFormatValues } from "react-number-format";
import { getValidDate } from "./utils/getValidDate";

type InvoiceAddProductProps = {
  /** Handle delete product, based on index */
  onDeleteProduct: (index: number) => void;
  /** Handle add product */
  onAddProduct: (product: ExtendProductInvoice) => void;
  /** Handle close modal */
  onCloseModal: () => void;
  /** Handle edit product */
  onEditProduct: (product: ExtendProductInvoice) => void;
  /** Get invoice already include VAT or not */
  includeVAT: boolean;
  /** Get state is on edit mode or not */
  isEdit?: boolean;
  /** Get selected product */
  selectedProduct: Product | null;
  /** Get detail of product if product is existing product in this invoice */
  selectedExistingProduct: ExtendProductInvoice | null;
  /** Get state modal leaving page is show or not */
  showModalLeavingPage: boolean;
};

const InvoiceAddProduct = (props: InvoiceAddProductProps) => {
  const dispatch = useDispatch();

  const {
    onCloseModal,
    onAddProduct,
    onDeleteProduct,
    onEditProduct,
    includeVAT,
    selectedProduct,
    isEdit,
    selectedExistingProduct,
    showModalLeavingPage,
  } = props;

  // redux store
  const showAddPackageModal = useSelector(
    (state: States) => state.package.visiblePackageModal
  );
  const productDetail = useSelector(
    (state: States) => state.catalog.productDetail
  );
  const isLoading = useSelector((state: States) => state.catalog.isLoading);

  const [sellBasePrice, setSellBasePrice] = useState(0);
  const [qty, setQty] = useState<number | undefined>(0);
  const [noBatch, setNoBatch] = useState("");
  const [ED, setED] = useState<Date | null>(null);
  const [selectedUnitBuy, setSelectedUnitBuy] = useState<Option | null>(null);
  const [selectedUnitSell, setSelectedUnitSell] = useState<Option | null>(null);
  const [buyPrice, setBuyPrice] = useState<number | undefined>(0);
  const [discType, setDiscType] = useState({ label: "%", value: "percentage" });
  const [discAmount, setDiscAmount] = useState<number | undefined>(0);
  const [sellMargin, setSellMargin] = useState<number | undefined>(0);
  const [sellPrice, setSellPrice] = useState<number | undefined>(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [errorValidation, setErrorValidation] = useState({
    qty: "",
    buyPrice: "",
    sellPrice: "",
  });
  const [focusesUnit, setFocusedUnit] = useState<
    null | "unit-buy" | "unit-sell"
  >(null);

  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showConfirmPriceChange, setShowConfirmPriceChange] = useState(false);

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        withFeedback
        icon={CloseIcon}
        alt="close"
        onClick={() => handleCloseModal()}
      />
    );
  };

  const renderRightHeader = () => {
    if (selectedExistingProduct) {
      return (
        <TouchableButton
          withFeedback
          icon={DeleteIcon}
          alt="delete"
          onClick={() => setShowDeleteConfirmModal(true)}
        />
      );
    }

    return null;
  };

  const handleChangeQty = (value: number | undefined) => {
    if (value === undefined) {
      setQty(value);
    }

    if ((value || 0) < 0) {
      return;
    }

    setQty(value);
  };

  /**  Get package options get from product detail*/
  const getPackageOptions = useMemo(() => {
    const options = (productDetail?.package || []).map((p) => {
      return {
        label: p.unit_name,
        value: p,
        status: null,
      };
    });

    const smallestUnit = productDetail?.unit!;

    options.unshift({
      label: smallestUnit,
      value: {
        conversion_qty: 1,
        conversion_unit: smallestUnit,
        id: "-1",
        smallest_qty: 1,
        unit_code: productDetail?.unit_code!,
        unit_name: smallestUnit,
      } as Package,
      status: "default" as any,
    });

    return options;
  }, [productDetail]);

  const handleChangeMargin = (value: number | undefined) => {
    setHasChanges(true);

    if (value != NaN && buyPrice) {
      const newValue = value ? +value : undefined;
      setSellMargin(newValue);
    }
  };

  const handleChangeMarginOnBlur = (e?: React.FormEvent) => {
    e?.preventDefault();

    const HPP = getHPP();
    const marginValue = ((sellMargin || 0) / 100) * HPP;
    const result = selectedUnitSell?.value.smallest_qty * (HPP + marginValue);

    setSellPrice(result);
  };

  const handleSetUnitSell = (option: Option) => {
    setHasChanges(true);
    setSelectedUnitSell(option);
    setSellPrice(option?.value.smallest_qty * sellBasePrice);
  };

  const handleChangeSellPrice = (value: number | undefined) => {
    setHasChanges(true);

    const newValue = value ? +value : undefined;

    if (newValue !== NaN) {
      const calculateSellBasePrice =
        (newValue || 0) / selectedUnitSell?.value.smallest_qty;

      setSellBasePrice(calculateSellBasePrice);
      setSellPrice(value);
    }
  };

  const handleChangeSellPriceOnBlur = (e?: React.FormEvent) => {
    e?.preventDefault();

    const HPP = getHPP();
    const margin = ((sellBasePrice - HPP) / HPP) * 100;
    setSellMargin(margin);
  };

  const handleValidation = () => {
    let isValid = true;

    let validation = {
      qty: "",
      buyPrice: "",
      sellPrice: "",
    };

    if (!qty) {
      isValid = false;
      validation.qty = "Kuantitas tidak boleh kosong";
    }

    if (!buyPrice && buyPrice !== 0) {
      isValid = false;
      validation.buyPrice = "Harga beli tidak boleh kosong";
    }

    if (sellPrice === undefined) {
      isValid = false;
      validation.sellPrice = "Harga tidak boleh kosong";
    }

    setErrorValidation(validation);

    return isValid;
  };

  const handleConfirmSave = () => {
    if (!handleValidation()) {
      return;
    }

    if (
      (productDetail?.price || 0) > sellBasePrice &&
      sellPrice !== undefined &&
      !selectedExistingProduct
    ) {
      setShowConfirmPriceChange(true);
      return;
    }

    handleAdd();
  };

  /** Handle add the product to invoice detail if already complete fill the form  */
  const handleAdd = () => {
    const stringDate = ED ? moment(ED).format("YYYY-MM-DD") : null;
    const buyBasePrice = (buyPrice || 0) / selectedUnitBuy?.value.smallest_qty;

    // recalculate margin to make sure margin is already updated
    const HPP = getHPP();
    const calculateMargin = ((sellBasePrice - HPP) / HPP) * 100;

    let newSellMargin = calculateMargin || 0;
    setSellMargin(calculateMargin);

    const newSellPrice =
      sellPrice === undefined ? productDetail?.price || 0 : sellBasePrice;

    if (sellPrice === undefined) {
      if (buyBasePrice === 0) {
        newSellMargin = 0;
      }
    }

    const productData: ExtendProductInvoice = {
      product_id: productDetail?.id!,
      product_name: productDetail?.name,
      qty: qty || 0,
      package_id_buy: selectedUnitBuy?.value.id,
      package_id_sell: selectedUnitSell?.value.id,
      package_buy_detail: selectedUnitBuy?.value!,
      package_sell_detail: selectedUnitSell?.value!,
      ED: stringDate!,
      discount_type_buy: discType.value as DiscountTypes,
      discount_amount: discAmount || 0,
      batch_no: noBatch,
      price_buy: buyBasePrice || 0,
      price_sell: newSellPrice,
      subtotal: getSubtotal,
      margin: newSellMargin,
      is_deleted: 0,
      is_editable: selectedExistingProduct?.is_editable || true,
    };
    setHasChanges(false);

    // handle product from existing invoice
    if (selectedExistingProduct) {
      onEditProduct({
        ...productData,
        index: selectedExistingProduct.index,
        id: selectedExistingProduct.id!,
        is_deleted: 0,
      });
      onCloseModal();
      return;
    }

    onAddProduct(productData);
    onCloseModal();
  };

  const handleChangeUnitBuy = (option: Option) => {
    const HPP = getHPP();
    const sellMargin = ((sellBasePrice - HPP) / HPP) * 100;

    setSelectedUnitBuy(option);
    setSellMargin(sellMargin);
  };

  const setShowAddPackageModal = (
    visible: boolean,
    focused: "unit-buy" | "unit-sell" | null
  ) => {
    dispatch(visiblePackageModal(visible));

    if (focused) {
      setFocusedUnit(focused);
    }
  };

  // get buy price base on smallest unit qty
  const getBuyBasePrice = useMemo(() => {
    let discount = discAmount;

    if (discType.value === "percentage") {
      discount = ((discAmount || 0) / 100) * (buyPrice || 0);
    }

    const calculateBuyBasePrice =
      ((buyPrice || 0) - (discount || 0)) / selectedUnitBuy?.value.smallest_qty;

    return calculateBuyBasePrice;
  }, [discAmount, buyPrice, selectedUnitBuy, discType]);

  const getSubtotal = useMemo(() => {
    const result = getBuyBasePrice * selectedUnitBuy?.value.smallest_qty;

    // prevent minus value
    if (result < 0) {
      return 0;
    }

    return getBuyBasePrice * selectedUnitBuy?.value.smallest_qty * (qty || 0);
  }, [getBuyBasePrice, qty]);

  useEffect(() => {
    dispatch(
      getProductDetailAsync.request({
        productID: selectedProduct?.id! || selectedExistingProduct?.product_id!,
      })
    );
  }, []);

  useEffect(() => {
    if (selectedExistingProduct) {
      const {
        price_buy,
        price_sell,
        qty,
        batch_no,
        package_sell_detail,
        package_buy_detail,
        discount_amount,
        discount_type_buy,
        ED,
        margin,
      } = selectedExistingProduct;

      setSelectedUnitSell({
        label: package_sell_detail?.unit_name,
        value: package_sell_detail,
      });

      setSelectedUnitBuy({
        label: package_buy_detail?.unit_name,
        value: package_buy_detail,
      });

      setNoBatch(batch_no);
      setQty(qty);
      setSellPrice(
        price_sell ? price_sell * package_sell_detail?.smallest_qty! : undefined
      );
      setSellBasePrice(price_sell);
      setBuyPrice(price_buy * package_buy_detail?.smallest_qty!);
      setDiscAmount(discount_amount);
      setED(getValidDate(ED));

      const basePrice = price_buy || 0;

      let HPP = basePrice;
      const vat = basePrice * 0.1;

      if (!includeVAT) {
        HPP = basePrice + vat;
      }

      const calculateMargin = ((price_sell - HPP) / HPP) * 100;
      setSellMargin(calculateMargin || margin || 0);

      let discountLabel = "%";

      if (discount_type_buy === "cash") {
        discountLabel = "Rp";
      }

      setDiscType({ label: discountLabel, value: discount_type_buy });
    }
  }, [selectedExistingProduct]);

  useEffect(() => {
    if (productDetail && productDetail.id === selectedProduct?.id) {
      if (!selectedExistingProduct && !focusesUnit) {
        setSelectedUnitBuy({
          label: productDetail?.unit!,
          value: {
            conversion_qty: 1,
            conversion_unit: productDetail?.unit!,
            id: "-1",
            smallest_qty: 1,
            unit_code: productDetail?.unit_code!,
            unit_name: productDetail?.unit!,
          } as Package,
        });

        setSelectedUnitSell({
          label: productDetail?.unit!,
          value: {
            conversion_qty: 1,
            conversion_unit: productDetail?.unit!,
            id: "-1",
            smallest_qty: 1,
            unit_code: productDetail?.unit_code!,
            unit_name: productDetail?.unit!,
          } as Package,
        });

        setSellPrice(productDetail.price ? productDetail.price : undefined);
        setSellBasePrice(productDetail.price);
      }
    }
  }, [productDetail, selectedExistingProduct]);

  const handleCloseModal = () => {
    if (hasChanges) {
      setShowConfirmExitModal(true);
      return;
    }

    onCloseModal();
  };

  const handleChangeBuyPrice = (price: number | undefined) => {
    setHasChanges(true);
    setBuyPrice(price);

    const currentBuyPrice = (price || 0) * selectedUnitBuy?.value.smallest_qty;
    const baseSell = currentBuyPrice * selectedUnitBuy?.value.smallest_qty;

    const hasPrice =
      selectedExistingProduct?.price_sell === 0 ||
      !!selectedProduct?.price ||
      !!selectedExistingProduct?.price_sell;

    if (!hasPrice) {
      const newSellPrice =
        currentBuyPrice / selectedUnitSell?.value.smallest_qty;

      setSellPrice(newSellPrice);
      setSellBasePrice(baseSell);
    }
  };

  const discountInputValidation = (value: NumberFormatValues) => {
    return discType.value === "percentage"
      ? +value.value >= 0 && +value.value <= 100
      : true;
  };

  const handleChangeBatchNo = (text: string) => {
    setHasChanges(true);
    setNoBatch(text.toUpperCase());
  };

  const getHPP = () => {
    const basePrice = Math.ceil(
      (buyPrice || 0) / selectedUnitBuy?.value.smallest_qty
    );
    const vat = basePrice * 0.1;

    if (!includeVAT) {
      let total = basePrice + vat;
      return total;
    }

    return basePrice;
  };

  const isDisable = useMemo(() => {
    return (
      showAddPackageModal ||
      showConfirmExitModal ||
      showDeleteConfirmModal ||
      showConfirmExitModal
    );
  }, [
    showAddPackageModal,
    showConfirmExitModal,
    showDeleteConfirmModal,
    showConfirmExitModal,
  ]);

  const handleSelectNewCreatedPackage = (o: Option) => {
    if (focusesUnit === "unit-buy") {
      setSelectedUnitBuy(o);
      return;
    }

    if (focusesUnit === "unit-sell") {
      setSelectedUnitSell(o);
      return;
    }
  };

  return (
    <>
      {showAddPackageModal ? (
        <AddPackageModal
          packages={productDetail?.package || []}
          unitOptions={getPackageOptions}
          selectedCatalog={productDetail}
          onSelectNewPackage={handleSelectNewCreatedPackage}
          onCloseModal={() => setShowAddPackageModal(false, null)}
        />
      ) : null}
      <Overlay isVisible={!showAddPackageModal}>
        {/* modal for leaving confirmation */}
        <ModalComponent
          title="Perubahan belum disimpan"
          isVisible={
            showConfirmExitModal && hasChanges && !showModalLeavingPage
          }
          subtitle="Jika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
          maxWidth="320px"
          destructiveTitle="Keluar Halaman"
          destructiveFunction={() => onCloseModal()}
          acceptTitle={"Batal"}
          acceptFunction={() => {
            setShowConfirmExitModal(false);
          }}
        />

        {/* modal for delete confirmation */}
        {showConfirmPriceChange ? (
          <ModalComponent
            title={`Yakin ubah harga ke Rp${sellBasePrice} dari Rp${
              productDetail?.price || 0
            }?`}
            isVisible={showConfirmPriceChange}
            subtitle=""
            maxWidth="320px"
            destructiveTitle="Ubah"
            destructiveFunction={() => handleAdd()}
            acceptTitle={"Batal"}
            acceptFunction={() => {
              setShowConfirmPriceChange(false);
            }}
          />
        ) : null}

        {/* modal for delete confirmation */}
        {selectedExistingProduct ? (
          <ModalComponent
            title={`Hapus ${productDetail?.name} ?`}
            isVisible={showDeleteConfirmModal}
            subtitle="Barang yang sudah dihapus tidak dapat dikembalikan lagi."
            maxWidth="320px"
            destructiveTitle="Hapus"
            destructiveFunction={() =>
              onDeleteProduct(selectedExistingProduct.index!)
            }
            acceptTitle={"Batal"}
            acceptFunction={() => {
              setShowDeleteConfirmModal(false);
            }}
          />
        ) : null}

        <Modal isVisible={!showAddPackageModal}>
          <TopBar
            title={
              selectedExistingProduct?.id ? "Edit barang" : "Tambahkan barang"
            }
            leftHeader={renderLeftHeader}
            rightHeader={renderRightHeader}
            isModal
          />
          <BodyPage>
            <Box p="24px">
              <H2>{productDetail?.name}</H2>

              <Flex justifyContent="center" width="100%" mt={3}>
                <Button
                  icon={SubstractIcon}
                  secondary
                  onClick={() => handleChangeQty(+(qty || 0) - 1)}
                  disabled={isDisable}
                />
                <Box px="0" width="100%">
                  <TextInput
                    type="number"
                    value={qty}
                    onChange={(value) =>
                      handleChangeQty(value ? +value : undefined)
                    }
                    textAlign="center"
                    isError={!!errorValidation.qty}
                    disabled={isDisable}
                  />
                </Box>
                <Button
                  icon={AddIcon}
                  secondary
                  onClick={() => handleChangeQty(+(qty || 0) + 1)}
                  disabled={isDisable}
                />
              </Flex>

              {errorValidation.qty ? (
                <Flex alignItems="center" mt="4px">
                  <Box>
                    <img src={AlertIcon} alt="alert" width="16" height="16" />
                  </Box>
                  <Text ml={0} fontSize="2" lineHeight="2" color="coralDark">
                    {errorValidation.qty}
                  </Text>
                </Flex>
              ) : null}

              <Flex mt={0}>
                <Select
                  label="Satuan beli"
                  selectedOption={selectedUnitBuy}
                  options={getPackageOptions}
                  onSelect={(option) => handleChangeUnitBuy(option)}
                  width={200}
                  createAction={() => setShowAddPackageModal(true, "unit-buy")}
                  isDisabled={isDisable}
                />
              </Flex>

              <Box mt={3}>
                <H3>Info Batch</H3>
                <Box mt={0}>
                  <TextInput
                    label="Nomor batch"
                    value={noBatch}
                    onChange={(value) => handleChangeBatchNo(value)}
                    disabled={isDisable}
                  />
                </Box>
                <Box mt={0}>
                  <DatePicker
                    label="Tanggal ED"
                    onChange={(date) => setED(date)}
                    selectedDate={ED}
                    disabled={isDisable}
                  />
                </Box>
              </Box>

              <Box mt={3}>
                <H3>Harga beli</H3>
                <Box mt={0}>
                  <TextInput
                    allowNegative={false}
                    label={`Harga ${
                      includeVAT ? `(termasuk PPN)` : "(tanpa PPN)"
                    }`}
                    type="number"
                    unitLabel={`/${selectedUnitBuy?.label}`}
                    inputLabel="Rp"
                    value={buyPrice}
                    decimalScale={2}
                    onChange={(value) =>
                      handleChangeBuyPrice(value ? +value : undefined)
                    }
                    isError={!!errorValidation.buyPrice}
                    subtitleType={"error"}
                    subtitle={errorValidation.buyPrice}
                    disabled={isDisable}
                  />
                  <Text fontSize="2" lineHeight="2"></Text>
                </Box>
                <Flex mt={0}>
                  <Select
                    label="Diskon"
                    options={discountType}
                    onSelect={(value) => {
                      setDiscType(value);
                      setHasChanges(true);
                    }}
                    selectedOption={discType}
                    width={72}
                    isDisabled={isDisable}
                  />
                  <Box ml={0}>
                    <TextInput
                      allowNegative={false}
                      type="number"
                      label={"\xa0"}
                      value={discAmount}
                      decimalScale={8}
                      onChange={(value) => {
                        setDiscAmount(value ? +value : undefined);
                        setHasChanges(true);
                      }}
                      unitLabel={discType.value === "percentage" ? "%" : null}
                      numberValidation={(value) =>
                        discountInputValidation(value)
                      }
                      disabled={isDisable}
                    />
                  </Box>
                </Flex>
              </Box>

              {isEdit && selectedExistingProduct?.id ? null : (
                <Box mt={3}>
                  <H3>Ubah harga jual</H3>
                  <Text fontFamily="Nunito">
                    Harga jual akan berlaku setelah faktur disimpan dan secara
                    otomatis akan dikonversi ke semua satuan & kemasan.
                  </Text>
                  <Flex mt="1">
                    <Flex flex="2" flexDirection="column">
                      <Text fontSize="2" lineHeight="2">
                        Harga beli sesudah PPN
                      </Text>
                      <Flex alignItems="center" height="100%">
                        <Text fontSize="2" lineHeight="2">
                          Rp{nominalFormat(getHPP())}/{productDetail?.unit}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex flex="2">
                      <Select
                        label="Satuan jual"
                        selectedOption={selectedUnitSell}
                        options={getPackageOptions}
                        onSelect={(option) => handleSetUnitSell(option)}
                        width={130}
                        createAction={() =>
                          setShowAddPackageModal(true, "unit-sell")
                        }
                        isDisabled={isDisable}
                      />
                    </Flex>
                  </Flex>

                  <>
                    <Flex mt={1}>
                      <Flex>
                        <form onSubmit={handleChangeSellPriceOnBlur}>
                          <TextInput
                            label="Harga"
                            type="number"
                            inputLabel="Rp"
                            unitLabel={`/${selectedUnitSell?.label}`}
                            value={sellPrice === undefined ? "" : sellPrice}
                            decimalScale={0}
                            onChange={(value) =>
                              handleChangeSellPrice(value ? +value : undefined)
                            }
                            allowNegative={false}
                            onBlur={handleChangeSellPriceOnBlur}
                            disabled={isDisable}
                            hidePlaceholder
                          />
                        </form>
                      </Flex>
                      <Flex ml={0}>
                        <form onSubmit={handleChangeMarginOnBlur}>
                          <TextInput
                            label="Margin"
                            unitLabel="%"
                            value={sellMargin}
                            decimalScale={2}
                            onChange={(value) =>
                              handleChangeMargin(value ? +value : undefined)
                            }
                            type="number"
                            numberValidation={(value) => +value.value >= -100}
                            onBlur={handleChangeMarginOnBlur}
                            disabled={isDisable}
                          />
                        </form>
                      </Flex>
                    </Flex>

                    {errorValidation.sellPrice ? (
                      <Flex mt="4px">
                        <img src={AlertIcon} width="14" height="14" />
                        <Text variant="sm" ml="0" color="coral">
                          {errorValidation.sellPrice}
                        </Text>
                      </Flex>
                    ) : (
                      <Text fontSize="2" lineHeight="2" mt="4px">
                        Harga saat ini adalah{" "}
                        <span className="bold">
                          {productDetail?.price
                            ? `Rp${nominalFormat(productDetail?.price)}`
                            : "-"}
                          /{productDetail?.unit}
                        </span>
                      </Text>
                    )}
                  </>
                </Box>
              )}
            </Box>

            <StickyBottomContainer
              position="absolute"
              bottom="0"
              width="calc(100% - 2rem)"
              alignItems="center"
              height="52px"
              px="1"
            >
              <Flex justifyContent="space-between" width="100%">
                <Flex alignItems="center">
                  <H4>Total</H4> &nbsp;
                  <Text fontSize="2" lineHeight="2">
                    Rp{nominalFormat(getSubtotal)}
                  </Text>
                </Flex>
                <Button
                  title="Simpan"
                  onClick={handleConfirmSave}
                  disabled={
                    isLoading ||
                    !qty ||
                    (!buyPrice && buyPrice !== 0) ||
                    isDisable
                  }
                />
              </Flex>
            </StickyBottomContainer>
          </BodyPage>
        </Modal>
      </Overlay>
    </>
  );
};

const BodyPage = styled(Body)`
  padding: 0px;
  overflow-y: scroll;
  height: 100%;
`;

export default InvoiceAddProduct;
