import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { doLogin } from "../../reducers/user/user.actionThunk";
import { handleChangeInputUser } from "../../reducers/user/user.action";
import { setNewPasswordAsync } from "../../reducers/auth/auth.action";
import { showToast } from "../../reducers/toast/toast.action";

// asset
import FarmacareLogo from "../../asset/img/logo.png";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import { Button } from "../../component/Button";
import { BodyPage, H1, Text, Wrapper } from "../../component/Styles";

// type
import { States } from "../../types/general";
import { phoneValidation } from "../../utils/phoneValidation";

const Invitation = () => {
  const dispatch = useDispatch();

  const routerLocation = useSelector((state: States) => state.router.location);
  const isLoading = useSelector((state: States) => state.auth.isLoading);

  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  const passwordValidation = () => {
    let isValid = false;
    if (
      /[0-9]/g.test(password) &&
      /[a-zA-Z]/g.test(password) &&
      password.length > 7
    ) {
      isValid = true;
    }

    return isValid;
  };

  const handleShowToast = () => {
    dispatch(
      showToast.request({
        type: "error",
        message: "Tolong isi data dengan benar",
      })
    );
  };

  const handleLogin = () => {
    const location: any = { ...routerLocation };
    const { query } = location;
    const username = query?.username;
    const password = query?.password;

    dispatch(
      handleChangeInputUser({ target: { id: "identifier", value: username } })
    );
    dispatch(
      handleChangeInputUser({ target: { id: "password", value: password } })
    );
    dispatch(doLogin());
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const location: any = { ...routerLocation };
    const { query } = location;
    const username = query?.username;

    if (!passwordValidation() || !phoneValidation(phoneNumber)) {
      handleShowToast();
      return;
    }

    if (password) {
      dispatch(
        setNewPasswordAsync.request({
          password,
          username: username,
          name,
          phoneNumber,
        })
      );
    }
  };

  useEffect(() => {
    // login to get session ID to set new password
    handleLogin();
  }, []);

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <Wrapper p="2">
        <Body>
          <H1>Terima Undangan</H1>
          <Text mt={0}>
            Formulir ini akan kadaluarsa dalam waktu singkat. <br /> Segera
            lengkapi formulir berikut untuk menerima undangan.
          </Text>
          {/* <div className="invitation__subtitle">
          Selamat datang di Farmacare. Untuk menerima undangan, yuk lengkapi
          data berikut.
        </div> */}
          <form onSubmit={(e) => handleSave(e)}>
            <div className="invitation__input">
              <TextInput
                label="Nama lengkapmu"
                placeholder="Masukkan nama lengkap"
                value={name}
                onChange={(text) => setName(text)}
              />
            </div>
            <div className="invitation__input">
              <TextInput
                label="Nomor teleponmu"
                placeholder="Masukkan no telepon"
                value={phoneNumber}
                onChange={(text) => setPhoneNumber(text)}
                subtitle={"Contoh: 081234567890"}
                type="number"
                disableNumberFormatting
                allowLeadingZeros
              />
            </div>
            <div className="invitation__input">
              <TextInput
                label="Buat password"
                placeholder="Masukkan password"
                value={password}
                onChange={(text) => setPassword(text)}
                subtitle={"Minimal 8 karakter, terdiri dari huruf dan angka"}
                type="password"
              />
            </div>
            <div className="button-container">
              <Button
                title="Terima undangan"
                fullWidth
                isLoading={isLoading}
                type="submit"
                disabled={!password || !phoneNumber || !name}
              />
            </div>
          </form>
        </Body>
      </Wrapper>
    </Layout>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
`;

const Body = styled(BodyPage)`
  margin-top: 1rem;

  .invitation__input {
    margin-top: 1rem;
  }

  .button-container {
    margin-top: 1.5rem;
  }

  form {
    margin-top: 1.5rem;
  }
`;

export default Invitation;
