// config
import axios from "../config/axios";

// constant
import { BASE_URL_BETA } from "../constant/api";

// utils
import localStorageService from "./localStorageService";
import errorReporter from './sentryErrorReporter'

// type
import { Package } from "../types/package";

export type Payload = Partial<Package> & { unit: string };

export const postNewPackage = async (payload: Payload) => {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/package`;
    const response = await axios.post(url, payload);

    if (response && response.status === 201) {
      const p = response.data.package;
      return p;
    }
  } catch (err) {
    errorReporter(err,  'POST_NEW_PACKAGE', payload)
    throw new Error(err);
  }
};
