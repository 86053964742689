import React, { useEffect, useMemo, useState } from "react";
import { push } from "connected-react-router";
import debounce from "lodash/debounce";

// component
import Layout from "../../component/Layout";
import Header from "../../component/Header/Header";
import TopBar from "../../component/TopBar";
import SearchBar from "../../component/SearchBar";
import Select from "../../component/Select";
import { BodyPage, Box, Flex, Text, Wrapper } from "../../component/Styles";
import InventoryDetailStockCardList from "./InventoryDetailStockCardList";
import Pagination from "../../component/Pagination";
import { Spinner } from "../../component/Loading";

// types
import { Option, States } from "../../types/general";
import { useDispatch, useSelector } from "react-redux";
import { getURLPathID } from "../../utils/getURLPathID";
import { getStockListAsync } from "../../reducers/inventory/inventory.action";

const InventoryStockHistory = () => {
  const dispatch = useDispatch();

  const sortOptions = [
    {
      label: "Urut transaksi terbaru",
      value: { sorted_by: "created_at", order_by: "asc" },
    },
    {
      label: "Urut transaksi terlama",
      value: { sorted_by: "created_at", order_by: "desc" },
    },
  ];

  const [activeSorting, setActiveSorting] = useState({
    label: "Urut transaksi terbaru",
    value: { sorted_by: "created_at", order_by: "asc" },
  });

  // state
  const [searchFilter, setSearchFilter] = useState("");

  // redux state
  const location = useSelector((state: States) => state.router.location);
  const product = useSelector((state: States) => state.inventory.detail);
  const stockList = useSelector((state: States) => state.inventory.stockList);
  const isLoading = useSelector(
    (state: States) => state.inventory.stockLoading
  );
  const currentPage = useSelector(
    (state: States) => state.inventory.stockPageIndex
  );
  const totalData = useSelector(
    (state: States) => state.inventory.stockPageTotalData
  );

  const getInventoryID = useMemo(() => {
    return getURLPathID(location.pathname, 2);
  }, [location]);

  // handle
  const handleChangeSearchFilter = debounce((text: string) => {
    dispatch(
      getStockListAsync.request({
        productID: getInventoryID,
        search: text,
        sorted_by: activeSorting.value.sorted_by,
        order_by: activeSorting.value.order_by,
        history: true,
      })
    );
    setSearchFilter(text);
  }, 800);

  const handleSelectActiveSort = (option: Option) => {
    dispatch(
      getStockListAsync.request({
        productID: getInventoryID,
        sorted_by: option.value.sorted_by,
        order_by: option.value.order_by,
        history: true,
      })
    );
    setActiveSorting(option);
  };

  const handleChangePage = (pageIndex: number) => {
    if (getInventoryID && !isNaN(+getInventoryID)) {
      dispatch(
        getStockListAsync.request({
          productID: getInventoryID,
          history: true,
          page: pageIndex,
          search: searchFilter,
          sorted_by: activeSorting.value.sorted_by,
          order_by: activeSorting.value.order_by,
        })
      );
    }
  };

  useEffect(() => {
    if (getInventoryID && !isNaN(+getInventoryID)) {
      dispatch(
        getStockListAsync.request({
          productID: getInventoryID,
          history: true,
        })
      );
    }
  }, [getInventoryID]);

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  return (
    <Layout>
      <TopBar title={product?.name} leftHeaderType="back" />
      <Wrapper p="2">
        <Header
          title={isLoading ? "Memuat..." : "Riwayat stok"}
          backFunc={() => handleNavigate(`/inventory/${product?.id}`)}
          backTitle={product?.name}
        />

        <BodyPage p="1">
          <Flex mt={2} flexDirection="column">
            <Text fontSize={2} lineHeight={2} color="black">
              Sisa stok
            </Text>
            <Text fontSize={4} lineHeight={4} color="black">
              {product?.stock_qty} {product?.stock_unit}
            </Text>
          </Flex>

          <Flex mt={3} maxWidth="400px">
            <SearchBar
              onChange={(text) => handleChangeSearchFilter(text)}
              placeholder="Cari transaksi"
            />
          </Flex>

          <Box mt={1} display={["block", "block", "none"]}>
            <Select
              options={sortOptions}
              selectedOption={activeSorting}
              onSelect={(option) => handleSelectActiveSort(option)}
            />
          </Box>

          <Box mt={2}>
            {isLoading ? (
              <Spinner />
            ) : (
              <InventoryDetailStockCardList
                list={stockList}
                activeFilter={activeSorting}
                setActiveFilter={handleSelectActiveSort}
                inventoryID={getInventoryID}
              />
            )}
          </Box>

          {!isLoading ? (
            <Flex justifyContent="center">
              <Pagination
                currentPage={currentPage}
                totalData={1}
                isLoading={isLoading}
                onChangePage={handleChangePage}
                totalPage={Math.ceil((totalData || 1) / 50)}
              />
            </Flex>
          ) : null}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default InventoryStockHistory;
