import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

// constant
import theme from "../../constant/styledTheme";

// asset
import CalendarIcon from "../../asset/icon/calendar.svg";
import ChevronIcon from "../../asset/icon/chevron-down.svg";

// type
import { States } from "../../types/general";

// component
import { Text } from "../../component/Styles";

export type TapInputProps = {
  bold?: boolean;
  label?: string;
  value?: string;
  type?: "calendar" | "select" | "company";
  onTap?: () => void;
  noBorder?: boolean;
};

const TapInput = (props: TapInputProps) => {
  const { bold, value, label, type, noBorder, onTap } = props;

  const selectedCompany = useSelector(
    (state: States) => state.company.selectedCompany
  );

  const handleOnClick = () => {
    if (onTap) {
      onTap();
      return;
    }
    return;
  };

  const renderIcon = useMemo(() => {
    switch (type) {
      case "calendar":
        return (
          <div className="tap-input__icon calendar__icon">
            <img src={CalendarIcon} alt="calendar" />
          </div>
        );
      case "company":
      case "select":
        return (
          <div className="tap-input__icon select__icon">
            <img src={ChevronIcon} alt="select" />
          </div>
        );
      default:
        return (
          <div className="tap-input__icon select__icon">
            <img src={ChevronIcon} alt="select" />
          </div>
        );
    }
  }, [type]);

  const renderValue = () => {
    if (type === "company") {
      return (
        <CompanyContainer
          fontSize="20px"
          lineHeight="4"
          color="blueDark"
          fontWeight="700"
          rubik
        >
          {selectedCompany?.name}
        </CompanyContainer>
      );
    }

    return <div className="tap-input__value text">{value}</div>;
  };

  return (
    <Wrapper onClick={handleOnClick} noBorder={!!noBorder} bold={!!bold}>
      {label ? <div className="tap-input__label text">{label}</div> : null}
      <div className="tap-input__container">
        {renderValue()}
        {renderIcon}
      </div>
    </Wrapper>
  );
};

const CompanyContainer = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 40px - 70px);

  @media (min-width: ${theme.breakpoints[3]}) {
    max-width: calc(100vw - 40px - 70px - 200px);
  }
`;

const Wrapper = styled.div<{ noBorder: boolean; bold: boolean }>`
  .tap-input__container {
    display: flex;
    align-items: center;
    width: 100%;
    border: ${({ noBorder }) =>
      noBorder ? "none" : `solid 2px ${theme.colors.whiteDarker}`};
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    > .tap-input__value {
      display: flex;
      align-items: center;
      font-weight: ${({ bold }) => (bold ? "700" : "normal")};
      width: 100%;
      padding: 5px 8px;
    }

    > .tap-input__icon {
      display: flex;
      align-items: center;
      background-color: "#fff";
    }

    .calendar__icon {
      background-color: ${theme.colors.whiteDarker};
    }

    .select__icon {
      height: 100%;
      background: #fff;
    }
  }

  :active {
    outline: 1px solid ${theme.colors.blue};
  }
`;

export default TapInput;
