import React from "react";
import styled from "styled-components";

// constant
import { colors } from "../../constant/theme";

// type
import { ProductSummary } from "../../types/transaction";

// utils
import { nominalFormat } from "../../utils/nominalFormat";

export type ProductItemProps = {
  product: ProductSummary;
};

const ProductItem = (props: ProductItemProps) => {
  const { product } = props;

  return (
    <Container>
      <div className="product-item__left">
        <div className="product__title text"> {product.name}</div>
        <div className="product__subtitle subtitle">
          Total penjualan Rp{nominalFormat(product.subtotal)}
        </div>
      </div>
      <div className="product-item__right">
        <div className="text">{`${nominalFormat(product.qty)} ${
          product.unit
        }`}</div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 8px 0px;
  border-bottom: 1px solid ${colors.secondary2};

  .product-item__left {
    display: flex;
    flex: 7;
    justify-content: space-between;
    flex-direction: column;

    > .product__title {
      font-weight: 700;
    }
  }

  .product-item__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 3;
  }
`;

export default ProductItem;
