import React, { useMemo } from "react";

// component
import { Flex, H1, Text } from "../Styles";
import { Button } from "../../component/Button";
import { ArrowBackIcon } from "../../asset/icon";

export type Props = {
  actionButton?: any;
  title?: string;
  backTitle?: string;
  disabled?: boolean;
  backFunc?: () => void;
};

const Header = (props: Props) => {
  const { actionButton, title, backTitle, disabled, backFunc } = props;

  const getTopTitleMargin = useMemo(() => {
    if (actionButton || backFunc) {
      return 1;
    }

    return 0;
  }, [actionButton || backFunc]);

  const handleBack = () => {
    if (disabled) {
      return;
    }
    backFunc!();
  };

  return (
    <Flex
      width="100%"
      maxWidth="1400px"
      flexDirection={["column", "column", backFunc ? "column" : "row-reverse"]}
      justifyContent={["none", "none", "space-between"]}
    >
      {actionButton || backFunc ? (
        <Flex
          width="100%"
          flexDirection={[
            backFunc ? "row-reverse" : "row",
            backFunc ? "row-reverse" : "row",
            backFunc ? "row-reverse" : "row",
            backFunc ? "row-reverse" : "row",
            "row",
          ]}
          alignItems="center"
          justifyContent={backFunc ? "space-between" : "flex-end"}
        >
          {backFunc ? (
            <Flex
              alignItems="center"
              display={["none", "none", "none", "none", "flex"]}
            >
              <Button
                icon={ArrowBackIcon}
                secondary
                onClick={() => handleBack()}
              />
              <Text ml="1"> {backTitle} </Text>
            </Flex>
          ) : null}
          <Flex justifyContent="flex-end">{actionButton}</Flex>
        </Flex>
      ) : null}
      <Flex justifyContent="flex-start" mt={getTopTitleMargin} width="100%">
        <H1>{title}</H1>
      </Flex>
    </Flex>
  );
};

export default Header;
