import React from "react";
import styled from "styled-components/macro";
import moment from "moment";

// component
import { Flex, RotatedBox, Text } from "../../component/Styles";

// type
import { InventoryBatch } from "../../types/inventory";

// Asset
import { ArrowBackIcon, Edit } from "../../asset/icon";
import { nominalFormat } from "../../utils/nominalFormat";

// util
import { renderDate } from "./utils/renderDate";
import { getValidDate } from "../../utils/getValidDate";

export type Props = {
  onClick?: (stock?: InventoryBatch) => void;
  inventory: InventoryBatch;
  isEdit?: boolean;
};

const InventoryBatchItem = (props: Props) => {
  const { onClick, inventory, isEdit } = props;

  const renderStockChanges = () => {
    if (inventory?.current_qty) {
      return (
        <Flex alignItems="center">
          <Text fontWeight="bold" fontSize="2" lineHeight="2" textAlign="right">
            {` ${inventory.batch_qty}`}
          </Text>
          <Flex mx={0}>
            <RotatedBox direction="flip-x">
              <img src={ArrowBackIcon} alt="ke" width="24" height="24" />
            </RotatedBox>
          </Flex>
          <Text fontWeight="bold" fontSize="2" lineHeight="2">
            <span
              className={
                inventory.batch_qty < inventory.current_qty
                  ? "success"
                  : "danger"
              }
            >
              {nominalFormat(inventory.current_qty)}
            </span>{" "}
            {inventory.unit}
          </Text>
        </Flex>
      );
    }

    return (
      <Text fontWeight="bold" fontSize="2" lineHeight="2" textAlign="right">
        <span className={+inventory.batch_qty === 0 ? "danger" : undefined}>
          {inventory.batch_qty}
        </span>
        {/* TODO: change to unit name */}
        {` ${inventory.unit}`}
      </Text>
    );
  };

  const handleClick = () => {
    if (!onClick) {
      return;
    }

    onClick(inventory);
  };

  return (
    <FlexCursor
      px={0}
      pb={1}
      pt={0}
      borderBottom="1px solid"
      borderColor="blackLighter"
      onClick={() => handleClick()}
      backgroundColor={
        inventory.batch_qty === 0 || inventory.current_qty === 0
          ? "whiteDark"
          : "#fff"
      }
    >
      {isEdit ? (
        <Flex flex={1} alignItems="center" justifyContent="center">
          <img src={Edit} width="24" height="24" alt="Edit" />
        </Flex>
      ) : (
        ""
      )}
      <Flex flex={9} flexDirection="column" pl={1}>
        <Flex flex={9}>
          <Flex flex={1}>
            <Text
              color="blueDark"
              fontWeight="bold"
              fontSize="2"
              lineHeight="2"
            >
              {inventory.batch_no || "STOCK"}
            </Text>
          </Flex>
          <Flex flex={2} justifyContent="flex-end">
            {renderStockChanges()}
          </Flex>
        </Flex>
        <Flex mt={8}>
          <Flex flex={1} flexDirection="column">
            <Text fontSize="1" lineHeight="1" fontWeight="bold">
              ED
            </Text>
            <Text fontSize="1" lineHeight="1">
              {renderDate(inventory.ED)}
            </Text>
          </Flex>
          <Flex flex={1} flexDirection="column">
            <Text fontSize="1" lineHeight="1" fontWeight="bold">
              Tanggal diterima
            </Text>
            <Text fontSize="1" lineHeight="1">
              {getValidDate(inventory.date_purchase)
                ? moment(getValidDate(inventory.date_purchase)).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </FlexCursor>
  );
};

const FlexCursor = styled(Flex)`
  cursor: pointer;
`;

export default InventoryBatchItem;
