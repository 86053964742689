import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

// asset
import CloseIcon from "../../asset/icon/close.svg";
import SidebarList from "./sidebarList.json";

//component
import SidebarItem from "./SidebarItem";
import { TouchableButton } from "../../component/Button";

// redux action
import { setShowSidebar } from "../../reducers/navigation/navigation.action";

// constant
import { APP_VERSION } from "../../constant/version";
import theme from "../../constant/styledTheme";
import metadata from "../../constant/metadata.json";
import { ENV } from "../../constant/env";

// type
import { States } from "../../types/general";

const Sidebar = () => {
  const dispatch = useDispatch();

  const router = useSelector((state: States) => state.router.location);

  const handleCloseSidebar = () => {
    dispatch(setShowSidebar(false));
  };

  const sidebarList = SidebarList;

  const bioString = localStorage.getItem("bio");

  const getActiveNav = (path: string, navItem: string) => {
    const splitPath = path.split("/");

    if (splitPath[1] === navItem) {
      return true;
    }

    return false;
  };

  const renderNavList = useMemo(() => {
    const bio = JSON.parse(bioString!);
    const path = router.pathname;

    const isOwner = bio?.role === "owner";
    const sidebarItems = sidebarList.map((option, index) => {
      return (
        <SidebarItem
          item={option}
          key={`sidebar-item-${index}`}
          isActive={getActiveNav(path, `${option.value}`)}
        />
      );
    });

    const employeeIndex = sidebarList.findIndex(
      (el) => el.value === "employee"
    );

    if (!isOwner) {
      sidebarItems.splice(employeeIndex, 1);
    }

    return sidebarItems;
  }, [bioString, router]);

  return (
    <Wrapper onClick={handleCloseSidebar}>
      <Container>
        <div className="button--close">
          <TouchableButton
            icon={CloseIcon}
            alt="close"
            onClick={handleCloseSidebar}
            noBackground
            lightIcon
          />
        </div>
        <div className="sidebar__list">{renderNavList}</div>

        <div className="sidebar__bottom">
          <SidebarItem
            item={{ label: "Logout", value: "logout" }}
            isActive={false}
          />
          <div className="sidebar__version">Farmacare {APP_VERSION}</div>
          {ENV === "development" || ENV === "test" ? (
            <div className="sidebar__version">
              Build {metadata.buildDate}.{metadata.buildMajor}.
              {metadata.buildMinor}.{metadata.buildRevision}/
              {metadata.buildNumber}
            </div>
          ) : null}
        </div>
      </Container>
    </Wrapper>
  );
};

const slideInAnimation = keyframes`
  100% { transform: translateX(0%); }   
`;

const Wrapper = styled.div`
  z-index: 99;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
`;

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-width: 240px;
  width: 30%;
  padding: 16px 8px;
  background: ${theme.colors.blueDark};
  z-index: 3;
  transform: translateX(-100%);
  animation: ${slideInAnimation} 0.4s forwards;

  .button--close {
    margin-left: 0px;
  }

  .sidebar__list {
  }

  .sidebar__bottom {
    position: absolute;
    width: calc(100% - 32px);
    bottom: 20px;

    .sidebar__version {
      margin-left: 8px;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;

      :not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;

export default Sidebar;
