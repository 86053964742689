import { action, createAsyncAction } from 'typesafe-actions'

// types
import * as types from './home.actionTypes';
import {filter, transaction} from '../../types'

export const setEmployeeFilter = (filter: {[key: string]: boolean}) => action(types.SET_EMPLOYEE_FILTER, { filter })

export const setSelectedDate = (date: filter.SelectedDate) => action(types.SET_SELECTED_DATE, { date  })

export const setPeriodType = (period: filter.PeriodType) => action(types.SET_PERIOD_TYPE, {period});

export const getDashboardSummaryAsync = createAsyncAction(
    types.GET_DASHBOARD_SUMMARY_REQUEST,
    types.GET_DASHBOARD_SUMMARY_SUCCESS,
    types.GET_DASHBOARD_SUMMARY_FAILURE
  )<undefined, transaction.TransactionSummary, undefined>();