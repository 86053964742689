import { takeLeading, put, delay } from "redux-saga/effects";
import { PURGE } from "redux-persist";
import { push } from "connected-react-router";
import { setShowSidebar } from "../../reducers/navigation/navigation.action";

// redux action
import { showToast } from "../toast/toast.action";

// config
import axios from "../../config/axios";

// related redux
import * as actions from "./auth.action";
import * as types from "./auth.actionTypes";

// constant
import { BASE_URL_BETA } from "../../constant/api";

// utils
import localStorageService from "../../utils/localStorageService";
import errorReporter from '../../utils/sentryErrorReporter'

function* submitForgotPassword(
  action: ReturnType<typeof actions.submitForgotPasswpordAsync.request>
) {
  try {
    const { identifier } = action.payload;
    const url = `${BASE_URL_BETA}/forget-password`;

    const bodyRequest = {
      identifier: identifier,
    };

    const response = yield axios.post(url, bodyRequest, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    if (response && response.status === 200) {
      yield put(push(`/forgot-password/sent?email=${identifier}`));
      yield put(
        showToast.request({
          duration: 5000,
          message: "Permintaan mu telah dikirim",
          type: "success",
        })
      );
      yield put(actions.submitForgotPasswpordAsync.success());
    }
  } catch (error) {
    
    yield put(
      showToast.request({
        duration: 5000,
        message: "Gagal memproses",
        type: "error",
      })
      );
    errorReporter(error,  types.POST_FORGOT_PASSWORD_REQUEST, action.payload)
    yield put(actions.submitForgotPasswpordAsync.failure({ error: error.message }));
  }
}

function* setNewPassword(
  action: ReturnType<typeof actions.setNewPasswordAsync.request>
) {
  try {
    const { password, username, name, phoneNumber } = action.payload;
    const session = localStorageService.getSessionID();

    if (!password || !session) {
      throw new Error("Invalid input");
    }

    const url = `${BASE_URL_BETA}/create-password`;
    const payload = {
      username,
      new_password: password,
      session,
      name,
      phone_number: phoneNumber
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 200) {
      localStorageService.clearSessionID();
      yield put(
        showToast.request({
          duration: 5000,
          message: "Berhasil mengubah password",
          type: "success",
        })
      );
      yield put(actions.setNewPasswordAsync.success());
      yield put(push("/signin"));
    }
  } catch (err) {
    if(err.response.status === 410){
      yield put(showToast.request({ message: "Anda sudah terdaftar, silahkan melakukan", duration: 5000, type: "error" }))
      yield put(push('/signin'));
      return;
    }
    
    errorReporter(err,  types.SET_NEW_PASSWORD_REQUEST, action.payload)
    yield put(
      showToast.request({
        duration: 5000,
        message: "Gagal mengubah password",
        type: "error",
      })
    );
    yield put(actions.setNewPasswordAsync.failure({ error: err.message }));
  }
}

function* submitNewPasswordForgot(
  action: ReturnType<typeof actions.submitNewPasswordForgotAsync.request>
) {
  try {
    const { username, newPassword, code } = action.payload;
    const url = `${BASE_URL_BETA}/confirm-password`;

    const bodyRequest = {
      identifier: username,
      new_password: newPassword,
      code: code,
    };

    const response = yield axios.post(url, bodyRequest, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    if (response.data.status_code === 200) {
      yield put(push("/signin"));
      yield put(showToast.request({ message: "Sukses ganti password", duration: 5000, type:"success"}))
    } 
  } catch (err) {
    if(err.response.status === 410){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan request sesi baru di halaman lupa password", duration: 5000, type:"error"}))
      yield put(push("/forgot-password"));
      return
    }
    errorReporter(err,  types.SET_NEW_PASSWORD_REQUEST, action.payload)
    yield put(showToast.request({ message: "Gagal memproses lupa password", duration: 5000, type:"error"}))
  }
}

function* handleLogin(action: ReturnType<typeof actions.loginAsync.request>) {
  try {
    const { identifier, password } = action.payload;

    if (!identifier || !password) {
      throw new Error("Invalid input");
    }

    const url = `${BASE_URL_BETA}/signin`;
    const payload = {
      identifier,
      password,
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 200) {
      yield delay(500)
      yield put({
        type: PURGE,
        key: "my-super-secret-key",
        result: () => null,
      })
      localStorage.removeItem("persist:root")

      yield put(actions.loginAsync.success());

      localStorage.setItem(
        "token",
        response.data.data.AuthenticationResult?.IdToken
      );
      localStorage.setItem(
        "accessToken",
        response.data.data.AuthenticationResult?.AccessToken
      );

      localStorage.setItem(
        "refreshToken",
        response.data.data.AuthenticationResult?.RefreshToken
      );

      if (response.data.data?.Session) {
        localStorageService.setSession(response.data.data?.Session);
      } else {
        localStorageService.removeSessionID();
      }

      localStorage.removeItem("is_login");
      localStorage.setItem("is_login", "true");

      if (response.data.data.AuthenticationResult?.IdToken) {
        yield put(push("/company?origin=login"));
      }
    }
  } catch (err) {
    errorReporter(err,  types.LOGIN_REQUEST, action.payload)
    yield put(
      showToast.request({
        duration: 5000,
        message: "Email atau password salah",
        type: "error",
      })
    );
    yield put(actions.loginAsync.failure());
  }
}

function* handleRegister(
  action: ReturnType<typeof actions.signUpAsync.request>
) {
  try {
    const { name, email, password, phoneNumber } = action.payload;

    const url = `${BASE_URL_BETA}/signup`;
    const payload = {
      name,
      email,
      password,
      phone_number: phoneNumber
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 200) {
      const userID = response.data.auth.user_id

      yield put(actions.signUpAsync.success({ userID }));
      yield put(push("/confirm"));
    }
  } catch (err) {

    if(err.response.status === 422){
      yield put(showToast.request({
        duration: 5000,
        message: "Namaakun telah terdaftar",
        type: "error",
      }))
      yield put(actions.signUpAsync.failure());
      return
    }

    if(err.response.status === 400){
      yield put(showToast.request({
        duration: 5000,
        message: "Password tidak sesuai",
        type: "error",
      }))
      yield put(actions.signUpAsync.failure());
      return
    }

    errorReporter(err,  types.SIGN_UP_REQUEST, action.payload)
    yield put(
      showToast.request({
        duration: 5000,
        message: "Gagal registrasi",
        type: "error",
      })
    );
    yield put(actions.signUpAsync.failure());
  }
}

function* handleLogout() {
  try {
    localStorage.clear();
    yield put(setShowSidebar(false));
    yield put(push("/signin"));

    // make sure not have change on redux
    yield delay(500);

    yield put({
      type: PURGE,
      key: "my-super-secret-key",
      result: () => null,
    });
  } catch (err) {
    localStorage.clear();
    yield put(push("/signin"));
  }
}

function* handleChangeEmail(
  action: ReturnType<typeof actions.changeEmailAsync.request>
) {
  try {
    const { email, password, user_id } = action.payload;
    const url = `${BASE_URL_BETA}/change-email`;
    const payload = {
      email,
      password,
      user_id
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 201) {
      yield put(actions.changeEmailAsync.success());
      yield put(push("/confirm"));

      yield delay(500);
      yield put({
        type: PURGE,
        key: "my-super-secret-key",
        result: () => null,
      })

    }
  } catch (err) {
    errorReporter(err,  types.HANDLE_CHANGE_EMAIL_REQUEST, action.payload)
    yield put(
      showToast.request({
        duration: 5000,
        message: "Gagal mengubah email",
        type: "error",
      })
    );
    yield put(actions.changeEmailAsync.failure());
  }
}


export default function* authSaga() {
  yield takeLeading(types.POST_FORGOT_PASSWORD_REQUEST, submitForgotPassword);
  yield takeLeading(
    types.POST_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_REQUEST,
    submitNewPasswordForgot
  );
  yield takeLeading(types.SET_NEW_PASSWORD_REQUEST, setNewPassword);
  yield takeLeading(types.LOGIN_REQUEST, handleLogin);
  yield takeLeading(types.LOGOUT, handleLogout);  
  yield takeLeading(types.SIGN_UP_REQUEST, handleRegister)
  yield takeLeading(types.HANDLE_CHANGE_EMAIL_REQUEST, handleChangeEmail)
}
