import moment from 'moment'

export const getValidDate = (stringDate?: string  | null) => {
    if (stringDate === "0000-00-00 00:00:00" || !stringDate || stringDate === 'None') {
      return null;
    }

    const checkDate = moment(stringDate).format()

    if(checkDate === "Invalid date"){
      return null
    }

    return moment(stringDate).toDate();
  };