export const SET_EMPLOYEE_FILTER = '@home/SET_EMPLOYEE_FILTER';

export const SET_PERIOD_TYPE = '@history/SET_PERIOD_TYPE';

export const CLEAR_HISTORY_LIST = '@history/CLEAR_HISTORY_LIST'

export const SET_SELECTED_DATE = '@history/SET_SELECTED_DATE'

export const GET_DASHBOARD_SUMMARY_REQUEST  = '@home/GET_DASHBOARD_SUMMARY_REQUEST';
export const GET_DASHBOARD_SUMMARY_SUCCESS  = '@home/GET_DASHBOARD_SUMMARY_SUCCESS';
export const GET_DASHBOARD_SUMMARY_FAILURE  = '@home/GET_DASHBOARD_SUMMARY_FAILURE';

