import React from "react";
import moment from "moment";

// component
import { Flex, Text } from "../../component/Styles";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { renderDate } from "./utils/renderDate";

// type
import { InventoryProduct } from "../../types/inventory";

export type Props = {
  onClick?: () => void;
  inventory: InventoryProduct;
};

const InvoiceDetailProductItem = (props: Props) => {
  const { onClick, inventory } = props;

  const handleClick = () => {
    if (!onClick) {
      return;
    }

    onClick();
  };

  return (
    <Flex
      px={0}
      pb={1}
      pt={0}
      flexDirection="column"
      borderBottom="1px solid"
      borderColor="blackLighter"
      onClick={() => handleClick()}
    >
      <Flex>
        <Flex flex={7}>
          <Text color="blueDark" fontWeight="bold" fontSize="2" lineHeight="2">
            {inventory.name}
          </Text>
        </Flex>
        <Flex flex={3} justifyContent="flex-end">
          <Text fontWeight="bold" fontSize="2" lineHeight="2" textAlign="right">
            <span className={+inventory.stock_qty === 0 ? "danger" : undefined}>
              {inventory.stock_qty}{" "}
            </span>{" "}
            {inventory.stock_unit}
          </Text>
        </Flex>
      </Flex>

      <Flex mt={8}>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            ED Terdekat
          </Text>
          <Text fontSize="1" lineHeight="1">
            {renderDate(inventory.nearest_ED)}
          </Text>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            Total harga beli
          </Text>
          <Text fontSize="1" lineHeight="1">
            Rp{nominalFormat(inventory.total_COGS)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InvoiceDetailProductItem;
