import React from "react";
import styled from "styled-components/macro";
import { TouchableButton } from "../../component/Button";

// asset
import { AddIcon } from "../../asset/icon";

// component
import { Flex, Text } from "../../component/Styles";

// type
import { Product } from "../../types/product";

// constant
import { nominalFormat } from "../../utils/nominalFormat";
import theme from "../../constant/styledTheme";

export type Props = {
  product: Product;
  onClick: (item: Product) => void;
};

const CatalogItem = (props: Props) => {
  const { product, onClick } = props;

  const handleClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }
    onClick(product);
  };

  return (
    <Card href="">
      <Container
        onClick={(e) => handleClick(e)}
        borderBottom="1px solid"
        borderColor="blackLighter"
        justifyContent="space-between"
        py={1}
        px={0}
      >
        <Flex flexDirection="column">
          <Text textAlign="left" variant="rg" fontWeight="700">
            {product.name}
          </Text>
          <Text variant="sm">
            <span className="bold">Stok</span>&nbsp;{nominalFormat(product.qty)}
            &nbsp;{product.unit}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Text variant="rg">
            {product.price || product.price === 0
              ? `Rp${nominalFormat(product.price)}`
              : "-"}
          </Text>
          <TouchableButton
            icon={AddIcon}
            alt="add"
            onClick={() => handleClick()}
          />
        </Flex>
      </Container>
    </Card>
  );
};

const Card = styled.a`
  text-decoration: none;
  color: ${theme.colors.black};
`;

const Container = styled(Flex)`
  cursor: pointer;
`;

export default CatalogItem;
