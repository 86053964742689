import { RootState } from '../store/rootReducers'

export interface States extends RootState {};
export interface Option {
    label: any;
    value: any;
    status? : any
}

export type ToastType = 'info' | 'success' | 'error' | 'deleted'

export type DiscountType = 'nominal' | 'percentage'

export type QueryParams = {
    page?: number,
    search?: string,
    sorted_by?: string,
    order_by?: string,
    rowPerPage?: number,
    history?: boolean;
    is_empty?: boolean;
}

export enum StockActionType {
    /** 
     * Penjualan mengurangi stok, transaksi normal :) kita mengurangi stok
     */
    'A111' = 111,
    /** 
     * retur dari penjualan, kita menambah stok, edit transaksi 
     */
    'A122' = 122, 
    /** 
     * batal penjualan, menambah stok 
     */
    'A133' = 133, 
    /**
     * penerimaan dari faktur, normal :) menambah stok
     */
    'A211' = 211,
    /**
     *  edit faktur yang menambah stok
     */
    'A221' = 221,
    /**
     * edit faktur yang mengurangi stok
     */
    'A222' = 222, 
    /**
     * retur faktur
     */
    'A231' = 231, 
    /**
     * batal faktur semua
     */
    'A232' = 232, 
    /**
     * update stok inventori menambahkan stok
     */
    'A311' = 311, 
    /**
     * update stok inventori mengurangi stok
     */
    'A312' = 312, 
    /**
     * stok opname menambahkan stok
     */
    'A411' = 411, 
    /**
     * stok opname mengurangi stok
     */
    'A412' = 412, 
    /**
     * mutasi stok, mengurangi stok
     */
    'A511' = 511, 
    /**
     * mutasi stok, menambahkan stok
     */
	'A512' = 512, 

}