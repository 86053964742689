import React, { useMemo } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components/macro";
import MaskedInput from "react-text-mask";
import id from "date-fns/locale/id";
import "react-datepicker/dist/react-datepicker.css";

// asset
import CalendarIcon from "../../asset/icon/calendar.svg";

// constant
import { colors } from "../../constant/theme";
import theme from "../../constant/styledTheme";

export type Props = {
  /** GET - user can select past date or not */
  allowSelectPastDate?: boolean;
  /** GET - disabled state */
  disabled?: boolean;
  /** GET - label of container date picker, position is upper of container */
  label: string;
  /** GET - selected date */
  selectedDate?: Date | null;
  /** handle change date, by typing or select on calendar tooltip */
  onChange: (date: Date | null) => void;
};

const DatePickerComponent = (props: Props) => {
  const { label, disabled, selectedDate, onChange } = props;

  /**
   * handle changing date, for now only handle single date
   * @param date
   */
  const handleChangeDate = (date: Date | [Date, Date] | null) => {
    if (Array.isArray(date)) {
      return;
    }
    onChange(date);
  };

  /**
   * This function to make sure selected date value if date format
   */
  const getValue = useMemo(() => {
    if (!selectedDate) {
      return null;
    }

    const dateFormat = moment(selectedDate).toDate();
    return dateFormat;
  }, [selectedDate]);

  return (
    <Wrapper>
      {label ? <div className="calendar__label text">{label}</div> : null}
      <Container disabled={!!disabled}>
        <div className="calendar__input">
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={getValue}
            onChange={(date) => handleChangeDate(date)}
            disabled={disabled}
            locale={id}
            placeholderText="DD/MM/YYYY"
            dayClassName={(date) => {
              if (
                moment(moment(date).format("YYYY-MM-DD")).isBefore(
                  moment().format("YYYY-MM-DD"),
                  "date"
                )
              ) {
                return "date-before";
              }

              return null;
            }}
            customInput={
              <MaskedInput
                inputMode="numeric"
                pattern="[0-9/]*"
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                keepCharPositions={true}
                guide={true}
              />
            }
          />
        </div>
        <div className="container__icon calendar__icon">
          <img src={CalendarIcon} alt="calendar" width="24" height="24" />
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Container = styled.div<{ disabled: boolean }>`
  height: 38px;
  display: flex;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? theme.colors.whiteDarker : theme.colors.blackLighter};
  border-radius: 4px;
  position: relative;
  box-shadow: ${({ disabled }) =>
    disabled
      ? "none"
      : `0px 1px 2px rgba(51, 51, 51, 0.15),
    inset 0px 1px 2px rgba(255, 255, 255, 0.15),
    inset 0px -1px 2px rgba(51, 51, 51, 0.15)`};
  background: ${({ disabled }) => (disabled ? "#fff" : theme.colors.whiteDark)};

  .react-datepicker__input-container > input {
    border: none;
    cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
    font-size: 14px;
    line-height: 24px;
    color: ${({ disabled }) =>
      disabled ? theme.colors.blackLighter : theme.colors.black};
    background: ${({ disabled }) =>
      disabled ? "#fff" : theme.colors.whiteDark};
    :focus {
      outline: 0;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    border: none;
    width: 100%;
  }

  .react-datepicker__day--selected {
    background: ${colors.primary1};
  }

  .react-datepicker__current-month,
  .react-datepicker__time-name {
    color: ${theme.colors.black};
    font-size: 14px;
    line-height: 24px;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }

  .date-before {
    color: ${theme.colors.blackLight};
  }

  .react-datepicker__navigation--previous {
    outline: none;
  }

  .react-datepicker__navigation--next {
    outline: none;
  }

  .calendar__input {
    width: 100%;
    padding: 5px 8px;
  }

  > .container__icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 8px;
    background: transparent;

    img {
      filter: ${({ disabled }) =>
        disabled
          ? "invert(84%) sepia(0%) saturate(1%) hue-rotate(193deg) brightness(100%) contrast(88%);"
          : "none"};
    }
  }

  .calendar__icon {
    background: transparent;
  }
`;

export default DatePickerComponent;
