import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { useSelector } from "react-redux";

// constant
import { device } from "../../constant/media";
import { colors } from "../../constant/theme";
import theme from "../../constant/styledTheme";

//component
import Sidebar from "../../component/Sidebar/Sidebar";
import DesktopSidebar from "../../component/Sidebar/DesktopSidebar";

// type
import { RootState } from "../../store/rootReducers";

const Layout = ({ children }: any) => {
  const isShowSidebar = useSelector(
    (state: RootState) => state.navigation.isShowSidebar
  );

  return (
    <Container>
      <DesktopSidebar />
      {isShowSidebar ? <Sidebar /> : null}
      <div className="layout__body">{children}</div>
    </Container>
  );
};

const slideUpAnimation = keyframes`
  100% { transform: translateX(0%); }   
`;

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  transform: translateY(200%);
  animation: ${slideUpAnimation} 0.15s forwards;
  border-right: 1px solid ${colors.secondary2};

  @media (min-width: ${theme.breakpoints[3]}) {
    transform: translateY(0);
    display: flex;
    animation: none;
  }

  .layout__body {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    height: calc(100vh);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media (min-width: ${theme.breakpoints[3]}) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default Layout;
