export const  sortingOptions = [
    {
      label: "Nama A-Z",
      value: {
        sorted_by: "name",
        order_by: "asc",
      },
    },
    {
      label: "Nama Z-A",
      value: {
        sorted_by: "name",
        order_by: "desc",
      },
    },
    {
      label: "Stok tersedikit",
      value: {
        sorted_by: "stock",
        order_by: "asc",
      },
    },
    {
      label: "Stok terbanyak",
      value: {
        sorted_by: "stock",
        order_by: "desc",
      },
    },
    {
      label: "ED terdekat",
      value: {
        sorted_by: "ED",
        order_by: "asc",
      },
    },
    {
      label: "ED terjauh",
      value: {
        sorted_by: "ED",
        order_by: "desc",
      },
    },
    {
      label: "Harga beli terkecil",
      value: {
        sorted_by: "HPP",
        order_by: "asc",
      },
    },
    {
      label: "Harga beli terbanyak",
      value: {
        sorted_by: "HPP",
        order_by: "desc",
      },
    },
  ];