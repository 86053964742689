import React from "react";
import styled from "styled-components/macro";
import moment from "moment";

// component
import { Flex, Text } from "../../component/Styles";

// type
import { InventoryStockCard } from "../../types/inventory";
import { StockActionType } from "../../types/general";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

export type Props = {
  inventory: InventoryStockCard;
};

const InventoryStockCardItem = (props: Props) => {
  const dispatch = useDispatch();

  const { inventory } = props;

  /**
   * render batchs, add suffix if batchs more than 3
   */
  const renderBatchs = () => {
    let batchs = inventory.batchs || [];
    let batchRender = batchs.slice(0, 3);
    let suffix = batchs.length > 3 ? batchs.length - 3 + " lainnya" : "";
    return batchRender.join(", ") + suffix;
  };

  const handleClick = () => {
    switch (inventory.action_type) {
      case StockActionType.A111:
        dispatch(push(`/history/${inventory.source_id}`));
        break;
      case StockActionType.A211:
      case StockActionType.A221:
      case StockActionType.A222:
        dispatch(push(`/invoice/${inventory.source_id}`));
        break;
      default:
        break;
    }
  };

  /**
   * get quantity with operator plus or minus
   */
  const getQtyOperator = () =>
    inventory.qty > 0 ? `+${inventory.qty}` : inventory.qty;

  const renderContent = () => {
    switch (inventory.action_type) {
      case StockActionType.A111:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              Penjualan #{inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.creator}
            </Text>
            <Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Jumlah
                </Text>
                <Text
                  fontSize={2}
                  lineHeight={2}
                  color={inventory.qty > 0 ? "green" : "coral"}
                >
                  {getQtyOperator()}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Sisa stok
                </Text>
                <Text fontSize={2} lineHeight={2}>
                  {inventory.qty_balance}
                </Text>
              </Flex>
            </Flex>
          </>
        );
      case StockActionType.A211:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              Penerimaan Faktur No. {inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.supplier_name}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              <span className="bold">Batch</span> {renderBatchs()}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.creator}
            </Text>
            <Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Jumlah
                </Text>
                <Text
                  fontSize={2}
                  lineHeight={2}
                  color={inventory.qty > 0 ? "green" : "coral"}
                >
                  {getQtyOperator()}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Sisa stok
                </Text>
                <Text fontSize={2} lineHeight={2}>
                  {inventory.qty_balance}
                </Text>
              </Flex>
            </Flex>
          </>
        );
      case StockActionType.A221:
      case StockActionType.A222:
      case StockActionType.A232:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              {inventory.action_type === 232 ? "Hapus" : "Edit"} Faktur No.{" "}
              {inventory.source_number}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.supplier_name}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              "{inventory.message_reason}"
            </Text>
            {inventory.action_type === 232 ? (
              <Text fontSize={2} lineHeight={1} color="black">
                <span className="bold">Batch</span> {renderBatchs()}
              </Text>
            ) : null}
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.creator}
            </Text>
            <Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Jumlah
                </Text>
                <Text
                  fontSize={2}
                  lineHeight={2}
                  color={inventory.qty > 0 ? "green" : "coral"}
                >
                  {getQtyOperator()}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Sisa stok
                </Text>
                <Text fontSize={2} lineHeight={2}>
                  {inventory.qty_balance}
                </Text>
              </Flex>
            </Flex>
          </>
        );
      case StockActionType.A311:
      case StockActionType.A312:
        return (
          <>
            <Text fontSize={2} lineHeight={2} color="blueDark" fontWeight="700">
              Update Stok
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              "{inventory.message_reason}"
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              <span className="bold">Batch</span> {renderBatchs()}
            </Text>
            <Text fontSize={2} lineHeight={1} color="black">
              {inventory.creator}
            </Text>
            <Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Jumlah
                </Text>
                <Text
                  fontSize={2}
                  lineHeight={2}
                  color={inventory.qty > 0 ? "green" : "coral"}
                >
                  {getQtyOperator()}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column" alignItems="flex-end">
                <Text
                  fontSize={1}
                  lineHeight={1}
                  fontWeight="700"
                  color="black"
                >
                  Sisa stok
                </Text>
                <Text fontSize={2} lineHeight={2}>
                  {inventory.qty_balance}
                </Text>
              </Flex>
            </Flex>
          </>
        );
      default:
        break;
    }
  };

  return (
    <FlexCursor
      paddingX={0}
      paddingTop={0}
      paddingBottom={0}
      borderBottom="1px solid"
      borderBottomColor="blackLighter"
      onClick={handleClick}
    >
      <Flex flex={2} alignItems="center">
        <Text fontSize={2} lineHeight={2} color="blackLight">
          {moment(inventory.created_at).format("HH:mm")}
        </Text>
      </Flex>
      <Flex flex={8} flexDirection="column">
        {renderContent()}
      </Flex>
    </FlexCursor>
  );
};

const FlexCursor = styled(Flex)`
  cursor: pointer;
`;

export default InventoryStockCardItem;
