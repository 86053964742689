import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import { getProductDetailAsync } from "../../reducers/catalog/catalog.action";
import { addPackageAsync } from "../../reducers/package/package.action";
import { showToast } from "../../reducers/toast/toast.action";

// component
import {
  Overlay,
  Modal,
  Body,
  Flex,
  Box,
  Text,
  StickyBottomContainer,
} from "../../component/Styles";
import { Button, TouchableButton } from "../../component/Button";
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import Select from "../../component/Select";
import { Modal as ModalComponent } from "../../component/Modal";

// asset
import { CloseIcon, AlertIcon } from "../../asset/icon";

// type
import { States, Option } from "../../types/general";
import { Package } from "../../types/package";
import { ProductDetail } from "../../types/product";

// utils
import { postNewPackage } from "../../utils/postNewPackage";

export type CatalogAddPackageProps = {
  /** GET - packages product list */
  packages: Partial<Package>[];
  /** GET - list options of product units */
  unitOptions: Option[];
  /** GET - selected product detail */
  selectedCatalog: ProductDetail | null;
  /** Handle select new crated package */
  onSelectNewPackage?: (p: Option) => void;
  /** Handle close modal */
  onCloseModal: () => void;
};

const CatalogAddPackage = (props: CatalogAddPackageProps) => {
  const dispatch = useDispatch();

  const {
    onCloseModal,
    onSelectNewPackage,
    packages,
    unitOptions,
    selectedCatalog,
  } = props;

  const isLoading = useSelector((state: States) => state.package.isLoading);

  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [unitAlias, setUnitAlias] = useState("");
  const [qty, setQty] = useState<number | undefined>(0);
  const [selectedUnit, setSelectedUnit] = useState<Option | null>(null);
  const [errorValidation, setErrorValidation] = useState({
    packageName: "",
    unitAlias: "",
    qty: "",
  });

  const handleChangeAlias = (alias: string) => {
    const clonedAlias = alias.trim();

    setHasChanges(true);
    setUnitAlias(clonedAlias.slice(0, 3));
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => handleCloseModal()}
        withFeedback
      />
    );
  };

  /**
   * Validate data before save make sure not duplicate and data clear
   */
  const validateAddPackage = () => {
    let isValid = true;
    const validation = {
      packageName: "",
      unitAlias: "",
      qty: "",
    };

    let isDuplicateCode = false;
    let isDuplicateName = false;

    const smallestUnit = unitOptions.find((o) => o.status === "default");

    if (
      (smallestUnit?.label! || "").toLowerCase() === packageName.toLowerCase()
    ) {
      isValid = false;
      validation.packageName =
        "Nama kemasan tidak boleh sama dengan nama satuan.";
    }

    if (
      (smallestUnit?.value.code! || "").toLowerCase() ===
      unitAlias.toLowerCase()
    ) {
      isValid = false;
      validation.packageName =
        "Singkatan kemasan tidak boleh sama dengan singkatan satuan.";
    }

    packages.forEach((p) => {
      if ((p.unit_name! || "").toLowerCase() === packageName.toLowerCase()) {
        isDuplicateName = true;
      }

      if ((p.unit_code! || "").toLowerCase() === unitAlias.toLowerCase()) {
        isDuplicateCode = true;
      }
    });

    if (isDuplicateName) {
      isValid = false;
      validation.packageName = "Nama kemasan sudah terdaftar pada barang ini.";
    }

    if (isDuplicateCode) {
      isValid = false;
      validation.unitAlias = "Satuan kemasan sudah terdaftar pada barang ini.";
    }

    if (!packageName) {
      isValid = false;
      validation.packageName = "Nama kemasan tidak boleh kosong.";
    }

    if (!unitAlias) {
      isValid = false;
      validation.unitAlias = "Nama singkatan kemasan tidak boleh kosong.";
    }

    if (!qty) {
      isValid = false;
      validation.unitAlias = "Kuantitas kemasan tidak boleh kosong.";
    }

    setErrorValidation(validation);

    return { isValid };
  };

  const handleSave = async () => {
    if (!validateAddPackage().isValid) {
      dispatch(
        showToast.request({
          message: "Mohon cek data anda.",
          duration: 5000,
          type: "error",
        })
      );
      return;
    }

    setErrorValidation({ packageName: "", unitAlias: "", qty: "" });
    setHasChanges(false);

    /**
     * trim to make sure data clean from whitespace
     */
    const clonedPackageName = packageName.trim();
    const clonedUnitCode = unitAlias.trim();

    // handle add package
    const smallestUnit = unitOptions.find((o) => o.status === "default");

    const payload = {
      product_id: selectedCatalog?.id,
      unit_name: clonedPackageName,
      unit_code: clonedUnitCode,
      conversion_qty: qty,
      conversion_unit: selectedUnit?.value?.conversion_unit,
      conversion_package_id: selectedUnit?.value.id! || null,
      smallest_qty:
        (selectedUnit?.value.smallest_qty || selectedUnit?.value.qty) *
        (qty || 0),
      // get smallest unit
      unit: smallestUnit?.value.unit_name,
    };

    if (onSelectNewPackage) {
      try {
        const p: Package = await postNewPackage(payload);
        dispatch(
          getProductDetailAsync.request({ productID: selectedCatalog?.id! })
        );

        onSelectNewPackage({
          label: p.unit_name,
          value: p as Package,
        } as Option);

        dispatch(
          showToast.request({
            message: "Berhasil menambahkan kemasan",
            type: "success",
            duration: 5000,
          })
        );
      } catch (err) {
        dispatch(
          showToast.request({
            message: "Gagal menambahkan kemasan",
            type: "error",
            duration: 5000,
          })
        );
      }
      onCloseModal();
      return;
    }

    dispatch(
      addPackageAsync.request({
        product_id: selectedCatalog?.id,
        unit_name: clonedPackageName,
        unit_code: clonedUnitCode,
        conversion_qty: qty,
        conversion_unit: selectedUnit?.value?.conversion_unit,
        conversion_package_id: selectedUnit?.value.id! || null,
        smallest_qty:
          (selectedUnit?.value.smallest_qty || selectedUnit?.value.qty) *
          (qty || 0),
        // get smallest unit
        unit: smallestUnit?.value.unit_name,
      })
    );
  };

  // Set selected unit to smallest unit
  useEffect(() => {
    const defaultUnit = unitOptions.find((u) => u.status === "default");
    setSelectedUnit(defaultUnit!);
  }, [unitOptions]);

  const handleCloseModal = () => {
    if (!hasChanges) {
      onCloseModal();
      return;
    }

    setShowConfirmExitModal(true);
  };

  const handleChangePackageName = (name: string) => {
    setHasChanges(true);
    setPackageName(name);

    const clonedName = name.trim();
    setUnitAlias(clonedName.slice(0, 3));
  };

  return (
    <Overlay isVisible>
      {/* Confirmation exit modal */}
      <ModalComponent
        title="Perubahan belum disimpan"
        isVisible={showConfirmExitModal && hasChanges}
        subtitle="Jiika kamu meninggalkan halaman ini, perubahan yang kamu buat akan hilang."
        maxWidth="320px"
        destructiveTitle="Keluar"
        destructiveFunction={() => onCloseModal()}
        acceptTitle={"Batal"}
        acceptFunction={() => {
          setShowConfirmExitModal(false);
        }}
      />

      <Modal isVisible>
        <Box>
          <TopBar
            title={"Tambah kemasan"}
            isModal
            leftHeader={renderLeftHeader}
          />
          <Box p="2">
            <Flex>
              <Flex>
                <TextInput
                  autoFocus
                  label="Nama kemasan"
                  value={packageName}
                  onChange={(text) => {
                    handleChangePackageName(text || "");
                  }}
                  isError={!!errorValidation.packageName}
                />
              </Flex>
              <Flex ml={2} alignItems="flex-end">
                <Flex alignItems="center">
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Text fontSize={2} lineHeight={2}>
                      Singkatan
                    </Text>
                    <Text fontSize={1} lineHeight={1}>
                      (3 huruf)
                    </Text>
                  </Flex>
                  <Box maxWidth="70px" ml="1">
                    <TextInput
                      value={unitAlias}
                      onChange={(text) => handleChangeAlias(text || "")}
                      isError={!!errorValidation.unitAlias}
                    />
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {errorValidation.packageName || errorValidation.unitAlias ? (
              <Flex mt={0}>
                <img src={AlertIcon} width="16" height="16" alt="alert" />
                <Text fontSize="1" lineHeight="1" ml="0" color="coralDark">
                  {errorValidation.packageName || errorValidation.unitAlias}
                </Text>
              </Flex>
            ) : null}

            <Flex mt={1}>
              <Flex width="20%">
                <TextInput
                  label="Isi"
                  type="number"
                  value={qty}
                  onChange={(value) => {
                    setQty(value ? +value : undefined);
                    setHasChanges(true);
                  }}
                />
              </Flex>
              <Flex alignItems="flex-end" ml="1">
                <Select
                  width={100}
                  label="Satuan"
                  options={unitOptions}
                  selectedOption={selectedUnit!}
                  onSelect={(option) => {
                    setSelectedUnit(option);
                    setHasChanges(true);
                  }}
                />
              </Flex>
            </Flex>
          </Box>

          <StickyBottomContainer
            px="2"
            py="1"
            background="white"
            position="absolute"
            width="calc(100% - 3rem)"
          >
            <Button
              title={"Tambahkan"}
              fullWidth
              disabled={!packageName || !qty || !unitAlias}
              onClick={handleSave}
              isLoading={isLoading}
            />
          </StickyBottomContainer>
        </Box>
      </Modal>
    </Overlay>
  );
};

export const BodyPage = styled(Body)`
  padding: 0px;
  position: relative;
  display: block;
`;

export default CatalogAddPackage;
