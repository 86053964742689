import React, { useState } from "react";
import { push } from "connected-react-router";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

//asset
import FarmacareLogo from "../../asset/img/logo.png";

// component
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import { Button } from "../../component/Button";
import { Body, Flex } from "../../component/Styles";

// constant
import { device } from "../../constant/media";
import { colors } from "../../constant/theme";

// utils
import { validateEmail } from "../../utils/validateEmail";

// type
import { States } from "../../types/general";

const Confirmation = () => {
  const dispatch = useDispatch();

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <img src={FarmacareLogo} alt="logo" width="128" />
      </HeaderContainer>
    );
  };

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  return (
    <Layout>
      <TopBar header={renderHeader} />
      <BodyPage>
        <Flex flexDirection="column" width="100%" maxWidth="962px">
          <h1>Buka emailmu</h1>
          <div className="text">
            Kami sudah mengirim email untuk mengkonfirmasi pendaftaranmu di
            Farmacare.
          </div>
          <Flex
            mt={10}
            pt={10}
            borderTop="1px solid"
            borderColor="secondary1"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="text">Alamat email salah?</div>
            <Button
              title="Ganti email"
              secondary
              onClick={() => handleNavigate("/change-email")}
            />
          </Flex>
        </Flex>
      </BodyPage>
    </Layout>
  );
};

const BodyPage = styled(Body)`
  @media ${device.lg} {
    display: flex;
    overflow-y: scroll;
    justify-content: center;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media ${device.lg} {
    border-bottom: 1px solid ${colors.secondary2};
  }
`;

export default Confirmation;
