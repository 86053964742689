import React, { useEffect, useMemo, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// redux action
import {
  getInventoryDetailAsync,
  getStockListAsync,
} from "../../reducers/inventory/inventory.action";
import { setShowUpdateStockModal } from "../../reducers/stock/stock.action";

// component
import { Box, Flex, BodyPage, Wrapper, Text, H2 } from "../../component/Styles";
import Layout from "../../component/Layout";
import TopBar from "../../component/TopBar";
import Header from "../../component/Header/Header";
import Select from "../../component/Select";
import Alert from "../../component/Alert";

// related component
import InventoryUpdateStockDetail from "./updateStock/InventoryUpdateStockDetail";
import InventoryDetailBatchList from "./InventoryDetailBatchList";
import InventoryUpdateStock from "./updateStock/InventoryUpdateStock";
import InventoryDetailStockCardList from "./InventoryDetailStockCardList";

// utils
import { getURLPathID } from "../../utils/getURLPathID";

// type
import { Option, States } from "../../types/general";
import { Button } from "../../component/Button";
import { nominalFormat } from "../../utils/nominalFormat";
import { Spinner } from "../../component/Loading";

// data
import { sortingOptions } from "./data/batchSortOption";
import Placeholder from "../../component/Placeholder";
import { AddIcon } from "../../asset/icon";

const InventoryDetail = () => {
  const dispatch = useDispatch();

  // redux state
  const location = useSelector((state: States) => state.router.location);
  const inventoryDetail = useSelector(
    (state: States) => state.inventory.detail
  );
  const isLoading = useSelector((state: States) => state.inventory.isLoading);
  const stockLoading = useSelector(
    (state: States) => state.inventory.stockLoading
  );
  const stockList = useSelector((state: States) => state.inventory.stockList);
  const totalStock = useSelector(
    (state: States) => state.inventory.stockPageTotalData
  );
  const showUpdateInventoryStockDetailModal = useSelector(
    (state: States) => state.stock.showUpdateStockModal
  );

  // state
  const [activeFilter, setActiveFilter] = useState({
    sorted_by: "batch_no",
    order_by: "asc",
  });

  const [activeStockFilter, setActiveStockFilter] = useState({
    label: "Urut transaksi terbaru",
    value: { sorted_by: "created_at", order_by: "asc" },
  });

  const [showUpdateStockDetail, setShowUpdateStockDetail] = useState(false);

  const setShowUpdateInventoryStockDetailModal = (visible: boolean) => {
    dispatch(setShowUpdateStockModal(visible));
  };

  const getInventoryID = useMemo(() => {
    return getURLPathID(location.pathname, 2);
  }, [location]);

  const handleNavigate = (route: string) => {
    dispatch(push(route));
  };

  const handleChangeActiveFilter = (value: any) => {
    dispatch(
      getInventoryDetailAsync.request({
        productID: getInventoryID,
        sorted_by: value.sorted_by,
        order_by: value.order_by,
      })
    );
    setActiveFilter(value);
  };

  const handleStockChangeActiveSort = (option: Option) => {
    dispatch(
      getStockListAsync.request({
        productID: getInventoryID,
        sorted_by: option.value.sorted_by,
        order_by: option.value.order_by,
        history: true,
      })
    );
    setActiveStockFilter(option);
  };

  const handleCloseUpdateInventoryStockDetailModal = () => {
    setShowUpdateInventoryStockDetailModal(false);
  };

  const getSelectedOption = useMemo(() => {
    const selectedOption = sortingOptions.find(
      (o) =>
        o.value.order_by === activeFilter.order_by &&
        o.value.sorted_by === activeFilter.sorted_by
    );

    return selectedOption;
  }, [activeFilter]);

  const getUpdateStockID = useMemo(() => {
    const updateStockID = getURLPathID(location.pathname, 4);
    const path = getURLPathID(location.pathname, 3);

    if (path === "update-stock") {
      return updateStockID;
    }

    return null;
  }, [location]);

  useEffect(() => {
    if (getInventoryID && !isNaN(+getInventoryID)) {
      dispatch(getInventoryDetailAsync.request({ productID: getInventoryID }));
      dispatch(getStockListAsync.request({ productID: getInventoryID }));
    }
  }, [getInventoryID]);

  useEffect(() => {
    if (getUpdateStockID && !isNaN(+getUpdateStockID)) {
      setShowUpdateStockDetail(true);
    }

    if (!getUpdateStockID) {
      setShowUpdateStockDetail(false);
    }
  }, [getUpdateStockID]);

  const disabled = useMemo(() => {
    return showUpdateInventoryStockDetailModal;
  }, [showUpdateInventoryStockDetailModal]);

  return (
    <Layout>
      {showUpdateStockDetail ? (
        <InventoryUpdateStockDetail inventoryID={getInventoryID} />
      ) : null}

      {showUpdateInventoryStockDetailModal ? (
        <InventoryUpdateStock
          onCloseModal={handleCloseUpdateInventoryStockDetailModal}
        />
      ) : null}

      <TopBar title="Stok" leftHeaderType="back" />
      <Wrapper p="2">
        <Header
          disabled={disabled}
          actionButton={
            <Flex>
              <Box mr={0}>
                <Button
                  disabled={isLoading || disabled}
                  title="Update stok"
                  onClick={() => setShowUpdateInventoryStockDetailModal(true)}
                  secondary
                />
              </Box>
              {/* Hide for now, not have product detail yet */}
              {/* <Box>
                <Button
                  disabled={isLoading}
                  title="Lihat di katalog"
                  onClick={() =>
                    handleNavigate(`/goods?productID=${inventoryDetail?.id}`)
                  }
                  secondary
                />
              </Box> */}
            </Flex>
          }
          title={isLoading ? "Memuat..." : inventoryDetail?.name}
          backFunc={() => handleNavigate("/inventory")}
          backTitle="Stok"
        />
        <BodyPage pb="4">
          {isLoading ? (
            <Box p={3} mt={2}>
              <Spinner />
            </Box>
          ) : (
            <>
              <Flex
                flexDirection={["column", "column", "row", "row"]}
                justifyContent={[
                  "flex-start",
                  "flex-start",
                  "space-between",
                  "space-between",
                ]}
                width="100%"
              >
                <Flex
                  flexDirection="column"
                  mt={2}
                  width={["100%", "100%", "40%", "40%"]}
                >
                  <Text fontSize="2" lineHeight="2" rubik>
                    Sisa stok
                  </Text>
                  <Text fontSize="4" lineHeight="4" rubik>
                    {nominalFormat(inventoryDetail?.stock_qty || 0)}{" "}
                    {inventoryDetail?.stock_unit}
                  </Text>
                </Flex>

                <Flex mt={2} width={["100%", "100%", "60%", "60%"]}>
                  <Flex flexDirection="column" flex="1">
                    <Text fontSize="2" lineHeight="2" rubik>
                      Total harga beli
                    </Text>
                    <Text
                      fontSize={["3", "3", "4", "4"]}
                      lineHeight={["3", "3", "4", "4"]}
                      rubik
                    >
                      Rp{nominalFormat(inventoryDetail?.total_COGS)}
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" flex="1">
                    <Text fontSize="2" lineHeight="2" rubik>
                      Rata-rata harga beli
                    </Text>
                    <Text
                      fontSize={["3", "3", "4", "4"]}
                      lineHeight={["3", "3", "4", "4"]}
                      rubik
                    >
                      Rp
                      {nominalFormat(
                        (inventoryDetail?.total_COGS! || 0) /
                          +(inventoryDetail?.stock_qty || 0)
                      )}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              {!(inventoryDetail?.batch_list || []).length ? (
                <Box mt={3}>
                  <H2>Sisa batch</H2>

                  <Box mt={1}>
                    <Placeholder
                      title="Tidak ada batch tersedia"
                      subtitle={
                        inventoryDetail?.is_new
                          ? "Lengkapi data faktur pembelian barang untuk melihat batch."
                          : "Stok barang ini kosong."
                      }
                      action={
                        inventoryDetail?.is_new ? (
                          <Button
                            icon={AddIcon}
                            secondary
                            title="Faktur pembelian"
                            onClick={() => handleNavigate(`/invoice/new`)}
                          />
                        ) : (
                          <Button
                            secondary
                            title="Lihat riwayat batch"
                            onClick={() =>
                              handleNavigate(
                                `/inventory/${inventoryDetail?.id}/history`
                              )
                            }
                          />
                        )
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  {/* if COGS is not accurate, then display alert */}
                  {inventoryDetail?.is_COGS_accurate ? (
                    <Box mt={3}>
                      <Alert
                        type="alert"
                        title="Data harga beli mungkin tidak akurat"
                        description="Terdapat batch pada stok dengan informasi pembelian yang tidak lengkap."
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                  <Box mt="3">
                    <H2> Sisa batch</H2>
                    <Box display={["block", "block", "none", "none"]} mt={2}>
                      <Select
                        options={sortingOptions}
                        selectedOption={getSelectedOption}
                        onSelect={(option) =>
                          handleChangeActiveFilter(option.value)
                        }
                        width={160}
                      />
                    </Box>

                    <Box
                      borderTop="1px solid"
                      borderTopColor="blackLighter"
                      mt={1}
                    >
                      <InventoryDetailBatchList
                        list={inventoryDetail?.batch_list || []}
                        activeFilter={activeFilter}
                        setActiveFilter={handleChangeActiveFilter}
                      />
                    </Box>
                    <Flex
                      borderBottom="1px solid"
                      borderBottomColor="blackLighter"
                      backgroundColor="whiteDark"
                      p="0"
                    >
                      <Button
                        title="Lihat semua batch"
                        inverse
                        onClick={() =>
                          handleNavigate(
                            `/inventory/${inventoryDetail?.id}/history`
                          )
                        }
                      />
                    </Flex>
                  </Box>
                </>
              )}

              <Box mt={3}>
                <H2>Riwayat Stok</H2>
                {stockLoading ? (
                  <Flex justifyContent="center" mt={3}>
                    <Spinner />
                  </Flex>
                ) : !(stockList || []).length ? (
                  <Flex
                    border="1px solid"
                    borderColor="blackLighter"
                    alignItems="center"
                    flexDirection="column"
                    borderRadius="4px"
                    p="3"
                    mt={1}
                  >
                    <Text fontSize="2" lineHeight="2" textAlign="center">
                      Belum ada riwayat stok pada barang ini.
                    </Text>
                  </Flex>
                ) : (
                  <>
                    <Box mt={1}>
                      <InventoryDetailStockCardList
                        list={stockList || []}
                        activeFilter={activeStockFilter}
                        setActiveFilter={handleStockChangeActiveSort}
                        inventoryID={
                          getInventoryID && !isNaN(+getInventoryID)
                            ? getInventoryID
                            : null
                        }
                      />
                    </Box>

                    {totalStock > 5 ? (
                      <Flex
                        borderBottom="1px solid"
                        borderBottomColor="blackLighter"
                        backgroundColor="whiteDark"
                        p="0"
                      >
                        <Button
                          title="Lihat selengkapnya"
                          inverse
                          onClick={() =>
                            handleNavigate(
                              `/inventory/${inventoryDetail?.id}/stock`
                            )
                          }
                        />
                      </Flex>
                    ) : null}
                  </>
                )}
              </Box>
            </>
          )}
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default InventoryDetail;
