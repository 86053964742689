/**
 * Generate query to hit backend 
 * make sure query key is acceptable by backend
 * @param query {key: value}
 */

export const generateSortFilterQuery = (query: {[key: string]: string | number | undefined | boolean}) => {
    const keyFormat: {[key: string]: string} = {
        page: "p",
        sortedBy: "sorted_by",
        sorted_by: "sorted_by",
        orderBy: "order_by",
        order_by: "order_by",
        search: "search",
        rp: "rp",
        rowPerPage: "rp",
        history: "history",
        is_empty: "is_empty"
    }

    const queryResult : {[key: string] : string | number | boolean} = {}

    Object.keys(query).forEach((q) => {
        if(keyFormat[q] && query[q]){
            queryResult[keyFormat[q]] = query[q]!;
        }
    })

    return queryResult
}