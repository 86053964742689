import { put, takeLeading, select } from "redux-saga/effects";
import { goBack, push } from "connected-react-router";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./invoice.action";
import * as types from "./invoice.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import { generateSortFilterQuery } from "../../utils/generateSortFilterQuery";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

function* getInvoiceList(
  action: ReturnType<typeof actions.getListInvoiceAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/invoice`;

    const query = generateSortFilterQuery(action.payload);
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      const invoice = {
        data: response.data.invoice || [],
        totalData: response.data.total_data,
      };
      yield put(actions.getListInvoiceAsync.success(invoice));
    }
  } catch (err) {
    yield put(actions.getListInvoiceAsync.failure());

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_INVOICE_LIST_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* getInvoiceDetail(
  action: ReturnType<typeof actions.getInvoiceDetailAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    const { invoiceID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/invoice/${invoiceID}`;
    const response = yield axios.get(url);

    if (response && response.status === 200) {
      yield put(actions.getInvoiceDetailAsync.success(response.data.invoice));
    }
  } catch (err) {
    yield put(actions.getInvoiceDetailAsync.failure());

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_INVOICE_DETAIL_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* addInvoice(
  action: ReturnType<typeof actions.addInvoiceAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    const { payload } = action;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/invoice`;
    const response = yield axios.post(url, payload);

    if (response && response.status === 201) {
      yield put(
        showToast.request({
          message: "Berhasil menambahkan faktur",
          type: "success",
          duration: 5000,
        })
      );
      yield put(actions.addInvoiceAsync.success(response.data.product));
      yield put(push("/invoice"));
    }
  } catch (err) {
    yield put(actions.addInvoiceAsync.failure());

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (
      err.response.status === 422 ||
      err.response.status === 409 ||
      err.response.status === 406
    ) {
      yield put(actions.addInvoiceAsync.failure());
      yield put(
        showToast.request({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
        })
      );
      return;
    }

    errorReporter(err, types.ADD_INVOICE_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal menambahkan faktur",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* updateInvoice(
  action: ReturnType<typeof actions.updateInvoiceAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const { payload } = action;
    const url = `${BASE_URL_BETA}/company/${companyID}/invoice/${payload.invoiceID}`;
    const response = yield axios.put(url, payload);

    const state = yield select();
    const query = state.router.location.query;
    const { origin, originID } = query;

    if (response && response.status === 201) {
      yield put(actions.updateInvoiceAsync.success());
      yield put(push(origin ? `/${origin}/${originID}` : `/invoice`));
      yield put(
        showToast.request({
          message: "Sukses memperbaharui faktur",
          type: "success",
          duration: 5000,
        })
      );
    }
  } catch (err) {
    yield put(actions.updateInvoiceAsync.failure());

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 406 || err.response.status === 409) {
      yield put(actions.updateInvoiceAsync.failure());
      yield put(
        showToast.request({
          message: err.response.data.message,
          type: "error",
          duration: 5000,
        })
      );
      return;
    }
    errorReporter(err, types.UPDATE_INVOICE_REQUEST, action.payload);

    yield put(
      showToast.request({
        message: "Gagal memperbaharui faktur",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* deleteInvoice(
  action: ReturnType<typeof actions.deleteInvoiceAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const { payload } = action;
    const url = `${BASE_URL_BETA}/company/${companyID}/invoice/${payload.invoiceID}`;
    const response = yield axios.delete(url, {
      data: {
        message_reason: action.payload.messageReason,
      },
    });

    const state = yield select();
    const query = state.router.location.query;
    const { origin, originID } = query;

    if (response && response.status === 200) {
      yield put(actions.deleteInvoiceAsync.success());
      yield put(push(origin ? `/${origin}/${originID}` : `/invoice`));
      yield put(
        showToast.request({
          message: "Sukses menghapus faktur",
          type: "deleted",
          duration: 5000,
        })
      );
    }
  } catch (err) {
    yield put(actions.deleteInvoiceAsync.failure());

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.DELETE_INVOICE_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal menghapus faktur",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* invoiceSaga() {
  yield takeLeading(types.ADD_INVOICE_REQUEST, addInvoice);
  yield takeLeading(types.UPDATE_INVOICE_REQUEST, updateInvoice);
  yield takeLeading(types.GET_INVOICE_LIST_REQUEST, getInvoiceList);
  yield takeLeading(types.GET_INVOICE_DETAIL_REQUEST, getInvoiceDetail);
  yield takeLeading(types.DELETE_INVOICE_REQUEST, deleteInvoice);
}
