import React, { useEffect, useMemo } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

// component
import TopBar from "../../component/TopBar";
import Layout from "../../component/Layout/Layout";
import TransactionItem from "./TransactionItem";
import Button from "../../component/Button/Button";
import { Box, BodyPage, Wrapper } from "../../component/Styles";
import Header from "../../component/Header/Header";
import Placeholder from "../../component/Placeholder";

// redux action
import {
  getTransactionListAsync,
  createTransactionAsync,
} from "../../reducers/transaction/transaction.action";
import { setSelectedTransaction } from "../../reducers/transaction/transaction.action";

// type
import { general } from "../../types/index";
import { AddIcon } from "../../asset/icon";

const TransactionList = () => {
  const dispatch = useDispatch();

  // redux state
  const transactionList = useSelector(
    (state: general.States) => state.transaction.transactionList
  );
  const isLoading = useSelector(
    (state: general.States) => state.transaction.isLoading
  );

  const renderList = useMemo(() => {
    return transactionList.map((t, index) => {
      return (
        <TransactionItem
          transaction={t}
          key={`transaction-${index}`}
          index={index}
        />
      );
    });
  }, [transactionList]);

  const validateEmptyTransaction = () => {
    if (transactionList) {
      const emptyTransaction = transactionList.find(
        (el) => el.total_item === 0
      );

      if (emptyTransaction) {
        dispatch(push(`/transaction/${emptyTransaction?.id}`));
        dispatch(setSelectedTransaction(emptyTransaction));
        return true;
      }
    }

    return false;
  };

  const handleCreateNewTransaction = () => {
    const hasEmptyTransaction = validateEmptyTransaction();

    if (hasEmptyTransaction) {
      return;
    }

    dispatch(createTransactionAsync.request());
  };

  useEffect(() => {
    dispatch(getTransactionListAsync.request());
  }, []);

  return (
    <Layout>
      <TopBar leftHeaderType="menu" companyHeader />
      <Wrapper p="2">
        <Header
          title="Kasir"
          actionButton={
            <Button
              icon={AddIcon}
              title="Penjualan"
              onClick={handleCreateNewTransaction}
              isLoading={isLoading}
            />
          }
        />
        <BodyPage>
          <Box mt={3}>
            {!transactionList.length ? (
              <Placeholder
                title="Belum ada penjualan"
                subtitle="Tambahkan transaksi untuk mencatat penjualanmu."
                action={
                  <Button
                    isLoading={isLoading}
                    secondary
                    icon={AddIcon}
                    title="Transaksi"
                    onClick={handleCreateNewTransaction}
                  />
                }
              />
            ) : (
              renderList
            )}
          </Box>
        </BodyPage>
      </Wrapper>
    </Layout>
  );
};

export default TransactionList;
