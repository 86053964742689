import React, { useMemo } from "react";
import moment from "moment";

// component
import { Flex, Box, Text } from "../../component/Styles";
import { ExtendProductInvoice } from "../../types/product";
import { nominalFormat } from "../../utils/nominalFormat";

// type
import { Package } from "../../types/package";

// utils
import { getValidDate } from "../../utils/getValidDate";
import { getMargin as calculateMargin } from "./utils/getMargin";

export type Props = {
  /** Get product detail */
  product: ExtendProductInvoice;
  /** Get subtotal */
  subtotal?: number;
  /** Get include PPN or not */
  includeVAT: boolean;
  /** handle open edit product modal  */
  onClick?: (product: ExtendProductInvoice) => void;
};

const InvoiceDetailProductItem = (props: Props) => {
  const { product, onClick, subtotal, includeVAT } = props;

  const renderDiscount = useMemo(() => {
    const { discount_amount, discount_type_buy, price_buy } = product;

    if (discount_type_buy === "cash") {
      return `${nominalFormat(price_buy.toFixed(2))}`;
    }

    return `${nominalFormat(discount_amount.toFixed(2))} %`;
  }, [product.discount_amount, product.discount_type_buy, product.price_buy]);

  const getMargin = (p: ExtendProductInvoice) => {
    const result = calculateMargin(p, includeVAT);
    return result;
  };

  const getConversionLabel = (p: Package) => {
    if (p.unit_name === p.conversion_unit) {
      return p.unit_code;
    }

    return `${p.unit_code} (${p.conversion_qty} ${p.conversion_unit})`;
  };

  const handleClick = () => {
    if (!onClick || product.is_deleted) {
      return;
    }

    onClick(product);
  };

  return (
    <Flex
      px={0}
      pb={1}
      pt={0}
      flexDirection="column"
      borderBottom="1px solid"
      borderColor="blackLighter"
      backgroundColor={product.is_deleted ? "whiteDarker" : "#fff"}
      onClick={() => handleClick()}
    >
      <Flex>
        <Flex flex={7}>
          <Text color="blueDark" fontWeight="bold" fontSize="2" lineHeight="2">
            {product.product_name}{" "}
            {product.is_deleted ? (
              <span className="danger">(dihapus)</span>
            ) : null}
          </Text>
        </Flex>
        <Flex flex={3} justifyContent="flex-end">
          <Text fontWeight="bold" fontSize="2" lineHeight="2">
            {nominalFormat(product.subtotal || subtotal)}
          </Text>
        </Flex>
      </Flex>

      <Box>
        <Text fontSize="1" lineHeight="1">
          {`Rp${nominalFormat(
            product.price_buy * (product.package_buy_detail?.smallest_qty || 1)
          )} x ${product.qty} ${getConversionLabel(
            product.package_buy_detail!
          )}  `}
        </Text>
        <Text fontSize="1" lineHeight="1" color="coral">
          {renderDiscount}
        </Text>
      </Box>

      <Flex mt={8}>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            Batch
          </Text>
          <Text fontSize="1" lineHeight="1">
            {product.batch_no || "-"}
          </Text>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            Harga jual
          </Text>
          <Text fontSize="1" lineHeight="1">
            {nominalFormat(product.price_sell)} /{" "}
            {getConversionLabel(product.package_buy_detail!)}
          </Text>
        </Flex>
      </Flex>

      <Flex mt={8}>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            ED
          </Text>
          <Text fontSize="1" lineHeight="1">
            {getValidDate(product.ED)
              ? moment(product.ED).format("DD MMM YYYY")
              : "-"}
          </Text>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Text fontSize="1" lineHeight="1" fontWeight="bold">
            Margin
          </Text>
          <Text fontSize="1" lineHeight="1">
            {getMargin(product)}%
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InvoiceDetailProductItem;
