import axios from '../../../config/axios'

// constant
import {BASE_URL_BETA} from '../../../constant/api'

// utils
import errorReporter from '../../../utils/sentryErrorReporter'

export const activationCompanyAsync = async(type: 'cancel' | 'request', companyID: number) => {
    try {
        const url = `${BASE_URL_BETA}/company/${companyID}`;

        const response = await axios.patch(url, { action: type }) ;

        if (response && response.status === 201) {
            return;
        }
    }
    catch(err){
        errorReporter(err)
        throw (err)
    }
}