import React from "react";
import styled from "styled-components/macro";

// component
import { Flex, Text } from "../../component/Styles";
import { getInitialChar } from "../../utils/getInitialChar";

export type Props = {
  name: string;
};

const InitialName = (props: Props) => {
  return (
    <InitialBox backgroundColor="blueLighter">
      <Text rubik variant="sm" fontWeight="700">
        {getInitialChar(props.name || "")}
      </Text>
      <Text ml="0">{props.name}</Text>
    </InitialBox>
  );
};

export default InitialName;

const InitialBox = styled(Flex)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border-radius: 12px;
`;
