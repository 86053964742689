import React from "react";
import { Redirect, Route } from "react-router-dom";

export const LoginRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        localStorage.getItem("is_login") &&
        localStorage.getItem("token") &&
        localStorage.getItem("companyID") &&
        rest.path === "/signin"
      ) {
        return (
          <Redirect
            to={{ pathname: "/transaction", state: { from: props.location } }}
          />
        );
      }

      return <Component {...props} />;
    }}
  />
);
