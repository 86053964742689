import React from "react";
import Raven from "raven-js";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components/macro";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// style
import "./index.css";
import "../src/asset/style/style.css";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import theme from "./constant/styledTheme";
import TagManager from "react-gtm-module";
import { store, history, persistor } from "./store/index";

// constant
import { APP_VERSION } from "./constant/version";
import { ENV, GTM_ID } from "./constant/env";
import { SENTRY_DNS } from "./constant/api";

if (ENV !== "development" && ENV !== "test") {
  try {
    Sentry.init({
      dsn: SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      release: APP_VERSION,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });

    Raven.config(SENTRY_DNS || "").install();
  } catch (err) {}
}

if (GTM_ID) {
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            {/* application elements */}
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
