import React, { useMemo } from "react";
import styled from "styled-components/macro";

// asset
import CheckIcon from "../../asset/icon/check-light.svg";

// constant
import { colors } from "../../constant/theme";

export type RadioButtonProps = {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: (value: boolean) => void;
};

export const RadioButton = (props: RadioButtonProps) => {
  const { checked, label, disabled, onClick } = props;

  const preventTriggerAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventTriggerAction(e);

    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(!checked);
    }

    return;
  };

  const renderBorderColor = useMemo(() => {
    switch (true) {
      case disabled:
        return colors.secondary2;
      case checked:
        return colors.primary1;
      default:
        return colors.secondary1;
    }
  }, [disabled, checked]);

  const renderBackgroundColor = useMemo(() => {
    switch (true) {
      case disabled:
        return colors.secondary2;
      case checked:
        return colors.primary1;
      default:
        return colors.secondary2;
    }
  }, [disabled, checked]);

  return (
    <Container
      background={renderBackgroundColor}
      borderColor={renderBorderColor}
      onClick={preventTriggerAction}
    >
      <div className="bounding-box" onClick={(e) => handleChange(e)}>
        <div className="radio-button__mark">
          {checked ? (
            <img
              src={CheckIcon}
              alt="check"
              height="9"
              width="8"
              className="radio-button__img"
            />
          ) : null}
        </div>
        <div className="radio-button__title text">{label ? label : null}</div>
      </div>
    </Container>
  );
};

export default RadioButton;

const Container = styled.div<{ background: string; borderColor: string }>`
  display: flex;
  align-items: center;
  height: 24px;
  width: auto;

  .bounding-box {
    display: flex;
    align-items: center;
    height: 24px;
  }

  .radio-button__mark {
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ background }) => background};
    transition: 0.5s ease;

    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid ${({ borderColor }) => borderColor};
  }

  .radio-button__title {
    margin-left: 10px;
  }

  cursor: pointer;
`;
