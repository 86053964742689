import { put, takeLeading } from "redux-saga/effects";

// config
import axios from "../../config/axios";

// redux action
import { showToast } from "../toast/toast.action";
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./inventory.action";
import * as types from "./inventory.actionTypes";

// utils
import localStorageService from "../../utils/localStorageService";
import { generateSortFilterQuery } from "../../utils/generateSortFilterQuery";
import errorReporter from "../../utils/sentryErrorReporter";

// constant
import { BASE_URL_BETA } from "../../constant/api";

function* getInventoryList(
  action: ReturnType<typeof actions.getInventoryListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const filter = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const query = generateSortFilterQuery(filter);
    const url = `${BASE_URL_BETA}/company/${companyID}/inventory`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(actions.getInventoryListAsync.success(response.data.inventory));
    }
  } catch (err) {
    yield put(actions.getInventoryListAsync.failure());

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }
    
    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_INVENTORY_LIST_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* getInventoryDetail(
  action: ReturnType<typeof actions.getInventoryDetailAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { productID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const query = generateSortFilterQuery(action.payload);
    const url = `${BASE_URL_BETA}/company/${companyID}/inventory/${productID}`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(
        actions.getInventoryDetailAsync.success(response.data.inventory)
      );
    }
  } catch (err) {
    yield put(actions.getInventoryDetailAsync.failure());

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_INVENTORY_DETAIL_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

function* getStockList(
  action: ReturnType<typeof actions.getStockListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();
    const { productID } = action.payload;

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const query = generateSortFilterQuery(action.payload);
    const url = `${BASE_URL_BETA}/company/${companyID}/stock/${productID}`;
    const response = yield axios.get(url, { params: query });

    if (response && response.status === 200) {
      yield put(
        actions.getStockListAsync.success({
          data: response.data.data,
          total_data: response.data.total_data,
        })
      );
    }
  } catch (err) {
    yield put(actions.getStockListAsync.failure());

    if(err.response.status === 401){
      yield put(showToast.request({ message: "Sesi anda telah berakhir, silahkan login kembali", duration: 5000, type: "error" }));
      yield put(handleLogout())
      return;
  }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(err, types.GET_STOCK_LIST_REQUEST, action.payload);
    yield put(
      showToast.request({
        message: "Gagal memuat",
        type: "error",
        duration: 5000,
      })
    );
  }
}

export default function* inventorySaga() {
  yield takeLeading(types.GET_INVENTORY_LIST_REQUEST, getInventoryList);
  yield takeLeading(types.GET_INVENTORY_DETAIL_REQUEST, getInventoryDetail);
  yield takeLeading(types.GET_STOCK_LIST_REQUEST, getStockList);
}
