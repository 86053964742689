export const GET_PACKAGES_REQUEST = '@package/GET_PACKAGES_REQUEST'
export const GET_PACKAGES_SUCCESS = '@package/GET_PACKAGES_SUCCESS'
export const GET_PACKAGES_FAILURE = '@package/GET_PACKAGES_FAILURE'

export const ADD_PACKAGE_REQUEST = '@package/ADD_PACKAGE_REQUEST'
export const ADD_PACKAGE_SUCCESS = '@package/ADD_PACKAGE_SUCCESS'
export const ADD_PACKAGE_FAILURE = '@package/ADD_PACKAGE_FAILURE'

export const EDIT_PACKAGE_REQUEST = '@package/EDIT_PACKAGE_REQUEST'
export const EDIT_PACKAGE_SUCCESS = '@package/EDIT_PACKAGE_SUCCESS'
export const EDIT_PACKAGE_FAILURE = '@package/EDIT_PACKAGE_FAILURE'

export const DELETE_PACKAGE_REQUEST = '@package/DELETE_PACKAGE_REQUEST'
export const DELETE_PACKAGE_SUCCESS = '@package/DELETE_PACKAGE_SUCCESS'
export const DELETE_PACKAGE_FAILURE = '@package/DELETE_PACKAGE_FAILURE'

export const VISIBLE_PACKAGE_MODAL = '@package/VISIBLE_PACKAGE_MODAL'
export const RESET_PACKAGE_STATE ='@package/RESET_PACKAGE_STATE'