import { createReducer, ActionType } from "typesafe-actions";

// actions
import * as actions from "./package.action";

// actions types
import * as types from "./package.actionTypes";

// type
import { Package } from "../../types/package";
export type PackageActions = ActionType<typeof actions>;

export type PackageState = {
  list: Package[];
  isLoading: boolean;
  visiblePackageModal: boolean;
};

const initialState: PackageState = {
  list: [],
  isLoading: false,
  visiblePackageModal: false
};

export const packageReducer = createReducer<PackageState, PackageActions>(
  initialState
)
  .handleAction(actions.getPackagesAsync.request, (state: PackageState) => ({
    ...state,
    isLoading: true,
    list: []
  }))
  .handleAction(
    actions.getPackagesAsync.success,
    (
      state: PackageState,
      action: ActionType<typeof actions.getPackagesAsync.success>
    ) => ({
      ...state,
      isLoading: false,
      list: action.payload.data,
    })
  )
  .handleAction(actions.getPackagesAsync.failure, (state: PackageState) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(actions.addPackageAsync.request, (state: PackageState) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(actions.addPackageAsync.success, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(actions.addPackageAsync.failure, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))

  .handleAction(actions.editPackageAsync.request, (state: PackageState) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(actions.editPackageAsync.success, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(actions.editPackageAsync.failure, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(actions.deletePackageAsync.request, (state: PackageState) => ({
    ...state,
    isLoading: true,
  }))
  .handleAction(actions.deletePackageAsync.success, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(actions.deletePackageAsync.failure, (state: PackageState) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(types.VISIBLE_PACKAGE_MODAL as any, ( state: PackageState, action:  ActionType<typeof actions.visiblePackageModal>) => ({
    ...state,
    isLoading: false,
    visiblePackageModal: action.payload.visible
  }))
  .handleAction(types.RESET_PACKAGE_STATE as any, ( state: PackageState, action:  ActionType<typeof actions.resetPackageState>) => ({
    ...state,
    isLoading: false,
    visiblePackageModal: false,
    list: []
  }))
