import React from "react";
import styled from "styled-components/macro";

// constant
import theme from "../../constant/styledTheme";

// assets
import SearchIcon from "../../asset/icon/search.svg";
import CloseIcon from "../../asset/icon/close.svg";

type SearchBarProps = {
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  onChange?: (text: string) => void;
};

const SearchBar = (props: SearchBarProps) => {
  const { placeholder, onChange, value, autoFocus } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleClear = () => {
    if (onChange) {
      onChange("");
    }
  };

  return (
    <SearchContainer>
      <input
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e)}
        className="search-bar"
        value={value}
        autoFocus={autoFocus}
      />
      {value ? (
        <img src={CloseIcon} alt="Clear" onClick={() => handleClear()} />
      ) : (
        <img src={SearchIcon} alt="Search" />
      )}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.blackLighter};
  box-shadow: inset 0px 2px 4px rgba(51, 51, 51, 0.15);

  .search-bar {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    padding: 5px 0px;
    outline: none;
    background: transparent;
  }

  :hover {
    border: 1px solid ${theme.colors.blue};
    transition: 0.2s ease-in;
  }

  :focus-within {
    border: 1px solid ${theme.colors.blue};
    transition: 0.2s ease-in;
  }

  :focus {
    border: 1px solid ${theme.colors.blue};
  }
`;

export default SearchBar;
