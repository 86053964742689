import React from "react";
import styled from "styled-components/macro";

// component
import { Flex, Text } from "../Styles";
import TouchableButton from "./TouchableButton";

type Props = {
  icon: any;
  title?: string;
  onClick?: () => void;
};

const TertiaryButton = (props: Props) => {
  const { icon, title, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Clickable onClick={handleClick} alignItems="center">
      <TouchableButton
        icon={icon}
        alt="action"
        height={16}
        width={16}
        onClick={handleClick}
      />
      <Text variant="rg" fontWeight="700" color="blueDark">
        {title}
      </Text>
    </Clickable>
  );
};

const Clickable = styled(Flex)`
  cursor: pointer;
`;

export default TertiaryButton;
