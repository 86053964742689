import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// redux action
import {
  updateProductTransactionAsync,
  addProductTransactionAsync,
  deleteProductTransactionAsync,
} from "../../reducers/transaction/transaction.action";
import { getProductDetailAsync } from "../../reducers/catalog/catalog.action";

// component
import TopBar from "../../component/TopBar";
import {
  Box,
  Modal,
  Overlay,
  Wrapper,
  BodyPage,
  Flex,
  Text,
} from "../../component/Styles";
import { TouchableButton } from "../../component/Button";
import { Button } from "../../component/Button";
import TextInput from "../../component/TextInput";
import Select from "../../component/Select";

// type
import { States, Option } from "../../types/general";
import { TransactionProduct } from "../../types/product";
import { TransactionDetail } from "../../types/transaction";

// asset
import {
  AddIcon,
  AlertIcon,
  CloseIcon,
  DeleteIcon,
  SubstractIcon,
} from "../../asset/icon";

// utils
import { nominalFormat } from "../../utils/nominalFormat";
import { getURLPathID } from "../../utils/getURLPathID";
import getProductDetail from "../Invoice/utils/getProductDetail";

export type Props = {
  transactionDetail: TransactionDetail | null;
  product: TransactionProduct | null;
  onCloseModal: () => void;
};

const PurchaseDetail = (props: Props) => {
  const dispatch = useDispatch();

  const options = [
    { label: "Rp", value: "cash" },
    { label: "%", value: "percentage" },
  ];

  const { transactionDetail, product, onCloseModal } = props;

  const [qty, setQty] = useState<number | undefined>(1);
  const [selectedDiscType, setSelectedDiscType] = useState<Option>({
    label: "%",
    value: "percentage",
  });
  const [discAmount, setDiscAmount] = useState<number | undefined>(0);
  const [errorValidation, setErrorValidation] = useState({
    discount: "",
  });
  const [price, setPrice] = useState(0);

  // redux state
  const location = useSelector((state: States) => state.router.location);
  const isLoading = useSelector((state: States) => state.transaction.isLoading);
  const productDetail = useSelector(
    (state: States) => state.catalog.productDetail
  );

  const getTransactionID = useMemo(() => {
    const getID = getURLPathID(location.pathname);
    return getID;
  }, [location]);

  const handleDeleteProduct = () => {
    if (product?.cart_product_id) {
      dispatch(
        deleteProductTransactionAsync.request({
          transactionID: getTransactionID,
          productID: product.cart_product_id!,
        })
      );
    }
  };

  const renderDiscount = (
    discType: "percentage" | "cash",
    discAmount: number
  ) => {
    if (discType === "percentage") {
      return `${discAmount} %`;
    }

    return `Rp${discAmount}`;
  };

  const getDiscount = (
    discType: "percentage" | "cash",
    discAmount: number,
    total: number
  ) => {
    if (discType === "cash") {
      return discAmount;
    }

    const discountVal = discAmount * total * 0.01;

    return discountVal.toFixed(2);
  };

  const getTotal = useMemo(() => {
    const total = (qty || 0) * (price || 0);

    const disc = getDiscount(selectedDiscType.value, discAmount || 0, total);

    return total - +disc;
  }, [qty, selectedDiscType, discAmount, product]);

  const validation = () => {
    let isValid = true;
    const errVal = {
      discount: "",
    };

    if (selectedDiscType.value === "percentage") {
      if ((discAmount || 0) > 100) {
        isValid = false;
        errVal.discount = "Diskon hanya boleh 0–100%";
      }
    } else {
      if ((discAmount || 0) > (qty || 0) * (price || 0)) {
        isValid = false;
        errVal.discount = "Diskon maksimum sebesar harga barang.";
      }
    }

    setErrorValidation(errVal);

    return isValid;
  };

  const handleAddProduct = () => {
    if (!validation()) {
      return;
    }

    if (transactionDetail) {
      if (product?.cart_product_id) {
        dispatch(
          updateProductTransactionAsync.request({
            transactionID: getTransactionID,
            productID: product?.cart_product_id!,
            qty: qty || 0,
            discType: selectedDiscType.value,
            discAmount: discAmount || 0,
          })
        );
        return;
      }

      dispatch(
        addProductTransactionAsync.request({
          transactionID: getTransactionID,
          qty: qty || 0,
          productID: product?.product_id!,
          discType: selectedDiscType.value,
          discAmount: discAmount || 0,
        })
      );
    }
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton onClick={onCloseModal} alt="close" icon={CloseIcon} />
    );
  };

  const handleChangeQty = (q: string | number | undefined) => {
    if (!q && q !== 0) {
      setQty(undefined);
      return;
    }

    setQty(+q);
  };

  const handleChangeDiscAmount = (val: string | undefined) => {
    if (!val) {
      setDiscAmount(undefined);
      return;
    }

    setDiscAmount(+val);
  };

  useEffect(() => {
    dispatch(
      getProductDetailAsync.request({ productID: product?.product_id! })
    );
  }, []);

  useEffect(() => {
    if (product) {
      setQty(product.qty || 1);
      setDiscAmount(product.discount_amount || 0);
      setPrice(product.price || 0);

      const discType = options.find((o) => o.value === product.discount_type);
      if (discType) {
        setSelectedDiscType(discType!);
      }
    }

    if (productDetail && productDetail.id === product?.product_id) {
      setPrice(productDetail.price);
    }
  }, [product, transactionDetail, productDetail]);

  return (
    <Overlay isVisible>
      <Modal isVisible>
        <TopBar
          isModal
          title={`#${transactionDetail?.unique_id}`}
          leftHeader={renderLeftHeader}
        />
        <Wrapper p={2}>
          <BodyPage>
            <Flex>
              <Text variant="lg" rubik>
                <span className="bold">{product?.product_name}</span>,{" "}
                {product?.unit}
              </Text>
            </Flex>
            <Text variant="rg" mt="0">
              <span className="bold">Stok</span>&nbsp;{productDetail?.qty || 0}
              &nbsp;
              {product?.unit}
            </Text>
            <Flex alignItems="flex-end" flexDirection="column" mt={3}>
              <Flex>
                <Text variant="rg">
                  {qty} x Rp
                  {nominalFormat(price)}{" "}
                </Text>
                {discAmount ? (
                  <Text variant="rg" color="coral">
                    &nbsp; -{" "}
                    {renderDiscount(selectedDiscType.value, discAmount)}
                  </Text>
                ) : null}
              </Flex>

              <Text
                variant="lg"
                fontFamily="Rubik"
                color={getTotal < 0 ? "coral" : "null"}
              >
                {getTotal < 0
                  ? `-Rp${nominalFormat(Math.abs(getTotal))}`
                  : ` Rp${nominalFormat(getTotal)}`}
              </Text>
            </Flex>
            <Flex width="100%" flexDirection="column">
              <Text variant="rg">Kuantitas</Text>
              <Flex width="100%">
                <Button
                  icon={SubstractIcon}
                  secondary
                  onClick={() => handleChangeQty((qty || 0) - 1)}
                  disabled={!qty}
                />

                <Flex width="100%" mx={0}>
                  <TextInput
                    type="number"
                    textAlign="center"
                    fullWidth
                    allowNegative={false}
                    value={qty}
                    onChange={(qty) => handleChangeQty(qty)}
                  />
                </Flex>

                <Button
                  icon={AddIcon}
                  secondary
                  onClick={() => handleChangeQty((qty || 0) + 1)}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" mt={0}>
              <Text variant="rg">Diskon</Text>
              <Flex>
                <Box mr={0}>
                  <Select
                    width={70}
                    options={options}
                    selectedOption={selectedDiscType}
                    onSelect={setSelectedDiscType}
                  />
                </Box>
                <TextInput
                  textAlign="right"
                  type="number"
                  onChange={(val) => handleChangeDiscAmount(val)}
                  value={discAmount}
                  allowNegative={false}
                  decimalScale={3}
                  isError={!!errorValidation.discount}
                />
              </Flex>
            </Flex>
            {errorValidation.discount ? (
              <Flex mt={"0.25rem"} alignItems="center">
                <img src={AlertIcon} alt="alert" width="16" height="16" />
                <Text color="coral" variant="sm" ml="0">
                  {errorValidation.discount}
                </Text>
              </Flex>
            ) : null}

            <Flex
              borderTop="1px solid"
              borderColor="blackLighter"
              justifyContent="space-between"
              mt="3"
              py="1"
            >
              <Button
                secondary
                title="Hapus"
                onClick={handleDeleteProduct}
                disabled={isLoading}
              />
              <Button
                disabled={qty === 0}
                isLoading={isLoading}
                onClick={handleAddProduct}
                title={product?.cart_product_id ? "Simpan" : "Tambahkan"}
              />
            </Flex>
          </BodyPage>
        </Wrapper>
      </Modal>
    </Overlay>
  );
};

export default PurchaseDetail;
