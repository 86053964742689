import { delay, put, takeLeading } from 'redux-saga/effects';

// related redux
import * as actions from './toast.action';
import * as types from './toast.actionTypes';


function* showToast(action: ReturnType<typeof actions.showToast.request>) { 
    try {
       const { type, duration } = action.payload

       if(duration){
            yield delay(duration)   
            yield put(actions.showToast.success())
       }

    } catch (err) { 
        console.error(err)
    }
}


export default function* toastSaga() {
    yield takeLeading(types.SHOW_TOAST_REQUEST, showToast);
}
