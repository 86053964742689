import React from "react";
import styled from "styled-components/macro";

// component
import { Flex } from "../Styles";

// constant
import theme from "../../constant/styledTheme";

export type Props = {
  id: string;
  label?: string;
  maxLength?: number;
  onChange: (text: string) => void;
};

const TextBox = (props: Props) => {
  const { id, maxLength, label, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    onChange(text);
  };

  return (
    <ExtendFlex flexDirection="column" width="100%">
      <label htmlFor="text-box"> {label || "Alasan perubahan"}</label>
      <textarea
        className="text-box__text-box"
        id={id}
        maxLength={maxLength || 255}
        onChange={(e) => handleChange(e)}
      />
    </ExtendFlex>
  );
};

const ExtendFlex = styled(Flex)`
  .text-box__text-box {
    min-height: 92px;
    padding: 0.5rem;
    border: 1px solid ${theme.colors.blackLighter};
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px rgba(51, 51, 51, 0.15);
    border-radius: 4px;
    font-family: Nunito;

    :active,
    :focus {
      outline-color: ${theme.colors.blueLight};
    }
  }

  > label {
    font-size: 0.825rem;
    line-height: 1.5rem;
    font-family: Rubik;
  }
`;

export default TextBox;
