export const GET_TRANSACTION_HISTORY_REQUEST = '@history/GET_TRANSACTION_HISTORY_REQUEST';
export const GET_TRANSACTION_HISTORY_SUCCESS = '@history/GET_TRANSACTION_HISTORY_SUCCESS';
export const GET_TRANSACTION_HISTORY_FAILURE = '@history/GET_TRANSACTION_HISTORY_FAILURE';

export const GET_TRANSACTION_HISTORY_DETAIL_REQUEST = '@history/GET_TRANSACTION_HISTORY_DETAIL_REQUEST';
export const GET_TRANSACTION_HISTORY_DETAIL_SUCCESS = '@history/GET_TRANSACTION_HISTORY_DETAIL_SUCCESS'
export const GET_TRANSACTION_HISTORY_DETAIL_FAILURE = '@history/GET_TRANSACTION_HISTORY_DETAIL_FAILURE'

export const SET_SELECTED_DATE = '@history/SET_SELECTED_DATE'

export const SET_PERIOD_TYPE = '@history/SET_PERIOD_TYPE';

export const CLEAR_HISTORY_LIST = '@history/CLEAR_HISTORY_LIST'