import { createReducer, ActionType, action } from 'typesafe-actions';

// actions 
import * as actions from './user.action'
import * as types from './user.actionTypes'

// type
import { UserState } from './type'

export type UserAction = ActionType<typeof actions>;

const initialState: UserState = {
    username: "",
    name: "",
    email: "",
    password: "",
    token: "",
    isRegisterSuccess:false,
    isLogin: false,
    identifier: "",
    listCompany:[],
  };
  

const userReducer = createReducer<UserState, UserAction>(initialState) 
  .handleAction(types.USER_SUCCESS_LOGIN as any, (state) => ({
    ...state, 
    isLogin: true
  }))
  .handleAction(types.USER_LOGOUT as any, () => ({
    ...initialState
  }))
  .handleAction(types.USER_SUCCESS_REGISTER as any, (state) => ({
    ...state,
    isRegisterSuccess: true,
    isLogin : false
  }))
  .handleAction(types.USER_SUCCESS_REGISTER_COMPANY as any, (state) => ({
    ...state
  }))
  .handleAction(types.USER_SUCCESS_CHANGE_PASSWORD as any, (state) => ({
    ...state
  }))
  .handleAction(types.USER_CHANGE_INPUT_USER as any, (state, action) => ({
    ...state, 
    [action.payload.el.target.id]: action.payload.el.target.value
  }))
  .handleAction(types.USER_GET_COMPANY_LIST as any, (initialState, action) => ({
    ...initialState, 
    listCompany: action.payload.list
  }))

export { userReducer }
