import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      const profile = rest.profile;
      const validateOwner = rest.isOwner;
      let isOwner = false;

      if (
        !(localStorage.getItem("is_login") && localStorage.getItem("token"))
      ) {
        return (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        );
      }

      if (!localStorage.getItem("companyID") && rest.path !== "/company") {
        return (
          <Redirect
            to={{ pathname: "/company", state: { from: props.location } }}
          />
        );
      }

      if (
        localStorage.getItem("is_login") &&
        localStorage.getItem("token") &&
        localStorage.getItem("companyID") &&
        rest.path === "/signin"
      ) {
        return (
          <Redirect
            to={{ pathname: "/transaction", state: { from: props.location } }}
          />
        );
      }

      if (validateOwner) {
        isOwner = profile?.role === "owner";

        if (!isOwner) {
          return (
            <Redirect
              to={{
                pathname: "/page-not-found",
                state: { from: props.location },
              }}
            />
          );
        }
      }

      return localStorage.getItem("is_login") &&
        localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      );
    }}
  />
);
