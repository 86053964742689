import { put, takeLeading } from "redux-saga/effects";
import { push } from "connected-react-router";
import axios from "../../config/axios";

// redux action
import { handleLogout } from "../auth/auth.action";

// related redux
import * as actions from "./transaction.action";
import * as types from "./transaction.actionTypes";

// type
import localStorageService from "../../utils/localStorageService";

// constant
import { BASE_URL_BETA } from "../../constant/api";
import { showToast } from "../toast/toast.action";

// utils
import errorReporter from "../../utils/sentryErrorReporter";

function* getOpenTransactionList(
  action: ReturnType<typeof actions.getTransactionListAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction`;
    const response = yield axios.get(url);

    if (response && response.status === 200) {
      yield put(
        actions.getTransactionListAsync.success({
          data: response.data.transaction,
        })
      );
    }

    yield put(
      actions.getTransactionListAsync.failure({
        error: "failed get transaction list",
      })
    );
  } catch (err) {
    yield put(
      actions.getTransactionListAsync.failure({
        error: "failed get transaction list",
      })
    );

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.GET_TRANSACTIONS_REQUEST);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* getTransactionDetail(
  action: ReturnType<typeof actions.getTransactionDetailAsync.request>
) {
  try {
    const { transactionID } = action.payload;

    const companyID = localStorageService.getCompanyID();
    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction/${transactionID}`;

    const response = yield axios.get(url);

    if (response && response.status === 200) {
      yield put(
        actions.getTransactionDetailAsync.success({ data: response.data.data })
      );
    } else {
      yield put(
        actions.getTransactionListAsync.failure({ error: "Failed to get data" })
      );
    }
  } catch (err) {
    yield put(
      actions.getTransactionListAsync.failure({ error: "Failed to get data" })
    );

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(push("/transaction"));
    yield put(
      showToast.request({
        message: "Transaksi tidak ditemukan atau sesi transaksi telah selesai.",
        duration: 5000,
        type: "error",
      })
    );
    errorReporter(err, types.GET_TRANSACTION_DETAIL_REQUEST);
  }
}

function* updateProductTransaction(
  action: ReturnType<typeof actions.updateProductTransactionAsync.request>
) {
  try {
    const {
      productID,
      qty,
      transactionID,
      discAmount,
      discType,
    } = action.payload;

    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const payload = {
      qty: qty || 0,
      discount_amount: discAmount,
      discount_type: discType,
    };

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction-detail/${productID}`;
    const response = yield axios.put(url, payload);

    if (response && response.status === 201) {
      yield put(actions.updateProductTransactionAsync.success());
      yield put(actions.getTransactionDetailAsync.request({ transactionID }));
      yield put(actions.setShowTransactionModalDetail(false));
    }
  } catch (err) {
    yield put(
      actions.updateProductTransactionAsync.failure({
        error: "Failed to get data",
      })
    );

    if (err.response.status === 401) {
      yield put(
        showToast.request({
          message: "Sesi anda telah berakhir, silahkan login kembali",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(
      err,
      types.UPDATE_PRODUCT_TRANSACTION_REQUEST,
      action.payload
    );
    yield put(
      showToast.request({
        message: "Gagal memuat",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* addProductTransaction(
  action: ReturnType<typeof actions.addProductTransactionAsync.request>
) {
  try {
    const {
      transactionID,
      qty,
      productID,
      discAmount,
      discType,
    } = action.payload;

    if (!transactionID) {
      return;
    }

    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction/${transactionID}`;
    const payload = {
      qty,
      discount_amount: discAmount,
      discount_type: discType,
      product_id: +productID,
    };

    const response = yield axios.post(url, payload);

    if (response && response.status === 201) {
      yield put(actions.addProductTransactionAsync.success());
      yield put(actions.getTransactionDetailAsync.request({ transactionID }));
      yield put(actions.setShowTransactionModalDetail(false));
      yield put(
        showToast.request({
          message: "Berhasil menambahkan barang",
          duration: 5000,
          type: "success",
        })
      );
    }
  } catch (err) {
    yield put(
      actions.addProductTransactionAsync.failure({
        error: "Failed to add data",
      })
    );

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    yield put(
      showToast.request({
        message: "Gagal menambahkan barang",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* deleteProductTransaction(
  action: ReturnType<typeof actions.deleteProductTransactionAsync.request>
) {
  try {
    const { productID, transactionID } = action.payload;

    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction-detail/${productID}`;
    const response = yield axios.delete(url);

    if (response && response.status === 200) {
      yield put(actions.deleteProductTransactionAsync.success());
      yield put(actions.getTransactionDetailAsync.request({ transactionID }));
      yield put(actions.setShowTransactionModalDetail(false));
      yield put(
        showToast.request({
          message: "Barang berhasil dihapus",
          duration: 5000,
          type: "deleted",
        })
      );
    }
  } catch (err) {
    yield put(
      actions.deleteProductTransactionAsync.failure({
        error: "Failed to add data",
      })
    );

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(
      err,
      types.DELETE_PRODUCT_TRANSACTION_REQUEST,
      action.payload
    );

    yield put(
      showToast.request({
        message: "Barang gagal dihapus",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* createNewTransaction(
  action: ReturnType<typeof actions.createTransactionAsync.request>
) {
  try {
    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction`;

    const response = yield axios.post(url);

    if (response && response.status === 201) {
      yield put(actions.getTransactionListAsync.request());
      yield put(actions.createTransactionAsync.success());
      return;
    } else {
      yield put(
        actions.createTransactionAsync.failure({ error: "Failed to add data" })
      );
    }
  } catch (err) {
    yield put(
      actions.createTransactionAsync.failure({ error: "Failed to add data" })
    );

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }
    errorReporter(err, types.CREATE_TRANSACTION_REQUEST);

    yield put(
      showToast.request({
        message: "Gagal memuat",
        duration: 5000,
        type: "error",
      })
    );
  }
}

function* deleteTransaction(
  action: ReturnType<typeof actions.deleteTransactionAsync.request>
) {
  try {
    const { transactionID } = action.payload;

    const companyID = localStorageService.getCompanyID();

    if (!companyID) {
      throw new Error("Company ID not found");
    }

    const url = `${BASE_URL_BETA}/company/${companyID}/transaction/${transactionID}`;

    const response = yield axios.delete(url);

    if (response && response.status === 200) {
      yield put(actions.getTransactionListAsync.request());
      yield put(actions.deleteTransactionAsync.success());
      yield put(
        showToast.request({
          message: "Transaksi berhasil di hapus",
          duration: 5000,
          type: "deleted",
        })
      );
      yield put(push("/transaction"));
      return;
    }
  } catch (err) {
    yield put(
      actions.deleteTransactionAsync.failure({ error: "Failed to delete data" })
    );

    if (err.response.status === 451) {
      yield put(
        showToast.request({
          message: "Apotek anda tidak aktif, hubungi bantuan@farmacare.id",
          duration: 5000,
          type: "error",
        })
      );
      yield put(handleLogout());
      return;
    }

    errorReporter(
      err,
      types.DELETE_PRODUCT_TRANSACTION_REQUEST,
      action.payload
    );

    yield put(
      showToast.request({
        message: "Gagal menghapus transaksi",
        duration: 5000,
        type: "error",
      })
    );
  }
}

export default function* transactionSaga() {
  yield takeLeading(
    types.ADD_PRODUCT_TRANSACTION_REQUEST,
    addProductTransaction
  );
  yield takeLeading(types.CREATE_TRANSACTION_REQUEST, createNewTransaction);
  yield takeLeading(types.GET_TRANSACTIONS_REQUEST, getOpenTransactionList);
  yield takeLeading(types.GET_TRANSACTION_DETAIL_REQUEST, getTransactionDetail);
  yield takeLeading(
    types.UPDATE_PRODUCT_TRANSACTION_REQUEST,
    updateProductTransaction
  );
  yield takeLeading(
    types.DELETE_PRODUCT_TRANSACTION_REQUEST,
    deleteProductTransaction
  );
  yield takeLeading(types.DELETE_TRANSACTION_REQUEST, deleteTransaction);
}
