import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

// redux action
import {
  addPBFAsync,
  editPBFAsync,
  deletePBFAsync,
} from "../../reducers/pbf/pbf.action";

// asset
import { CloseIcon, DeleteIcon } from "../../asset/icon";

// component
import TopBar from "../../component/TopBar";
import TextInput from "../../component/TextInput";
import { Button, TouchableButton } from "../../component/Button";
import {
  Box,
  Body,
  Overlay,
  Modal,
  StickyBottomContainer,
} from "../../component/Styles";

// type
import { States } from "../../types/general";
import { PBF } from "../../types/pbf";

export type PBFEditModalProps = {
  /** Handle close modal */
  onCloseModal: () => void;
  /** GET - selected PBF detail */
  selectedPBF?: PBF | null;
  /** GET - PBF list */
  PBFList: PBF[];
};

const PBFEditModal = (props: PBFEditModalProps) => {
  const dispatch = useDispatch();

  const { onCloseModal, selectedPBF, PBFList } = props;

  const isLoading = useSelector((state: States) => state.pbf.isLoading);

  const [pbfName, setPbfName] = useState<string>("");
  const [validationError, setValidationError] = useState({
    pbfName: "",
  });

  const handleDeletePBF = () => {
    dispatch(deletePBFAsync.request({ PBFid: selectedPBF?.id! }));
  };

  const renderLeftHeader = () => {
    return (
      <TouchableButton
        icon={CloseIcon}
        alt="close"
        onClick={() => onCloseModal()}
      />
    );
  };

  const renderRightHeader = () => {
    if (!selectedPBF) {
      return;
    }

    return (
      <TouchableButton
        icon={DeleteIcon}
        alt="delete"
        onClick={() => handleDeletePBF()}
        withFeedback
      />
    );
  };

  const handleSave = () => {
    setValidationError({ pbfName: "" });

    const duplicateIndex = PBFList.findIndex(
      (p) => p.supplier_name === pbfName
    );

    if (duplicateIndex !== -1) {
      setValidationError({ pbfName: "Nama PBF sudah terdaftar" });
      return;
    }

    if (!pbfName) {
      setValidationError({ pbfName: "Nama PBF wajib diisi" });
      return;
    }

    if (!selectedPBF) {
      dispatch(addPBFAsync.request({ name: pbfName }));
      return;
    }

    // update selected pbf if already exist
    dispatch(editPBFAsync.request({ name: pbfName, PBFid: selectedPBF.id }));
  };

  useEffect(() => {
    if (selectedPBF) {
      setPbfName(selectedPBF.supplier_name);
    }
  }, [selectedPBF]);

  return (
    <Overlay isVisible={true}>
      <Modal isVisible={true}>
        <TopBar
          title={selectedPBF ? "Edit PBF" : "Tambah PBF"}
          isModal
          leftHeader={renderLeftHeader}
          rightHeader={renderRightHeader}
        />
        <BodyPage>
          <Box p={2}>
            <TextInput
              label="Nama PBF"
              value={pbfName}
              isError={!!validationError.pbfName}
              subtitle={validationError.pbfName}
              subtitleType="error"
              onChange={(text) => setPbfName(text)}
              autoFocus
            />
          </Box>
          <StickyBottomContainer
            position="absolute"
            px={2}
            py={1}
            width="calc(100% - 3rem)"
          >
            <Button
              title={selectedPBF ? "Simpan" : "Tambahkan"}
              fullWidth
              onClick={() => handleSave()}
              isLoading={isLoading}
              disabled={!pbfName}
            />
          </StickyBottomContainer>
        </BodyPage>
      </Modal>
    </Overlay>
  );
};

const BodyPage = styled(Body)`
  padding: 0px;
`;

export default PBFEditModal;
